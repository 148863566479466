export const errorMessages = {
  fillAllFieldsToContinue: 'Заполните все обязательные поля для продолжения',
  serverRequestError: `Не удалось обработать Ваш запрос`,
  needFixErrorToContinue: `Исправьте ошибки чтобы продолжить`,
  needFixErrorToUpdateSetting: `Исправьте ошибки чтобы обновить настройки`,
  chooseOperationFromList: `Выберите операцию из списка`,
  afterCreateCantBeEditOperation: `После создани операции не допускается изменение`,
  clientCreateError: `При создании клиента произошла ошибка`,
  clientUpdateError: `При обновлении клиента произошла ошибка`,
  operationCreateError: `При создании операции произошла ошибка`,
  operationUpdateError: `При обновлении операции произошла ошибка`,
  operationConfirmError: `При подтверждении операции произошла ошибка`,
  agentServiceHardwareError: 'Не удалось идентифицировать компьютер для работы',
  operatorHardwareOrganizationPointError: 'Данный компьютер не привязан к точке',
  hardwareEmpty: 'Не удалось опрeделить параметры компьютера',
  checkMTSAgent: 'Проверьте активность службы MTSAgentHelper',
  chooseBpa: 'Выберите БПА из списка',
  chooseRole: 'Выберите роль из списка',
  chooseKkt: 'Выберите ККТ из списка',
  choosePoint: 'Выберите точку из списка',
  chooseBpaAndPoint: 'Выберите БПА и точку из списка',
  chooseOperation: 'Выберите операцию из списка',
  chooseOperationType: 'Выберите тип операции из списка',
  chooseCheckToPrint: 'Выберите чеки из списка для начала печати',
  chooseOperator: 'Выберите сотрудника из списка',
  chooseForCreateCheck: 'Выберите для печати чеков',
  createOperatorFirst: 'Сначала создайте сотрудника',
  createPointFirst: 'Сначала создайте точку',
  createOrganizationFirst: 'Сначала создайте БПА',
  alreadyExist: 'Уже существует',
  alreadyConfirm: 'Операция уже подтверждена',
  waitForConfirm: 'Операция ожидает подтврждения',
  alreadyReject: 'Операция отклонена',
  settingsDidNotChange: 'Настройки не изменились',
  limitsDidNotChanged: 'Лимиты не изменились',
  limitTransferDayMinMaxError: 'Лимит на одну операцию не может превышать лимит на день',
  cantFindOperationType: 'Не удалось опеределить тип операции',
  billAlreadyExist: 'Чек уже был распечатан',
  rejectBillAlreadyExist: 'Чек отмены уже был распечатан',
  fillCommissionFields: 'Заполните обязательные поля для расчета комиссии',
  calculateCommission: 'Рассчитайте комиссию',
  fillCommissionError: 'Для расчета комиссии оставьте одно из полей пустым: Сумма внесения или Сумма выдачи',
  chooseFile: 'Выберите файл',
  notSupportedFile: 'Неподдерживаемый тип файла',
  ssoCheckError: 'Неподдерживаемый тип файла',
}

