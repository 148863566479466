import {IControlHelper, IStepsControlHelper} from "../models/common.models";

export function addOperationStepsFunctions(helper: IStepsControlHelper): IStepsControlHelper {
  helper.getControl = function(stepId: string, controlName: string, arrayId: string): IControlHelper {
    const stepIndex = helper.items.findIndex(x => x.id === stepId);
    if (stepIndex < 0) {
      return null;
    }
    let controlArrIndex = 0;
    if (arrayId) {
      controlArrIndex = helper.items[stepIndex].controlsArr.findIndex(x => x.arrayId === arrayId);
      if (controlArrIndex < 0) {
        return null;
      }
    }
    const controlIndex = helper.items[stepIndex].controlsArr[controlArrIndex].controls.findIndex(x => x.name === controlName);
    return controlIndex > -1 ? helper.items[stepIndex].controlsArr[controlArrIndex].controls[controlIndex] : null;
  }

  helper.getArrayIndex = function(arrayId: string): number {
    return helper.formArray.value.findIndex(
      x => x.arrayId === arrayId
    );
  }
  return helper;
}
