<div class="expand" [ngClass]="{'open': expanded}" id="operationCryptoKeySettings">
  <app-operation-crypto-keys-model (modalClosed)="modalClosed($event)"
                   #addOperationCryptoKey
  ></app-operation-crypto-keys-model>

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКА КЛЮЧЕЙ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-filter-users-proposals download-title" (click)="filterExpand = !filterExpand">Фильтры</p>
    <div class="expand-table-container">
      <div *ngIf="searchForm">

        <div class="filter-wrapper">
          <div class="filter-left-wrapper" [ngClass]="{'open': filterExpand}">
            
            <div class="filter-left-column-item">
              <div class="filter-left-row-item search">
                <app-input
                  inputType="search"
                  placeholder="Поиск"
                  name="Search"
                  width="full"
                  wrapperClass="inputBox-group"
                  [parentForm]="searchForm"></app-input>
              </div>
            </div>
            <span class="download-title" (click)="resetAll()"
               *ngIf="isNeedFilterReset">Сбросить фильтр</span>
          </div>
          <div class="filter-right-wrapper">
            <button class="button main"
                    (click)="addOperationCryptoKey.toggleModal(null)">
              СОЗДАТЬ
            </button>
          </div>
        </div>
      </div>

      <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!operationCryptoKeyResponse && operationCryptoKeyResponse.cryptoInfos.length > 0 else emptyList">
        <table class="table w-100">
          <thead class="table-header">
          <th>НАЗВАНИЕ</th>
          <th>СОДЕРЖИМОЕ</th>
          
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let operationCryptoKey of operationCryptoKeyResponse.cryptoInfos; let i=index"
              class="table-row" (click)="!isLoad && addOperationCryptoKey.toggleModal(operationCryptoKey)">
            <td class="td-role">{{operationCryptoKey.name}}</td>
            <td class="td-role">{{operationCryptoKey.publicKey}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <app-paginator #paginatorComp
        [page]="paginator.page"
        [total]="operationCryptoKeyResponse?.totalCount"
        [take]="paginator.take"
        [id]="paginatorId"
        (pageChange)="pageChanged($event)"
      ></app-paginator>
      <ng-template #emptyList>
        <div class="empty-list">
          <p>{{
            isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одного ключа...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
