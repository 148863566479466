import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Guid} from "guid-typescript";
import {
  ApiClient,
  HardwareProposalFilterEnum,
  HardwareProposalOrganizationPointFilterRequest,
  IHardwareProposalOrganizationPointFilterRequest,
  IHardwareProposalOrganizationPointResponse,
  IPaginatorRequest,
  IUserFilterResponse,
  PaginatorRequest,
  UserAvailableDataRequest
} from "../../../../../kernel/ApiClient";
import {FormControl, FormGroup} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {Select, Store} from "@ngxs/store";
import {createPaginator} from "../../../../../kernel/helpers/paginator.helper";
import {debounceTime, map, take, takeUntil} from "rxjs/operators";
import {PaginatorComponent} from "../../../../core/pages/paginator/paginator.component";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {UserAvailableDataEnum} from "../../../../../kernel/enum/user-available-data";
import {getSelectedLabelForNewAvailable} from "../../../../../kernel/helpers/data.helper";
import {IDataSelectedHelper} from "../../../../../kernel/models/common.models";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-hardware-proposals',
  templateUrl: './hardware-proposals.component.html',
  styleUrls: ['./hardware-proposals.component.scss', '../../settings/settings.component.scss']
})
export class HardwareProposalsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @ViewChild('paginatorComp') paginatorComp: PaginatorComponent;
  @Input() id: string

  availableOrganizations: IDataSelectedHelper[] = [];
  availableOrganizationPoints: IDataSelectedHelper[] = [];

  subs = new Subscription();
  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;
  requestSub: any;

  proposalResponse: IHardwareProposalOrganizationPointResponse;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;
  isDesc = true;

  columns = HardwareProposalFilterEnum;
  sortField = HardwareProposalFilterEnum.Created;

  searchForm: FormGroup;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get isNeedFilterReset(): boolean {
    return this.searchForm && (
      !!this.searchForm.value.Search ||
      !this.searchForm.value.IsNew ||
      this.searchForm.value.OrganizationId ||
      this.searchForm.value.OrganizationPointId
    )
  }

  get organizationId(): string {
    const index = this.availableOrganizations.findIndex(x => x.label === this.searchForm.value.OrganizationId);
    return index > -1 ? this.availableOrganizations[index].id : null;
  }
  get organizationPointId(): string {
    const index = this.availableOrganizationPoints.findIndex(x => x.label === this.searchForm.value.OrganizationPointId);
    return index > -1 ? this.availableOrganizationPoints[index].id : null;
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
    }));
    this.paginator = createPaginator(5);
    this.createFormControls();
    this.getFilteredProposals();
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    this.searchForm = new FormGroup({
      Search: new FormControl(null),
      IsNew: new FormControl(true),
      OrganizationId: new FormControl(null),
      OrganizationPointId: new FormControl(null),
    });

    this.searchForm.controls.OrganizationPointId.disable();

    this.searchForm.controls.OrganizationId.valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.OrganizationPoint,
            organizationId: this.organizationId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableOrganizationPoints = arr as IDataSelectedHelper[];
              this.searchForm.controls.OrganizationPointId.enable();
              this.searchForm.controls.OrganizationPointId.setValue(getSelectedLabelForNewAvailable(this.searchForm.value.OrganizationPointId, this.availableOrganizationPoints));
            });
        } else {
          this.availableOrganizationPoints = [];
          this.searchForm.controls.OrganizationPointId.disable();
        }
      });
  }

  resetAll(): void {
    this.isDesc = true;
    this.sortField = HardwareProposalFilterEnum.Created;
    this.paginator.page = 1;
    this.searchForm.controls.Search.setValue(null);
    this.searchForm.controls.IsNew.setValue(true);
    this.searchForm.controls.OrganizationId.setValue(null);
    this.searchForm.controls.OrganizationPointId.setValue(null);
    this.searchForm.controls.OrganizationPointId.disable();
    this.getFilteredProposals();
  }

  ngOnDestroy(): void {
    this.checkRequestSub();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  valueChangeListener(): void {
    this.searchForm.valueChanges
      .pipe(
        map(data => ({
          search: data.Search,
          isNew: data.IsNew
        })),
        takeUntil(this.ngUnsubscribe),
        debounceTime(500))
      .subscribe(data => {
        this.getFilteredProposals();
      })
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  setSort(type: HardwareProposalFilterEnum): void {
    this.isDesc = this.sortField !== type ? false : !this.isDesc;
    this.sortField = type;
    this.getFilteredProposals();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
      this.getFilteredProposals();
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  modalClosed(event: any) {
    if (!!event) {
      this.getFilteredProposals();
    }
  }

  getFilteredProposals(): void {
    this.checkRequestSub()
    const payload: IHardwareProposalOrganizationPointFilterRequest = {
      paginator: this.paginator as PaginatorRequest,
      query: this.searchForm.value.Search,
      isNew: this.searchForm.value.IsNew,
      organizationId: this.organizationId,
      organizationPointId: this.organizationPointId,
      isDesc: this.isDesc,
      column: HardwareProposalFilterEnum.Created
    };
    this.requestSub = this.apiClient.hardware_GetFilteredProposal(payload as HardwareProposalOrganizationPointFilterRequest).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.proposalResponse = data as IUserFilterResponse;
        this.paginatorComp.initType(this.proposalResponse?.totalCount ?? 0);
      }, error => {
        this.notify.error(error.message)
        console.error(error)
      });
    if (this.valueChangeFirst) {
      this.valueChangeFirst = false
      this.valueChangeListener()
    }
  }

  proposalChanged(): void {
    this.getFilteredProposals();
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredProposals();
  }
}
