import {Injectable} from '@angular/core';
import {
  IClientDocument, ICommissionApiHelper, ICommissionHelper,
  IControlFillHelper,
  IControlHelper, IOperationDataArrayItemHelper,
  IOperationDataHelper,
  IOperationDataItemHelper, IOperationStatusHelper,
  IStepsControlHelper
} from "../../../kernel/models/common.models";
import {
  convertToSimpleTypeObject,
  getSelectId,
  getValueById,
  getValueFromModel,
  isDatepickerInput,
  isPhoneInput,
  setFormGroupEnabledOrDisabled
} from "../../../kernel/helpers/data.helper";
import {OperationVariableService} from "./operation-variable.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {OperationLoaderComponent} from "../pages/operation-loader/operation-loader.component";
import {takeUntil} from "rxjs/operators";
import {ClientsListComponent} from "../dialogs/clients-list/clients-list.component";
import {BillingTypeEnum, ICommission, IFee, ITransfer} from "../../../kernel/ApiClient";
import {OperationClientStepComponent} from "../dialogs/operation-client-step/operation-client-step.component";
import {OperationArrayService} from "./operation-array.service";
import {OperationCommissionListComponent} from "../dialogs/operation-commission-list/operation-commission-list.component";
import {OperationHelperControlService} from "./operation-helper-control.service";
import {BehaviorService} from "../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {getOperationStatus} from "../../../kernel/helpers/operation.helper";
import {
  errorFinalStatuses,
  notFinalStatuses,
  readyForConfirmStatuses
} from "../../../kernel/enum/operation-status.enum";
import {DecimalPipe, Location} from "@angular/common";
import {messages} from "../../../kernel/constants/messages";
import {NotifyService} from "../../core/services/notify.service";
import {Store} from "@ngxs/store";
import {HomeRedirect} from "../../../kernel/store/actions/user.actions";
import {errorMessages} from "../../../kernel/constants/errors";
import {mockMTDynamicResponse} from "../../../kernel/mt-dynamic/services/mt-dynamic.service.spec";
import {MtDynamicService} from "../../../kernel/mt-dynamic/services/mt-dynamic.service";

@Injectable({
  providedIn: 'root',
})
export class OperationHelperService {
  constructor(
    private dialog: MatDialog,
    private variables: OperationVariableService,
    private array: OperationArrayService,
    private helperControl: OperationHelperControlService,
    private behavior: BehaviorService,
    private decimalPipe: DecimalPipe,
    private notify: NotifyService,
    private location: Location,
    private store: Store,
    private mtDynamic: MtDynamicService,
  ) {
  }

  createInfo(): void {
    this.variables.info = [];

    const result = this.mtDynamic.mapInfoData();
    let arr: IOperationDataItemHelper[] = [];

    result.map(x => {
      arr.push({
        title: x.key,
        value: x.value,
        isUsual: true,
      });
    });

    this.variables.info.push({
      title: 'Информация об операции',
      array: [ { items: arr } ]
    });

    this.variables.operationInfoArray.map((operation, operationIndex) => {
      this.createInfoArrayBySteps(operation, operationIndex);
    });
    if (this.variables.isClientNeed) {
      this.createInfoArrayBySteps(this.variables.clientHelper);
    }
  }

  createInfoArrayBySteps(data: IStepsControlHelper, operationIndex = -1): void {
    data.items.map((item, itemIndex) => {
      let temp: IOperationDataHelper = {
        id: item.id,
        title: item.title,
        array: []
      };
      item.controlsArr.map((controlArr, controlArrIndex) => {
        const items: IOperationDataItemHelper[] = [];
        controlArr.controls.map((control, controlIndex) => {
          if (!control.isHide && control.name !== `isConsider` && !control.isCash &&
            (
              item.isFormGroup ?
                !!data.formGroup.get(`${item.id}_${control.name}`) :
                !!data.formArray.controls[data.getArrayIndex(controlArr.arrayId)].get(`${item.id}_${control.name}`)
            )) {
            items.push(
              this.getOperationDataItem(control,
                item.isFormGroup ?
                  data.formGroup.value[`${item.id}_${control.name}`] :
                  data.formArray.value[data.getArrayIndex(controlArr.arrayId)][`${item.id}_${control.name}`]
              )
            );
          }
        });
        if (items?.length > 0) {
          temp.array.push({items});
        }
      });
      if (!!temp && !!temp.array && temp.array.length > 0) {
        this.variables.info.push(temp);
      }
    });
  }

  getOperationDataItem(control: IControlHelper, value: any): IOperationDataItemHelper {
    let temp =  {
      title: control.label,
      value,
      isDate: isDatepickerInput(control.inputType),
      isCash: control.isCash,
      isPhone: isPhoneInput(control.inputType),
      isYesOrNo: control.isCheckbox,
      isUsual: false,
      isCard: control.isCard
    } as IOperationDataItemHelper;
    temp.isUsual = !temp.isDate && !temp.isCash && !temp.isPhone && !temp.isYesOrNo && !temp.isCard;
    if (!control.isDefaultInfoArray) {
      return temp;
    }
    temp.isUsual = true;
    temp.isDate = false;
    temp.isCash = false;
    temp.isPhone = false;
    temp.isYesOrNo = false;
    temp.isCard = false;
    return temp;
  }

  clientAndOperationCreatedDisableForms(): void {
    if (!this.variables.canGoToOnlyConfirm) {
      return;
    }
    this.variables.clientHelper.formGroup = setFormGroupEnabledOrDisabled(this.variables.clientHelper.formGroup, [], true);
    this.variables.clientHelper.formArray.controls.map(x => {
      x = setFormGroupEnabledOrDisabled(x as FormGroup, [], true);
    });
    this.variables.operationInfoArray.map(operation => {
      operation.formGroup = setFormGroupEnabledOrDisabled(operation.formGroup, [], true);
      operation.formArray.controls.map(x => {
        x = setFormGroupEnabledOrDisabled(x as FormGroup, [], true);
      });
    });
    this.variables.operationHelperGroup.get(`OperationClientDocumentType`).disable();
    this.variables.operationHelperGroup.get(`OperationClientDocumentSeries`).disable();
    this.variables.operationHelperGroup.get(`OperationClientDocumentNumber`).disable();
    this.variables.operationHelperGroup.get(`DataCorrect`).disable();
  }

  startLoader(): void {
    if (this.variables.loaderIsActive || !this.variables.isReady) {
      return;
    }
    this.variables.loaderIsActive = true;
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true;
    // dialogConfig.data = {
    //   operations: this.variables.operations
    // };
    this.variables.loaderSub = this.dialog.open(OperationLoaderComponent, dialogConfig)
    this.variables.loaderSub.afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.resetAllForNewOperation(!!data);
        this.variables.loaderIsActive = false;
      })
  }

  openClientChooseDialog(isFromHistory: boolean): void {
    if (this.variables.clientListActive || !this.variables.isReady) {
      return;
    }
    this.variables.clientListActive = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      client: this.variables.client,
      history: this.variables.history,
      document: {
        type: getSelectId(
          this.variables.operationHelperGroup.value.OperationClientDocumentType,
          this.variables.selectedDataAll.documents,
          false
        ),
        typeRu: this.variables.operationHelperGroup.value.OperationClientDocumentType,
        series: this.variables.operationHelperGroup.value.OperationClientDocumentSeries,
        number: this.variables.operationHelperGroup.value.OperationClientDocumentNumber,
      } as IClientDocument,
      isFromHistory
    };
    this.variables.clientListSub = this.dialog.open(ClientsListComponent, dialogConfig);
    this.variables.clientListSub
      .afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data: {
          operation: ITransfer;
          empty: boolean;
          isConfirm: boolean;
        }) => {
          this.variables.clientListActive = false;
          if (data) {
            if (!data.isConfirm) {
              this.variables.client = null;
              this.clientChanged();
            }
            if (data.operation) {
              this.variables.transferForDynamicData = data.operation;
              this.transferChanged(data.operation);
              this.mtDynamic.refillWithHistory();
            }
          }
        });
  }

  transferChanged(operation: ITransfer): void {
    if (!operation) {
      return;
    }
    let arr: IControlFillHelper[] = [
      // { value: operation.beneficiary.lastName, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `receiverInfo_lastName`}},
      // { value: operation.beneficiary.firstName, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `receiverInfo_firstName`}},
      // { value: operation.beneficiary.middleName, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `receiverInfo_middleName`}},
      { value: operation.countryCode, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `mainInfo_countryCode`}},
      { value: operation.money.acceptedMoney.currencyCode, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `mainInfo_acceptedCurrency`}},
      { value: operation.money.withdrawMoney.currencyCode, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `mainInfo_withdrawCurrency`}},
      { value: operation.money.withdrawMoney.amount, step: { arrayId: null, operationIndex: this.variables.operationIndex, fullName: `mainInfo_withdrawAmount`}},
    ];
    // this.variables.operationInfoArray[this.variables.operationIndex].formArray = new FormArray([]);
    // this.variables.operationInfoArray[this.variables.operationIndex].items[2].controlsArr = [];
    /* if (!!operation.beneficiary.phoneNumbers) {
      operation.beneficiary.phoneNumbers.map((x) => {
        this.array.addFormArrayItem(this.variables.operationInfoArray[this.variables.operationIndex].items[2].id, x, this.variables.operationIndex);
      });
    } else {
      this.array.addFormArrayItem(this.variables.operationInfoArray[this.variables.operationIndex].items[2].id, null, this.variables.operationIndex);
    } */
    arr.map(x => {
      let step = this.helperControl.getControlHelper(x.step.fullName, x.step.arrayId, this.variables.operationIndex);
      if (!!step) {
        this.variables.operationInfoArray[this.variables.operationIndex]
          .formGroup.get(x.step.fullName)
          .setValue(getValueFromModel(x.value, this.helperControl.getControlByStep(step, this.variables.operationInfoArray, this.variables.clientHelper)));
      }
    });
  }

  clientChanged(): void {
    this.variables.clientHelper.isCreated = !!this.variables.client;
    this.variables.clientHelper.isUpdated = false;
    if (!this.variables.client) {
      this.variables.clientHelper.items.map(step => {
        if (step.isFormGroup) {
          step.controlsArr[0].controls.map(control => {
            this.variables.clientHelper.formGroup.get(`${step.id}_${control.name}`).setValue(control.value);
          });
        }
      });
      this.variables.clientHelper.formArray = new FormArray([]);
      setTimeout(() => {
        this.variables.clientHelper.items[1].controlsArr = [];
        this.variables.clientHelper.items[2].controlsArr = [];
        this.array.addFormArrayItem(this.variables.clientHelper.items[1].id);
        this.array.addFormArrayItem(this.variables.clientHelper.items[2].id);
      }, 0);
      return;
    }
    let arr: IControlFillHelper[] = [
      { value: this.variables.client.clientId, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_clientId`}},
      { value: this.variables.client.lastName, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_lastName`}},
      { value: this.variables.client.firstName, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_firstName`}},
      { value: this.variables.client.middleName, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_middleName`}},
      { value: this.variables.client.countryOfResidence, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_countryOfResidence`}},
      { value: this.variables.client.citizenship, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_citizenship`}},
      { value: this.variables.client.gender, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_gender`}},
      { value: this.variables.client.birthDate, step: { arrayId: null, operationIndex: -1, fullName: `mainInfo_birthDate`}},
    ];
    if (!!this.variables.client.addresses) {
      Object.keys(this.variables.client.addresses).forEach(name => {
        if (!!this.variables.client.addresses[name]) {
          Object.keys(this.variables.client.addresses[name]).forEach(field => {
            arr.push({
              value: this.variables.client.addresses[name][field],
              step: {
                arrayId: null,
                operationIndex: -1,
                fullName: `${name}_${field}`
              }
            });
          });
        }
      });
    }
    this.variables.clientHelper.formArray = new FormArray([]);
    this.variables.clientHelper.items[1].controlsArr = [];
    this.variables.clientHelper.items[2].controlsArr = [];
    if (!!this.variables.client.phoneNumbers) {
      this.variables.client.phoneNumbers.map((x) => {
        this.array.addFormArrayItem(this.variables.clientHelper.items[1].id, x);
      });
    } else {
      this.array.addFormArrayItem(this.variables.clientHelper.items[1].id);
    }
    if (!!this.variables.client.documents){
      this.variables.client.documents.map((x) => {
        let model = {};
        model = convertToSimpleTypeObject(x, model);
        this.array.addFormArrayItem(this.variables.clientHelper.items[2].id, model);
      });
    } else {
      this.array.addFormArrayItem(this.variables.clientHelper.items[2].id);
    }
    arr.map(x => {
      let step = this.helperControl.getControlHelper(x.step.fullName, null, x.step.operationIndex);
      if (!!step) {
        this.variables.clientHelper
          .formGroup.get(x.step.fullName)
          .setValue(getValueFromModel(x.value, this.helperControl.getControlByStep(step, this.variables.operationInfoArray, this.variables.clientHelper)));
      }
    });
  }

  createAnotherClient(): void {
    this.variables.client = null;
    this.clientChanged();
    this.openClientEditDialog();
  }

  openClientEditDialog(): void {
    if (this.variables.currentStep !== 1 || this.variables.clientDialogFirstOpen || !this.variables.isReady) {
      return;
    }
    this.variables.clientDialogFirstOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      clientHelper: this.variables.clientHelper,
      selectedDataAll: this.variables.selectedDataAll,
      searchNumber: !this.variables.client ? this.variables.operationHelperGroup.get('OperationClientDocumentNumber').value : null,
    };
    this.variables.clientDialogFirstSub = this.dialog.open(OperationClientStepComponent, dialogConfig);
    this.variables.clientDialogFirstSub
      .afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.variables.clientHelper = data;
        }
        this.variables.clientDialogFirstOpen = false;
        }
      );
  }

  openCommissionListDialog(data: ICommissionApiHelper[], operationIndex: number): void {
    if (!this.variables.isReady) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = data;
    if (this.variables.commissionDialogSub) {
      this.variables.commissionDialogSub.close(null);
    }
    this.variables.commissionDialogSub = this.dialog.open(OperationCommissionListComponent, dialogConfig);
    this.variables.commissionDialogSub
      .afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data: { paymentSystemId: string, orgCom: number }) => {
          if (data) {
            if (this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_commission`)) {
              this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_commission`).setValue(data.orgCom);
            }
            const cords = this.variables.operationInfoArray[this.variables.operationIndex].paymentCord
              .cords;
            const value =
              getValueById(
                this.variables.operationInfoArray[this.variables.operationIndex]
                  .items[cords[0]]
                  .controlsArr[cords[1]]
                  .controls[cords[2]]
                  .selectData,
                data.paymentSystemId);
            this.variables.operationInfoArray[this.variables.operationIndex].formGroup
              .get('mainInfo_paymentSystemId').setValue(value);
          }
        }
      );
  }

  fillOperationConfirmForLoader(): void {
    this.variables.confirmDialogType = 0;
    this.variables.confirmDialogTitle = `Подтверждение операции`;
  }

  fillOperationsForConfirmLoader(): void {
    let type = 1;
    let arr: IOperationStatusHelper[] = [];
    if ((this.variables.isTransfer && (!this.variables.operations || this.variables.operations.length === 0)) ||
      (!this.variables.isTransfer && (!this.variables.paymentHubOperations || this.variables.paymentHubOperations.length === 0))) {
      this.fillOperationConfirmForLoader();
      this.variables.confirmDialogInfo = arr;
      return;
    }
    let dialogMessage = '';
    if (this.variables.isTransfer) {
      this.variables.operations.map(operation => {
        let temp = getOperationStatus(operation.transferStatus);
        dialogMessage = temp.hint;
        temp.transferNum = operation.transferNum;
        temp.sum = `${this.decimalPipe.transform(operation.money.acceptedMoney.amount, '1.2','ru')} ${operation.money.acceptedMoney.currencyCode}`;
        arr.push(temp);
        if (errorFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = -1;
        }
        if (notFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = 0;
        }
        if (readyForConfirmStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = 2;
        }
      });
    } else {
      this.variables.paymentHubOperations.map(operation => {
        let temp = getOperationStatus(operation.transferStatus);
        dialogMessage = temp.hint;
        temp.transferNum = operation.transferNum;
        temp.sum = `${this.decimalPipe.transform(operation.acceptedMoney, '1.2','ru')} ${operation.acceptedMoneyCurrencyCode}`;
        arr.push(temp);
        if (errorFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = -1;
        }
        if (notFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = 0;
        }
        if (readyForConfirmStatuses.findIndex(x => x === operation.transferStatus) > -1 && type === 1) {
          type = 2;
        }
      });
    }
    this.variables.confirmDialogInfo = arr;
    if (type === 0) {
      this.fillOperationConfirmForLoader();
      return;
    }
    this.behavior.popupLoaderStatus.next(null);
    this.variables.confirmDialogType = type;
    this.variables.confirmDialogMessage = dialogMessage;
    switch (type){
      case 1:
        this.variables.confirmDialogTitle = 'Операция успешно подтверждена!';
        this.variables.confirmDialogImgSrc = `/assets/img/icons/check-green.svg`;
        this.notify.success(this.variables.currentStep === 2 ? messages.operationCreated : messages.operationConfirm);
        // this.behavior.operationBuilderCommandStatus.next({
        //   type: BuilderCommandEnum.CheckCashboxCountBeforeBilling,
        //   model: { payload: {
        //       numDevice: 0,
        //       type: BillingTypeEnum.SecondCheck,
        //       isPreview: true,
        //       isFromQueue: false,
        //       operationId: this.variables.isTransfer ? this.variables.operations[0].transferId : this.variables.paymentHubOperations[0].transferId
        //     }}
        // });
        break;
      case -1:
        this.variables.confirmDialogTitle = 'При подтверждении операции возникли ошибки';
        this.variables.confirmDialogImgSrc = `/assets/img/icons/error-ic.svg`;
        break;
      case 2:
        this.variables.confirmDialogTitle = 'Операция принята в обработку!';
        this.variables.confirmDialogImgSrc = `/assets/img/icons/check-green.svg`;
        this.behavior.operationBuilderCommandStatus.next({
          type: BuilderCommandEnum.CheckCashboxCountBeforeBilling,
          model: { payload: {
              numDevice: 0,
              type: BillingTypeEnum.SecondCheck,
              isPreview: true,
              isFromQueue: false,
              operationId: this.variables.isTransfer ? this.variables.operations[0].transferId : this.variables.paymentHubOperations[0].transferId
            }}
        });
        break;
    }
    this.variables.confirmDialogInfo = arr;
  }

  resetAllForNewOperation(event: boolean): void {
    if (!this.location.path().includes('/operation/')) {
      return;
    }
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.DefaultState,
      model: {
        editOperationId: this.variables.editOperationId, operationType: this.variables.operationType
      }
    });
  }

  rewriteLocation(): void {
    if (!this.variables.isReady) {
      return;
    }
    this.location.replaceState(`operation/${this.variables.operationType}/${this.variables.currentStep}${this.variables.editOperationId ? '/' + this.variables.editOperationId : ''}`);
  }
}
