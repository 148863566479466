import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {
  ApiClient,
  EntityTypeEnum,
  EntitySettingKeyStringEnum,
  IOrganizationPointAddOrUpdateRequest,
  IOrganizationPointResponse,
  IUpdateEntitySettingsRequest,
  IUserResponse,
  OrganizationPointAddOrUpdateRequest,
  UpdateEntitySettingItemRequest,
  UpdateEntitySettingsRequest,
  IUpdateLimitsRequest, LimitTypeEnum, UpdateLimitItemRequest, UpdateLimitsRequest, LimitValueHelper, 
  IHardwareOrganizationPointsResponse, IHardwareOrganizationPointsItemResponse, 
  IHardwareOrganizationPointDeleteRequest, HardwareOrganizationPointDeleteRequest
} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorService} from "../../../core/services/behavior.service";
import {NotifyService} from "../../../core/services/notify.service";
import {
  controlValueToWorkHours,
  getErrorMessage,
  getSelectId,
  isExpectedError,
  mapInvalidFields,
  workHoursToControlValue
} from "../../../../kernel/helpers/data.helper";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../kernel/constants/messages";
import {errorMessages} from "../../../../kernel/constants/errors";
import {fadeIn, fadeInOut, fadeInOutStagged} from "../../../../kernel/animation";
import {GetAvailableData} from "../../../../kernel/store/actions/dictionary.actions";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../kernel/models/common.models";
import {validateTime} from "../../../../kernel/validators";

@Component({
  selector: 'app-organization-points-modal',
  templateUrl: './organization-points-modal.component.html',
  styleUrls: ['./organization-points-modal.component.scss'],
  animations: [fadeInOutStagged, fadeInOut, fadeIn]
})
export class OrganizationPointsModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  subs = new Subscription();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getTimeZones) availableTimeZones$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  visible = false;

  availableOrganizations: IDataSelectedHelper[];
  availableTimeZones: IDataSelectedHelper[];
  organizationPoint: IOrganizationPointResponse;
  hardwareOrganizationPoints: IHardwareOrganizationPointsResponse;

  organizationPointForm: FormGroup;

  submitted = false;
  submittedSettings = false;
  submittedLimits = false;

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: [{title: 'Основные данные', index: 1}, {title: 'Настройки', index: 2}, {title: 'Лимиты', index: 3}, {title: 'Привязки', index: 4}]
  };

  constructor(
    private behavior: BehaviorService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  get mscTimeZone(): string {
    const index = this.availableTimeZones.findIndex(x => x.id === '58' || x.label.includes('Москва'));
    return index > -1 ? this.availableTimeZones[index].label : (this.availableTimeZones?.length > 0 ? this.availableTimeZones[0].label : null);
  }


  ngOnInit() {
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
    }));
    this.subs.add(this.availableTimeZones$.subscribe(availableTimeZones => {
      this.availableTimeZones = availableTimeZones;
    }));    
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControl(): void {
    if (this.organizationPointForm) {
      return
    }

    this.organizationPointForm = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      PlaceAddress: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      LegalAddress: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      IsBlocked: new FormControl(false),
      IsFranchise: new FormControl(false),
      TimeZoneId: new FormControl(this.mscTimeZone, [Validators.required]),
      PointOfSalesId: new FormControl(null, [Validators.required]),
      TerminalId: new FormControl(null),
      PointCode: new FormControl(null),
      OrganizationId: new FormControl(null, [Validators.required]),
      Phone: new FormControl(''),
      Email: new FormControl(null),
      WorkStart: new FormControl(null, [validateTime()]),
      WorkEnd: new FormControl(null, [validateTime()]),
      LimitForTransfer: new FormControl(null, [Validators.min(0)]),
      LimitForDay: new FormControl(null, [Validators.min(0)]),
      LimitForMonth: new FormControl(null, [Validators.min(0)]),
    });
  }

  checkFormControls(): void {
    if (this.organizationPoint) {
      this.organizationPointForm.controls.Name.setValue(this.organizationPoint.name);
      this.organizationPointForm.controls.PlaceAddress.setValue(this.organizationPoint.placeAddress);
      this.organizationPointForm.controls.LegalAddress.setValue(this.organizationPoint.legalAddress);
      this.organizationPointForm.controls.IsBlocked.setValue(this.organizationPoint.isBlocked);
      this.organizationPointForm.controls.IsFranchise.setValue(this.organizationPoint.isFranchise);
      this.organizationPointForm.controls.PointOfSalesId.setValue(this.organizationPoint.pointOfSalesId);
      this.organizationPointForm.controls.Phone.setValue(this.organizationPoint.phone);
      this.organizationPointForm.controls.Email.setValue(this.organizationPoint.email);
      this.organizationPointForm.controls.TerminalId.setValue(this.organizationPoint.terminalId);
      this.organizationPointForm.controls.PointCode.setValue(this.organizationPoint.pointCode);
      const timeZone = this.availableTimeZones.find(x => String(x.id) === String(this.organizationPoint.timeZoneId));
      this.organizationPointForm.controls.TimeZoneId.setValue(timeZone?.label ?? null);
      const organization = this.availableOrganizations.find(x => String(x.id) === String(this.organizationPoint.organizationId));
      this.organizationPointForm.controls.OrganizationId.setValue(organization?.label ?? null);
    } else {
      this.resetFormControls();
    }
  }

  resetFormControls(): void {
    this.organizationPointForm.controls.Name.setValue(null);
    this.organizationPointForm.controls.PlaceAddress.setValue(null);
    this.organizationPointForm.controls.LegalAddress.setValue(null);
    this.organizationPointForm.controls.PointOfSalesId.setValue(null);
    this.organizationPointForm.controls.IsBlocked.setValue(false);
    this.organizationPointForm.controls.IsFranchise.setValue(true);
    this.organizationPointForm.controls.Phone.setValue('');
    this.organizationPointForm.controls.Email.setValue(null);
    this.organizationPointForm.controls.TimeZoneId.setValue(this.mscTimeZone);
    this.organizationPointForm.controls.TerminalId.setValue(null);
    this.organizationPointForm.controls.PointCode.setValue(null);
    this.organizationPointForm.controls.OrganizationId.setValue(null);
    this.organizationPointForm.controls.WorkStart.setValue(null);
    this.organizationPointForm.controls.WorkEnd.setValue(null);
    this.organizationPointForm.controls.LimitForTransfer.setValue(null);
    this.organizationPointForm.controls.LimitForDay.setValue(null);
    this.organizationPointForm.controls.LimitForMonth.setValue(null);
  }

  addOrUpdateOrganization(): void {
    this.submitted = true;
    if (this.organizationPointForm.invalid) {
      return;
    }

    const payload: IOrganizationPointAddOrUpdateRequest = {
      id: !!this.organizationPoint ? this.organizationPoint.id : null,
      name: this.organizationPointForm.value.Name,
      placeAddress: this.organizationPointForm.value.PlaceAddress,
      legalAddress: this.organizationPointForm.value.LegalAddress,
      isBlocked: this.organizationPointForm.value.IsBlocked,
      isFranchise: this.organizationPointForm.value.IsFranchise,
      pointOfSalesId: this.organizationPointForm.value.PointOfSalesId,
      phone: this.organizationPointForm.value.Phone,
      email: this.organizationPointForm.value.Email,
      timeZoneId: Number(getSelectId(this.organizationPointForm.value.TimeZoneId, this.availableTimeZones, false)),
      organizationId: String(getSelectId(this.organizationPointForm.value.OrganizationId, this.availableOrganizations, false)),
      terminalId: this.organizationPointForm.value.TerminalId,
      pointCode: this.organizationPointForm.value.PointCode
    };

    (this.organizationPoint ?
      this.apiClient.organizationPoint_UpdateOrganizationPoint(payload as OrganizationPointAddOrUpdateRequest) :
      this.apiClient.organizationPoint_CreateOrganizationPoint(payload as OrganizationPointAddOrUpdateRequest))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (this.organizationPoint) {
              this.notify.success(messages.pointUpdated)
            } else {
              this.notify.success(messages.pointCreated)
            }
            this.organizationPoint = data as IOrganizationPointResponse;

            this.organizationPointForm.get('WorkStart').setValue(workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayStartInfo?.value ?? null));
            this.organizationPointForm.get('WorkEnd').setValue(workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayEndInfo?.value ?? null));

            this.organizationPointForm.get('LimitForTransfer').setValue(this.organizationPoint.organizationPointLimits.limitForOperation?.value?.maxValue ?? null);
            this.organizationPointForm.get('LimitForDay').setValue(this.organizationPoint.organizationPointLimits.limitForDay?.value?.maxValue ?? null);
            this.organizationPointForm.get('LimitForMonth').setValue(this.organizationPoint.organizationPointLimits.limitForMonth?.value?.maxValue ?? null);

            this.store.dispatch(new GetAvailableData({ type: UserAvailableDataEnum.OrganizationPoint }));
          },
          (error) => {
            this.notify.error(getErrorMessage(error));
            console.error(error);
          });
  }

  toggleModal(organizationPointForm: IOrganizationPointResponse) {
    this.organizationPoint = organizationPointForm as IOrganizationPointResponse;
    this.submitted = false;
    this.submittedSettings = false;
    this.submittedLimits = false;
    if (this.organizationPoint) {
      this.getOrganizationPointLimits();
      this.getOrganizationPointSettings();
      this.getHardwareOrganizationPoints();
    }
    this.topMenu.currentIndex = 1;
    this.modalClosed.emit(this.visible);
    this.visible = !this.visible;
    this.checkFormControls();
  }

  getOrganizationPointLimits(): void {
    if (!this.organizationPoint) {
      return;
    }
    this.apiClient.limit_GetOrganizationPointLimits(this.organizationPoint.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.organizationPoint.organizationPointLimits = data;
        this.organizationPointForm.get('LimitForTransfer').setValue(this.organizationPoint.organizationPointLimits.limitForOperation?.value?.maxValue ?? null);
        this.organizationPointForm.get('LimitForDay').setValue(this.organizationPoint.organizationPointLimits.limitForDay?.value?.maxValue ?? null);
        this.organizationPointForm.get('LimitForMonth').setValue(this.organizationPoint.organizationPointLimits.limitForMonth?.value?.maxValue ?? null);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateOrganizationPointLimits(): void {
    this.submittedLimits = true;
    if (!this.organizationPoint) {
      this.notify.error(errorMessages.createOperatorFirst)
      return;
    }
    if (
      this.organizationPointForm.get('LimitForTransfer').invalid ||
      this.organizationPointForm.get(`LimitForDay`).invalid ||
      this.organizationPointForm.get(`LimitForMonth`).invalid ||
      !this.organizationPointForm.get('LimitForTransfer').value ||
      !this.organizationPointForm.get('LimitForDay').value ||
      !this.organizationPointForm.get('LimitForMonth').value
    ) {
      if (!this.organizationPointForm.get('LimitForTransfer').value) {
        this.organizationPointForm.get('LimitForTransfer').setErrors({required: true});
      }
      if (!this.organizationPointForm.get('LimitForDay').value) {
        this.organizationPointForm.get('LimitForDay').setErrors({required: true});
      }
      if (!this.organizationPointForm.get('LimitForMonth').value) {
        this.organizationPointForm.get('LimitForMonth').setErrors({required: true});
      }
      this.notify.error(errorMessages.needFixErrorToUpdateSetting);
      return;
    }
    // if (this.organizationPointForm.get('LimitForTransfer').value > this.organizationPointForm.get('LimitForDay').value) {
    //   this.organizationPointForm.get('LimitForTransfer').setErrors({customError: errorMessages.limitTransferDayMinMaxError});
    //   this.notify.error(errorMessages.needFixErrorToUpdateSetting);
    //   return;
    // }
    const payload: IUpdateLimitsRequest = {
      alsoUpdateClients: false,
      entityId: this.organizationPoint.id,
      entityType: EntityTypeEnum.OrganizationPoint,
      limitForOperation: { minValue: 0, maxValue: this.organizationPointForm.get('LimitForTransfer').value} as LimitValueHelper,
      limitForDay: { minValue: 0, maxValue: this.organizationPointForm.get('LimitForDay').value} as LimitValueHelper,
      limitForMonth: { minValue: 0, maxValue: this.organizationPointForm.get('LimitForMonth').value} as LimitValueHelper,
      isActive: true
    };
    // if (payload.items.length == 0) {
    //   this.notify.warning(errorMessages.limitsDidNotChanged);
    //   return;
    // }
    this.apiClient.limit_UpdateLimits(payload as UpdateLimitsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  getOrganizationPointSettings(): void {
    if (!this.organizationPoint) {
      return;
    }
    this.apiClient.setting_GetOrganizationPointSettings(this.organizationPoint.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.organizationPoint.organizationPointSettings = data;
        this.organizationPointForm.get('WorkStart').setValue(workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayStartInfo?.value ?? null));
        this.organizationPointForm.get('WorkEnd').setValue(workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayEndInfo?.value ?? null));
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateOrganizationPointSettings(): void {
    this.submittedSettings = true;
    if (!this.organizationPoint) {
      this.notify.error(errorMessages.createPointFirst)
      return;
    }
    if (
      this.organizationPointForm.get('WorkStart').invalid ||
      this.organizationPointForm.get(`WorkEnd`).invalid ||
      !this.organizationPointForm.get('WorkStart').value ||
      !this.organizationPointForm.get('WorkEnd').value
    ) {
      this.notify.error(errorMessages.needFixErrorToUpdateSetting);
      return;
    }
    const payload: IUpdateEntitySettingsRequest = {
      entityId: this.organizationPoint.id,
      entityType: EntityTypeEnum.OrganizationPoint,
      items: []
    };
    if (workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayStartInfo.value) !== this.organizationPointForm.get('WorkStart').value) {
      payload.items.push({
        settingId: this.organizationPoint.organizationPointSettings.workDayStartInfo.id,
        keyString: EntitySettingKeyStringEnum.WorkDayStart,
        value: controlValueToWorkHours(this.organizationPointForm.get('WorkStart').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (workHoursToControlValue(this.organizationPoint.organizationPointSettings.workDayEndInfo.value) !== this.organizationPointForm.get('WorkEnd').value) {
      payload.items.push({
        settingId: this.organizationPoint.organizationPointSettings.workDayEndInfo.id,
        keyString: EntitySettingKeyStringEnum.WorkDayEnd,
        value: controlValueToWorkHours(this.organizationPointForm.get('WorkEnd').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (payload.items.length == 0) {
      this.notify.warning(errorMessages.settingsDidNotChange);
      return;
    }
    this.apiClient.setting_UpdateEntitySettingsASync(payload as UpdateEntitySettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  getHardwareOrganizationPoints(): void {
    if (!this.organizationPoint) {
      return;
    }
    this.apiClient.hardware_GetHardwareOrganizationPoints(this.organizationPoint.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hardwareOrganizationPoints = data;
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  removeHardwareFromPoint(item: IHardwareOrganizationPointsItemResponse): void {
    const payload: IHardwareOrganizationPointDeleteRequest = {
      id: item.id      
    };
    this.apiClient.hardware_DeleteHardwareOrganizationPoint(payload as HardwareOrganizationPointDeleteRequest)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.notify.success(messages.hardwareRemoved)
      this.hardwareOrganizationPoints.items = this.hardwareOrganizationPoints.items.filter(obj => obj.id != item.id)
    }, error => {
      this.notify.error(error.message)
      console.error(error)
    })
  }
  
}
