<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Отмена операции</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper mb-20">

  </div>


  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main"
              [style.width.px]="200" (click)="changeBeneficiary()">Подтвердить</button>
      <button class="button unfilled decoration-under" (click)="close()" [style.padding-left.px]="0">Закрыть</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="200"
              (click)="close()" [style.margin-right.px]="20">Закрыть</button>
      <button class="button main f-right"
              [style.width.px]="180" (click)="changeBeneficiary()">Подтвердить</button>
    </div>
  </footer>

</div>
