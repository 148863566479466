import {Injectable} from '@angular/core';
import {BillingVariablesService} from "./billing-variables.service";
import {takeUntil} from "rxjs/operators";
import {ApiClient, CreateBillRequest, OperationPaymentTypeEnum} from "../../../kernel/ApiClient";
import {NotifyService} from "../../core/services/notify.service";
import {getErrorMessage} from "../../../kernel/helpers/data.helper";
import {messages} from "../../../kernel/constants/messages";
import {BehaviorService} from "../../core/services/behavior.service";
import {PopupLoaderTypeEnum} from "../../../kernel/enum/popupLoaderTypeEnum.enum";
import {KkmService} from "./kkm.service";
import {FormArray} from "@angular/forms";
import {BillSourceEnum} from "../../../kernel/enum/billSourceEnum.enum";

@Injectable({
  providedIn: 'root',
})
export class KkmQueueService {
  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService,
    private variables: BillingVariablesService,
    private behavior: BehaviorService,
    private kkm: KkmService
  ) {
  }

  printAllSelected(isFull: boolean, countPrint: number, checkedQueueFormArray: FormArray): void {
    if (this.variables.breakAllQueueAction) {
      return;
    }
    this.variables.breakAllQueueAction = true;
    this.variables.printInProgress = true;
    this.variables.countPrint = countPrint;
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.BillingPrintProgress);
    if (isFull) {
      this.printAllDb();
    } else {
      this.printSelected(checkedQueueFormArray);
    }
  }

  printAllDb(): void {
    this.apiClient.billing_GetAllAvailableBillInfoToPrint()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.variables.toPrint = data;
        this.printNextInQueueToPrint();
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  printSelected(checkedQueueFormArray: FormArray): void {
    this.variables.toPrint = [];
    checkedQueueFormArray.value.map(x => {
      if (x.checked) {
        this.variables.toPrint.push({
          queueId: x.queueId,
          operationId: x.operationId,
          type: x.type
        });
      }
    });
    this.printNextInQueueToPrint();
  }

  printNextInQueueToPrint(): void {
    if (!this.variables.toPrint || this.variables.toPrint.length === 0) {
      this.notify.success(messages.checksPrintingEnd);
      this.variables.breakAllQueueAction = false;
      this.variables.printInProgress = false;
      this.behavior.popupLoaderStatus.next(null);
      return;
    }
    this.variables.currentPrintIndex++;
    this.apiClient.billing_GetBill({
      type: this.variables.toPrint[0].type,
      operationId: this.variables.toPrint[0].operationId,
      isPreview: false,
      isFromQueue: true,
      numDevice: Number(this.variables.currentKkt?.NumDevice ?? 0)
    } as CreateBillRequest)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.kkm.printBill(data, {
          operationId: this.variables.toPrint[0].operationId,
          type: this.variables.toPrint[0].type,
          isQueue: true,
          paymentType: OperationPaymentTypeEnum.PaymentCash,
          kktId: this.variables.currentKkt.IdDevice,
          //TODO check it
          cashierId: null,
          checkNumber: null,
          kktShiftNumber: null
        });
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
