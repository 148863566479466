<div class="page-wrapper">
  <p class="page-title pb-5">Операции</p>
  <div [style.height.px]="45">
    <p class="download-title" (click)="download()" [style.margin-top.px]="0" [ngClass]="{'opacity-05 disabled': downloadInProgress, 'pointer': !downloadInProgress}">
       {{ downloadInProgress ? 'Формируется отчет...' : 'Скачать отчет' }}</p>
    <p class="download-title" (click)="resetAll()" [style.margin-top.px]="0"
       *ngIf="isNeedFilterReset">Сбросить фильтр</p>
  </div>

  <p class="expand-filter-operations download-title mt-10" (click)="filterExpand = !filterExpand">Фильтры</p>
  <div class="operation-filter-wrapper" [ngClass]="{'open': filterExpand}"
       *ngIf="!!operationFilterForm" [formGroup]="operationFilterForm">
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Номер операции"
                 label="Номер операции"
                 name="TransferNumber"
                 width="full"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="БПА"
                 label="БПА"
                 name="OrganizationId" wrapperClass="inputBox-group operation-filter-item"
                 [onlyVisible]="isOneOrganization"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="availableOrganizations || []"
                 [selectDataAll]="availableOrganizations || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Точка"
                 label="Точка"
                 name="OrganizationPointId" wrapperClass="inputBox-group operation-filter-item"
                 [onlyVisible]="isOneOrganizationPoint"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="availableOrganizationPoints || []"
                 [selectDataAll]="availableOrganizationPoints || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Сотрудник"
                 label="Сотрудник"
                 name="OperatorId" wrapperClass="inputBox-group operation-filter-item"
                 [onlyVisible]="isOneOperator"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="availableUsers || []"
                 [selectDataAll]="availableUsers || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Сумма от"
                 label="Сумма от"
                 name="SumFrom"
                 width="full"
                 [mask]="'0*.00'"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Сумма до"
                 label="Сумма до"
                 name="SumTo"
                 width="full"
                 [mask]="'0*.00'"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Статус"
                 label="Статус"
                 name="Status" wrapperClass="full"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="statuses || []"
                 [selectDataAll]="statuses || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="picker"
                 placeholder="Дата от"
                 label="Дата от"
                 name="DateFrom"
                 width="full"
                 [mask]="'00.00.0000'"
                 [minDate]="dateFromMin"
                 [maxDate]="dateFromMax"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="picker"
                 placeholder="Дата до"
                 label="Дата до"
                 name="DateTo"
                 width="full"
                 [mask]="'00.00.0000'"
                 [minDate]="dateToMin"
                 [maxDate]="dateToMax"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Страна"
                 label="Страна"
                 name="Country" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="countries || []"
                 [selectDataAll]="countries || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Валюта выдачи"
                 label="Валюта выдачи"
                 name="WithdrawCurrency" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600"
                 [selectData]="currencies || []"
                 [selectDataAll]="currencies || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper p-relative">
      <div class="operation-filter-item operation-filter-button-wrapper">
        <button class="button main search" (click)="getFilteredOperations()">
          Поиск
        </button>
      </div>
    </div>
  </div>

  <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!operations && operations.operations.length > 0 else emptyList">
    <table class="table w-100">
      <thead class="table-header">
      <th>НОМЕР</th>
      <th>СТАТУС</th>
      <th
        (click)="setSort(columns.User)"
        [ngClass]="{'active': sortField === columns.User, 'non-desc': sortField === columns.User && !isDesc}">
        ФИО <img class="table-header-arrow_down" src="assets/img/icons/arrow-down.svg"/></th>
      <th>ТЕЛЕФОН</th>
      <th
        (click)="setSort(columns.Created)"
        [ngClass]="{'active': sortField ==columns.Created, 'non-desc': sortField ==columns.Created && !isDesc}">
        ДАТА <img class="table-header-arrow_down" src="assets/img/icons/arrow-down.svg"/></th>
      <th
        (click)="setSort(columns.Sum)"
        [ngClass]="{'active': sortField ==columns.Sum, 'non-desc': sortField ==columns.Sum && !isDesc}">
        СУММА <img class="table-header-arrow_down" src="assets/img/icons/arrow-down.svg"/></th>
      </thead>
      <tbody class="text-align-center">
      <tr *ngFor="let operation of operations.operations; let i=index" class="table-row p-relative"
      (click)="openOperationInfo(operation.transferId)">
        <td class="td-role td-transfer-number" *ngIf="(isAllNonBPAAdmins$ | async) else operatorNumber">
          <span class="operation-bill-value" *ngIf="!!operation.confirmationSentAt">Предчек: <strong>{{operation.firstBill | answerPipe}}</strong> Чек: <strong>{{operation.secondBill | answerPipe}}</strong></span>
          {{operation.paymentSystemTransferNum ?? operation.transferNum}}
          <span class="operation-status-value">{{operation.transferStatus}}</span>
        </td>
        <ng-template #operatorNumber>
          <td class="td-role td-transfer-number">
            {{operation.paymentSystemTransferNum ?? operation.transferNum}}
          </td>
        </ng-template>
        <td class="td-role">{{operation.transferStatus | operationStatus}}</td>
        <td class="td-role">{{operation.user.lastName}} {{operation.user.firstName}}</td>
        <td class="td-role">{{operation.user.phone | phonePipe}}</td>
        <td class="td-phone">{{(operation.created) | dateLocal: 'HH:mm dd.MM.yyyy': false}}</td>
        <td class="td-email">{{(operation.acceptedMoney + operation.organizationFee) | number: "1.2":"ru"}} {{operation.acceptedMoneyCurrencyCode}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <app-paginator #paginatorComp
    [page]="paginator.page"
    [total]="operations?.totalCount"
    [take]="paginator.take"
    [id]="paginatorId"
    (pageChange)="pageChanged($event)"
  ></app-paginator>
  <div class="f-12 t-align-center font-b pt-10 opacity-0-75" *ngIf="operations">{{ operations.totalCount }} {{ titleBottom }} на сумму {{ operations.totalSum.toFixed(2) | number: "1.2":"ru" }} RUB</div>
  <ng-template #emptyList>
    <div class="empty-list">
      <p>{{
        isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одной операции...'
        }}</p>
    </div>
  </ng-template>
</div>
