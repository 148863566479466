<section id="auth">

  <div class="auth">
    <div class="left-content-wrapper">
      <div class="left-content">
        <p class="main-title color-white">Свобода переводов!</p>
        <p class="auth-subtitle color-white">Онлайн сервис для Банковских Платежных Агентов</p>
      </div>
    </div>
    <div class="right-content-wrapper">
      <div class="right-content" id="auth_right_content">
        <div class="right-form-wrapper">

          <router-outlet class="home"></router-outlet>

        </div>
      </div>
    </div>
  </div>
</section>
