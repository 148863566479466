<div class="expand" [ngClass]="{'open': expanded}" id="siteSettings">


  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/settings.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКИ САЙТА</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-title">Настройки сайта</p>


    <form class="user-settings-wrapper" [formGroup]="settingForm" autocomplete="off">

      <div class="menu-nav-wrapper">
        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="topMenu.currentIndex = item.index" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>
      </div>

      <div *ngIf="topMenu.currentIndex === 1">
        <app-input
          inputType="text"
          placeholder="Активность 1С"
          label="Активность 1С"
          name="DelayCountCheck"
          wrapperClass="inputBox-group half-left-user-setting"
          hintBottom="Количество запросов для проверки 1С модуля"
          mask="0*"
          [controlInvalid]="submittedSite"
          [parentForm]="settingForm"></app-input>

        <div class="operation-half-right-right">
        </div>

        <div class="operation-half-left-right">
          <app-checkbox
            [wrapperClass]="'inputBox-group'"
            [parentForm]="settingForm"
            name="User1CHardwareCompare"
            label="Проверка токенов модуля и сайта на одном ПК"
            [controlInvalid]="submittedSite"
          ></app-checkbox>
        </div>

        <div class="t-align-center mt-50 pb-30">
          <button class="button main" (click)="updateSiteSettings()">Обновить</button>
        </div>
      </div>
    </form>
  </div>
</div>
