<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header"  [ngClass]="{'pb-0': !!user}">
        <div class="modal-title">{{user ? 'Обновление данных' : 'Создание сотрудника'}}</div>
        <img class="modal-button-close" src="../../../../assets/img/icons/close.svg" (click)="toggleModal(null)" alt=""/>
      </div>
      <div class="modal-subtitle" *ngIf="user">{{user.lastName}} {{user.firstName}} {{user.patronymic}}</div>
      <div *ngIf="!success">
        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="topMenu.currentIndex = item.index" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>
      </div>
      <div class="modal-wrapper" *ngIf="userForm" [style.min-height.px]="success ? 305 : 557">
        <div *ngIf="!success" [formGroup]="userForm" class="users-modal-wrapper">
          <div *ngIf="topMenu.currentIndex === 1">

            <app-input inputType="text"
                       placeholder="Фамилия сотрудника"
                       label="Фамилия сотрудника"
                       name="LastName"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Имя сотрудника"
                       label="Имя сотрудника"
                       name="FirstName"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Отчество сотрудника"
                       label="Отчество сотрудника"
                       name="Patronymic"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="000000000000"
                       label="ИНН"
                       name="Inn"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [maxLength]="12"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <ng-container *ngIf="!user">
              <app-input inputType="select"
                         placeholder="БПА"
                         label="БПА"
                         name="OrganizationId"
                         wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                         [contentMaxHeight]="600"
                         [selectData]="availableOrganizations || []"
                         [selectDataAll]="availableOrganizations || []"
                         [controlInvalid]="submitted"
                         [parentForm]="userForm"></app-input>

              <app-input inputType="select"
                         placeholder="Точка"
                         label="Точка"
                         name="OrganizationPointId"
                         wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                         [contentMaxHeight]="600"
                         [selectData]="availableOrganizationPoints || []"
                         [selectDataAll]="availableOrganizationPoints || []"
                         [controlInvalid]="submitted"
                         [parentForm]="userForm"></app-input>
            </ng-container>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted" [onlySelect]="true"
                       [placeholder]="'Роль сотрудника'" [label]="'Роль сотрудника'"
                       name="Role" wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [contentMaxHeight]="600"
                       [selectData]="availableUserRoles || []"
                       [selectDataAll]="availableUserRoles || []"
                       [parentForm]="userForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted"
                       [placeholder]="'Часовой пояс'"
                       [label]="'Часовой пояс'"
                       name="TimeZoneId" wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [contentMaxHeight]="600"
                       [selectData]="availableTimeZones || []"
                       [selectDataAll]="availableTimeZones || []"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Номер телефона"
                       label="Номер телефона"
                       name="Phone"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       maskPrefix="+7"
                       mask="(000)-000-0000"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Email"
                       label="Email"
                       name="Email"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>



            <app-checkbox
              [wrapperClass]="'inputBox-group'"
              *ngIf="user"
              [parentForm]="userForm"
              name="IsBlocked"
              label="Заблокировать сотрудника"
              [controlInvalid]="submitted"
            ></app-checkbox>


            <div class="modal-button-wrapper t-align-center">

              <button class="button border" [@fadeInOut] [style.margin.px]="10"
                      (click)="toggleModal(null)">Отменить</button>
              <button class="button main" [style.margin.px]="10"
                      [ngClass]="{'disabled': !!userForm.invalid}"
                      (click)="arrOrUpdateUser()"
                      [@fadeInOut]>{{user ? 'Обновить' : 'Создать'}}</button>

              <div
                *ngIf="!success && user && (isAdministratorNabix || isSuperAdministratorNabix)"
                (click)="resetPasswordFromAdmin()"
                class="download-title mt-20"
              >Сбросить пароль</div>
            </div>
          </div>

          <div *ngIf="topMenu.currentIndex === 2">
            <app-input inputType="text"
                       placeholder="08:00"
                       label="Начало рабочего дня"
                       name="WorkStart"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       mask="00:00"
                       [checkTime]="true"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="20:00"
                       label="Конец рабочего дня"
                       name="WorkEnd"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       mask="00:00"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="userForm"></app-input>

            <div (click)="updateUserSettings()" class="download-title t-align-center">Обновить настройки</div>
          </div>

          <div *ngIf="topMenu.currentIndex === 3 && isOperator">
            <app-input inputType="text"
                       placeholder="15000"
                       label="Лимит на перевод"
                       name="LimitForTransfer"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="300000"
                       label="Лимит на рабочий день"
                       name="LimitForDay"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="3000000"
                       label="Лимит на месяц"
                       name="LimitForMonth"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="userForm"></app-input>

            <div (click)="updateUserLimits()" class="download-title t-align-center">Обновить лимиты</div>
          </div>

          <div *ngIf="topMenu.currentIndex === 4 && (isOperator || isAdministratorBPA)">

            <app-input [inputType]="'select'"
                       [controlInvalid]="submittedRelationships"
                       [placeholder]="'БПА'" [label]="'БПА'"
                       [readonly]="isAdministratorBpa"
                       name="OrganizationId" [wrapperClass]="isOperator ? 'inputBox-group d-inline-block w-48 mr-2' : 'inputBox-group'"
                       [contentMaxHeight]="600"
                       [selectData]="availableOrganizations || []"
                       [selectDataAll]="availableOrganizations || []"
                       [parentForm]="userForm"></app-input>

            <ng-container *ngIf="isOperator">
              <app-input [inputType]="'select'"
                         [controlInvalid]="submittedRelationships"
                         [placeholder]="'Точка'" [label]="'Точка'"
                         name="OrganizationPointId" wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                         [contentMaxHeight]="600"
                         [selectData]="availableOrganizationPoints || []"
                         [selectDataAll]="availableOrganizationPoints || []"
                         [parentForm]="userForm"></app-input>
            </ng-container>

            <div class="user-relationship-button-wrapper mb-20">
              <div (click)="setOrRemoveEntityRelationship(true, true)" class="download-title" *ngIf="isAllNonBPAAdmins">Привязать БПА</div>
              <div (click)="setOrRemoveEntityRelationship(false, true)" class="download-title" *ngIf="isOperator">Привязать точку</div>
            </div>
            <div class="user-relationship-button-wrapper">
              <div (click)="setOrRemoveUserOrganizationPointsRelationships(true)" class="download-title" *ngIf="isAllNonBPAAdmins || isAdministratorBpa">Привязать все точки БПА</div>
              <div (click)="setOrRemoveUserOrganizationPointsRelationships(false)" class="download-title" *ngIf="isOperator">Отвязать все точки БПА</div>
            </div>

            <div class="user-relationship-title">Привязки к БПА</div>

            <ng-container *ngIf="user?.relationships?.userOrganizations?.length > 0 else emptyOrgRelationShip">
              <div class="user-relationship-item-wrapper" *ngFor="let item of user.relationships.userOrganizations; let i=index">
                <div class="user-relationship-item-title">{{item.label}}</div>
                <div class="user-relationship-item-action" (click)="setOrRemoveEntityRelationship(true, false, item.id)">Отвязать</div>
              </div>
            </ng-container>

            <ng-template #emptyOrgRelationShip>
              <div class="empty-list-relationship t-align-center">
                <p>Пока что нет ни одной привязки к БПА</p>
              </div>
            </ng-template>

            <ng-container *ngIf="isOperator">
              <div class="user-relationship-title">Привязки к точкам</div>

              <ng-container *ngIf="user?.relationships?.userOrganizationPoints?.length > 0 else emptyOrgPointRelationShip">
                <div class="user-relationship-item-wrapper" *ngFor="let item of user.relationships.userOrganizationPoints; let i=index">
                  <div class="user-relationship-item-title">{{item.label}}</div>
                  <div class="user-relationship-item-action" (click)="setOrRemoveEntityRelationship(false, false, item.id)">Отвязать</div>
                </div>
              </ng-container>

              <ng-template #emptyOrgPointRelationShip>
                <div class="empty-list-relationship t-align-center">
                  <p>Пока что нет ни одной привязки к точкам</p>
                </div>
              </ng-template>
            </ng-container>

          </div>


        </div>

        <div class="t-align-center" *ngIf="success">

          <img class="statusIco" src="../../../../assets/img/icons/check-green.svg" alt="">
          <div class="user-message"><p class="user-message-info">Логин:</p> <p class="user-message-item">{{login | phonePipe}}</p></div>
          <div
            class="user-message"
            [style.margin-bottom.px]="40"
          ><p class="user-message-info">Пароль:</p> <p class="user-message-item">{{message}}</p></div>

          <div class="modal-button-wrapper t-align-center">
            <button class="button main" [style.margin-left.px]="20"
                    (click)="toggleModal(null)"
                    [@fadeInOut]>Закрыть</button>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
