import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {IUserResponse} from "../../../../kernel/ApiClient";
import {isKkm} from "../../../../kernel/helpers/user.helper";
import {BillingBuilderService} from "../../services/billing-builder.service";

@Component({
  selector: 'app-queue-status',
  templateUrl: './queue-status.component.html',
  styleUrls: ['./queue-status.component.scss']
})
export class QueueStatusComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;

  subs = new Subscription();
  user: IUserResponse;
  isKkm = false;
  isFirst = true;

  constructor(
    private billing: BillingBuilderService
  ) { }

  get isWorkLikeKkmQueue(): boolean { return this.billing.variables.isWorkLikeKkmQueue; }
  get isWorkLikeKkmQueueIsLoad(): boolean { return this.billing.variables.isWorkLikeKkmQueueIsLoad; }

  ngOnInit(): void {
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.isKkm = isKkm(this.user.organization);
        if (this.isKkm) {
          if (this.isFirst) {
            this.isFirst = false;
            this.billing.kkm.checkQueueStatus();
          }
        } else {
          this.isFirst = false;
        }
      }
    }));
  }

  checkStatus(): void {
    this.billing.kkm.checkQueueStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
