<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header"  [ngClass]="{'pb-0': !!organization}">
        <div class="modal-title">{{organization ? 'Обновление данных' : 'Создание БПА'}}</div>
        <img class="modal-button-close" src="../../../../assets/img/icons/close.svg" (click)="toggleModal(null)" alt=""/>
      </div>
      <div class="modal-subtitle" *ngIf="organization">{{organization.name}}</div>
      <div>
        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="topMenu.currentIndex = item.index" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>
      </div>
      <div class="modal-wrapper p-relative" *ngIf="organizationForm" [style.min-height.px]="619">
        <div [formGroup]="organizationForm">
          <div *ngIf="topMenu.currentIndex === 1">
            <app-input inputType="text"
                       placeholder="Название"
                       label="Название"
                       name="Name"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="ИНН"
                       label="ИНН"
                       name="Inn"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       [maxLength]="12"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="123456 от 01.01.2020"
                       label="Соглашение"
                       name="AgreementInfo"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Номер счета"
                       label="Номер счета"
                       name="BalanceAccount"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Agent Code"
                       label="Agent Code"
                       name="AgentCode"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="SPName"
                       label="SPName"
                       name="SpName"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       label="Баланс"
                       name="IncomingBalance"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Номер телефона"
                       label="Номер телефона"
                       name="Phone"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       maskPrefix="+7"
                       mask="(000)-000-0000"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Email"
                       label="Email"
                       name="Email"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted"
                       placeholder="Тип"
                       label="Тип"
                       name="OrganizationTypeId"
                       wrapperClass="operation-half-item-left"
                       [contentMaxHeight]="600"
                       [selectData]="availableOrganizationTypes || []"
                       [selectDataAll]="availableOrganizationTypes || []"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Сертификат"
                       label="Сертификат"
                       name="CertificateName"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="BpId Франшиза"
                       label="BpId Франшиза"
                       name="BpId"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="BpId не Франшиза"
                       label="BpId не Франшиза"
                       name="BpIdNotFranchise"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="textarea"
                       placeholder="Фактический адрес"
                       label="Фактический адрес"
                       name="PlaceAddress"
                       width="full"
                       rows="3"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submitted"
                       [maxLength]="200"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="textarea"
                       placeholder="Юридический адрес"
                       label="Юридический адрес"
                       name="LegalAddress"
                       width="full"
                       rows="3"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [maxLength]="200"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       placeholder="Описание"
                       label="Описание"
                       name="Description"
                       width="full"
                       wrapperClass=""
                       [controlInvalid]="submitted"
                       [parentForm]="organizationForm"></app-input>


            <app-checkbox
              
              [parentForm]="organizationForm"
              name="IsBlocked"
              label="Заблокировать БПА"
              wrapperClass="operation-half-item-left"
              [controlInvalid]="submitted"
            ></app-checkbox>

            <app-checkbox
              
              [parentForm]="organizationForm"
              name="IsEPO"
              label="ЕПО/РТК"
              wrapperClass="operation-half-item-right"
              [controlInvalid]="submitted"
            ></app-checkbox>


            <div class="modal-button-wrapper t-align-center">

              <button class="button border" [@fadeInOut] [style.margin.px]="10"
                      (click)="toggleModal(null)">Отменить</button>
              <button class="button main" [style.margin.px]="10"
                      [ngClass]="{'disabled': !!organizationForm.invalid}"
                      (click)="addOrUpdateOrganization()"
                      [@fadeInOut]>{{organization ? 'Обновить' : 'Создать'}}</button>
            </div>
          </div>

          <div *ngIf="topMenu.currentIndex === 2">
            <app-input inputType="text"
                       label="Уведомление баланса"
                       name="OrganizationNotifyLowBalance"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       mask="0*"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       label="Фискальное время"
                       name="TimeForNotForbiddenOperationCreateWithoutFiscal"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       mask="0*"
                       maskSuffix=" мин"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       label="Количество лицензий ККМ"
                       name="MaxKkm"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       mask="0*"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       label="НДС"
                       name="TaxNds"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       mask="0*"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <app-input inputType="text"
                       label="Department"
                       name="Department"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationForm"></app-input>

            <div (click)="updateOrganizationSettings()" class="download-title t-align-center">Обновить настройки</div>

            <div>
              <div class="entity-setting-hint"><strong>Уведомление баланса </strong>это минимальная сумма после которой будет появлятся диалоговое окно с напоминанием пополнить баланс</div>
              <div class="entity-setting-hint"><strong>Фискальное время </strong>это максимальное время доступности работы с операциями без фискального чека, мин</div>
              <div class="entity-setting-hint"><strong>Количество лицензий ККМ </strong>для ККМ БПА это максимальное количество ключей достыпных для работы с кассами. 1 ключ = 1ПКб на ПК могут меняться кассы. Ключ привязывается к ПК, шт.</div>
            </div>
          </div>

        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
