export const environment = {
  name: 'dev',
  production: false,
  apiUrl: '',
  statusRequestDelay: 10000,
  countRequestStatusTry: 10,
  countRequestAccessTry: 100,
  countConfirmOperationTry: 10,
  mainCurrency: 'Российский рубль',
  mainCurrencyId: 'RUB',
  ssoAuthParam: {
    callback: 'url'
  }
};
