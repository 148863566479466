import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ApiClient,
  FileParameter,
  IOrganizationBalanceInfo,
  OrganizationBalanceInfo
} from "../../../../kernel/ApiClient";
import {NotifyService} from "../../services/notify.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../kernel/constants/messages";
import {getErrorMessage, mapInvalidFields} from "../../../../kernel/helpers/data.helper";

@Component({
  selector: 'app-main-organization-balance-info',
  templateUrl: './main-organization-balance-info.component.html',
  styleUrls: ['./main-organization-balance-info.component.scss']
})
export class MainOrganizationBalanceInfoComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  balances: IOrganizationBalanceInfo[] = [];

  availableIcoTypes = ['xml'];
  file: File;

  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService
  ) { }

  ngOnInit(): void {
    this.apiClient.organization_GetOrganizationsBalancesInfo()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.balances = data;
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async fileChanged(event: any) {
    if (!event) {
      return
    }
    this.file = event.data
  }

  updateBalances(): void {
    const payload: FileParameter = {
      data: this.file,
      fileName: this.file.name
    }
    this.apiClient.mTSFacade_UpdateOrganizationsBalancesPOST(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.actionSuccess);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
