import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {IBillingToPrintInfoResponse} from "../../../kernel/ApiClient";
import {IUnitItemResponse} from "../../../kernel/models/billing/kkm.models";
import {BillSourceEnum} from "../../../kernel/enum/billSourceEnum.enum";

@Injectable({
  providedIn: 'root',
})
export class BillingVariablesService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  public agentUrl: string;
  public kkmUrl = 'https://localhost:5893/Execute';
  public cashboxListDialogOpen = false;
  public cashboxListDialogSub = null;
  public billPreviewDialogOpen = false;
  public billPreviewDialogSub = null;
  public isWorkLikeKkmQueue = false;
  public isWorkLikeKkmQueueIsLoad = false;
  public isWorkLikeKkmQueueSub = null;

  public breakAllQueueAction = false;
  public printInProgress = false;
  public numDevice = 0;
  public currentPrintIndex = 0;
  public countPrint = 0;
  public toPrint: IBillingToPrintInfoResponse[] = [];
  public listUnit: IUnitItemResponse[] = [];
  public currentKkt: IUnitItemResponse;
  public source: BillSourceEnum;


  async defaultQueueState(): Promise<void> {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.breakAllQueueAction = false;
    this.printInProgress = false;
    this.numDevice = 0;
    this.currentPrintIndex = 0;
    this.countPrint = 0;
    this.toPrint = [];
    this.listUnit = [];
    this.currentKkt = null;
    return;
  }

  async defaultState(): Promise<void> {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.source = BillSourceEnum.None;
    this.isWorkLikeKkmQueue = false;
    this.isWorkLikeKkmQueueIsLoad = false;
    this.isWorkLikeKkmQueueSub = null;
    this.cashboxListDialogOpen = false;
    this.cashboxListDialogSub = null;
    this.billPreviewDialogOpen = false;
    this.billPreviewDialogSub = null;
    await this.defaultQueueState();
    return;
  }
}
