export enum BuilderCommandEnum {
  InitOperationsStatusTimer = 'InitOperationsStatusTimer',
  StopOperationsStatusTimer = 'StopOperationsStatusTimer',
  DefaultState = 'DefaultState',
  DefaultStateVariable = 'DefaultStateVariable',
  OnClientSearch = 'OnClientSearch',
  AddFormArrayItem = 'AddFormArrayItem',
  RemoveFormArrayItem = 'RemoveFormArrayItem',
  CreateBill = 'CreateBill',
  CreateBillQueueItem = 'CreateBillQueueItem',
  PrintNextInQueueToPrint = 'PrintNextInQueueToPrint',
  CheckCashboxCountBeforeBilling = 'CheckCashboxCountBeforeBilling',
  PrintBill = 'PrintBill',
  FinalizeOperationBillVariable = 'FinalizeOperationBillVariable',
  CalculateOrganizationCommission = 'CalculateOrganizationCommission',
  GetMTDynamicFields = 'GetMTDynamicFields',
}
