import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {ApiClient, ICreateBillRequest, ICreatePreBillRequest, IUserResponse} from "../../../../kernel/ApiClient";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotifyService} from "../../../core/services/notify.service";
import {Select, Store} from "@ngxs/store";
import {IUnitListResponse} from "../../../../kernel/models/billing/kkm.models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IDataSelectedHelper} from "../../../../kernel/models/common.models";
import {errorMessages} from "../../../../kernel/constants/errors";
import {BehaviorService} from "../../../core/services/behavior.service";
import {UserState} from "../../../../kernel/store/state/user.state";
import { isKkm } from "../../../../kernel/helpers/user.helper";
import {BuilderCommandEnum} from "../../../../kernel/enum/builder-command.enum";

@Component({
  selector: 'app-cashbox-list-dialog',
  templateUrl: './cashbox-list-dialog.component.html',
  styleUrls: ['./cashbox-list-dialog.component.scss']
})
export class CashboxListDialogComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  subs = new Subscription();

  user: IUserResponse;
  cashbox: IUnitListResponse;
  payload: ICreateBillRequest;
  prePayload: ICreatePreBillRequest
  availableCashbox: IDataSelectedHelper[] = [];
  cashboxForm: FormGroup;
  submitted = false;
  isKkm = false;
  isFirst = true;

  constructor(
    public dialogRef: MatDialogRef<CashboxListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cashbox: IUnitListResponse, payload: ICreateBillRequest, prePayload: ICreatePreBillRequest
    },
    private apiClient: ApiClient,
    private notify: NotifyService,
    private store: Store,
    private behavior: BehaviorService,
  ) {
    if (!data) {
      this.close();
    }
    this.cashbox = data.cashbox;
    this.payload = data.payload;
    this.prePayload = data.prePayload;
  }

  ngOnInit(): void {
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.isKkm = isKkm(this.user.organization);
        if (this.isFirst) {
          this.isFirst = false;
          if (this.isKkm) {
            this.initMenu();
          } else {
            this.queueBill();
          }
        }
       }
    }));
  }

  initMenu(): void {
    if (this.cashboxForm) {
      return;
    }
    this.availableCashbox = [];
    this.cashbox.ListUnit.map((x, index) => {
      this.availableCashbox.push({
        id: String(x.NumDevice),
        label: `${x.IdTypeDevice}` + (x.INN ? ` - ИНН: ${x.INN}` : ''),
        role: String(x.TypeDevice)
      });
    });
    this.cashboxForm = new FormGroup({
      NumDevice: new FormControl(null, [Validators.required])
    });
  }



  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  printBill(): void {
    if (this.cashboxForm.invalid) {
      this.notify.error(errorMessages.chooseKkt);
      return;
    }
    this.payload.numDevice = Number(this.cashboxForm.value.NumDevice);
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.CreateBill,
      model: { payload: this.payload, prePayload: this.prePayload }
    });
    this.close();
  }

  queueBill(): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.CreateBillQueueItem,
      model: { type: this.payload.type, operationId: this.payload.operationId, body: this.prePayload }
    });
    this.close();
  }
}
