<div class="page-wrapper">
  <div>

    <p class="page-title">Очередь</p>

    <div class="search-form-queue" *ngIf="searchForm">
      <app-input inputType="select"
                 placeholder="БПА"
                 label="БПА"
                 name="OrganizationId" wrapperClass="operation-item-one-third"
                 [onlyVisible]="isOneOrganization"
                 [contentMaxHeight]="600"
                 [selectData]="availableOrganizations || []"
                 [selectDataAll]="availableOrganizations || []"
                 [parentForm]="searchForm"></app-input>
      <app-input inputType="select"
                 placeholder="Точка"
                 label="Точка"
                 name="OrganizationPointId" wrapperClass="operation-item-two-third"
                 [onlyVisible]="isOneOrganizationPoint"
                 [contentMaxHeight]="600"
                 [selectData]="availableOrganizationPoints || []"
                 [selectDataAll]="availableOrganizationPoints || []"
                 [parentForm]="searchForm"></app-input>
      <app-input inputType="select"
                 placeholder="Сотрудник"
                 label="Сотрудник"
                 name="OperatorId" wrapperClass="operation-item-three-third"
                 [onlyVisible]="isOneOperator"
                 [contentMaxHeight]="600"
                 [selectData]="availableUsers || []"
                 [selectDataAll]="availableUsers || []"
                 [parentForm]="searchForm"></app-input>

      <app-input
                 inputType="text"
                 placeholder="Номер операции"
                 label="Номер операции"
                 name="TransferNum"
                 width="full"
                 [wrapperClass]="isKkm ? 'operation-item-one-third' : 'operation-half-item-left'"
                 [parentForm]="searchForm"></app-input>

      <app-input *ngIf="isKkm"
                 inputType="select"
                 placeholder="ККТ для печати"
                 label="ККТ для печати"
                 name="NumDevice" wrapperClass="operation-item-two-third"
                 [contentMaxHeight]="600"
                 [selectData]="availableCashbox || []"
                 [selectDataAll]="availableCashbox || []"
                 [parentForm]="searchForm"></app-input>

      <div [class]="isKkm ? 'operation-item-three-third' : 'operation-half-item-left'">
        <button class="button main search w-100" (click)="getFilteredQueue()">
          Поиск
        </button>
      </div>

      <p class="download-title mt-5 mb-0 t-align-center" (click)="resetAll()"
            *ngIf="isNeedFilterReset">Сбросить фильтр</p>
    </div>

    <div class="billing-button-wrapper active" *ngIf="queue">
      <ng-container *ngIf="isKkm else oneCActions">
        <div class="operation-half-item-left t-align-center">
          <button class="button unfilled decoration-under" [style.width.px]="300" [style.height.px]="60" [disabled]="onlyView || breakAllAction" (click)="clickAll()">{{selectedAll ? 'Отменить выбор' : 'Выбрать всю страницу'}}</button>
        </div>
        <div class="operation-half-item-right t-align-center">
          <button class="button unfilled decoration-under" [style.width.px]="300" [style.height.px]="60" [disabled]="onlyView || breakAllAction || isNeedFilterReset || queue?.totalCount === 0" (click)="printAllSelected(true)">Распечатать все ({{queue.totalCount}})</button>
        </div>
      </ng-container>
      <ng-template #oneCActions>
        <div class="t-align-center">
          <button class="button unfilled decoration-under" [style.width.px]="300" [style.height.px]="60" [disabled]="onlyView || breakAllAction || isNeedFilterReset || queue?.totalCount === 0" (click)="removeAll()">Удалить все ({{queue.totalCount}})</button>
        </div>
      </ng-template>
    </div>
    <div class="billing-button-wrapper" [ngClass]="{'active': countChosen > 0}" *ngIf="isKkm">
      <div class="operation-half-item-left t-align-center">
        <button class="button unfilled decoration-under" [style.width.px]="330" [style.height.px]="60" [disabled]="onlyView || breakAllAction" (click)="printAllSelected(false)">Распечатать выбранные ({{countChosen}})</button>
      </div>
      <div class="operation-half-item-left t-align-center">
        <button class="button unfilled decoration-under" [style.width.px]="300" [style.height.px]="60" [disabled]="onlyView || breakAllAction" (click)="removeAllSelected()">Удалить выбранные ({{countChosen}})</button>
      </div>
    </div>

    <div class="cards-wrapper" *ngIf="!!queue && queue.items.length > 0 else emptyList">
      <div class="card-wrapper" *ngFor="let item of queue.items; let i=index">
        <div class="card-wrapper--header">
          <div class="card-wrapper--header--title">{{item.type === billTypes.PreBill ? 'Предчек уточнения' :  item.operation.operationType.title}}</div>
          <div class="card-wrapper--header--subtitle">{{item.type === billTypes.PreBill ? '' : item.operation.transferNum}}</div>
          <div class="card-wrapper--header--subtitle-abs">{{item.type | billType}}</div>
        </div>
        <div class="card-wrapper--body">
              <div class="card-wrapper--header--item">
                <div class="card-item--info">{{item.organization.name}}</div>
                <div class="card-item--info">{{item.organizationPoint.name}}</div>
                <div class="card-item--info">{{item.user.lastName}} {{item.user.firstName}}</div>
                <div class="card-item--info">{{item.user.phone | phonePipe}}</div>
                <div class="card-wrapper--single mb-0 mt-20">
                  <span class="card-wrapper--single--amount" *ngIf="item.type !== billTypes.PreBill">{{item.operation.acceptedTotalMoney || 0 | number: "1.2":"ru" }} {{item.operation.acceptedTotalMoneyCurrencyCode}}</span>
                </div>
                <div class="card-wrapper--single mt-0">
                  <span class="card-wrapper--single--time">{{item.created | dateLocal:'HH:mm:ss':false:false}}</span>
                  <span class="card-wrapper--single--date">{{item.created | dateLocal:'dd:MM:yyyy':false:false}}</span>
                </div>
                <div class="card-wrapper--single mt-0" *ngIf="!isKkm">
                  <button class="button unfilled decoration-under" [style.width.px]="185" [style.height.px]="60" [disabled]="onlyView || breakAllAction" (click)="removeItem(item.id)">Удалить</button>
                </div>
                <div class="card-wrapper--single mt-20" *ngIf="isKkm">
                  <app-checkbox
                    name="checked"
                    [parentForm]="checkedQueueFormArray.controls[i]"></app-checkbox>
                </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-paginator #paginatorComp *ngIf="paginator"
                 [page]="paginator.page"
                 [total]="queue?.totalCount"
                 [take]="paginator.take"
                 [id]="paginatorId"
                 (pageChange)="pageChanged($event)"
  ></app-paginator>

  <ng-template #emptyList>
    <div class="empty-list">
      <p *ngIf="!isLoad">{{
        isNeedFilterReset ? 'По Вашему запросу не найдено элементов...' : 'Пока что нет ни одного элемента в очереди...'
        }}</p>
      <p *ngIf="isLoad">Поиск...</p>
    </div>
  </ng-template>

</div>
