import {FormGroup} from '@angular/forms';
import {Store} from '@ngxs/store';
import {ApiClient} from '../../../../kernel/ApiClient';
import {NotifyService} from '../../../core/services/notify.service';
import {Subject} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {errorMessages} from '../../../../kernel/constants/errors';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {messages} from '../../../../kernel/constants/messages';
import {Navigate} from '@ngxs/router-plugin';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-auth-restore',
  templateUrl: './auth-restore.component.html',
  styleUrls: ['./auth-restore.component.scss', '../auth-area/auth-area.component.scss']
})
export class AuthRestoreComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  isSubmit = false;

  constructor(
    private authService: AuthService,
    private store: Store,
    private apiClient: ApiClient,
    private notify: NotifyService,
    private route: ActivatedRoute,
  ) {
    this.authService.url = null;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (!!params && !!params.url) {
        this.authService.url = String(params.url);
        this.checkAccess();
      } else {
        this.goto('login');
      }
    });
  }

  get authGroup(): FormGroup {
    return this.authService.authGroup;
  }
  get isRestoreCorrect(): boolean {
    return this.authService.isRestoreCorrect;
  }
  get isUrlCorrect(): boolean {
    return this.authService.isUrlCorrect;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/auth/${url}`]));
  }

  onRestore(): void {
    this.isSubmit = true;
    if (!this.isRestoreCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }
    this.apiClient
      .auth_RestorePassword(this.authService.restorePayload)
      .subscribe(
        () => {
          this.notify.success(messages.passwordChanged);
          this.authService.setDefaultState();
          this.goto('login');
        },
        error => {
          this.notify.error(this.authService.showError(error));
          console.error(error);
        }
      );
  }

  checkAccess(): void {
    this.authService.isUrlCorrect = false;
    this.apiClient
      .auth_ResetPasswordCheckAccess(this.authService.url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.authService.isUrlCorrect = true;
        },
        error => {
          this.goto('login');
          this.notify.error(error.message);
        }
      );
  }
}
