import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Select} from '@ngxs/store';
import {UserState} from '../../../../kernel/store/state/user.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;
  @Select(UserState.isAllNonBPAAdmins) isAllNonBPAAdmins$: Observable<boolean>;
  subs = new Subscription();
  isAuthorized = false;
  isOperator = false;
  isAllNonBPAAdmins = false;

  ngOnInit(): void {
    this.subs.add(
      this.isAuthorized$.subscribe(isAuthorized => {
        this.isAuthorized = isAuthorized;
      })
    );
    this.subs.add(
      this.isOperator$.subscribe(isOperator => {
        this.isOperator = isOperator;
      })
    );
    this.subs.add(
      this.isAllNonBPAAdmins$.subscribe(isAllNonBPAAdmins => {
        this.isAllNonBPAAdmins = isAllNonBPAAdmins;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
