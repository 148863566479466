import {ApiClient} from "../../../../kernel/ApiClient";
import {ICommissionHelper, IDictionaries, IStepsControlHelper} from "../../../../kernel/models/common.models";
import {NotifyService} from "../../../core/services/notify.service";
import {Subject, Subscription} from "rxjs";
import {Component, Input, OnInit, Output} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorService} from "../../../core/services/behavior.service";
import {takeUntil} from "rxjs/operators";
import {OperationBuilderService} from "../../services/operation-builder.service";
import {OperationVariableService} from "../../services/operation-variable.service";
import {errorMessages} from "../../../../kernel/constants/errors";

@Component({
  selector: 'app-operation-data-step',
  templateUrl: './operation-data-step.component.html',
  styleUrls: [
    './operation-data-step.component.scss',
    '../mts-operation/mts-operation.component.scss',
  ],
})
export class OperationDataStepComponent {
  constructor(
    private builder: OperationBuilderService,
    private notify: NotifyService
  ) {}

  get variables(): OperationVariableService {
    return this.builder.variables;
  }
  get operationIndex(): number {
    return this.builder.variables.operationIndex;
  }
  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get operationInfoArray(): IStepsControlHelper[] {
    return this.builder.variables.operationInfoArray;
  }
  get commissionDialogExist(): boolean {
    return this.operationInfoArray[this.operationIndex].commissions?.length > 0;
  }
  get isTransfer(): boolean {
    return this.variables.isTransfer;
  }

  openCommissionList(): void {
    this.builder.helper.openCommissionListDialog(
      this.operationInfoArray[this.operationIndex].commissions,
      this.operationIndex
    );
  }

  get calculateCommissionsDisabled(): boolean {
    return this.variables.isTransfer ?
      !this.variables.isReady ||
      !this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_countryCode ||
      !this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedCurrency ||
      !this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_withdrawCurrency ||
      (!this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_withdrawAmount &&
        !this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedAmount) :
      !this.variables.isReady ||
      !this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedAmount;
  }

  get payAttentionForCalculateCommission(): boolean {
    return this.variables.payAttentionForCalculateCommission;
  }

  calculateCommissions(): void {
    if (this.calculateCommissionsDisabled) {
      this.notify.error(errorMessages.fillCommissionFields);
      return;
    }
    if (!this.variables.isTransfer) {
      this.builder.commission.calculateOrganizationCommission(
        this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedAmount,
        this.variables.operationIndex
      );
      return;
    }
    // TODO back when acceptedAmount will be active
    // if (
    //   this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_withdrawAmount &&
    //   this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedAmount
    // ) {
    //   this.notify.error(errorMessages.fillCommissionError);
    //   return;
    // }
    this.builder.commission.calculateCommission(
      this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_countryCode,
      this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedCurrency,
      this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_withdrawAmount,
      this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_withdrawCurrency,
      // this.variables.operationInfoArray[this.variables.operationIndex].formGroup.value.mainInfo_acceptedAmount,
      null,
      this.variables.operationIndex
    )
  }
}
