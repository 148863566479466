import {
  IControlGroupHelper,
  IControlHelper,
  IControlsArrayHelper,
  IStepsControlHelper
} from "../../models/common.models";
import {Guid} from "guid-typescript";
import {FormArray, FormGroup, Validators as v} from "@angular/forms";
import {addOperationStepsFunctions} from "../functions.helper";
import {onlyTrue} from "../../validators";

export function createPaymentHubControlHelper(fields: IControlHelper[]): IStepsControlHelper {
  const result = {
    items: [
      createPaymentHubControlHelperParams(
        'mainInfo',
        'Основные данные операции',
        Guid.raw(),
        fields
      ),
    ],
    formGroup: new FormGroup({}),
    formArray: new FormArray([]),
  };
  return addOperationStepsFunctions(result);
}

export function createPaymentHubControlHelperParams(
  id: string,
  title: string,
  arrayId: string,
  fields: IControlHelper[]
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createPaymentHubControlHelperControls(arrayId, fields)],
  } as IControlGroupHelper;
}
export function createPaymentHubControlHelperControls(arrayId: string, fields: IControlHelper[]): IControlsArrayHelper {
  let arr = {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isHide: true,
        isInput: true,
        inputType: `number`,
        placeholder: `Комиссия`,
        label: `Комиссия`,
        name: `organizationCommission`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        isCash: true,
        inputType: `number`,
        placeholder: `Сумма внесения`,
        label: `Сумма внесения`,
        name: `acceptedAmount`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: "separator.2",
        maskSuffix: " RUB",
        validators: [v.required],
      }
    ],
  } as IControlsArrayHelper;
  arr.controls = arr.controls.concat(fields);
  return arr;
}
