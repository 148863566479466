import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import {FileParameter} from "../../../../kernel/ApiClient";
import {errorMessages} from "../../../../kernel/constants/errors";
import {NotifyService} from "../../services/notify.service";

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent implements OnInit {
  @ViewChild('photoInput') photoInput: ElementRef;
  @Input() title: string;
  @Input() isLabel = true;
  @Input() availableTypes = null;
  @Input() abortedAll = false;
  @Input() isMulti = false;
  @Input() file?: File;
  @Input() type = 'button';
  @Output() fileChange = new EventEmitter<FileParameter>();
  @Output() filesChange = new EventEmitter<FileParameter[]>();

  fileTitle = '';
  fileSize = 0;
  isFile = false;
  selectedFile: any;

  isBtn = false;
  isText = false;

  constructor(
    private notify: NotifyService
  ) { }

  ngOnInit() {
    this.fileTitle = this.isMulti ? 'Файлы не выбраны' : 'Файл не выбран';
    this.isBtn = this.type === 'button';
    this.isText = this.type === 'text';
    this.selectedFile = this.file;
    if (!!this.selectedFile) {
      this.fileTitle = this.selectedFile.name;
      this.fileSize = this.selectedFile.size;
      this.isFile = true;
    }
  }

  onFileChange(event: any) {
    if (this.abortedAll) {
      return;
    }
    let fi = event.srcElement;
    if (fi.files && fi.files[0]) {
      const nameArr = fi.files[0].name.split('.');
      const ext = nameArr[nameArr.length - 1];
      if (!!this.availableTypes && this.availableTypes.findIndex(x => String(x).toUpperCase() === String(ext).toUpperCase()) < 0) {
        this.notify.error(errorMessages.notSupportedFile);
        return;
      }
      if (this.isMulti) {
        let modelArr: FileParameter[] = [];
        let size = 0;
        for (let i = 0; i < fi.files.length; i++) {
          modelArr.push({
            data: fi.files[i],
            fileName: fi.files[i].name
          });
          size += fi.files[i].size;
        }
        this.fileTitle = `Выбрано ${modelArr.length} файла(ов)`;
        this.fileSize = size;
        this.selectedFile = modelArr;
        this.filesChange.emit(modelArr);
        this.isFile = true;
        return;
      }
      this.fileTitle = fi.files[0].name;
      this.isFile = true;
      const model: FileParameter = {
        data: fi.files[0],
        fileName: fi.files[0].name
      };
      this.selectedFile = model;
      this.fileSize = model && model.data ? model.data.size : 0;
      this.fileChange.emit(this.selectedFile);
    }
  }

  removeFile(): void {
    if (this.abortedAll) {
      return;
    }
    this.isFile = false;
    this.fileTitle = this.isMulti ? 'Файлы не выбраны' : 'Файл не выбран';
    this.fileSize = 0;
    this.photoInput.nativeElement.value = '';
    this.selectedFile = null;
    this.fileChange.emit(null);
  }
}

