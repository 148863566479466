import {Component, Inject, OnInit} from "@angular/core";
import {Select, Store} from "@ngxs/store";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Navigate} from "@ngxs/router-plugin";
import {IOperationStatusHelper} from "../../../../kernel/models/common.models";
import {getOperationStatus} from "../../../../kernel/helpers/operation.helper";
import {errorFinalStatuses, notFinalStatuses} from "../../../../kernel/enum/operation-status.enum";
import {DecimalPipe} from "@angular/common";
import {ITransferResponse} from "../../../../kernel/ApiClient";
import {OperationVariableService} from "../../services/operation-variable.service";

@Component({
  selector: 'app-operation-loader',
  templateUrl: './operation-loader.component.html',
  styleUrls: ['./operation-loader.component.scss']
})
export class OperationLoaderComponent {

  constructor(
    public dialogRef: MatDialogRef<OperationLoaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // operations: ITransferResponse[]
    },
    private store: Store,
    private decimalPipe: DecimalPipe,
    private variables: OperationVariableService
  ) {
  }

  get operations(): ITransferResponse[] {
    return this.variables.operations;
  }
  get title(): string {
    return this.variables.confirmDialogTitle;
  }
  get message(): string {
    return this.variables.confirmDialogMessage;
  }
  get imgSrc(): string {
    return this.variables.confirmDialogImgSrc;
  }
  get type(): number {
    return this.variables.confirmDialogType;
  }
  get info(): IOperationStatusHelper[] {
    return this.variables.confirmDialogInfo;
  }

  close(resetAll = true): void {
    this.dialogRef.close(resetAll);
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]));
    this.close();
  }
}
