import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {Navigate} from "@ngxs/router-plugin";
import {IOperationStepItem} from "../../../../kernel/models/common.models";
import {OperationBuilderService} from "../../services/operation-builder.service";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss', '../mts-operation/mts-operation.component.scss']
})
export class StepperComponent {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private builder: OperationBuilderService
  ) { }

  get operationSteps(): IOperationStepItem[] {
    return this.builder.variables.operationSteps;
  }
  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get title(): string {
    return this.builder.variables.operationTypeEnum?.title ?? 'Денежный перевод';
  }
  get logo(): string {
    return this.builder.variables.operationTypeEnum.ico;
  }

  async goto(step: number): Promise<void> {
    await this.builder.stepper.gotoStep(step, 0);
  }

}
