import { Injectable } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {getErrorMessage, getUTCDate, getUTCDateTime, mapInvalidFields} from "../../../kernel/helpers/data.helper";
import {
  ApiClient,
  BillingTypeEnum,
  Client,
  ClientForUpdate,
  ClientResponse,
  Commission, CommissionAmounts,
  IClientResponse,
  ICommission,
  IOperationItemFilterResponse,
  IPaymentHubResponseOfPaymentHubOperationCheckStatusResponse,
  ITransfer,
  LoggerErrorTypeEnum,
  OperationAddOrUpdateRequestOfTransfersCreateRequest,
  PaymentHubResponseOfPaymentHubOperationCheckStatusResponse,
  TransferResponse,
  TransfersConfirmRequest,
  TransfersCreateRequest
} from "../../../kernel/ApiClient";
import {OperationVariableService} from "./operation-variable.service";
import {Store} from "@ngxs/store";
import {NotifyService} from "../../core/services/notify.service";
import {timer} from "rxjs/internal/observable/timer";
import {environment} from "../../../kernel/environments/environment";
import {notFinalStatuses, readyForConfirmStatuses} from "../../../kernel/enum/operation-status.enum";
import {createClientPayload} from "../../../kernel/helpers/client.helper";
import {PopupLoaderTypeEnum} from "../../../kernel/enum/popupLoaderTypeEnum.enum";
import {BehaviorService} from "../../core/services/behavior.service";
import {OperationHelperService} from "./operation-helper.service";
import {messages} from "../../../kernel/constants/messages";
import {createOperationPayload} from "../../../kernel/helpers/operation.helper";
import {forkJoin, Observable} from "rxjs";
import {errorMessages} from "../../../kernel/constants/errors";
import {OperationCommissionService} from "./operation-commission.service";
import * as deepEqual from "deep-equal";
import {OperationPaymentHubService} from "./operation-payment-hub.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {mockMTDynamicResponse} from "../../../kernel/mt-dynamic/services/mt-dynamic.service.spec";
import {MtDynamicService} from "../../../kernel/mt-dynamic/services/mt-dynamic.service";

@Injectable({
  providedIn: 'root',
})
export class OperationApiService {
  constructor(
    private apiClient: ApiClient,
    private store: Store,
    private notify: NotifyService,
    private behavior: BehaviorService,
    private variables: OperationVariableService,
    private helper: OperationHelperService,
    private commission: OperationCommissionService,
    private paymentHub: OperationPaymentHubService,
    private mtDynamic: MtDynamicService,
  ) {
  }

  setOperationsIds(withRun = false): void {
    this.variables.requestIds = [];
    if (this.variables.isTransfer) {
      this.variables.operations.map(operation => {
        if (!!operation && (notFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 ||
          readyForConfirmStatuses.findIndex(x => x === operation.transferStatus) > -1)) {
          this.variables.requestIds.push( operation.transferId);
        }
      });
    } else {
      this.variables.paymentHubOperations.map(operation => {
        if (!!operation && (notFinalStatuses.findIndex(x => x === operation.transferStatus) > -1 ||
          readyForConfirmStatuses.findIndex(x => x === operation.transferStatus) > -1)) {
          this.variables.requestIds.push(operation.id);
        }
      });
    }
    if (withRun) {
      this.variables.isTransfer ? this.checkOperationStatuses() : this.paymentHub.checkPaymentHubOperationStatuses();
    }
  }

  checkOperationStatuses(): void {
    setTimeout(() => {
      if (!this.variables.isReady) {
        return;
      }
      if (!this.variables.requestIds || this.variables.requestIds.length === 0) {
        this.behavior.popupLoaderStatus.next(null);
        this.variables.blockAllActions = false;
        this.stopOperationsStatusTimer();
        return;
      }
      this.apiClient
        .transfers_GetTransfersByIds(this.variables.requestIds)
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(
          data => {
            this.variables.operations = data;
            this.helper.fillOperationsForConfirmLoader();
            if (this.variables.confirmDialogType === 0) {
              if (this.variables.countRequestStatusTry > 0) {
                this.variables.countRequestStatusTry--;
                this.checkOperationStatuses();
              }
            }
          },
          error => {
            if (this.variables.countRequestStatusTry > 0) {
              this.variables.countRequestStatusTry--;
              this.checkOperationStatuses();
            } else {
              this.behavior.popupLoaderStatus.next(null);
              this.variables.requestIds = [];
              this.stopOperationsStatusTimer();
              this.notify.error(getErrorMessage(error));
            }
            console.error(error);
          }
        );
    }, (this.variables.countRequestStatusTry) * 1000);
  }

  initOperationsStatusTimer(): void {
    if (this.variables.timerStopped === false) {
      return;
    }
    this.variables.timerStopped = false;
    this.variables.timerSubscribe = timer(0, ((environment.countRequestStatusTry * (environment.countRequestStatusTry + 1) / 2) * 1000)).subscribe(
      () => {
        this.variables.countRequestStatusTry = environment.countRequestStatusTry;
        this.setOperationsIds(true);
      }
    );
  }

  stopOperationsStatusTimer(): void {
    if (this.variables.timerStopped === false) {
      this.variables.timerSubscribe.unsubscribe();
      this.variables.timerStopped = true;
    }
  }

  createOrUpdateClient(): void {
    if (!this.variables.isReady) {
      return;
    }
    this.checkPaymentSystemExist();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!this.variables.isClientNeed) {
      if (this.variables.isTransfer) {
        this.createOperation();
      } else {
        this.paymentHub.createPaymentHubOperation();
      }
      return;
    }
    this.variables.blockAllActions = true;
    const payload = createClientPayload(this.variables.clientHelper, this.variables.selectedDataAll);
    payload.birthDate = getUTCDate(payload.birthDate, true);
    payload.documents.map(x => {
      x.fields.issueDate = x.fields.issueDate ? getUTCDate(x.fields.issueDate, true) : null;
      x.fields.expiryDate = x.fields.expiryDate ? getUTCDate(x.fields.expiryDate, true) : null;
    });
    if (this.variables.client) {
      this.variables.client.birthDate = getUTCDate(this.variables.client.birthDate, true);
      this.variables.client.documents.map(x => {
        x.fields.issueDate = x.fields.issueDate ? getUTCDate(x.fields.issueDate, true) : null;
        x.fields.expiryDate = x.fields.expiryDate ? getUTCDate(x.fields.expiryDate, true) : null;
      });
    }
    payload.clientId = this.variables.client?.clientId;
    const isUpdated = deepEqual(payload, this.variables.client);
    if (this.variables.clientHelper.isCreated && isUpdated) {
      this.getClientById(payload.clientId);
      return;
    }
    this.behavior.popupLoaderStatus.next(this.variables.clientHelper.isCreated ? PopupLoaderTypeEnum.UpdateClient : PopupLoaderTypeEnum.CreateClient);
    const sub = this.variables.clientHelper.isCreated ?
      this.apiClient.clients_EditClient(payload as ClientForUpdate) :
      this.apiClient.clients_CreateClient(payload as Client);
    sub
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data) => {
          if (this.variables.client) {
            if (isUpdated) {
              this.notify.success(messages.clientUpdated);
            }
          } else {
            this.notify.success( messages.clientCreated);
          }
          this.variables.client = data;
          this.variables.clientHelper.isCreated = true;
          this.variables.clientCreated = true;
          this.behavior.popupLoaderStatus.next(null);
          this.getClientById(this.variables.client.clientId);
        },
        (error) => {
          this.behavior.popupLoaderStatus.next(null);
          this.variables.blockAllActions = false;

          if (error.message.includes("Ошибка при запросе изменения досье клиента к серверу МТС - В запросе отсутствуют параметры для изменения")){
            this.variables.clientCreated = true;
            this.variables.clientHelper.formGroup = mapInvalidFields(this.variables.clientHelper.formGroup, error.invalidFields);
            this.notify.warning(getErrorMessage(error));
            this.getClientById(this.variables.client.clientId);
          }
          else {
            this.variables.clientCreated = false;
            this.variables.clientHelper.formGroup = mapInvalidFields(this.variables.clientHelper.formGroup, error.invalidFields);
            this.notify.error(getErrorMessage(error));
          }

          console.error(error);
        },
      );
  }

  getClientById(clientId: string): void {
    if (!this.variables.isReady) {
      return;
    }
    this.apiClient.clients_FindClientById(clientId)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.variables.client = data;
          this.variables.clientCreated = true;
          if (this.variables.isTransfer) {
            this.createOperation();
          } else {
            this.paymentHub.createPaymentHubOperation();
          }
         },
        (error) => {
          this.variables.clientCreated = false;
          this.variables.blockAllActions = false;
          this.variables.clientHelper.formGroup = mapInvalidFields(this.variables.clientHelper.formGroup, error.invalidFields);
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
  }

  createOperation(): void {
    if (!this.variables.isReady) {
      return;
    }
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.CreateOperation);
    let arr: any[] = [];
    this.variables.operationInfoArray.map((x, operationIndex) => {
      const payload = {
        acceptedMoney: x.formGroup.value.mainInfo_acceptedAmount,
        operationParams: this.mtDynamic.mapRequest()
        // operationParams: createOperationPayload(this.variables.client.clientId, x, this.variables.selectedDataAll) as TransfersCreateRequest
      } as OperationAddOrUpdateRequestOfTransfersCreateRequest;
      arr.push(this.apiClient.transfers_CreateTransfer(payload));
    });
    this.variables.blockAllActions = true;
    forkJoin(arr)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data) => {
          data.map((x, index) => {
            this.variables.operationInfoArray[index].transfer = x as ITransfer;
          });
          this.variables.operationCreated = true;
          this.variables.blockAllActions = false;
          this.helper.clientAndOperationCreatedDisableForms();
          this.variables.operations = data;
          this.behavior.popupLoaderStatus.next(null);
          // TODO
          // this.initOperationsStatusTimer();
          this.behavior.operationBuilderCommandStatus.next({
            type: BuilderCommandEnum.CheckCashboxCountBeforeBilling,
            model: {
              payload: {
                numDevice: 0,
                type: BillingTypeEnum.FirstCheck,
                isPreview: true,
                isFromQueue: false,
                operationId: this.variables.operations[0].transferId
              }}
          });
        },
        (error) => {
          if (error.type === LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance) {
            this.behavior.infoDialogListenerStatus.next({
              type: LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance,
              title: error.message
            });
          }
          this.commission.recalculateAllCommissions();
          this.variables.operationCreated = false;
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
    // this.apiClient.transfers_Create()
  }

  checkPaymentSystemExist(): void {
    if (this.variables.isTransfer) {
      this.variables.operationInfoArray.map(x => {
        const arr: ICommission[] = [];
        x.commissions.map(com => {
          arr.push({
            paymentSystemId: com.paymentSystemId,
            commissionId: com.commissionId,
            countryCode: com.countryCode,
            nameCyrillic: com.nameCyrillic,
            money: com.money as CommissionAmounts,
          })
        });
        this.apiClient.commissions_CheckExistPaymentSystems(arr as Commission[])
          .pipe(takeUntil(this.variables.ngUnsubscribe))
          .subscribe(() => {}, (error) => {
            console.error(error);
          })
      });
    }
  }

  confirmOperation(confirmStepIsValid: boolean): void {
    if (!this.variables.isReady) {
      return;
    }
    if (!confirmStepIsValid) {
      this.notify.error(errorMessages.needFixErrorToContinue, null, 15000);
      return;
    }
    this.variables.blockAllActions = true;
    this.helper.startLoader();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.variables.isTransfer) {
      this.tryConfirmOperation(environment.countConfirmOperationTry);
    } else {
      this.paymentHub.tryConfirmOperation(environment.countConfirmOperationTry);
    }
  }

  tryConfirmOperation(countTry: number): void {
    setTimeout(() => {
      this.helper.fillOperationsForConfirmLoader();
      let arr: Observable<TransferResponse>[] = [];
      this.variables.operationInfoArray.map(x => {
        if (!!x.transfer) {
          arr.push(this.apiClient.transfers_ConfirmTransfer({
            transferId: x.transfer.transferId
          } as TransfersConfirmRequest));
        }
      });
      forkJoin(arr)
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(
          (data) => {
            data.map((x, index) => {
              this.variables.operationInfoArray[index].transfer = x as ITransfer;
            });
            this.variables.operations = data;
            this.helper.fillOperationsForConfirmLoader();
            this.initOperationsStatusTimer();
          },
          (error) => {
            if (error?.type === LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance) {
              countTry = 0;
              this.behavior.infoDialogListenerStatus.next({
                type: LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance,
                title: error.message
              });
            }
            if (countTry > 0) {
              countTry--;
              this.tryConfirmOperation(countTry--);
            } else {
              this.variables.blockAllActions = false;
              this.notify.error(getErrorMessage(error));
            }
            console.error(error);
          },
        );
    }, (10 - countTry) * 1000);
  }

  onClientSearch(): void {
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.ClientSearch);
    this.apiClient
      .clients_GetClientByDocumentNumber(
        String(this.variables.operationHelperGroup.value.OperationClientDocumentNumber)
      )
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        data => {
          this.variables.client = data;
          this.helper.clientChanged();
          if (data) {
            this.variables.lastFindDocumentNumber = this.variables.operationHelperGroup.value.OperationClientDocumentNumber
            this.onClientOperationSearch();
          }
          this.behavior.popupLoaderStatus.next(null);
        },
        error => {
          this.behavior.popupLoaderStatus.next(null);
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  onClientOperationSearch(): void {
    if (!this.variables.isReady) {
      return;
    }
    this.apiClient
      .transfers_FindTransfers(this.variables.client.clientId)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        data => {
          this.variables.history = data;
          if (data) {
            this.helper.openClientChooseDialog(false);
          }
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  async operationApprovalForInit(temp: number): Promise<void> {
    temp--;
    setTimeout(async () => {
      if (temp <= 0) {
        return;
      }
      if (!this.variables.operationTypeEnum) {
        await this.operationApprovalForInit(temp);
        return;
      }
      this.apiClient
        .transfers_CanCreateTransfer(this.variables.operationTypeEnum.id)
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(() => {
            return;
          },
          error => {
            if (error.type === LoggerErrorTypeEnum.OperationApprovalLowBalance ||
              error.type === LoggerErrorTypeEnum.TimeForNotForbiddenOperationCreateWithoutFiscal ||
              error.type === LoggerErrorTypeEnum.OperationTypeForbiddenForOrganization) {
              this.behavior.infoDialogListenerStatus.next({
                type: error.type,
                title: error.message
              });
              console.error(error);
            } else {
              console.error(error);
              this.notify.error(error.message);
            }
          }
        );
    }, ((environment.countRequestAccessTry - temp + 1) * 100));
  }

  getMTDynamicFields(paymentSystemId: string): void {
    if (!paymentSystemId || paymentSystemId === this.variables.dynamicPaymentSystemId) {
      return;
    }
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.GetMtDynamicFields);
    this.apiClient.transfers_GetFieldsScheme(paymentSystemId)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
          this.behavior.popupLoaderStatus.next(null);
          this.variables.transferSchema = {
            uiSchema: data.uiSchema,
            jsonSchema: data.jsonSchema,
            error: {
              code: 0,
              message: ''
            }
          };
          this.variables.dynamicPaymentSystemId = paymentSystemId;
          this.mtDynamic.mapResponse();
        },
        error => {
          console.error(error);
          this.notify.error(error.message);
          this.variables.useDefaultMtDynamicFields();
          this.behavior.popupLoaderStatus.next(null);
        }
      );
  }
}
