<div class="expand" [ngClass]="{'open': expanded}" id="filteredProposals">
  <app-hardware-proposals-modal (modalClosed)="modalClosed($event)"
                                (proposalStateChange)="getFilteredProposals()"
                   #addProposals
  ></app-hardware-proposals-modal>

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/window-success.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">ЗАПРОСЫ НА ПРИВЯЗКУ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-filter-users-proposals download-title" (click)="filterExpand = !filterExpand">Фильтры</p>
    <div class="expand-table-container">
      <div *ngIf="searchForm">
        <div class="filter-wrapper">
          <div class="filter-left-wrapper" [ngClass]="{'open': filterExpand}">
            <div class="filter-left-column-item">
              <div class="filter-left-row-item">
                <app-input inputType="select"
                           placeholder="БПА"
                           label="БПА"
                           name="OrganizationId"
                           wrapperClass="inputBox-group"
                           [contentMaxHeight]="600"
                           [selectData]="availableOrganizations || []"
                           [selectDataAll]="availableOrganizations || []"
                           [parentForm]="searchForm"></app-input>
              </div>
              <div class="filter-left-row-item">
                <app-input inputType="select"
                           placeholder="Точка"
                           label="Точка"
                           name="OrganizationPointId"
                           wrapperClass="inputBox-group"
                           [contentMaxHeight]="600"
                           [selectData]="availableOrganizationPoints || []"
                           [selectDataAll]="availableOrganizationPoints || []"
                           [parentForm]="searchForm"></app-input>
              </div>
            </div>
            <div class="filter-left-column-item mt-20">
              <div class="filter-left-row-item search">
                <app-input
                  inputType="search"
                  placeholder="Поиск"
                  name="Search"
                  width="full"
                  wrapperClass="inputBox-group"
                  [parentForm]="searchForm"></app-input>
              </div>
              <div class="filter-left-row-item">
                <app-checkbox
                  class="inputBox-group knowledge-filter-check"
                  [parentForm]="searchForm"
                  name="IsNew"
                  label="Только новые"
                ></app-checkbox>
              </div>
            </div>
            <span class="download-title" (click)="resetAll()"
               *ngIf="isNeedFilterReset">Сбросить фильтр</span>
          </div>
        </div>

       </div>

      <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!proposalResponse && proposalResponse.proposals.length > 0 else emptyList">
        <table class="table w-100">
          <thead class="table-header">
          <th class="text-align-left">ПИН</th>
          <th class="text-align-left">ФИО</th>
          <th>ТЕЛЕФОН</th>
          <th
            (click)="setSort(columns.Created)"
            [ngClass]="{'active': sortField === columns.Created, 'non-desc': sortField === columns.Created && !isDesc}">
            ДАТА СОЗДАНИЯ <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.Updated)"
            [ngClass]="{'active': sortField === columns.Updated, 'non-desc': sortField === columns.Updated && !isDesc}">
            ДАТА ИЗМЕНЕНИЯ <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          </thead>
          <tbody class="text-align-center">
          <tr *ngFor="let proposal of proposalResponse.proposals; let i=index"
              class="table-row" (click)="!isLoad && addProposals.toggleModal(proposal)">
            <td class="td-pin text-align-left">{{proposal.pin}}</td>
            <td class="td-fio text-align-left">{{proposal.proposalUser.lastName}} {{proposal.proposalUser.firstName}}</td>
            <td class="td-phone">{{proposal.proposalUser.phone | phonePipe}}</td>
            <td class="td-created">{{proposal.created | dateLocal:'dd.MM.yyyy HH:mm:ss':false:false}}</td>
            <td class="td-updated">{{proposal.updated | dateLocal:'dd.MM.yyyy HH:mm:ss':false:false}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <app-paginator #paginatorComp
        [page]="paginator.page"
        [total]="proposalResponse?.totalCount"
        [take]="paginator.take"
        [id]="paginatorId"
        (pageChange)="pageChanged($event)"
      ></app-paginator>
      <ng-template #emptyList>
        <div class="empty-list">
          <p>{{
            isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одного запроса на привязку...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
