import {OperationVariableService} from "./operation-variable.service";
import {Injectable} from "@angular/core";
import {OperationListenerHelperService} from "./operation-listener-helper.service";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {operationAddressesArray} from "../../../kernel/constants/common.constants";
import {BehaviorService} from "../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";


@Injectable({
  providedIn: 'root',
})
export class OperationListenerService {
  constructor(
    private variables: OperationVariableService,
    private listenerHelper: OperationListenerHelperService,
    private behavior: BehaviorService
  ) {
  }

  clientFormArrayListener(arrayId: string): void {
    if (!this.variables.clientHelper) {
      return;
    }
    const index = this.variables.clientHelper.formArray.value.findIndex(
      x => x.arrayId === arrayId
    );
    if (index < 0) {
      return;
    }
    if (this.variables.clientHelper.formArray.controls[index].get('documents_type')) {
      this.variables.clientHelper.formArray.controls[index]
        .get('documents_type')
        .valueChanges.pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(data => {
          if (data) {
            this.listenerHelper.fill_documentType(data, arrayId, index);
          }
        })
    }
  }

  clientBackCashListener(): void {
    this.variables.operationHelperGroup
      .get(`CashboxAmount`)
      .valueChanges.pipe(debounceTime(300), takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.variables.operationHelperGroup.get(`CashboxAmount`).setErrors(this.listenerHelper.fill_backAmountError(data));
      });
  }

  clientValueChangeListener(helper: IStepsControlHelper): IStepsControlHelper {
    helper.formGroup.valueChanges
      .pipe(
        debounceTime(1500),
        map(d => ({
          mainInfo_lastName: d.mainInfo_lastName,
          mainInfo_firstName: d.mainInfo_firstName,
          mainInfo_middleName: d.mainInfo_middleName,
        })),
        distinctUntilChanged(
          (a, b) => JSON.stringify(a) === JSON.stringify(b)
        ),
        takeUntil(this.variables.ngUnsubscribe)
      ).subscribe(d => {
        this.listenerHelper.fill_currentNonDbClientFIO(d);
    });
    return helper;
  }

  valueChangeListener(helper: IStepsControlHelper): IStepsControlHelper {
    if (helper.formGroup.get(`mainInfo_acceptedCurrency`)) {
      if (helper.getControl('mainInfo', 'acceptedCurrency', null).selectData?.length === 1) {
        helper.formGroup.get(`mainInfo_acceptedCurrency`)
          .setValue(helper.getControl('mainInfo', 'acceptedCurrency', null).selectData[0].label);
      }
      helper.formGroup.get(`mainInfo_withdrawCurrency`).disable();
      helper.formGroup.get(`mainInfo_withdrawAmount`).disable();
      helper.formGroup.get(`mainInfo_acceptedAmount`).disable();

      helper.formGroup.get(`mainInfo_withdrawCurrency`).valueChanges
        .pipe(debounceTime(100),
          takeUntil(this.variables.ngUnsubscribe))
        .subscribe(() => {
          this.listenerHelper.fill_withdrawAmountCurrencySuffix(helper);
        });

      helper.formGroup.valueChanges
        .pipe(
          debounceTime(100),
          filter((d: any) =>
            d.mainInfo_countryCode ||
            d.mainInfo_acceptedCurrency ||
            d.mainInfo_withdrawCurrency ||
            d.mainInfo_withdrawAmount),
          map((d) => ({
            mainInfo_countryCode: d.mainInfo_countryCode,
            mainInfo_acceptedCurrency: d.mainInfo_acceptedCurrency,
            mainInfo_withdrawCurrency: d.mainInfo_withdrawCurrency,
            mainInfo_withdrawAmount: d.mainInfo_withdrawAmount,
          })),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
          takeUntil(this.variables.ngUnsubscribe),
        )
        .subscribe((d) => {
          this.listenerHelper.fill_commissionFormByCountryId(helper, d);
        });
    }
    if (helper.formGroup.get(`mainInfo_countryCode`)) {
      helper.formGroup.get(`mainInfo_countryCode`).valueChanges
        .subscribe(data => {
          // MT DYNAMIC
          this.listenerHelper.fill_infoByMainInfoCountry(helper, data);
        });
    }
    operationAddressesArray.map(
      addressPrefix => {
        if (helper.formGroup.get(`${addressPrefix}_isConsider`)) {
          helper.formGroup
            .get(`${addressPrefix}_isConsider`)
            .valueChanges.pipe(
            debounceTime(1500),
            takeUntil(this.variables.ngUnsubscribe))
            .subscribe(data => {
              this.listenerHelper.fill_isConsiderAddress(helper, data, addressPrefix);
            });
        }

        if (helper.formGroup.get(`${addressPrefix}_city`)) {
          helper.formGroup.get(`${addressPrefix}_city`).valueChanges
            .pipe(debounceTime(100),
              takeUntil(this.variables.ngUnsubscribe))
            .subscribe(() => {
              helper.formGroup.get(`${addressPrefix}_locality`).setValue(helper.formGroup.value[`${addressPrefix}_city`]);
            });
        }

        if (helper.formGroup.get(`${addressPrefix}_countryCode`) ||
          helper.formGroup.get(`${addressPrefix}_zipCode`) ||
          helper.formGroup.get(`${addressPrefix}_region`) ||
          helper.formGroup.get(`${addressPrefix}_district`) ||
          helper.formGroup.get(`${addressPrefix}_city`) ||
          helper.formGroup.get(`${addressPrefix}_locality`) ||
          helper.formGroup.get(`${addressPrefix}_street`) ||
          helper.formGroup.get(`${addressPrefix}_house`) ||
          helper.formGroup.get(`${addressPrefix}_block`) ||
          helper.formGroup.get(`${addressPrefix}_building`) ||
          helper.formGroup.get(`${addressPrefix}_apartment`)) {
          helper.formGroup.valueChanges
            .pipe(
              debounceTime(1000),
              filter(
                (d: any) =>
                  d[`${addressPrefix}_countryCode`] ||
                  d[`${addressPrefix}_zipCode`] ||
                  d[`${addressPrefix}_region`] ||
                  d[`${addressPrefix}_district`] ||
                  d[`${addressPrefix}_city`] ||
                  d[`${addressPrefix}_locality`] ||
                  d[`${addressPrefix}_street`] ||
                  d[`${addressPrefix}_house`] ||
                  d[`${addressPrefix}_block`] ||
                  d[`${addressPrefix}_building`] ||
                  d[`${addressPrefix}_apartment`]
              ),
              map(d => ({
                [`${addressPrefix}_countryCode`]: d[
                  `${addressPrefix}_countryCode`
                  ],
                [`${addressPrefix}_zipCode`]: d[`${addressPrefix}_zipCode`],
                [`${addressPrefix}_region`]: d[`${addressPrefix}_region`],
                [`${addressPrefix}_district`]: d[`${addressPrefix}_district`],
                [`${addressPrefix}_city`]: d[`${addressPrefix}_city`],
                [`${addressPrefix}_locality`]: d[`${addressPrefix}_locality`],
                [`${addressPrefix}_street`]: d[`${addressPrefix}_street`],
                [`${addressPrefix}_house`]: d[`${addressPrefix}_house`],
                [`${addressPrefix}_block`]: d[`${addressPrefix}_block`],
                [`${addressPrefix}_building`]: d[`${addressPrefix}_building`],
                [`${addressPrefix}_apartment`]: d[`${addressPrefix}_apartment`],
              })),
              distinctUntilChanged(
                (a, b) => JSON.stringify(a) === JSON.stringify(b)
              ),
              takeUntil(this.variables.ngUnsubscribe)
            )
            .subscribe(() => {
              this.listenerHelper.fill_isConsiderAddressAllFields(helper, addressPrefix);
              this.listenerHelper.fill_checkIsConsiderAddressAllFields(helper);
            });
        }
      }
    );

    this.listenerHelper.fill_isClientNeed();
    if (helper.formGroup.get(`mainInfo_acceptedAmount`)) {
      helper.formGroup.get(`mainInfo_acceptedAmount`)
        .valueChanges.pipe(
        debounceTime(100),
        takeUntil(this.variables.ngUnsubscribe))
        .subscribe(data => {
          this.listenerHelper.fill_isClientNeed();
        });
    }

    if (helper.formGroup.get(`mainInfo_paymentSystemId`)) {
      helper.formGroup.get(`mainInfo_paymentSystemId`).valueChanges
        .subscribe(data => {
          this.listenerHelper.setValidator_BeneficiaryAccountNumber(helper, data);
        });
    }
    return helper;
  }


  clientDocumentChangeListener(): void {
    this.variables.operationHelperGroup.valueChanges
      .pipe(
        debounceTime(1500),
        filter(
          d =>
            !!d.OperationClientDocumentNumber &&
            d.OperationClientDocumentNumber.length > 7
        ),
        map(d => ({
          operationClientDocumentNumber: d.OperationClientDocumentNumber,
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.variables.ngUnsubscribe)
      )
      .subscribe(() => {
        this.behavior.operationBuilderCommandStatus.next({ type: BuilderCommandEnum.OnClientSearch });
      });
    this.variables.operationHelperGroup.controls.OperationClientDocumentType.setValue(
      !!this.variables.selectedDataAll.documents && !!this.variables.selectedDataAll.documents[0]
        ? this.variables.selectedDataAll.documents[0].label
        : null
    );
  }
}
