import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {getUTCDateTime} from "../helpers/data.helper";

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'dateLocal'
})
export class DateLocalPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(date: Date, format: string, withOffset = true, onlyDate = false): any {
    if (!date) {
      return '';
    }

    const temp = getUTCDateTime(date);
    if (withOffset) {
      return this.datePipe.transform(temp, format) + (onlyDate ? '' : this.offset);
    }
    return this.datePipe.transform(temp, format);
  }

  get offset(): string {
    const offset = (-1) * (new Date().getTimezoneOffset());
    const hour = (offset - (offset % 60)) / 60;
    const minute = offset % 60;
    return ' ' + (offset > 0 ? '+' : '') + ((hour > 9 ? hour : '0' + hour) + ':' + (minute > 9 ? minute : '0' + minute));
  }
}
