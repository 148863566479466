import {
  IOperationConfirmRequest,
  IPaymentHubOperationCreateRequest,
  OperationTypeEnum,
  PaymentHubOperationSourceProductTypeEnum
} from "../../ApiClient";
import {FormGroup, Validators as v} from "@angular/forms";

//TODO add additional fields
export function createPaymentHubOperationPayload(
  clientId: string,
  formGroup: FormGroup,
  operationType: OperationTypeEnum,
): IPaymentHubOperationCreateRequest {
  return {
    sourceProductType: PaymentHubOperationSourceProductTypeEnum.Cash,
    operationType,
    nonMaskedSourceProductId: getNonMaskedProductId(formGroup, operationType),
    clientId,
    totalSum: formGroup.value.mainInfo_acceptedAmount,
    sum: formGroup.value.mainInfo_acceptedAmount,
    additionalFieldList: getAdditionalFieldList(formGroup, operationType),
  } as IPaymentHubOperationCreateRequest;
}

export function confirmPaymentHubOperationPayload(
  operationId: string
): IOperationConfirmRequest {
  return {
    operationId,
    additionalFieldList: []
  } as IOperationConfirmRequest;
}

export function getAdditionalFieldList(
  formGroup: FormGroup,
  operationType: OperationTypeEnum
): {name: string; value: string}[] {
  switch (operationType) {
    case OperationTypeEnum.UniversalBankPH:
    case OperationTypeEnum.DocCredoBankPH:
    case OperationTypeEnum.DushanbeCityСortiMilliPH:
    case OperationTypeEnum.MobidramPH:    
    case OperationTypeEnum.AlifBankByPhonePH:
    case OperationTypeEnum.DushanbeCityByPhonePH:
    case OperationTypeEnum.DushanbeCityForSVTFavriByPhonePH:
    case OperationTypeEnum.DushanbeCityForAmonatBankByPhonePH:
    case OperationTypeEnum.DushanbeCityForAziziLightningByPhonePH:
    case OperationTypeEnum.DushanbeCityForBankArvandByPhonePH:
    case OperationTypeEnum.DushanbeCityForMDOArdoByPhonePH:
      return [
        {name: 'id1', value: formGroup.value.mainInfo_id1},
        {name: 'id2', value: formGroup.value.mainInfo_id2},
        {name: 'id3', value: formGroup.value.mainInfo_id3},
        {name: 'id4', value: formGroup.value.mainInfo_id4}
      ];
    case OperationTypeEnum.AnyCardsCashless:
      return [
        {name: 'id1', value: formGroup.value.mainInfo_id1},
        {name: 'cardExpiry', value: formGroup.value.mainInfo_cardExpiry},
        {name: 'payerName', value: formGroup.value.mainInfo_payerName}
      ];
    case OperationTypeEnum.OrienBankByPhonePH:
      return [
        {name: 'id1', value: formGroup.value.mainInfo_phoneNumber},
      ];
    default:
      return [];
  }
}

export function getNonMaskedProductId(
  formGroup: FormGroup,
  operationType: OperationTypeEnum
): string {
  switch (operationType) {
    case OperationTypeEnum.TinkoffCardPH:
    case OperationTypeEnum.HomeCreditCardPH:
    case OperationTypeEnum.RusStandardCardPH:
    case OperationTypeEnum.MTSBankCardPH:
    case OperationTypeEnum.TinkoffLoanPH:
    case OperationTypeEnum.HomeCreditLoanPH:
    case OperationTypeEnum.RusStandardLoanByAccountPH:
    case OperationTypeEnum.MTSBankLoanPH:
    case OperationTypeEnum.RusStandardLoanByAgreementNumberPH:
    case OperationTypeEnum.MTSPhonePH:
    case OperationTypeEnum.BeelinePhonePH:
    case OperationTypeEnum.MegaphonePhonePH:
    case OperationTypeEnum.Tele2PhonePH:
    case OperationTypeEnum.SkylinkGeneralPhonePH:
    case OperationTypeEnum.TinkoffPhonePH:
    case OperationTypeEnum.MTSSatelliteTVPH:
    case OperationTypeEnum.MTSIneractiveSatelliteTVPH:
    case OperationTypeEnum.MTSFixedByPhoneNumberPH:
    case OperationTypeEnum.MGTSPH:
    case OperationTypeEnum.RusTelecomPH:
    case OperationTypeEnum.MTSPhoneByAccountNumberPH:
    case OperationTypeEnum.MTSFixedByAccount11_13PH:
    case OperationTypeEnum.MTSFixedByAccount12PH:
    case OperationTypeEnum.MGTSByAccountNumberPH:
    case OperationTypeEnum.UniversalBankPH:
    case OperationTypeEnum.DocCredoBankPH:
    case OperationTypeEnum.DushanbeCityСortiMilliPH:
    case OperationTypeEnum.MobidramPH:
    case OperationTypeEnum.OrienBankByPhonePH:
    case OperationTypeEnum.AlifBankByPhonePH:
    case OperationTypeEnum.DushanbeCityByPhonePH:
    case OperationTypeEnum.DushanbeCityForSVTFavriByPhonePH:
    case OperationTypeEnum.DushanbeCityForAmonatBankByPhonePH:
    case OperationTypeEnum.DushanbeCityForAziziLightningByPhonePH:
    case OperationTypeEnum.DushanbeCityForBankArvandByPhonePH:
    case OperationTypeEnum.DushanbeCityForMDOArdoByPhonePH:
    case OperationTypeEnum.UzbekistanPhonePH:
    case OperationTypeEnum.AnyCardsPH:
    case OperationTypeEnum.AnyCardsCashless:
    case OperationTypeEnum.YotaInternetPH:
    case OperationTypeEnum.YotaPhonePH:
      return formGroup.value.mainInfo_id1;
    default:
      return null;
  }
}
