import {Pipe, PipeTransform} from '@angular/core';
import {answerPipeTransform} from "./pipe.helper";

@Pipe({
  name: 'answerPipe',
})
export class AnswerPipe implements PipeTransform {
  transform(data: string | boolean): string {
    return answerPipeTransform(data);
  }
}
