<div class="expand" [ngClass]="{'open': expanded}" id="operationSettings" xmlns="http://www.w3.org/1999/html">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/mac-with-signal-sign.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКИ ПК</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.min-height.px]="150" *ngIf="keyInfo">

      <ng-container *ngIf="keyInfo.key">
        <p class="hardware-key-info mt-30 mb-20">Ключ ККМ для этого ПК</p>
        <div class="hardware-key-info" appClipboard [copyText]="keyInfo.key">
          <p class="hardware-key-info">{{ keyInfo.key }}</p>
          <span class="download-title">Скопировать ключ</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!keyInfo.key">
        <p class="hardware-key-info mt-50">К данному ПК не привязан ключ ККМ</p>
        <p class="hardware-key-info" *ngIf="keyInfo.current < keyInfo.max">Вы можете <span class="download-title" (click)="getKkmKeyForPc()">добавить</span> для этого ПК ключ ККМ.</p>
        <p class="hardware-key-info" *ngIf="keyInfo.current >= keyInfo.max">Вы не можете добавить для этого ПК ключ ККМ. Исчерпан лимит для БПА. Обратитесь к Администратору.</p>
      </ng-container>

<!--      <div class="t-align-center mt-50 pb-30">-->
<!--        <button class="button main" [ngClass]="{'disabled': isLoad}" (click)="updateHardwareSetting()">Обновить</button>-->
<!--      </div>-->

    </div>
  </div>
</div>
