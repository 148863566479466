import {Injectable} from '@angular/core';
import {ICommissionItemHelper, IOperationDataItemHelper} from "../../../kernel/models/common.models";
import {nullOrUndefinedOrEmpty} from "../../../kernel/helpers/data.helper";
import {DateLocalPipe} from "../../../kernel/pipes/date-local.pipe";
import {DecimalPipe} from "@angular/common";
import {convertPhone} from "../../../kernel/helpers/user.helper";
import {answerPipeTransform, convertToCardNumber} from "../../../kernel/pipes/pipe.helper";
import {getOperationStatus} from "../../../kernel/helpers/operation.helper";

@Injectable({
  providedIn: 'root',
})
export class PipeTransformService {

  constructor(
    private dateLocalPipe: DateLocalPipe,
    private numberPipe: DecimalPipe
  ) {
  }

  convertToValue(item: IOperationDataItemHelper): string {
    if (nullOrUndefinedOrEmpty(item) || nullOrUndefinedOrEmpty(item.value)) {
      return '';
    }
    if (item.isDate) {
      return this.convertToDateString(item.value);
    }
    if (item.isDateTime) {
      return this.convertToDateTimeString(item.value);
    }
    if (item.isCash) {
      return this.convertToCashString(item.value);
    }
    if (item.isPhone) {
      return this.convertToPhoneString(item.value);
    }
    if (item.isYesOrNo) {
      return this.convertToAnswerString(item.value);
    }
    if (item.isCard) {
      return this.convertToCardNumberString(item.value);
    }
    if (item.isOperationStatus) {
      return this.convertToOperationStatusString(item.value);
    }
    return String(item.value);
  }

  convertComToValue(item: ICommissionItemHelper): string {
    if (nullOrUndefinedOrEmpty(item) || nullOrUndefinedOrEmpty(item.value)) {
      return '';
    }
    if (!!item.currency) {
      return `${item.isRate ? this.convertToRateString(item.value) : this.convertToCashString(item.value)} ${item.currency ?? 'RUB'}`
    }
    return String(item.value);
  }

  convertToDateString(value: any): string {
    return this.dateLocalPipe.transform(value, 'dd.MM.yyyy', false, true);
  }
  convertToDateTimeString(value: any): string {
    return this.dateLocalPipe.transform(value, 'HH:mm:ss dd.MM.yyyy', false);
  }
  convertToCashString(value: any): string {
    return this.numberPipe.transform(value, '1.2', 'ru');
  }
  convertToRateString(value: any): string {
    return this.numberPipe.transform(value, '1.4', 'ru');
  }
  convertToPhoneString(value: any): string {
    return convertPhone(value);
  }
  convertToAnswerString(value: any): string {
    return answerPipeTransform(value);
  }
  convertToCardNumberString(value: any): string {
    return convertToCardNumber(value);
  }
  convertToOperationStatusString(value: any): string {
    return getOperationStatus(value).status;
  }
}
