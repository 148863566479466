export function answerPipeTransform(data: string | boolean): string {
  const val = /TRUE/i.test(String(data).toUpperCase());
  return val ? 'Да' : 'Нет';
}

export function convertToCardNumber(data: string): string {
  if (!data || data.length < 16) {
    return data;
  }
  if (data.length === 16) {
    `${data.substring(0,4)} ${data.substring(4,8)} ${data.substring(8,12)} ${data.substring(12,16)}`
  }
  if (data.length === 19) {
    `${data.substring(0,4)} ${data.substring(4,8)} ${data.substring(8,12)} ${data.substring(12,16)} ${data.substring(16,19)}`
  }
  return  data;
}
