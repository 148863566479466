import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators as v} from "@angular/forms";
import {validateEmail} from "../../../kernel/validators";
import {LoginProviderEnum} from "../../../kernel/enum/login-provider.enum";
import {
  IHardwareData,
  UserForgotPasswordRequest,
  UserLoginRequest,
  UserRestorePasswordRequest
} from "../../../kernel/ApiClient";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {isExpectedError, mapInvalidFields} from "../../../kernel/helpers/data.helper";
import {errorMessages} from "../../../kernel/constants/errors";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  public authGroup: FormGroup;
  public isForgotByPhone = true;
  public isUrlCorrect = false;
  public url: string;

  get fv(): { [k: string]: string | number | boolean | File | Date | unknown } {
    return this.authGroup.value;
  }

  get f(): { [k: string]: AbstractControl } {
    return this.authGroup.controls
  }

  get isLoginCorrect(): boolean {
    return (
      !!this.authGroup &&
      !this.f.Phone.errors &&
      !this.f.Password.errors
    );
  }

  get isForgotCorrect(): boolean {
    return !!this.authGroup && !this.f.Phone.errors;
  }

  get isForgotByPhoneCorrect(): boolean {
    return !!this.authGroup && !this.f.Phone.errors;
  }

  get isForgotByEmailCorrect(): boolean {
    return !!this.authGroup && !this.f.Email.errors;
  }

  get isRestoreCorrect(): boolean {
    return (
      !!this.authGroup &&
      !this.f.Phone.errors &&
      !this.f.Email.errors &&
      !this.f.NewPassword.errors &&
      !this.f.RepeatNewPassword.errors &&
      this.isNewPasswordCompared
    );
  }

  get isNewPasswordCompared(): boolean {
    return (
      !!this.authGroup &&
      ((!!this.fv.NewPassword &&
        !!this.fv.RepeatNewPassword &&
        this.fv.NewPassword ===
        this.fv.RepeatNewPassword) ||
        (!this.fv.NewPassword &&
          !this.fv.RepeatNewPassword))
    );
  }

  loginPayload(hardware: IHardwareData): UserLoginRequest {
    return {
      phone: `+7${String(this.fv.Phone)}`,
      provider: String(LoginProviderEnum.MTSPhone),
      password: this.fv.Password,
      remember: this.fv.RememberMe,
      hardware
    } as UserLoginRequest
  }

  get forgotPayload(): UserForgotPasswordRequest {
    return {
      login: this.isForgotByPhone
        ? `+7${String(this.authGroup.value.Phone)}`
        : this.authGroup.value.Email,
    } as UserForgotPasswordRequest
  }

  get restorePayload(): UserRestorePasswordRequest {
    return {
      phone: `+7${String(this.authGroup.value.Phone)}`,
      email: this.authGroup.value.Email,
      password: this.authGroup.value.NewPassword,
      repeatPassword: this.authGroup.value.RepeatNewPassword,
      url: this.url,
    } as UserRestorePasswordRequest
  }

  setDefaultValidators(): void {
    this.f.Phone.setValidators([v.required]);
    this.f.Email.setValidators([v.required, validateEmail()]);
    this.f.Password.setValidators([v.required]);
    this.f.NewPassword.setValidators(null);
    this.f.RepeatNewPassword.setValidators(null);
    this.f.RememberMe.setValidators(null);
  }

  setDefaultState(): void {
    if (!this.authGroup) {
      return;
    }
    this.f.Phone.setValue('');
    this.f.Email.setValue(null);
    this.f.Password.setValue(null);
    this.f.NewPassword.setValue(null);
    this.f.RepeatNewPassword.setValue(null);
    this.f.RememberMe.setValue(false);
  }

  createFormControls(): void {
    this.authGroup = new FormGroup({
      Phone: new FormControl('', [v.required]),
      Email: new FormControl(null, [v.required, validateEmail]),
      Password: new FormControl(null, [v.required]),
      NewPassword: new FormControl(null),
      RepeatNewPassword: new FormControl(null),
      RememberMe: new FormControl(false),
    });
    this.setDefaultValidators();

    this.authGroup.valueChanges
      .pipe(
        debounceTime(100),
        filter(d => d.NewPassword && d.RepeatNewPassword),
        map(d => ({
          newPassword: d.NewPassword,
          repeatNewPassword: d.RepeatNewPassword,
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(d => {
        this.authGroup.controls.NewPassword.setErrors(
          !!d.newPassword &&
          !!d.repeatNewPassword &&
          d.newPassword !== d.repeatNewPassword
            ? {pwdDontMatch: true}
            : null
        );
      });
  }

  showError(error: any): string {
    if (isExpectedError(error)) {
      this.authGroup = mapInvalidFields(
        this.authGroup,
        error.invalidFields
      );
      return error.message;
    }
    return errorMessages.serverRequestError;
  }
}
