import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {
  ApiClient,
  BillingTypeEnum,
  CreateBillRequest,
  ICreateBillRequest,
  ICreatePreBillRequest
} from "../../../../kernel/ApiClient";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotifyService} from "../../../core/services/notify.service";
import {Store} from "@ngxs/store";
import {BehaviorService} from "../../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../../kernel/enum/builder-command.enum";
import {takeUntil} from "rxjs/operators";
import {getErrorMessage} from "../../../../kernel/helpers/data.helper";
import {IUnitListResponse} from "../../../../kernel/models/billing/kkm.models";

@Component({
  selector: 'app-bill-preview-dialog',
  templateUrl: './bill-preview-dialog.component.html',
  styleUrls: ['./bill-preview-dialog.component.scss']
})
export class BillPreviewDialogComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  payload: ICreateBillRequest;
  prePayload: ICreatePreBillRequest
  bill: any;
  isLoad = true;

  constructor(
    public dialogRef: MatDialogRef<BillPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      payload: ICreateBillRequest, prePayload: ICreatePreBillRequest
    },
    private apiClient: ApiClient,
    private notify: NotifyService,
    private store: Store,
    private behavior: BehaviorService
  ) {
    if (!data) {
      this.close();
    }
    this.payload = data.payload;
    this.prePayload = data.prePayload;
  }

  ngOnInit(): void {
    (this.payload.type === BillingTypeEnum.TestCheck ? this.apiClient.billing_GetTestBill(this.payload as CreateBillRequest) :
      this.apiClient.billing_GetBill(this.payload as CreateBillRequest))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.bill = data;
        this.isLoad = false;
      }, error => {
        this.isLoad = false;
        this.notify.error(getErrorMessage(error));
        this.close();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  printBill(): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.PrintBill,
      model: { bill: this.bill, helper: this.payload  }
    });
    this.close();
  }

  queueBill(): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.CreateBillQueueItem,
      model: { type: this.payload.type, operationId: this.payload.operationId, body: this.prePayload }
    });
    this.close();
  }
}
