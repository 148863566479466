<div class="dialog-wrapper" [style.max-width.px]="650">
  <header class="dialog-header">
    <p class="dialog-title">Выберите ККТ для печати!</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper" *ngIf="cashbox && cashboxForm && availableCashbox?.length > 0">

    <div class="cashbox-items-wrapper mb-30">
      <app-radio
        [controlInvalid]="submitted"
        [inline]="false"
        [showRole]="true"
        name="NumDevice"
        [selectData]="availableCashbox || []"
        [parentForm]="cashboxForm"></app-radio>
    </div>

  </div>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main"
              [style.width.px]="185" (click)="printBill()">Подтвердить</button>
      <button class="button unfilled decoration-under" (click)="queueBill()">Добавить в очередь</button>
      <button class="button unfilled decoration-under" (click)="close()">Закрыть</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <div>
        <button class="button border decoration-under" [style.width.px]="135"
                (click)="close()" [style.margin-right.px]="20">Закрыть</button>
        <button class="button main f-right"
                [style.width.px]="185" (click)="printBill()">Подтвердить</button>
      </div>
      <div class="t-align-center mt-20">
        <button class="button unfilled decoration-under" (click)="queueBill()">Добавить в очередь</button>
      </div>
    </div>


  </footer>

</div>
