import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {PersonalAreaComponent} from './app/pages/personal-area/personal-area.component';
import {AuthGuard} from '../kernel/guards/auth.guard';
import {HomeComponent} from './app/pages/home/home.component';
import {OperationsComponent} from './operations-list/pages/operations/operations.component';
import {SupportComponent} from './app/pages/support/support.component';
import {SettingsComponent} from './settings/pages/settings/settings.component';
import {MtsOperationComponent} from './mts-operation/pages/mts-operation/mts-operation.component';
import {AuthAreaComponent} from "./auth/pages/auth-area/auth-area.component";
import {AuthLoginComponent} from "./auth/pages/auth-login/auth-login.component";
import {AuthForgotComponent} from "./auth/pages/auth-forgot/auth-forgot.component";
import {AuthRestoreComponent} from "./auth/pages/auth-restore/auth-restore.component";
import {QueueComponent} from "./billing/pages/queue/queue.component";
import {SsoAuthComponent} from "./app/pages/sso-auth/sso-auth.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'auth/login'},
  {
    path: '',
    component: PersonalAreaComponent,
    children: [
      {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
      {
        path: 'operations',
        component: OperationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'sso-auth',
        component: SsoAuthComponent
      },
      {
        path: 'operation/:operationType/:step',
        component: MtsOperationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'operation/:operationType/:step/:id',
        component: MtsOperationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'operation/:operationName',
        pathMatch: 'full',
        redirectTo: 'operation/:operationName/1',
      },
      {path: 'support', component: SupportComponent, canActivate: [AuthGuard]},
      {path: 'queue', component: QueueComponent, canActivate: [AuthGuard]},
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
      },
      // {path: '**', pathMatch: 'full', redirectTo: 'home'}
    ]
  },
  {
    path: 'auth',
    component: AuthAreaComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'login'},
      {path: 'login', component: AuthLoginComponent},
      {path: 'forgot', component: AuthForgotComponent},
      {path: 'restore', component: AuthRestoreComponent},
      {path: 'restore/:url', component: AuthRestoreComponent},
      {path: '**', pathMatch: 'full', redirectTo: 'login'}
    ],
  },
  {path: '**', pathMatch: 'full', redirectTo: 'auth'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
