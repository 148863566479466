import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {NotifyService} from "../../../core/services/notify.service";
import {Observable, Subject, Subscription} from "rxjs";
import {BehaviorService} from "../../../core/services/behavior.service";
import {BillingTypeEnum, IUserResponse} from "../../../../kernel/ApiClient";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {Guid} from "guid-typescript";
import {UserState} from "../../../../kernel/store/state/user.state";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  subs = new Subscription();

  phoneArr = [];

  constructor(
    private notify: NotifyService,
    private store: Store,
    private behavior: BehaviorService,
    private billing: BillingBuilderService
  ) { }

  ngOnInit(): void {
    this.phoneArr = [];
    this.phoneArr.push('+74959091508');
    this.behavior.initCenterStatus.next(true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createTestCheck(): void {
    this.billing.checkCashboxCountBeforeBilling({
      numDevice: 0,
      type: BillingTypeEnum.TestCheck,
      isPreview: true,
      isFromQueue: false,
      operationId: Guid.raw()
    });
  }
}

