import { Injectable } from '@angular/core';
import {
  IMtDynamicFiledHelper,
  IMtJsonSchemaResponse, IMtSchemaResponse,
  IMtUiSchemaResponse,
} from "../models/mt-dynamic.models";
import {OperationVariableService} from "../../../modules/mts-operation/services/operation-variable.service";
import {Guid} from "guid-typescript";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {getIdByValue, getValueById} from "../../helpers/data.helper";

@Injectable({
  providedIn: 'root',
})

export class MtDynamicService {
  constructor(
    private variables: OperationVariableService
  ) {}

  get transferSchema(): IMtSchemaResponse | undefined {
    return this.variables.transferSchema;
  }
  get transferFields(): IMtDynamicFiledHelper[] | undefined {
    return this.variables.transferFields;
  }

  public mapResponse(): void {
    this.variables.transferFields = [];
    this.variables.transferArrayFields = [];
    this.variables.transferDynamicFormArray.clear();
    this.variables.transferDynamicFormGroup = new FormGroup({});
    
    this.variables.transferFields =
      this.updateWithUiSchema([],
        this.transferSchema.jsonSchema.required,
        this.transferSchema.jsonSchema.properties,
        this.transferSchema.jsonSchema.anyOf,
        this.transferSchema.uiSchema,
        null,
        '',
        'object',
        null,
        false);
        this.updateWithControls(this.variables.transferFields);

    this.refillWithHistory();
    this.setDefaultsIfNeed(this.variables.transferFields);
  }

  public refillFirstStep(): void {
    this.fillControls();
  }

  private getClientAddress(addressType: string): string {
    return !!this.variables.transferForDynamicData &&
      !!this.variables.transferForDynamicData.beneficiary &&
      !!this.variables.transferForDynamicData.beneficiary.addresses &&
      !!this.variables.transferForDynamicData.beneficiary.addresses[addressType] &&
      !!this.variables.transferForDynamicData.beneficiary.addresses[addressType].countryCode ?
      this.variables.transferForDynamicData.beneficiary.addresses[addressType].countryCode : null;
  }

  public refillWithHistory(): void {
    if (!this.variables.transferForDynamicData) {
      return;
    }

    const arr = [
      { ctrl: 'beneficiary_&&&_lastName', value: this.variables.transferForDynamicData.beneficiary.lastName },
      { ctrl: 'beneficiary_&&&_firstName', value: this.variables.transferForDynamicData.beneficiary.firstName },
      { ctrl: 'beneficiary_&&&_middleName', value: this.variables.transferForDynamicData.beneficiary.middleName },
      { ctrl: 'beneficiary_&&&_addresses_&&&_residentalAddress_&&&_countryCode', value: getValueById(
          this.variables.selectedDataAll.countriesAddresses,
          this.getClientAddress('residentalAddress'),
        ) },
      { ctrl: 'beneficiary_&&&_addresses_&&&_registrationAddress_&&&_countryCode', value: getValueById(
          this.variables.selectedDataAll.countriesAddresses,
          this.getClientAddress('registrationAddress'),
        ) },
      { ctrl: 'beneficiary_&&&_addresses_&&&_birthPlaceAddress_&&&_countryCode', value: getValueById(
          this.variables.selectedDataAll.countriesAddresses,
          this.getClientAddress('birthPlaceAddress'),
        ) },
    ];

    arr.map(x => {
      //заполняем значением из истории переводов только в том случае если default не указан     
      if (!!this.variables.transferDynamicFormGroup.get(x.ctrl)
      && !this.variables.transferDynamicFormGroup.get(x.ctrl)['schema'].default) {
        this.variables.transferDynamicFormGroup.get(x.ctrl).setValue(x.value);
      }
    });

    const arrToRemove = [
      'beneficiary_&&&_phoneNumbers'
    ];

    let removeIndex = 0;

    while (removeIndex > -1) {
      removeIndex = this.variables.transferDynamicFormArray.controls.findIndex(x => arrToRemove.findIndex(y => y === x.get('path').value) > -1);

      if (removeIndex > -1) {
        this.removeDynamicFormArrayItem(this.variables.transferDynamicFormArray.controls[removeIndex].get('arrayId').value, true);
      }
    }

    let fieldIndex = this.variables.transferArrayFields.findIndex(x => x.controlName === 'beneficiary_&&&_phoneNumbers');
    if (!!this.variables.transferForDynamicData.beneficiary.phoneNumbers) {
      this.variables.transferForDynamicData.beneficiary.phoneNumbers.map((x) => {
        if (fieldIndex > -1) {
          let arrayId = this.addDynamicFormArrayItem(this.variables.transferArrayFields[fieldIndex].children, this.variables.transferArrayFields[fieldIndex].typeArrayId, 'beneficiary_&&&_phoneNumbers');
          let arrIndex = this.variables.transferDynamicFormArray.controls.findIndex(x => String(x.get('arrayId').value) === String(arrayId));
          if (arrIndex > -1) {
            this.variables.transferDynamicFormArray.controls[arrIndex].get('beneficiary_&&&_phoneNumbers_&&&_number').setValue(x.number);
          }
        }
    });
    } else {
      this.addDynamicFormArrayItem(this.variables.transferArrayFields[fieldIndex].children, this.variables.transferArrayFields[fieldIndex].typeArrayId, 'beneficiary_&&&_phoneNumbers');
    }
  }

  public mapRequest(): any {
    let result = {};
    this.fillControls();
    result = this.buildCreateRequest(result, this.variables.transferFields);
    return result;
  }


  public addDynamicFormArrayItem(fields: IMtDynamicFiledHelper[] | undefined, typeArrayId: string, path: string): string {
    const formGroup = new FormGroup({});
    fields.map(field => {
      if (field.type !== 'object' && field.type !== 'array') {
        let ctrl = new FormControl(null,this.getFieldVValidators(field));
        if (!!field.default) {
          ctrl.setValue(field.default);
        }
        ctrl['schema'] = field;
        formGroup.addControl(field.controlName, ctrl)
      }
    });

    const arrayId = Guid.raw();
    const tempId = new FormControl(arrayId, null);
    const tempTypeId = new FormControl(typeArrayId, null);
    const tempPath = new FormControl(path, null);
    tempId.markAsDirty();
    tempTypeId.markAsDirty();
    formGroup.addControl(`arrayId`, tempId);
    formGroup.addControl(`typeArrayId`, tempTypeId);
    formGroup.addControl(`path`, tempPath);
    this.variables.transferDynamicFormArray.push(formGroup);

    return arrayId;
  }

  public removeDynamicFormArrayItem(arrayId: string | undefined, withLast: boolean = false): void {
    const index = this.variables.transferDynamicFormArray.controls.findIndex(fg => (fg as FormGroup).get('arrayId').value === arrayId)
    const anotherIndex = this.variables.transferDynamicFormArray.controls.findIndex(fg =>
      (fg as FormGroup).get('arrayId').value !== arrayId &&
      (fg as FormGroup).get('typeArrayId').value === this.variables.transferDynamicFormArray.value[index].typeArrayId
    )
    if (anotherIndex < 0 && !withLast) {
      return;
    }
    this.variables.transferDynamicFormArray.removeAt(index);
  }

  public mapInfoData(): {key: string, value: string}[] {
    let result: {key: string, value: string}[] = [];
    this.fillControls();

    Object.keys(this.variables.transferDynamicFormGroup.controls).forEach(name => {
      let ctrl = this.variables.transferDynamicFormGroup.get(name);
      if (ctrl && ctrl['schema'] && !ctrl['schema'].isHidden && !!ctrl.value) {
        result.push({
          key: ctrl['schema'].inputLabel,
          value: ctrl.value,
        })
      }
    });

    this.variables.transferDynamicFormArray.controls.map(fg => {
      Object.keys(fg['controls']).forEach(name => {
        let ctrl = fg.get(name);
        if (ctrl && ctrl['schema'] && !ctrl['schema'].isHidden && !!ctrl.value) {
          result.push({
            key: ctrl['schema'].inputLabel,
            value: ctrl.value,
          })
        }
      });
    });

    return result;
  }

  private buildCreateRequest(result: any, fields: IMtDynamicFiledHelper[], formArrayItem?: FormGroup | undefined): any {
    fields.map(field => {
      if (field.type !== 'object' && field.type !== 'array') {
        if (field.parentType === 'object') {
          result[field.name] = field.selectedData.length > 0 ?
            getIdByValue(field.selectedData, String(this.variables.transferDynamicFormGroup.value[field.controlName])) :
            this.variables.transferDynamicFormGroup.value[field.controlName];
        }
        if (field.parentType === 'array' && !!formArrayItem) {
          result[field.name] = field.selectedData.length > 0 ?
            getIdByValue(field.selectedData, String(formArrayItem.value[field.controlName])) :
            formArrayItem.value[field.controlName];
        }
      }
      if (field.type === 'object') {
        if (!result.hasOwnProperty(field.name)) {
          result[field.name] = {};
        }
        result[field.name] = this.buildCreateRequest(result[field.name], field.children);
      }
      if (field.type === 'array') {
        if (!result.hasOwnProperty(field.name)) {
          result[field.name] = [];
        }
        (this.variables.transferDynamicFormArray.controls.filter(x => x.get('typeArrayId').value === field.typeArrayId) as FormGroup[]).map(fg => {
          let obj = {};
          obj = this.buildCreateRequest(obj, field.children, fg);
          result[field.name].push(obj);
        });
      }
    });
    return result;
  }

  private fillControls(): void {
    Object.keys(this.variables.operationInfoArray[this.variables.operationIndex].formGroup.controls).map(ctrlName => {
      let arr = ctrlName.split('_');
      let apiName = arr[arr.length - 1];
      if (this.variables.transferDynamicFormGroup.get(apiName)) {
        this.setIdFromMainInfo(apiName, ctrlName);
      }
    });
    this.setIdFromMainInfo('clientId', 'mainInfo_clientId');
  }

  private setIdFromMainInfo(apiName: string, ctrlName: string): void {
    if (this.variables.transferDynamicFormGroup.get(apiName)) {
      this.variables.transferDynamicFormGroup.get(apiName).setValue(this.getIdFromMainInfo(apiName, this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(ctrlName)?.value ?? null));
    }
  }

  private getIdFromMainInfo(apiName: string, value: string): string {
    switch (apiName) {
      case 'countryCode':
        return getIdByValue(this.variables.selectedDataAll.countriesAddresses, value);
      case 'paymentSystemId':
        const psIndex = this.variables.operationInfoArray[this.variables.operationIndex].commissions.findIndex(x => x.nameCyrillic === value);
        return psIndex > -1 ? this.variables.operationInfoArray[this.variables.operationIndex].commissions[psIndex].paymentSystemId : value;
      case 'clientId':
        return this.variables.clientId;
      default:
        return value;
    }
  }

  private updateWithUiSchema(
    fields: IMtDynamicFiledHelper[],
    required?: string[] | undefined,
    properties?: IMtJsonSchemaResponse | undefined,
    anyOf?: IMtJsonSchemaResponse[] | undefined,
    uiScheme?: IMtUiSchemaResponse | null | string | undefined,
    path?: string | undefined,
    parentName?: string | undefined,
    parentType?: string | undefined,
    typeArrayId?: string | undefined,
    parentHidden?: boolean | undefined,): IMtDynamicFiledHelper[] {
    if (!properties) {
      return this.sortArray(fields);
    }
    Object.keys(properties).forEach(name => {
      if (!!properties[name].type && name !== 'type') {
        let temp = this.createUiItem(anyOf, name, required, properties, uiScheme, path, parentName, parentType, typeArrayId, parentHidden);
        if (properties[name].type === 'object' || properties[name].type === 'array') {
          if (properties[name].type === 'array') {
            temp.typeArrayId = Guid.raw();
            properties[name].items.map(itemArray => {
              temp.children = temp.children.concat(this.updateWithUiSchema([], itemArray.required, itemArray.properties, itemArray.anyOf, !!uiScheme ? uiScheme[name] : null, !!path ? `${path}_&&&_${name}` : name, name, 'array', temp.typeArrayId, temp.isHidden))
            });
            this.variables.transferArrayFields.push(temp);
          }
          if (properties[name].type === 'object') {
            temp.children = this.updateWithUiSchema([], properties[name].required, properties[name].properties, properties[name].anyOf, !!uiScheme ? uiScheme[name] : null, !!path ? `${path}_&&&_${name}` : name, name,'object', null, temp.isHidden);
          }
          temp.hasOnlyObjectsOrArrays = temp.children.every(x => x.type === 'object' || x.type === 'array');
        }

        fields.push(temp);
      }
    });
    return this.sortArray(fields);
  }

  private updateWithControls(fields: IMtDynamicFiledHelper[]): void {
    fields.map(field => {
      if (field.parentType === 'object' && field.type !== 'object' && field.type !== 'array') {
        let ctrl = new FormControl(null, this.getFieldVValidators(field));       
        ctrl['schema'] = field;
        this.variables.transferDynamicFormGroup.addControl(field.controlName, ctrl)

      }
      if (field.type === 'array') {
        const index = this.variables.transferDynamicFormArray.controls.findIndex(x => x.get('typeArrayId').value === field.typeArrayId);
        if (index < 0) {
          this.addDynamicFormArrayItem(field.children, field.typeArrayId, field.controlName);
        }
      }
      if (field.type === 'object' || field.type === 'array') {
        this.updateWithControls(field.children);
      }
    });
  }

  private setDefaultsIfNeed(fields: IMtDynamicFiledHelper[]): void {
    fields.map(field => {
      if (field.parentType === 'object' && field.type !== 'object' && field.type !== 'array') {      

        if (!!field.default) {
          this.variables.transferDynamicFormGroup.get(field.controlName).setValue(field.default);
        }
      }
      
      if (field.type === 'object' || field.type === 'array') {
        this.setDefaultsIfNeed(field.children);
      }
    });
  }

  private getFieldVValidators(field: IMtDynamicFiledHelper): any {
    let arr = [];
    if (field.isRequired && !field.parentHidden) {
      arr.push(Validators.required);
    }
    if (!!field.pattern) {
      arr.push(Validators.pattern(field.pattern))
    }
    return arr.length === 0 ? null : arr;
  }

  private sortArray(array: IMtDynamicFiledHelper[]): IMtDynamicFiledHelper[] {
    array.sort((a, b) => {
      if (a.children.length <= 0 && b.children.length > 0) {
        return -1; // a comes before b
      } else if (b.children.length <= 0 && a.children.length > 0) {
        return 1; // a comes after b
      } else {
        return 0; // no change in order
      }
    });
    return array;
  }

  private createUiItem(
    anyOf: IMtJsonSchemaResponse[] | undefined,
    name: string,
    required?: string[] | undefined,
    properties?: IMtJsonSchemaResponse | undefined,
    uiScheme?: IMtUiSchemaResponse | null | string | undefined,
    path?: string | undefined,
    parentName?: string | undefined,
    parentType?: string | undefined,
    typeArrayId?: string | undefined,
    parentHidden?: boolean | undefined,
  ): IMtDynamicFiledHelper {
    const controlName = !!path ? `${path}_&&&_${name}` : name;
    const isObjectOrArray = properties[name].type === 'object' || properties[name].type === 'array';
    const temp: IMtDynamicFiledHelper = {
      inputLabel: controlName.split('_&&&_').join(' '),
      name,
      controlName,
      title: controlName.split('_&&&_').join(' '),
      placeholder: '',
      isRequired: !!required && required.findIndex(x => x === name) > -1,
      isBeneficiaryField: name === 'beneficiary' || controlName.includes('beneficiary_&&&_'),
      type: properties[name].type,
      parentType,
      isHidden: false,
      jsonSchema: isObjectOrArray ? properties[name] : null,
      uiSchema: isObjectOrArray &&!!uiScheme ? uiScheme[name] : null,
      inputType: properties[name].type === 'string' ? 'text' : properties[name].type,
      enum: null,
      children: [],
      hasOnlyObjectsOrArrays: false,
      typeArrayId: typeArrayId,
      selectedData: [],
      parentHidden,
      isAnyOf: !!anyOf ? anyOf.findIndex(x => x.required != null && x.required.findIndex(y => y === controlName) > -1) > -1 : false,
      pattern: properties[name].pattern,
      default: properties[name].default
    };

    if (!!properties && properties[name]) {
      temp.title = properties[name]['title'] ? properties[name]['title'] : temp.title;
    }

    if (!!uiScheme && !!uiScheme[name]) {
      temp.title = uiScheme[name]['ui:title'] ? String(uiScheme[name]['ui:title']) : temp.title;
      temp.inputLabel = temp.title;
      temp.placeholder = uiScheme[name]['ui:placeholder'] ? String(uiScheme[name]['ui:placeholder']) : controlName;
      temp.isHidden = uiScheme[name]['ui:widget'] ? uiScheme[name]['ui:widget'] == 'hidden' : false;
      if (uiScheme[name]['ui:options']) {
        temp.inputType = uiScheme[name]['ui:options']['inputType'];
      }
      if (uiScheme[name]['enum']) {
        temp.enum = uiScheme[name]['enum'];
        temp.inputType = 'select';
        this.addSelectedData(temp);
      }
      if (name.toUpperCase() === 'countryCode'.toUpperCase()) {
        temp.inputType = 'select';
        temp.selectedData = this.variables.selectedDataAll.countriesAddresses;
      }
    }

    if (parentName === 'phoneNumbers') {
      temp.inputType = 'tel'
    }

    return temp;
  }

  private addSelectedData(temp: IMtDynamicFiledHelper): void {
    if (temp.name === 'countryCode') {
      temp.selectedData = this.variables.selectedDataAll.countriesAddresses.filter(item => temp.enum.includes(item.label));
    }
  }
}
