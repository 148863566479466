import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Select, Store} from "@ngxs/store";
import {NotifyService} from "../../../core/services/notify.service";
import {Observable, Subject, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {errorMessages} from "../../../../kernel/constants/errors";
import {messages} from "../../../../kernel/constants/messages";
import {takeUntil} from "rxjs/operators";
import {
  ApiClient,
  HardwareData,
  HardwareProposalOrganizationPointRequest,
  IHardwareData
} from "../../../../kernel/ApiClient";
import {firstCharLowerCase, hardwareEmpty, randomString} from "../../../../kernel/helpers/data.helper";
import {UserState} from "../../../../kernel/store/state/user.state";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-auth-hardware-proposal',
  templateUrl: './auth-hardware-proposal.component.html',
  styleUrls: ['./auth-hardware-proposal.component.scss']
})
export class AuthHardwareProposalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.hardware) hardware$: Observable<IHardwareData>;

  subs = new Subscription();
  hardware: IHardwareData;
  proposalHint = 'Например: Компьютер на точке Пример';
  pin = '';
  closeText = 'Отмена';

  existFromUser = false;
  success = false;
  createdTime: Date;

  proposalForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AuthHardwareProposalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store,
    private apiClient: ApiClient,
    private notify: NotifyService,
    private authService: AuthService
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.createFormControl();
    this.pin = randomString(10, true, false, true, false);
    this.subs.add(this.hardware$.subscribe(hardware => {
      this.hardware = hardware;
    }));
  }

  createFormControl(): void {
    this.proposalForm = new FormGroup({
      ProposalComment: new FormControl('')
    });
  }

  sendProposal(): void {
    if (hardwareEmpty(this.hardware)) {
      this.notify.error(`${errorMessages.hardwareEmpty} - ${firstCharLowerCase(errorMessages.checkMTSAgent)}`);
      return
    }
    if (this.success) {
      return;
    }
    this.apiClient.hardware_CreateHardwareProposalOrganizationPointRequest({
      phone: `+7${String(this.authService.fv.Phone)}`,
      pwd: this.authService.fv.Password,
      hardware: this.hardware as HardwareData,
      pin: this.pin,
      comment: this.proposalForm.value.ProposalComment
    } as HardwareProposalOrganizationPointRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data && data.pin === this.pin && !this.existFromUser) {
          this.notify.success(messages.proposalCreate);
        }
        if (data && data.pin !== this.pin) {
          this.pin = data.pin;
          this.existFromUser = true;
          this.proposalForm.get(`ProposalComment`).setValue(data.comment);
          this.notify.info(messages.proposalFromUserExist);
          this.createdTime = data.created;
        }
        this.success = true;
        this.proposalForm.controls.ProposalComment.disable();
        this.closeText = 'Закрыть';
      }, error => {
        this.notify.error(error.message);
        console.error(error);
      })
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
