<p class="main-title">Восстановите свой пароль</p>
<p class="auth-subtitle">Вы можете восстановить свой Пароль используя адрес электронной почты или номер телефона</p>

<div class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">

  <app-input *ngIf="isForgotByPhone"
             [controlInvalid]="isSubmit"
             name="Phone"
             label="Мобильный телефон"
             placeholder=""
             inputType="tel"
             [parentForm]="authGroup"
             autocomplete="off"
             [isRequired]="isForgotByPhone"
             maskPrefix="+7"
             mask="(000) 000 0000"
             [wrapperClass]="'mb-30'"></app-input>

  <app-input *ngIf="!isForgotByPhone"
             [controlInvalid]="isSubmit"
             name="Email"
             label="Электронная почта"
             placeholder=""
             inputType="text"
             [parentForm]="authGroup"
             autocomplete="off"
             [isRequired]="!isForgotByPhone"
             [wrapperClass]="'mb-30'"></app-input>

  <div class="t-align-center mb-20">
    <button class="button unfilled decoration-under" (click)="changeForgotType()">Попробовать через {{isForgotByPhone ? 'почту' : 'телефон'}}</button>
  </div>


  <div class="btn-wrapper-mobile">
    <button class="button main" [ngClass]="{'disabled': !isForgotCorrect}" [style.width.px]="190" (click)="onForgot()">Восстановить</button>
    <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
  </div>
  <div class="btn-wrapper-full">
    <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
    <button class="button main f-right" [ngClass]="{'disabled': !isForgotCorrect}" [style.width.px]="190" (click)="onForgot()">Восстановить</button>
  </div>

</div>
