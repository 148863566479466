import { Injectable } from '@angular/core';
import { IHeaderNotify } from 'src/kernel/models/common.models';

@Injectable({
  providedIn: 'root',
})
export class NotifyMessageService {
  public notifies: IHeaderNotify[] = [];
  public notifyOpen = false;
}
