import { FileParameter } from './../../../../kernel/ApiClient';
import { IOperationCryptoKeyResponse } from '../../../../kernel/ApiClient';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {
  ApiClient,
  EntityTypeEnum,
  EntitySettingKeyStringEnum,
  IOrganizationPointAddOrUpdateRequest,
  IOrganizationPointResponse,
  IUpdateEntitySettingsRequest,
  IUserResponse,
  OrganizationPointAddOrUpdateRequest,
  UpdateEntitySettingItemRequest,
  UpdateEntitySettingsRequest,
  IUpdateLimitsRequest, LimitTypeEnum, UpdateLimitItemRequest, UpdateLimitsRequest, LimitValueHelper
} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorService} from "../../../core/services/behavior.service";
import {NotifyService} from "../../../core/services/notify.service";
import {
  controlValueToWorkHours,
  getErrorMessage,
  getSelectId,
  isExpectedError,
  mapInvalidFields,
  workHoursToControlValue
} from "../../../../kernel/helpers/data.helper";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../kernel/constants/messages";
import {errorMessages} from "../../../../kernel/constants/errors";
import {fadeIn, fadeInOut, fadeInOutStagged} from "../../../../kernel/animation";
import {GetAvailableData} from "../../../../kernel/store/actions/dictionary.actions";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../kernel/models/common.models";
import {validateTime} from "../../../../kernel/validators";

@Component({
  selector: 'app-operation-crypto-keys-model',
  templateUrl: './operation-crypto-keys-model.component.html',
  styleUrls: ['./operation-crypto-keys-model.component.scss'],
  animations: [fadeInOutStagged, fadeInOut, fadeIn]
})
export class OperationCryptoKeysModelComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  subs = new Subscription();
  visible = false;

  operationCryptoKey: IOperationCryptoKeyResponse;

  operationCryptoKeyForm: FormGroup;

  submitted = false;

  availableIcoTypes = ['pem'];
  file:File;

  constructor(
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient) {

  }

  ngOnInit() {
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControl(): void {
    if (this.operationCryptoKeyForm) {
      return
    }

    this.operationCryptoKeyForm = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      File: new FormControl('', [Validators.required]),
      //fileSource: new FormControl('', [Validators.required])
    });
  }

  checkFormControls(): void {
    if (this.operationCryptoKey) {
      this.operationCryptoKeyForm.controls.Name.setValue(this.operationCryptoKey.name);
    } else {
      this.resetFormControls();
    }
  }

  resetFormControls(): void {
    this.operationCryptoKeyForm.controls.Name.setValue(null);
    this.operationCryptoKeyForm.controls.File.setValue(null);

  }

  addOrUpdateOperationCryptoKey(): void {
    this.submitted = true;
    if (this.operationCryptoKeyForm.invalid) {
      return;
    }
    const payload: FileParameter = {
      data: this.file,
      fileName: this.file.name
    };

    (this.operationCryptoKey ?
      this.apiClient.cryptoInfo_Update(this.operationCryptoKey.id, this.operationCryptoKeyForm.value.Name, payload) :
      this.apiClient.cryptoInfo_Create(this.operationCryptoKeyForm.value.Name, payload))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (this.operationCryptoKey) {
              this.notify.success(messages.operationCryptoKeyUpdated)
            } else {
              this.notify.success(messages.operationCryptoKeyCreated)
            }
            this.operationCryptoKey = data as IOperationCryptoKeyResponse;
            this.toggleModal(null);
          },
          (error) => {
            this.notify.error(getErrorMessage(error));
            console.error(error);
          });
  }

  deleteRelatedOperationTypes(){
    this.apiClient.cryptoInfo_DeleteRelatedOperation(this.operationCryptoKey.id)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
        if (data == true) {
          this.notify.success(messages.operationCryptoKeyRelatedOperationTypesDeleted)
        }
      },
      (error) => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  deleteKey(){
    this.apiClient.cryptoInfo_Delete(this.operationCryptoKey.id)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
        if (data == true) {
          this.notify.success(messages.operationCryptoKeyDeleted)
        }
        this.toggleModal(null);
      },
      (error) => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  toggleModal(operationCryptoKeyForm: IOperationCryptoKeyResponse) {
    this.operationCryptoKey = operationCryptoKeyForm as IOperationCryptoKeyResponse;
    this.submitted = false;
    this.modalClosed.emit(this.visible);
    this.visible = !this.visible;
    this.checkFormControls();
  }

  async fileChanged(event: any) {
    if (!event) {
      return
    }
    this.file = event.data;
    this.operationCryptoKeyForm.controls.File.setValue(event.data);
  }


}
