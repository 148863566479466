import {MtsOperationComponent} from './pages/mts-operation/mts-operation.component';
import {CommonModule} from '@angular/common';
import {ClientsListComponent} from './dialogs/clients-list/clients-list.component';
import {OperationClientSearchComponent} from './pages/operation-client-search/operation-client-search.component';
import {OperationClientStepComponent} from './dialogs/operation-client-step/operation-client-step.component';
import {StepperComponent} from './pages/stepper/stepper.component';
import {CommissionComponent} from './pages/commission/commission.component';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CoreModule} from '../core/core.module';
import {OperationLoaderComponent} from './pages/operation-loader/operation-loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MY_FORMATS} from '../../kernel/helpers/data.helper';
import {ReactiveFormsModule} from '@angular/forms';
import {OperationCommissionListComponent} from "./dialogs/operation-commission-list/operation-commission-list.component";
import {OperationConfirmStepComponent} from "./pages/operation-confirm-step/operation-confirm-step.component";
import {OperationCreateStepComponent} from "./pages/operation-create-step/operation-create-step.component";
import {OperationDataStepComponent} from "./pages/operation-data-step/operation-data-step.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {MatDialogModule} from "@angular/material/dialog";
import {OperationCommissionService} from "./services/operation-commission.service";
import {OperationArrayService} from "./services/operation-array.service";
import {OperationBuilderService} from "./services/operation-builder.service";
import {OperationHelperService} from "./services/operation-helper.service";
import {OperationInitService} from "./services/operation-init.service";
import {OperationListenerService} from "./services/operation-listener.service";
import {OperationStepperService} from "./services/operation-stepper.service";
import {OperationValidationService} from "./services/operation-validation.service";
import {OperationVariableService} from "./services/operation-variable.service";
import {OperationApiService} from "./services/operation-api.service";
import {OperationListenerHelperService} from "./services/operation-listener-helper.service";
import {OperationHelperControlService} from "./services/operation-helper-control.service";
import {OperationPaymentHubService} from "./services/operation-payment-hub.service";
import { TransferChangeBeneficiaryComponent } from './dialogs/transfer-change-beneficiary/transfer-change-beneficiary.component';
import { TransferRejectComponent } from './dialogs/transfer-reject/transfer-reject.component';
import {OperationFieldBuilderService} from "./services/operation-field-builder.service";
import { OperationDynamicDataStepComponent } from './pages/operation-dynamic-data-step/operation-dynamic-data-step.component';
import { OperationDynamicDataItemStepComponent } from './pages/operation-dynamic-data-step/operation-dynamic-data-item-step/operation-dynamic-data-item-step.component';
import { OperationDynamicDataItemFieldsComponent } from './pages/operation-dynamic-data-step/operation-dynamic-data-item-fields/operation-dynamic-data-item-fields.component';

@NgModule({
  declarations: [
    CommissionComponent,
    MtsOperationComponent,
    OperationClientSearchComponent,
    OperationConfirmStepComponent,
    OperationCreateStepComponent,
    OperationDataStepComponent,
    OperationLoaderComponent,
    StepperComponent,
    ClientsListComponent,
    OperationClientStepComponent,
    OperationCommissionListComponent,
    TransferChangeBeneficiaryComponent,
    TransferRejectComponent,
    OperationDynamicDataStepComponent,
    OperationDynamicDataItemStepComponent,
    OperationDynamicDataItemFieldsComponent
  ],
  entryComponents: [
    ClientsListComponent,
    OperationClientStepComponent,
    OperationCommissionListComponent,
    TransferRejectComponent,
    TransferChangeBeneficiaryComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDialogModule,
    CoreModule,
    RouterModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CommissionComponent,
    MtsOperationComponent,
    OperationClientSearchComponent,
    OperationConfirmStepComponent,
    OperationCreateStepComponent,
    OperationDataStepComponent,
    OperationLoaderComponent,
    StepperComponent,
    ClientsListComponent,
    OperationClientStepComponent,
    OperationCommissionListComponent,
    TransferRejectComponent,
    TransferChangeBeneficiaryComponent
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    OperationCommissionService,
    OperationApiService,
    OperationListenerHelperService,
    OperationArrayService,
    OperationBuilderService,
    OperationFieldBuilderService,
    OperationHelperControlService,
    OperationHelperService,
    OperationInitService,
    OperationListenerService,
    OperationStepperService,
    OperationValidationService,
    OperationVariableService,
    OperationPaymentHubService
  ],
})
export class OperationModule {}
