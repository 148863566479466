import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  AmountTypeEnum,
  ApiClient,
  CountryUpdateRequest,
  CreateLimitsRequest,
  EntityTypeEnum,
  ICountriesResponse,
  ICountryUpdateRequest,
  IOperationTypeResponse,
  IOperationTypeUpdatePositionRequest,
  IOperationTypeUpdateRequest,
  IPaymentSystemResponse,
  IPaymentSystemUpdateRequest,
  IUpdateLimitsRequest,
  IUserResponse,
  LimitValueHelper,
  OperationTypeUpdatePositionRequest,
  OperationTypeUpdateRequest,
  PaymentSystemUpdateRequest,
  UpdateLimitsRequest
} from "../../../../../kernel/ApiClient";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../../kernel/models/common.models";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {messages} from "../../../../../kernel/constants/messages";
import {getErrorMessage, getSelectId, getSelectIdNullable, inRange} from "../../../../../kernel/helpers/data.helper";
import {debounceTime, takeUntil} from "rxjs/operators";
import {errorMessages} from "../../../../../kernel/constants/errors";
import {SetOperationTypes} from "../../../../../kernel/store/actions/dictionary.actions";
import {GlobalBuilderService} from "../../../../core/services/global-builder.service";
import {BehaviorService} from "../../../../core/services/behavior.service";
import {StaticDictionariesEnum} from "../../../../core/services/static-dictionaries.service";

@Component({
  selector: 'app-operation-settings',
  templateUrl: './operation-settings.component.html',
  styleUrls: ['./operation-settings.component.scss', '../../settings/settings.component.scss']
})
export class OperationSettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getOperationTypes) availableOperationTypes$: Observable<IOperationTypeResponse>;
  @Select(DictionaryState.getPaymentSystems) availablePaymentSystems$: Observable<IPaymentSystemResponse>;
  @Select(DictionaryState.getDbCountries) availableCountries$: Observable<ICountriesResponse>;
  @Select(DictionaryState.getOperationCryptoKeys) availableOperationCryptoKeys$: Observable<IDataSelectedHelper[]>;
  @Input() id: string

  availableCountries: ICountriesResponse;
  availableCountriesArr: IDataSelectedHelper[] = [];
  availablePaymentSystems: IPaymentSystemResponse;
  availablePaymentSystemsArr: IDataSelectedHelper[] = [];
  availableOperationTypes: IOperationTypeResponse;
  availableOperationTypesArr: IDataSelectedHelper[] = [];
  availableOperationCryptoKeys: IDataSelectedHelper[];
  availableOperationCryptoKeysArr: IDataSelectedHelper[] = [];

  subs = new Subscription();
  requestSub: any;
  requestAllClientsSub: any;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  countryForm: FormGroup;
  paymentSystemForm: FormGroup;
  operationTypeForm: FormGroup;

  submitted = false;
  submittedAllClients = false;

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: [{title: 'Типы операций', index: 1}, {title: 'Платежные системы', index: 2},  {title: 'Страны', index: 3},  {title: 'Порядок операций', index: 4}]
  };

  operationIco: string;
  availableIcoTypes = ['jpeg', 'png', 'jpg', 'svg'];

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private builder: GlobalBuilderService,
    private behavior: BehaviorService
  ) { }

  get countryId(): string {
    return getSelectId(this.countryForm.value.Type, this.availableCountriesArr, false) as string;
  }
  get operationTypeId(): string {
    return getSelectId(this.operationTypeForm.value.Type, this.availableOperationTypesArr, false) as string;
  }
  get paymentSystemId(): string {
    return getSelectId(this.paymentSystemForm.value.Type, this.availablePaymentSystemsArr, false) as string;
  }
  get countryPayload(): ICountryUpdateRequest {
    return {
      id: this.countryId,
      clientLimitForOperation: this.countryForm.value.ClientLimitForOperation ?? 0,
      clientLimitForDay: this.countryForm.value.ClientLimitForDay ?? 0,
      clientLimitForMonth: this.countryForm.value.ClientLimitForMonth ?? 0,
      isActiveLimits: this.countryForm.value.IsActiveLimits
    };
  }
  get operationTypePositionsPayload(): IOperationTypeUpdatePositionRequest[] {
    const arr: IOperationTypeUpdatePositionRequest[] = [];
    this.availableOperationTypesArr.map(x => arr.push({ id: x.id, position: x.position }));
    return arr;
  }
  get operationTypePayload(): IOperationTypeUpdateRequest {
    return {
      id: this.operationTypeId,
      title: this.operationTypeForm.value.Title,
      description: this.operationTypeForm.value.Description,
      clientLimitForOperation: this.operationTypeForm.value.ClientLimitForOperation ?? 0,
      clientLimitForOperationNotFranchise: this.operationTypeForm.value.ClientLimitForOperationNotFranchise ?? 0,
      clientLimitForDay: this.operationTypeForm.value.ClientLimitForDay ?? 0,
      clientLimitForDayNotFranchise: this.operationTypeForm.value.ClientLimitForDayNotFranchise ?? 0,
      clientLimitForMonth: this.operationTypeForm.value.ClientLimitForMonth ?? 0,
      clientLimitForMonthNotFranchise: this.operationTypeForm.value.ClientLimitForMonthNotFranchise ?? 0,
      isActiveLimits: this.operationTypeForm.value.IsActiveLimits,
      serviceCode: this.operationTypeForm.value.ServiceCode,
      serviceCodeNotFranchise: this.operationTypeForm.value.ServiceCodeNotFranchise,
      minClientSum: Number(this.operationTypeForm.value.MinClientSum),
      isMinClientSumActive: this.operationTypeForm.value.IsMinClientSumActive,
      ico: this.operationIco,
      organizationCommissionType: AmountTypeEnum.Percent,
      isActiveOrganizationCommission: this.operationTypeForm.value.IsActiveOrganizationCommission,
      organizationMaxCommission: this.operationTypeForm.value.OrganizationMaxCommission,
      organizationMaxCommissionNotFranchise: this.operationTypeForm.value.OrganizationMaxCommissionNotFranchise,
      cryptoInfoId: getSelectIdNullable(this.operationTypeForm.value.OperationCryptoKeyId, this.availableOperationCryptoKeys),
      isNeedEncryption: this.operationTypeForm.value.IsNeedEncryption
    };
  }
  async fileChanged(event: any) {
    if (!event) {
      this.operationIco = null;
      return;
    }
    const file = event.data
    this.operationIco = await this.toBase64(file) as string
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
  get paymentSystemPayload(): IPaymentSystemUpdateRequest {
    return {
      id: this.paymentSystemId,
      supportPhone: this.paymentSystemForm.value.SupportPhone,
      supportEmail: this.paymentSystemForm.value.SupportEmail,
      supportSite: this.paymentSystemForm.value.SupportSite
    };
  }
  get apiClientEntityUpdateObservable(): {request: Observable<void>, type: StaticDictionariesEnum, method: string} {
    switch (this.topMenu.currentIndex) {
      case 1:
        return {
          request: this.apiClient.dictionariesUpdate_UpdateOperationType(this.operationTypePayload as OperationTypeUpdateRequest),
          type: StaticDictionariesEnum.OperationType,
          method: 'updateOperationTypeLimits'
        };
      case 2:
        return {
          request: this.apiClient.dictionariesUpdate_UpdatePaymentSystem(this.paymentSystemPayload as PaymentSystemUpdateRequest),
          type: StaticDictionariesEnum.PaymentSystem,
          method: null
        };
      case 3:
        return {
          request: this.apiClient.dictionariesUpdate_UpdateCountry(this.countryPayload as CountryUpdateRequest),
          type: StaticDictionariesEnum.Country,
          method: 'updateCountryLimits'
        };
      case 4:
        return {
          request: this.apiClient.dictionariesUpdate_UpdateOperationPositionsType(this.operationTypePositionsPayload as OperationTypeUpdatePositionRequest[]),
          type: StaticDictionariesEnum.OperationType,
          method: null
        };
    }
    return null;
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.availablePaymentSystems$.subscribe(availablePaymentSystems => {
      this.availablePaymentSystemsArr = [];
      this.availablePaymentSystems = availablePaymentSystems;
      if (this.availablePaymentSystems) {
        this.availablePaymentSystems.items.map(x => {
          this.availablePaymentSystemsArr.push({
            id: x.id,
            label: x.nameCyrillic
          });
        });
      }
    }));
    this.subs.add(this.availableOperationTypes$.subscribe(availableOperationTypes => {
      this.availableOperationTypesArr = [];
      this.availableOperationTypes = availableOperationTypes;
      if (this.availableOperationTypes) {
        this.availableOperationTypes.items.map(x => {
          this.availableOperationTypesArr.push({
            id: x.id,
            label: x.title,
            position: x.position,
            role: x.type
          });
        });
      }
    }));
    this.subs.add(this.availableCountries$.subscribe(availableCountries => {
      this.availableCountriesArr = [];
      this.availableCountries = availableCountries;
      if (this.availableCountries) {
        this.availableCountries.items.map(x => {
          this.availableCountriesArr.push({
            id: x.id,
            label: x.title
          });
        });
      }
    }));
    this.subs.add(this.availableOperationCryptoKeys$.subscribe(availableOperationCryptoKeys => {
      this.availableOperationCryptoKeys = availableOperationCryptoKeys;
    }));
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  changeSettingsType(index: number): void {
    this.topMenu.currentIndex = index;
    this.submitted = false;
    this.submittedAllClients = false;
  }

  fillCountriesFromApi(): void {
    this.apiClient.dictionaries_FillCountries()
      .subscribe(
        () => {
          this.notify.success(messages.countriesRefilled);
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  createFormControls(): void {
    this.countryForm = new FormGroup({
      Type: new FormControl(null, [v.required]),
      ClientLimitForOperation: new FormControl(null, [v.required, v.min(0)]),
      ClientLimitForDay: new FormControl(null, [v.required, v.min(0)]),
      ClientLimitForMonth: new FormControl(null, [v.required, v.min(0)]),
      IsActiveLimits: new FormControl(false, [v.required])
    });
    this.operationTypeForm = new FormGroup({
      Type: new FormControl(null, [v.required]),
      Title: new FormControl(null, v.required),
      Description: new FormControl(null),
      ClientLimitForOperation: new FormControl(null, [v.min(0)]),
      ClientLimitForOperationNotFranchise: new FormControl(null, [v.min(0)]),
      ClientLimitForDay: new FormControl(null, [v.min(0)]),
      ClientLimitForDayNotFranchise: new FormControl(null, [v.min(0)]),
      ClientLimitForMonth: new FormControl(null, [v.min(0)]),
      ClientLimitForMonthNotFranchise: new FormControl(null, [v.min(0)]),
      IsActiveLimits: new FormControl(false, [v.required]),
      ServiceCode: new FormControl(null),
      ServiceCodeNotFranchise: new FormControl(null),
      MinClientSum: new FormControl(null),
      IsMinClientSumActive: new FormControl(null),
      IsActiveOrganizationCommission: new FormControl(null),
      OrganizationMaxCommission: new FormControl(null, [v.required, v.min(0), v.max(100)]),
      OrganizationMaxCommissionNotFranchise: new FormControl(null, [v.required, v.min(0), v.max(100)]),
      OperationCryptoKeyId: new FormControl(null),
      IsNeedEncryption: new FormControl(false, [v.required])
    });
    this.paymentSystemForm = new FormGroup({
      Type: new FormControl(null, [v.required]),
      SupportPhone: new FormControl(null, v.required),
      SupportSite: new FormControl(null, v.required),
      SupportEmail: new FormControl(null, v.required)
    });

    this.createListener();
  }

  fillCountryForm(index: number) {
    this.countryForm.get(`ClientLimitForOperation`).setValue(index > -1 ? this.availableCountries.items[index].clientLimitForOperation : null);
    this.countryForm.get(`ClientLimitForDay`).setValue(index > -1 ? this.availableCountries.items[index].clientLimitForDay : null);
    this.countryForm.get(`ClientLimitForMonth`).setValue(index > -1 ? this.availableCountries.items[index].clientLimitForMonth : null);
    this.countryForm.get(`IsActiveLimits`).setValue(index > -1 ? this.availableCountries.items[index].isActiveLimits : false);
  }
  fillOperationTypeForm(index: number) {
    this.operationTypeForm.get(`Title`).setValue(index > -1 ? this.availableOperationTypes.items[index].title : null);
    this.operationTypeForm.get(`Description`).setValue(index > -1 ? this.availableOperationTypes.items[index].description : null);
    this.operationTypeForm.get(`ClientLimitForOperation`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForOperation : null);
    this.operationTypeForm.get(`ClientLimitForOperationNotFranchise`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForOperationNotFranchise : null);
    this.operationTypeForm.get(`ClientLimitForDay`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForDay : null);
    this.operationTypeForm.get(`ClientLimitForDayNotFranchise`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForDayNotFranchise : null);
    this.operationTypeForm.get(`ClientLimitForMonth`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForMonth : null);
    this.operationTypeForm.get(`ClientLimitForMonthNotFranchise`).setValue(index > -1 ? this.availableOperationTypes.items[index].clientLimitForMonthNotFranchise : null);
    this.operationTypeForm.get(`IsActiveLimits`).setValue(index > -1 ? this.availableOperationTypes.items[index].isActiveLimits : false);
    this.operationTypeForm.get(`ServiceCode`).setValue(index > -1 ? this.availableOperationTypes.items[index].serviceCode : null);
    this.operationTypeForm.get(`ServiceCodeNotFranchise`).setValue(index > -1 ? this.availableOperationTypes.items[index].serviceCodeNotFranchise : null);
    this.operationTypeForm.get(`MinClientSum`).setValue(index > -1 ? this.availableOperationTypes.items[index].minClientSum : null);
    this.operationTypeForm.get(`IsMinClientSumActive`).setValue(index > -1 ? this.availableOperationTypes.items[index].isMinClientSumActive : null);
    this.operationTypeForm.get(`IsActiveOrganizationCommission`).setValue(index > -1 ? this.availableOperationTypes.items[index].isActiveOrganizationCommission : null);
    this.operationTypeForm.get(`OrganizationMaxCommission`).setValue(index > -1 ? this.availableOperationTypes.items[index].organizationMaxCommission : null);
    this.operationTypeForm.get(`OrganizationMaxCommissionNotFranchise`).setValue(index > -1 ? this.availableOperationTypes.items[index].organizationMaxCommissionNotFranchise : null);
    const operationCryptoKey = this.availableOperationCryptoKeys.find(x => String(x.id) === String( this.availableOperationTypes.items[index]?.operationCryptoKeyId));
    this.operationTypeForm.get(`OperationCryptoKeyId`).setValue(operationCryptoKey?.label ?? null);
    this.operationTypeForm.get(`IsNeedEncryption`).setValue(index > -1 ? this.availableOperationTypes.items[index].isNeedEncryption : false);
  }
  fillPaymentSystemForm(index: number) {
    this.paymentSystemForm.get(`SupportPhone`).setValue(index > -1 ? this.availablePaymentSystems.items[index].supportPhone : null);
    this.paymentSystemForm.get(`SupportSite`).setValue(index > -1 ? this.availablePaymentSystems.items[index].supportSite : null);
    this.paymentSystemForm.get(`SupportEmail`).setValue(index > -1 ? this.availablePaymentSystems.items[index].supportEmail : null);
  }

  createListener(): void {
    this.countryForm.get(`Type`).valueChanges
      .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fillCountryForm(this.availableCountries.items.findIndex(x => x.id === this.countryId));
      });
    this.paymentSystemForm.get(`Type`).valueChanges
      .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fillPaymentSystemForm(this.availablePaymentSystems.items.findIndex(x => x.id === this.paymentSystemId));
      });
    this.operationTypeForm.get(`Type`).valueChanges
      .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fillOperationTypeForm(this.availableOperationTypes.items.findIndex(x => x.id === this.operationTypeId));
      });
  }

  ngOnDestroy(): void {
    this.checkRequestSub();
    this.checkRequestAllClientsSub();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe();
    }
  }
  checkRequestAllClientsSub(): void {
    if (this.requestAllClientsSub) {
      this.requestAllClientsSub.unsubscribe();
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  moveOperationType(positionIndex: number, move: number ): void {
    if (!inRange(positionIndex + move, 0, this.availableOperationTypesArr.length - 1)) {
      return;
    }
    const currentPos = this.availableOperationTypesArr[positionIndex].position;
    const requestedPos = this.availableOperationTypesArr[positionIndex+move].position;
    this.availableOperationTypesArr[positionIndex].position = requestedPos;
    this.availableOperationTypesArr[positionIndex+move].position = currentPos;

    const temp = this.availableOperationTypesArr[positionIndex];
    this.availableOperationTypesArr[positionIndex] = this.availableOperationTypesArr[positionIndex + move];
    this.availableOperationTypesArr[positionIndex + move] = temp;
  }

  updateEntity(): void {
    this.submitted = true;
    if (this.topMenu.currentIndex === 1 && this.operationTypeForm.invalid ||
      this.topMenu.currentIndex === 2 && this.paymentSystemForm.invalid ||
      this.topMenu.currentIndex === 3 && this.countryForm.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }
    this.checkRequestSub();
    const temp = this.apiClientEntityUpdateObservable;
    this.requestSub = temp.request
      .subscribe(
        () => {
          this.notify.success(messages.dataChanged);
          if (temp.method) {
            this[temp.method]();
          }
          this.behavior.getStaticDictionaryStatus.next(temp.type);
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  updateOperationTypeLimits(): void {
    const payload: IUpdateLimitsRequest = {
      alsoUpdateClients: false,
      entityId: this.operationTypeId,
      entityType: EntityTypeEnum.OperationType,
      limitForOperation: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForOperation').value ?? 0} as LimitValueHelper,
      limitForOperationNotFranchise: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForOperationNotFranchise').value ?? 0} as LimitValueHelper,
      limitForDay: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForDay').value ?? 0} as LimitValueHelper,
      limitForDayNotFranchise: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForDayNotFranchise').value ?? 0} as LimitValueHelper,
      limitForMonth: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForMonth').value ?? 0} as LimitValueHelper,
      limitForMonthNotFranchise: { minValue: 0, maxValue: this.operationTypeForm.get('ClientLimitForMonthNotFranchise').value ?? 0} as LimitValueHelper,
      isActive: this.operationTypeForm.get(`IsActiveLimits`).value
    };
    this.apiClient.limit_UpdateLimits(payload as UpdateLimitsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateCountryLimits(): void {
    const payload: IUpdateLimitsRequest = {
      alsoUpdateClients: false,
      entityId: this.countryId,
      entityType: EntityTypeEnum.Country,
      limitForOperation: { minValue: 0, maxValue: this.countryForm.get('ClientLimitForOperation').value ?? 0} as LimitValueHelper,
      limitForDay: { minValue: 0, maxValue: this.countryForm.get('ClientLimitForDay').value ?? 0} as LimitValueHelper,
      limitForMonth: { minValue: 0, maxValue: this.countryForm.get('ClientLimitForMonth').value ?? 0} as LimitValueHelper,
      isActive: this.countryForm.get(`IsActiveLimits`).value
    };
    this.apiClient.limit_UpdateLimits(payload as UpdateLimitsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateForAllClients(): void {
    if (this.topMenu.currentIndex === 2) {
      return;
    }
    this.submittedAllClients = true;
    if (this.topMenu.currentIndex === 1 && !this.operationTypeId ||
      this.topMenu.currentIndex === 3 && !this.countryId) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }
    this.checkRequestAllClientsSub();
    this.requestAllClientsSub = this.apiClient.limit_UpdateAllClientLimitsByEntityId({
      entityId: this.topMenu.currentIndex === 1 ? this.operationTypeId : this.countryId,
      entityType: this.topMenu.currentIndex === 1 ? EntityTypeEnum.OperationType : EntityTypeEnum.Country
    } as CreateLimitsRequest)
      .subscribe(
        () => {
          this.notify.success(messages.limitsUpdated);
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }
}
