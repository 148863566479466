import {IOrganizationResponse, IUserResponse} from "../ApiClient";
import {RoleProvider} from "../enum/role-provider.enum";

export function isOperator(user: IUserResponse): boolean {
  return !!user && user.roleName === RoleProvider.Operator;
}

export function convertPhone(phone: string): string {
  if (!phone) {
    return phone;
  }
  if (phone.length === 10 && !phone.includes('+7')) {
    phone = `+7${phone}`;
  }
  if (phone.length === 11 && !phone.includes('+')) {
    phone = `+${phone}`;
  }
  if (phone.length === 12) {
    phone = phone.substring(0, 2) + ' (' +
      phone.substring(2, 5) + ') ' +
      phone.substring(5, 8) + '-' +
      phone.substring(8, 10) + '-' +
      phone.substring(10, 12)
  }
  return phone;
}

export function isKkm(organization: IOrganizationResponse): boolean {
  return organization?.organizationType?.id === 1;
}
