<div class="expand" [ngClass]="{'open': expanded}" id="filteredUsers">
  <app-organization-points-modal (modalClosed)="modalClosed($event)"
                   #addOrganizationPoints
  ></app-organization-points-modal>

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">ТОЧКИ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-filter-users-proposals download-title" (click)="filterExpand = !filterExpand">Фильтры</p>
    <div class="expand-table-container">
      <div *ngIf="searchForm">

        <div class="filter-wrapper">
          <div class="filter-left-wrapper" [ngClass]="{'open': filterExpand}">
            <div class="filter-left-column-item">
              <div class="filter-left-row-item">
                <app-input inputType="select"
                           placeholder="БПА"
                           label="БПА"
                           name="OrganizationId"
                           wrapperClass="inputBox-group"
                           [contentMaxHeight]="600"
                           [selectData]="availableOrganizations || []"
                           [selectDataAll]="availableOrganizations || []"
                           [parentForm]="searchForm"></app-input>
              </div>
            </div>
            <div class="filter-left-column-item">
              <div class="filter-left-row-item search">
                <app-input
                  inputType="search"
                  placeholder="Поиск"
                  name="Search"
                  width="full"
                  wrapperClass="inputBox-group"
                  [parentForm]="searchForm"></app-input>
              </div>
              <div class="filter-left-row-item">
                <app-checkbox
                  class="inputBox-group knowledge-filter-check"
                  [parentForm]="searchForm"
                  name="OnlyActive"
                  label="Только активные"
                ></app-checkbox>
              </div>
            </div>
            <span class="download-title" (click)="resetAll()"
               *ngIf="isNeedFilterReset">Сбросить фильтр</span>
          </div>
          <div class="filter-right-wrapper">
            <button class="button main"
                    (click)="addOrganizationPoints.toggleModal(null)">
              СОЗДАТЬ
            </button>
          </div>
        </div>
      </div>

      <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!organizationPointResponse && organizationPointResponse.organizationPoints.length > 0 else emptyList">
        <table class="table w-100">
          <thead class="table-header">
          <th>НАЗВАНИЕ</th>
          <th>СОТРУДНИКИ</th>
          <th>ПК</th>
          <th>ФРАНШИЗА</th>
          <th></th>
          </thead>
          <tbody class="text-align-center">
          <tr *ngFor="let organizationPoint of organizationPointResponse.organizationPoints; let i=index"
              class="table-row" (click)="!isLoad && addOrganizationPoints.toggleModal(organizationPoint)">
            <td class="td-role">{{organizationPoint.name}}</td>
            <td class="td-role">{{organizationPoint.usersCount}}</td>
            <td class="td-role">{{organizationPoint.hardwarePcsCount}}</td>
            <td *ngIf="organizationPoint.isFranchise" class="t-align-center"><img src="/assets/img/icons/check-ok.svg"></td>
             <td *ngIf="!organizationPoint.isFranchise" class="t-align-center"><img src="/assets/img/icons/error.svg"></td>                       
            <td *ngIf="organizationPoint.isBlocked" class="t-align-center"><img src="/assets/img/icons/error.svg"></td>
            <td *ngIf="!organizationPoint.isBlocked" class="t-align-center"><img src="/assets/img/icons/success.svg"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <app-paginator #paginatorComp
        [page]="paginator.page"
        [total]="organizationPointResponse?.totalCount"
        [take]="paginator.take"
        [id]="paginatorId"
        (pageChange)="pageChanged($event)"
      ></app-paginator>
      <ng-template #emptyList>
        <div class="empty-list">
          <p>{{
            isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одной точки...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
