<p class="main-title">Добро пожаловать в MTS Pay!</p>
<p class="auth-subtitle">Пожалуйста, войдите в систему, используя свой логин и пароль. Также Вы можете восстановить свой пароль или создать новую учетную запись.</p>

<div class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">
  <app-input [controlInvalid]="isSubmit" name="Phone" label="Мобильный телефон" placeholder="Мобильный телефон" inputType="tel"
             [parentForm]="authGroup" autocomplete="off" [isRequired]="true" [wrapperClass]="'mb-30'"
             maskPrefix="+7" mask="(000) 000 0000"></app-input>

  <app-input [idInput]="'passwordInput'"  inputType="password" placeholder="Пароль" label="Пароль" name="Password" wrapperClass="mb-30" autocomplete="on"
             [controlInvalid]="isSubmit" [isRequired]="true" [parentForm]="authGroup"></app-input>

  <app-checkbox  [label]="'Запомнить меня'" [controlInvalid]="isSubmit"
                 name="RememberMe" [wrapperClass]="" [parentForm]="authGroup"></app-checkbox>

  <div class="btn-wrapper-mobile">
    <button class="button main" [ngClass]="{'disabled': !isLoginCorrect}"
            [style.width.px]="135" (click)="onLogin()">Войти</button>
    <button class="button unfilled decoration-under" (click)="goto('forgot')" [style.padding-left.px]="0">Забыли пароль?</button>
  </div>
  <div class="btn-wrapper-full">
    <button class="button unfilled decoration-under" (click)="goto('forgot')" [style.padding-left.px]="0">Забыли пароль?</button>
    <button class="button main f-right" [ngClass]="{'disabled': !isLoginCorrect}"
            [style.width.px]="135" (click)="onLogin()">Войти</button>
  </div>

</div>
