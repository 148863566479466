import {Component, OnDestroy, OnInit} from '@angular/core';
import {Navigate} from "@ngxs/router-plugin";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {HomeRedirect} from "../../../../kernel/store/actions/user.actions";

@Component({
  selector: 'app-auth-area',
  templateUrl: './auth-area.component.html',
  styleUrls: ['./auth-area.component.scss']
})
export class AuthAreaComponent implements OnInit, OnDestroy {
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  subs = new Subscription();

  constructor(
    private authService: AuthService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.authService.createFormControls();
    this.subs.add(
      this.isAuthorized$.subscribe(isAuthorized => {
        if (isAuthorized) {
          this.store.dispatch(new HomeRedirect());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.authService.ngUnsubscribe.next();
    this.authService.ngUnsubscribe.complete();
  }
}
