import {NgModule} from "@angular/core";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {AppRoutingModule} from "../app-routing.module";
import {KkmService} from "./services/kkm.service";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {BrowserModule} from "@angular/platform-browser";
import {BillingBuilderService} from "./services/billing-builder.service";
import { CashboxListDialogComponent } from './dialogs/cashbox-list-dialog/cashbox-list-dialog.component';
import {BillingVariablesService} from "./services/billing-variables.service";
import {BillingHelperService} from "./services/billing-helper.service";
import {CoreModule} from "../core/core.module";
import {BillingService} from "./services/billing.service";
import { QueueStatusComponent } from './pages/queue-status/queue-status.component';
import { BillPreviewDialogComponent } from './dialogs/bill-preview-dialog/bill-preview-dialog.component';
import { QueueComponent } from './pages/queue/queue.component';
import {KkmQueueService} from "./services/kkm-queue.service";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CashboxListDialogComponent,
    QueueStatusComponent,
    BillPreviewDialogComponent,
    QueueComponent
  ],
  entryComponents: [
    CashboxListDialogComponent,
    BillPreviewDialogComponent
  ],
  imports: [
    BrowserModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    FormsModule,
    CoreModule
  ],
    exports: [
      CashboxListDialogComponent,
      QueueStatusComponent,
      BillPreviewDialogComponent
    ],
  providers: [
    BillingBuilderService,
    BillingService,
    BillingVariablesService,
    BillingHelperService,
    KkmService,
    KkmQueueService
  ]
})
export class BillingModule {}
