import {Injectable} from '@angular/core';
import {OperationVariableService} from "./operation-variable.service";
import {OperationInitService} from "./operation-init.service";
import {createOperationControlHelper} from "../../../kernel/helpers/operation.helper";
import {FormControl, Validators as v} from "@angular/forms";
import {createClientControlHelper} from "../../../kernel/helpers/client.helper";
import {Store} from "@ngxs/store";
import {OperationCommissionService} from "./operation-commission.service";
import {OperationApiService} from "./operation-api.service";
import {OperationListenerHelperService} from "./operation-listener-helper.service";
import {OperationArrayService} from "./operation-array.service";
import {OperationHelperService} from "./operation-helper.service";
import {OperationListenerService} from "./operation-listener.service";
import {OperationStepperService} from "./operation-stepper.service";
import {OperationValidationService} from "./operation-validation.service";
import {OperationHelperControlService} from "./operation-helper-control.service";
import {BehaviorService} from "../../core/services/behavior.service";
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {Location} from "@angular/common";
import {OperationTypeEnum} from "../../../kernel/ApiClient";
import {OperationPaymentHubService} from "./operation-payment-hub.service";
import {
  createPaymentHubControlHelper
} from "../../../kernel/helpers/payment-hub-helpers/payment-hub-one-field.helper";
import {
  createPaymentHubCISCardControlHelper
} from "../../../kernel/helpers/payment-hub-helpers/payment-hub-cis-cards.helper";
import { createPaymentHubCISCardControlHelperv2 }
from '../../../kernel/helpers/payment-hub-helpers/payment-hub-cis-cards-v2-without-phone-mask.helper';
import {OperationFieldBuilderService} from "./operation-field-builder.service";
import { createPhPhoneOnlyDigitsControlHelper } from '../../../kernel/helpers/payment-hub-helpers/payment-hub-phone-only-digits.helper';
import {MtDynamicService} from "../../../kernel/mt-dynamic/services/mt-dynamic.service";

@Injectable({
  providedIn: 'root',
})
export class OperationBuilderService {

  constructor(
    public variables: OperationVariableService,
    public commission: OperationCommissionService,
    public api: OperationApiService,
    public listenerHelper: OperationListenerHelperService,
    public array: OperationArrayService,
    public helper: OperationHelperService,
    public helperControl: OperationHelperControlService,
    public init: OperationInitService,
    public listener: OperationListenerService,
    public validation: OperationValidationService,
    public stepper: OperationStepperService,
    public paymentHub: OperationPaymentHubService,
    public mtDynamic: MtDynamicService,
    private operationFieldService:  OperationFieldBuilderService,
    private store: Store,
    private behavior: BehaviorService,
    private location: Location
  ) {
  }

  async initOperationSteps(): Promise<void> {
    if (this.variables.isFirst) {
      if (this.variables.operationTypes?.items?.length > 0) {
        const index = this.variables.operationTypes.items.findIndex(x => x.url === this.variables.operationType);
        this.variables.operationTypeEnum = this.variables.operationTypes.items[index];
      }
      this.variables.operations = [];
      this.init.createOperationHelperFormControls();
      this.variables.isFirst = false;
      this.variables.operationInfoArray = [];
      await this.createFirstStep();
      await this.createSecondStep();
      await this.stepper.gotoStep(this.variables.currentStep, 0, false, true);
      this.variables.operationInfoArray[this.variables.operationInfoArray.length - 1] = this.listener.valueChangeListener(this.variables.operationInfoArray[this.variables.operationInfoArray.length - 1]);
      if (this.variables.isTransfer) {
        this.commission.paymentSystemListener(this.variables.operationInfoArray.length - 1);
        // TODO commission listener for transfer
        // this.commission.calculateCommissionListener(this.variables.operationInfoArray.length - 1);
      }
      this.commission.calculateCommissionListener(this.variables.operationInfoArray.length - 1);
      this.variables.clientHelper = this.listener.valueChangeListener(this.variables.clientHelper);
      this.variables.clientHelper = this.listener.clientValueChangeListener(this.variables.clientHelper);
      this.listener.clientBackCashListener();
      this.listener.clientDocumentChangeListener();

      this.variables.operationInfoArray[0].items.map((item, itemIndex) => {
        if (item.isFormArray) {
          this.variables.operationInfoArray[0].items[itemIndex].controlsArr = [];
          this.array.addFormArrayItem(item.id, null, 0);
        }
      });
      this.variables.clientHelper.items.map((item, itemIndex) => {
        if (item.isFormArray) {
          this.variables.clientHelper.items[itemIndex].controlsArr = [];
          this.array.addFormArrayItem(item.id);
        }
      });
    }
    return;
  }

  getOperationPayload(): IStepsControlHelper {
    switch (this.variables.operationTypeEnum.type) {
      case OperationTypeEnum.TransferMTS:
        return createOperationControlHelper(this.variables.selectedDataAll);
      case OperationTypeEnum.TinkoffCardPH:
      case OperationTypeEnum.HomeCreditCardPH:
      case OperationTypeEnum.RusStandardCardPH:
      case OperationTypeEnum.MTSBankCardPH:
      case OperationTypeEnum.AnyCardsPH:
        return createPaymentHubControlHelper([this.operationFieldService.cardField()]);
      case OperationTypeEnum.TinkoffLoanPH:
      case OperationTypeEnum.HomeCreditLoanPH:
      case OperationTypeEnum.RusStandardLoanByAccountPH:
      case OperationTypeEnum.MTSBankLoanPH:
        return createPaymentHubControlHelper([this.operationFieldService.accountField(`Номер счёта`)]);
      case OperationTypeEnum.RusStandardLoanByAgreementNumberPH:
        return createPaymentHubControlHelper([this.operationFieldService.agreementField()]);
      case OperationTypeEnum.MTSPhonePH:
      case OperationTypeEnum.BeelinePhonePH:
      case OperationTypeEnum.MegaphonePhonePH:
      case OperationTypeEnum.Tele2PhonePH:
      case OperationTypeEnum.SkylinkGeneralPhonePH:
      case OperationTypeEnum.TinkoffPhonePH:
      case OperationTypeEnum.MTSSatelliteTVPH:
      case OperationTypeEnum.MTSIneractiveSatelliteTVPH:
      case OperationTypeEnum.MTSFixedByPhoneNumberPH:
      case OperationTypeEnum.MGTSPH:
      case OperationTypeEnum.YotaPhonePH:
        return createPaymentHubControlHelper([this.operationFieldService.phoneField(`+7`, ` (000) 000-0000`)]);
      case OperationTypeEnum.OrienBankByPhonePH:
        return createPhPhoneOnlyDigitsControlHelper();
      case OperationTypeEnum.UzbekistanPhonePH:
        return createPaymentHubControlHelper([this.operationFieldService.phoneField(`+998`, ` 00 000 0000`)]);
      case OperationTypeEnum.RusTelecomPH:
        return createPaymentHubControlHelper([this.operationFieldService.phoneOrAccountField()]);
      case OperationTypeEnum.YotaInternetPH:
        return createPaymentHubControlHelper([this.operationFieldService.phoneOrAccountYotaField()]);
      case OperationTypeEnum.MTSPhoneByAccountNumberPH:
      case OperationTypeEnum.MTSFixedByAccount11_13PH:
      case OperationTypeEnum.MTSFixedByAccount12PH:
      case OperationTypeEnum.MGTSByAccountNumberPH:
        return createPaymentHubControlHelper([this.operationFieldService.accountField(`Номер лицевого счета`)]);
      case OperationTypeEnum.AnyCardsCashless:
        return createPaymentHubControlHelper([
          this.operationFieldService.cardField(),
          this.operationFieldService.payerNameField(),
          this.operationFieldService.cardDateField()
        ]);
      case OperationTypeEnum.UniversalBankPH:
      case OperationTypeEnum.DocCredoBankPH:
      case OperationTypeEnum.DushanbeCityСortiMilliPH:
      case OperationTypeEnum.MobidramPH:

      case OperationTypeEnum.AlifBankByPhonePH:
        return createPaymentHubCISCardControlHelper();
      case OperationTypeEnum.DushanbeCityСortiMilliPH:
      case OperationTypeEnum.DushanbeCityByPhonePH:
      case OperationTypeEnum.DushanbeCityForSVTFavriByPhonePH:
      case OperationTypeEnum.DushanbeCityForAmonatBankByPhonePH:
      case OperationTypeEnum.DushanbeCityForAziziLightningByPhonePH:
      case OperationTypeEnum.DushanbeCityForBankArvandByPhonePH:
      case OperationTypeEnum.DushanbeCityForMDOArdoByPhonePH:
        return createPaymentHubCISCardControlHelperv2();

    }
    return null;
  }

  async createFirstStep(): Promise<void> {
    const operationHelper = this.getOperationPayload();
    operationHelper.items.map((item, itemIndex) => {
      if (item.isFormGroup) {
        item.controlsArr.map((controlArr, controlArrIndex) => {
          controlArr.controls.map((control, controlIndex) => {
            const temp = new FormControl(control.value ?? '',v.compose(control.validators) ?? null);
            if (control.disable) {
              temp.disable();
            }
            operationHelper.formGroup.addControl(`${item.id}_${control.name}`, temp);
            this.helperControl.addControlHelperArrayItem({
              fullName: `${item.id}_${control.name}`,
              name: control.name,
              arrayId: null,
              operationIndex: this.variables.operationInfoArray.length,
              itemIndex,
              controlArrIndex,
              controlIndex,
              parentName: item.id
            });
          });
        });
      }
    });
    this.variables.operationInfoArray.push(operationHelper);
    return;
  }

  async createSecondStep(): Promise<void> {
    const clientHelper = createClientControlHelper(this.variables.selectedDataAll);
    clientHelper.items.map((item, itemIndex) => {
      if (item.isFormGroup) {
        item.controlsArr.map((controlArr, controlArrIndex) => {
          controlArr.controls.map((control, controlIndex) => {
            const temp = new FormControl(control.value ?? '',v.compose(control.validators) ?? null)
            clientHelper.formGroup.addControl(`${item.id}_${control.name}`, temp);
            this.helperControl.addControlHelperArrayItem({
              fullName: `${item.id}_${control.name}`,
              name: control.name,
              arrayId: null,
              operationIndex: -1,
              itemIndex,
              controlArrIndex,
              controlIndex,
              parentName: item.id
            });
          });
        });
      }
    });
    this.variables.clientHelper = clientHelper;
    return;
  }

  async defaultState(editOperationId = null, operationType = null): Promise<void> {
    if (this.variables.operationTypes?.items?.length > 0) {
      await this.variables.defaultState(editOperationId, operationType);
      await this.initOperationSteps();
      this.variables.isReady = true;
    }
  }
}
