import { Injectable } from '@angular/core';
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {FormGroup} from "@angular/forms";
import {OperationVariableService} from "./operation-variable.service";
import {OperationHelperService} from "./operation-helper.service";
import {NotifyService} from "../../core/services/notify.service";
import {errorMessages} from "../../../kernel/constants/errors";

@Injectable({
  providedIn: 'root',
})
export class OperationValidationService {
  constructor(
    private variables: OperationVariableService,
    private helper: OperationHelperService,
    private notify: NotifyService
  ) {
  }

  get nextBtnDisabled(): boolean {
    return this.variables.currentStep === 3 && !this.confirmStepIsValid;
  }

  get firstInvalidStep(): number {
    if (!this.dataStepIsValid.valid) {
      return 1;
    }
    if (!this.createStepIsValid) {
      return 2;
    }
    if (!this.confirmStepIsValid) {
      return 3;
    }
    return 1;
  }

  get dataStepIsValid(): { oValid: boolean, cValid: boolean, valid: boolean } {
    const oValid = this.dataOperationIsValid;
    const cValid = this.dataClientIsValid;
    return {
      oValid,
      cValid,
      valid: oValid && cValid
    };
  }

  get dataOperationIsValid(): boolean {
    let result = true;
    this.variables.operationInfoArray.map(x => x.items.map(y => y.isSubmitted = true));
    this.variables.operationInfoArray.map(operation => {
      if (!this.stepsIsValid(operation)) {
        result = false;
      }
    });
    return result && this.variables.isMTDynamicValid;
  }

  get dataClientIsValid(): boolean {
    if (!this.variables.isClientNeed) {
      return true;
    }
    this.variables.clientHelper.items.map(x => x.isSubmitted = true);
    return this.stepsIsValid(this.variables.clientHelper);
  }

  get createStepIsValid(): boolean {
    return !!this.variables.operationHelperGroup &&
      !this.variables.operationHelperGroup.get('DataCorrect').errors;
  }

  get confirmStepIsValid(): boolean {
    return !!this.variables.operationHelperGroup &&
      !this.variables.operationHelperGroup.get('CashboxAmount').errors &&
      this.variables.commissionTotal <= this.variables.operationHelperGroup.value.CashboxAmount;
  }

  canReachTargetStep(step: number, first = false): boolean {
    if (first || step === 1) {
      return true;
    }
    if (step === 2) {
      const temp = this.dataStepIsValid;
      if (!temp.cValid && temp.oValid) {
        this.helper.openClientEditDialog();
      }
      return temp.valid;
    }
    if (step === 3) {
      this.variables.thirdSubmitted = true;
      return this.dataStepIsValid.valid && this.createStepIsValid;
    }
    if (step === 4) {
      return this.dataStepIsValid.valid && this.createStepIsValid && this.confirmStepIsValid;
    }
    return false;
  }

  stepsIsValid(steps: IStepsControlHelper): boolean {
    let result = true;
    if (!this.arrIsValid(steps.formGroup)) {
      result = false;
    }
    if (!!steps.formArray && !!steps.formArray.controls) {
      steps.formArray.controls.map(x => {
        if (!this.arrIsValid(x as FormGroup)) {
          result = false;
        }
      })
    }
    return result;
  }

  arrIsValid(formGroup: FormGroup): boolean {
    if (this.variables.isNeedCalculateCommission) {
      console.log('isNeedCalculateCommission')
      this.notify.error(errorMessages.calculateCommission);
      return false;
    }
    let result = true;
    Object.keys(formGroup.controls).forEach(name => {
      if (!!formGroup.get(name) && !!formGroup.get(name).errors) {
        console.log({
          name,
          control: formGroup.get(name),
        });
        result = false;
        if (name.includes('organizationCommission') && !this.variables.isTransfer ||
          name.includes('mainInfo_commission') && this.variables.isTransfer) {
          console.log('mainInfo_commission')
          this.notify.error(errorMessages.calculateCommission);
        }
      }
    });1
    return result;
  }
}
