<div class="expand" [ngClass]="{'open': expanded}" id="clientLimits">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/window-success.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">ЛИМИТЫ КЛИЕНТА</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.padding-bottom.px]="20">
      <div class="filter-wrapper">
        <div class="filter-left-wrapper" [ngClass]="{'open': filterExpand}">
          <div class="filter-left-column-item">
            <div class="filter-left-row-item">
              <app-input
                inputType="search"
                placeholder="Номер документа"
                label="Номер документа"
                name="DocumentNumber"
                hint="Серия и номер слитно"
                width="full"
                wrapperClass="inputBox-group"
                [parentForm]="searchForm"></app-input>
            </div>
          </div>
        </div>
      </div>

      <div class="client-limit-info-wrapper" *ngIf="clientLimitsResponse else emptyDocumentNumber">
        <p class="client-limit-title"><span class="client-name-title">{{clientName}}</span><span class="client-phone-title">{{phoneNumber | phonePipe}}</span></p>

        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="topMenu.currentIndex = item.index" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>

        <ng-container *ngIf="topMenu.currentIndex === 1">
          <div class="main-head-limits-wrapper">
            <div class="main-head-limit-item-wrapper">
              <div class="main-head-stats-title">Лимит на операцию</div>
              <div class="main-head-stats-value">{{clientLimitsResponse.limitForOperation.balance || 0 | number: "1.2":"ru"}} RUB</div>
            </div>
            <div class="main-head-limit-item-wrapper">
              <div class="main-head-stats-title">Лимит на день</div>
              <div class="main-head-stats-value">{{clientLimitsResponse.limitForDay.balance || 0 | number: "1.2":"ru"}} RUB</div>
            </div>
            <div class="main-head-limit-item-wrapper">
              <div class="main-head-stats-title">Лимит на месяц</div>
              <div class="main-head-stats-value">{{clientLimitsResponse.limitForMonth.balance || 0 | number: "1.2":"ru"}} RUB</div>
            </div>
          </div>
          <ng-container *ngFor="let operationLimits of clientLimitsResponse.operationsLimits">
            <p class="client-limit-title">{{operationLimits.operation.title}}</p>
            <div class="main-head-limits-wrapper">
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит на операцию</div>
                <div class="main-head-stats-value">{{operationLimits.limitForOperation.value.maxValue || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в день</div>
                <div class="main-head-stats-value">{{operationLimits.limitForDay.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в месяц</div>
                <div class="main-head-stats-value">{{operationLimits.limitForMonth.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
            </div>
            <div class="main-head-limits-wrapper">
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит на операцию (не франшиза)</div>
                <div class="main-head-stats-value">{{operationLimits.limitForOperationNotFranchise.value.maxValue || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в день (не франшиза)</div>
                <div class="main-head-stats-value">{{operationLimits.limitForDayNotFranchise.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в месяц (не франшиза)</div>
                <div class="main-head-stats-value">{{operationLimits.limitForMonthNotFranchise.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let countryLimits of clientLimitsResponse.countriesLimits; let i=index">
            <p class="client-limit-title">{{countryLimits.country.title}}</p>
            <div class="main-head-limits-wrapper">
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит на операцию</div>
                <div class="main-head-stats-value">{{countryLimits.limitForOperation.value.maxValue || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в день</div>
                <div class="main-head-stats-value">{{countryLimits.limitForDay.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
              <div class="main-head-limit-item-wrapper">
                <div class="main-head-stats-title">Лимит в месяц</div>
                <div class="main-head-stats-value">{{countryLimits.limitForMonth.balance || 0 | number: "1.2":"ru"}} RUB</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="topMenu.currentIndex === 2">

          <div class="client-limits-edit-wrapper">
            <app-input inputType="text"
                       placeholder="15000"
                       label="Лимит на операцию"
                       name="OperationLimit"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submitted"
                       [parentForm]="userLimitsForm"></app-input>
            <app-input inputType="text"
                       placeholder="15000"
                       label="Лимит на день"
                       name="DayLimit"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submitted"
                       [parentForm]="userLimitsForm"></app-input>
            <app-input inputType="text"
                       placeholder="15000"
                       label="Лимит на месяц"
                       name="MonthLimit"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submitted"
                       [parentForm]="userLimitsForm"></app-input>

            <div class="mt-20 mb-10" *ngIf="isAllNonBPAAdmins">
              <div class="operation-item-one-third">
                <app-checkbox
                  [wrapperClass]="'inputBox-group'"
                  [parentForm]="userLimitsForm"
                  name="IsActiveLimits"
                  label="Главные лимиты активны"
                  [controlInvalid]="submitted"
                ></app-checkbox>
              </div>
            </div>

            <ng-container *ngIf="clientLimitsResponse?.operationsLimits?.length > 0">
              <ng-container *ngFor="let item of clientLimitsResponse.operationsLimits; let i=index">
                <p class="client-limit-title">{{item.operation.title}}</p>
                <app-input inputType="text"
                           placeholder="15000"
                           label="Лимит на операцию"
                           name="operationLimit"
                           width="full"
                           wrapperClass="operation-item-one-third"
                           mask="separator.2"
                           maskSuffix=" RUB"
                           [readonly]="true"
                           [controlInvalid]="submitted"
                           [parentForm]="userLimitsOperationTypesFormArray.controls[i]"></app-input>
                <app-input inputType="text"
                           placeholder="15000"
                           label="Лимит на день"
                           name="dayLimit"
                           width="full"
                           wrapperClass="operation-item-two-third"
                           mask="separator.2"
                           maskSuffix=" RUB"
                           [readonly]="true"
                           [controlInvalid]="submitted"
                           [parentForm]="userLimitsOperationTypesFormArray.controls[i]"></app-input>
                <app-input inputType="text"
                           placeholder="15000"
                           label="Лимит на месяц"
                           name="monthLimit"
                           width="full"
                           wrapperClass="operation-item-three-third"
                           mask="separator.2"
                           maskSuffix=" RUB"
                           [readonly]="true"
                           [controlInvalid]="submitted"
                           [parentForm]="userLimitsOperationTypesFormArray.controls[i]"></app-input>
              </ng-container>
            </ng-container>
          </div>

          <div class="t-align-center mt-20 mb-10">
            <button class="button main"
                    [style.width.px]="230"
                    [style.height.px]="60" (click)="updateLimits()">Обновить лимиты</button>
          </div>

        </ng-container>
      </div>

      <ng-template #emptyDocumentNumber>
        <div class="empty-list">
          <p>Укажите номер документа клиента, чтобы увидеть его лимиты...</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
