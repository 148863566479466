import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Store} from "@ngxs/store";
import {ApiClient, HardwareData, IHardwareData, UserLoginSsoRequest} from "../../../../kernel/ApiClient";
import {
  HomeRedirect,
  ResetUser,
  SetToken,
  SetUser
} from "../../../../kernel/store/actions/user.actions";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {HardwareService} from "../../../core/services/hardware.service";
import {NotifyService} from "../../../core/services/notify.service";
import {getErrorMessage} from "../../../../kernel/helpers/data.helper";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../kernel/environments/environment";
import {StaticDictionariesService} from "../../../core/services/static-dictionaries.service";
import {BehaviorService} from "../../../core/services/behavior.service";

@Component({
  selector: 'app-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: ['./sso-auth.component.scss']
})
export class SsoAuthComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  hardware: IHardwareData;
  isFirst = true;

  callback: string;
  title: string;

  constructor(
    private apiClient: ApiClient,
    private store: Store,
    private notify: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
    private staticDictionariesService: StaticDictionariesService,
    private hardwareService: HardwareService,
    private behavior: BehaviorService
  ) {
    this.route.queryParams
      .subscribe(params => {
        this.callback = params[environment.ssoAuthParam.callback];
        }
      );
  }

  ngOnInit(): void {
    this.title = 'Ожидайте...';
    this.behavior.ssoHardwareChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (!!state) {
          this.loginAnyway();
        }
      });
  }

  loginAnyway(): void {
    this.hardware = this.hardwareService.hardware;
    if (this.isFirst) {
      this.isFirst = false;
      this.checkUid();
    }
  }

  gotoCallback(): void {
    if (this.callback) {
      this.router.navigate([this.callback]);
    } else {
      this.store.dispatch(new HomeRedirect());
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkUid(): void {
    this.title = 'Проверка пользователя...';
    this.apiClient
      .auth_LoginBySso({
        hardware: this.hardware as HardwareData,
        remember: false
      } as UserLoginSsoRequest)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(token => this.store.dispatch(new SetToken(token))),
        switchMap(() => this.apiClient.users_GetCurrentUser())
      )
      .subscribe(
        user => {
          this.staticDictionariesService.isRequestSendToDictionary = false;
          this.store.dispatch(new SetUser(user.user));
          this.gotoCallback();
        },
        error => {
          this.resetUserAndGotoAuth(error);
        }
      );
  }

  resetUserAndGotoAuth(error): void {
    this.store.dispatch(new ResetUser())
    this.notify.error(getErrorMessage(error));
  }
}

