import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IDictionaries, IOperationDataHelper, IOperationDataItemHelper} from "../../../../kernel/models/common.models";
import {Observable, Subject} from "rxjs";
import {Select} from "@ngxs/store";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-info-array',
  templateUrl: './info-array.component.html',
  styleUrls: ['./info-array.component.scss']
})
export class InfoArrayComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() info: IOperationDataHelper[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
