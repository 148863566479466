<ng-container *ngIf="!!info">
  <div class="operation-summary-wrapper"
       *ngFor="let item of info; let ngItemIndex=index"
       [ngClass]="{'parent': !item.array || item.array.length === 0}">
    <p class="operation-summary-title" [ngClass]="{'sub': item.array && item.array.length > 0}"> {{item.title }}</p>

    <ng-container *ngFor="let array of item.array; let ngArrayIndex=index">
      <ng-container *ngFor="let arrayItem of array.items; let ngArrayItemIndex=index">
        <div class="operation-summary-items-wrapper">
          <div class="operation-summary-item-wrapper" *ngIf="arrayItem?.title && arrayItem?.value">
            <div class="operation-summary-item-title">{{arrayItem.title}}:</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isDate">{{arrayItem.value | dateLocal:'dd.MM.yyyy':false:true}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isDateTime">{{arrayItem.value | dateLocal:'HH:mm:ss dd.MM.yyyy': false}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isCash">{{arrayItem.value || 0 | number: "1.2":"ru"}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isPhone">{{arrayItem.value | phonePipe}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isYesOrNo">{{arrayItem.value | answerPipe}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isUsual">{{arrayItem.value}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isCard">{{arrayItem.value | cardNumber}}</div>
            <div class="operation-summary-item-value" *ngIf="!!arrayItem.isOperationStatus">{{arrayItem.value | operationStatus}}</div>
          </div>
          <div class="operation-summary-item-wrapper d-block" *ngIf="arrayItem.isWarning">
            <div class="operation-summary-item-warning">{{arrayItem.title}}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
