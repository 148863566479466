import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  ApiClient,
  CountryUpdateRequest,
  CreateLimitsRequest,
  EntityTypeEnum,
  ICountriesResponse,
  ICountryUpdateRequest,
  IOperationTypeResponse,
  IOperationTypeUpdatePositionRequest,
  IOperationTypeUpdateRequest, IOrganizationOperationTypeRelationshipRequest,
  IPaymentSystemResponse,
  IPaymentSystemUpdateRequest,
  IUserResponse,
  OperationTypeResponse,
  OperationTypeUpdatePositionRequest,
  OperationTypeUpdateRequest, OrganizationOperationTypeRelationshipRequest,
  PaymentSystemUpdateRequest
} from "../../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../../kernel/models/common.models";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {GlobalBuilderService} from "../../../../core/services/global-builder.service";
import {getErrorMessage, getSelectId, inRange} from "../../../../../kernel/helpers/data.helper";
import {messages} from "../../../../../kernel/constants/messages";
import {debounceTime, takeUntil} from "rxjs/operators";
import {errorMessages} from "../../../../../kernel/constants/errors";
import {SetOperationTypes} from "../../../../../kernel/store/actions/dictionary.actions";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-organization-operation-types',
  templateUrl: './organization-operation-types.component.html',
  styleUrls: ['./organization-operation-types.component.scss']
})
export class OrganizationOperationTypesComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getOperationTypes) availableOperationTypes$: Observable<IOperationTypeResponse>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Input() id: string

  availableOperationTypes: IOperationTypeResponse;
  availableOperationTypesArr: IDataSelectedHelper[] = [];
  availableOrganizations: IDataSelectedHelper[] = [];

  subs = new Subscription();

  expanded = false;
  isFirst = true;

  organizationOperationTypeForm: FormGroup;

  organizationOperationTypes: IOperationTypeResponse;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private builder: GlobalBuilderService,
    private behavior: BehaviorService
  ) { }

  get organizationId(): string {
    return getSelectId(this.organizationOperationTypeForm.value.OrganizationId, this.availableOrganizations, false) as string;
  }
  get operationTypeId(): string {
    return getSelectId(this.organizationOperationTypeForm.value.OperationTypeId, this.availableOperationTypesArr, false) as string;
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => this.availableOrganizations = availableOrganizations));
    this.subs.add(this.availableOperationTypes$.subscribe(availableOperationTypes => {
      this.availableOperationTypesArr = [];
      this.availableOperationTypes = availableOperationTypes;
      if (this.availableOperationTypes) {
        this.availableOperationTypes.items.map(x => {
          this.availableOperationTypesArr.push({
            id: x.id,
            label: x.title,
            position: x.position,
            role: x.type
          });
        });
      }
    }));
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    this.organizationOperationTypeForm = new FormGroup({
      OrganizationId: new FormControl(null, [v.required]),
      OperationTypeId: new FormControl(null, v.required)
    });
    this.organizationOperationTypeForm.get(`OrganizationId`)
      .valueChanges
      .pipe(debounceTime(100), takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.getOrganizationOperationTypeList(this.organizationId);
      });
  }

  getOrganizationOperationTypeList(orgId: string): void {
    this.apiClient.dictionaries_GetOperationTypesByOrganizationId(orgId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.organizationOperationTypes = data;
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  removeOrganizationOperationType(operationTypeId: string): void {
    if (!this.organizationId) {
      this.notify.error(errorMessages.chooseBpa);
      return;
    }
    this.updateOrganizationOperationTypeRelationship({
      organizationId: this.organizationId,
      operationTypeId,
      state: false
    });
  }

  updateOrganizationOperationType(state: boolean): void {
    if (!this.organizationId) {
      this.notify.error(errorMessages.chooseBpa);
      return;
    }
    if (!this.operationTypeId) {
      this.notify.error(errorMessages.chooseOperationType);
      return;
    }
    this.updateOrganizationOperationTypeRelationship({
      organizationId: this.organizationId,
      operationTypeId: this.operationTypeId,
      state
    });
  }

  updateOrganizationOperationTypes(state: boolean): void {
    if (!this.organizationId) {
      this.notify.error(errorMessages.chooseBpa);
      return;
    }
    this.updateOrganizationOperationTypeRelationship({
      organizationId: this.organizationId,
      operationTypeId: null,
      state
    });
  }

  updateOrganizationOperationTypeRelationship(payload: IOrganizationOperationTypeRelationshipRequest): void {
    this.apiClient.organization_SetOrganizationOperationType(payload as OrganizationOperationTypeRelationshipRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.actionSuccess);
        this.getOrganizationOperationTypeList(payload.organizationId);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });

  }
}
