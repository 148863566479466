import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  ApiClient,
  IOperationTypeResponse,
  IOrganizationCommissionOperationTypeResponse,
  IUserResponse, OrganizationCommissionOperationTypeRequest
} from "../../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {IDataSelectedHelper} from "../../../../../kernel/models/common.models";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {GlobalBuilderService} from "../../../../core/services/global-builder.service";
import {getErrorMessage, getSelectId} from "../../../../../kernel/helpers/data.helper";
import {SetOperationTypes} from "../../../../../kernel/store/actions/dictionary.actions";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {errorMessages} from "../../../../../kernel/constants/errors";
import {messages} from "../../../../../kernel/constants/messages";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-organization-operation-type-commission',
  templateUrl: './organization-operation-type-commission.component.html',
  styleUrls: ['./organization-operation-type-commission.component.scss']
})
export class OrganizationOperationTypeCommissionComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(UserState.isAdministratorBPA) isAdministratorBPA$: Observable<boolean>;
  @Select(DictionaryState.getOperationTypes) availableOperationTypes$: Observable<IOperationTypeResponse>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Input() id: string

  availableOperationTypes: IOperationTypeResponse;
  availableOperationTypesArr: IDataSelectedHelper[] = [];
  availableOrganizations: IDataSelectedHelper[] = [];

  subs = new Subscription();

  user: IUserResponse;
  isAdministratorBPA = false;

  submitted = false;
  expanded = false;
  isFirst = true;

  hint = 'Выберите операцию';
  hintNotFranchise = '';

  organizationOperationTypeForm: FormGroup;

  commissions: IOrganizationCommissionOperationTypeResponse[] = [];

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private builder: GlobalBuilderService,
    private behavior: BehaviorService
  ) { }

  get operationTypeId(): string {
    return getSelectId(this.organizationOperationTypeForm.value.OperationTypeId, this.availableOperationTypesArr, false) as string;
  }
  get organizationId(): string {
    return getSelectId(this.organizationOperationTypeForm.value.OrganizationId, this.availableOrganizations, false) as string;
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.user$.subscribe(user => this.user = user));
    this.subs.add(this.isAdministratorBPA$.subscribe(isAdministratorBPA => {
      this.isAdministratorBPA = isAdministratorBPA;
      this.checkAdminBpaOrganizationId();
    }));
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
      this.checkAdminBpaOrganizationId();
    }));
    this.subs.add(this.availableOperationTypes$.subscribe(availableOperationTypes => {
      this.availableOperationTypesArr = [];
      this.availableOperationTypes = availableOperationTypes;
      if (this.availableOperationTypes) {
        this.availableOperationTypes.items.map(x => {
          this.availableOperationTypesArr.push({
            id: x.id,
            label: x.title,
            position: x.position,
            role: x.type
          });
        });
      }
    }));
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          } else {
            this.defaultFormControl();
            this.apiClient.dictionaries_GetOperationTypes()
              .subscribe(data => {
                data.items.map((x, index) => {
                  if (!x.ico) {
                    data.items[index].ico = '../../../../assets/img/icons/3-spin-dots.svg';
                  }
                });
                this.builder.operation.variables.operationTypes = data;
                this.store.dispatch(new SetOperationTypes(data));
                this.builder.operation.helper.resetAllForNewOperation(true);
              }, error => {
                this.notify.error(error.message);
              });
          }
        }
      });
  }

  defaultFormControl(): void {
    this.organizationOperationTypeForm.controls.OrganizationId.setValue(null);
    this.organizationOperationTypeForm.controls.OperationTypeId.setValue(null);
    this.organizationOperationTypeForm.controls.IsActiveOrganizationCommission.setValue(false);
    this.organizationOperationTypeForm.controls.OrganizationCommission.setValue(null);
    this.organizationOperationTypeForm.controls.OrganizationCommissionNotFranchise.setValue(null);
    this.checkAdminBpaOrganizationId();
  }

  checkAdminBpaOrganizationId(): void {
    if (!!this.organizationOperationTypeForm &&
      this.isAdministratorBPA &&
      !!this.availableOrganizations[0]) {
      this.organizationOperationTypeForm
        .get(`OrganizationId`)
        .setValue(this.availableOrganizations[0].label);
    }
  }

  createFormControls(): void {
    this.organizationOperationTypeForm = new FormGroup({
      OrganizationId: new FormControl(null, v.required),
      OperationTypeId: new FormControl(null, v.required),
      IsActiveOrganizationCommission: new FormControl(null),
      OrganizationCommission: new FormControl(null, [v.min(0), v.max(100)]),
      OrganizationCommissionNotFranchise: new FormControl(null, [v.min(0), v.max(100)])
    });

    this.checkAdminBpaOrganizationId();

    this.organizationOperationTypeForm.get(`OperationTypeId`).valueChanges
      .pipe(debounceTime(100), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fillOperationTypeForm(this.availableOperationTypes.items.findIndex(x => x.id === this.operationTypeId));
        this.fillCurrentCommission();
      });
    this.organizationOperationTypeForm.get(`OrganizationId`).valueChanges
      .pipe(debounceTime(100), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fillCurrentCommission();
      });

    this.organizationOperationTypeForm.valueChanges
      .pipe(
        debounceTime(100),
        filter(d => d.OperationTypeId && d.OrganizationId),
        map(d => ({
          operationTypeId: d.OperationTypeId,
          organizationId: d.OrganizationId,
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.fillCurrentCommission();
      });
  }

  fillCurrentCommission(): void {
    const index = this.commissions.findIndex(x => x.organizationId === this.organizationId && x.operationTypeId === this.operationTypeId);
    if (index > -1) {
      this.organizationOperationTypeForm.get(`IsActiveOrganizationCommission`).setValue(this.commissions[index].isActiveCommission);
      this.organizationOperationTypeForm.get(`OrganizationCommission`).setValue(this.commissions[index].commission);
      this.organizationOperationTypeForm.get(`OrganizationCommissionNotFranchise`).setValue(this.commissions[index].commissionNotFranchise);
    } else {
      this.organizationOperationTypeForm.get(`IsActiveOrganizationCommission`).setValue(false);
      this.organizationOperationTypeForm.get(`OrganizationCommission`).setValue(null);
      this.organizationOperationTypeForm.get(`OrganizationCommissionNotFranchise`).setValue(null);
    }
  }

  fillOperationTypeForm(index: number) {
    if (index < 0) {
      this.organizationOperationTypeForm.get(`IsActiveOrganizationCommission`).setValue(null);
      this.organizationOperationTypeForm.get(`OrganizationCommission`).setValue(null);
      this.organizationOperationTypeForm.get(`OrganizationCommissionNotFranchise`).setValue(null);
      this.hint = 'Выберите операцию';
      this.hintNotFranchise = '';
      this.organizationOperationTypeForm.get(`OrganizationCommission`).setValidators([v.min(0), v.max(100)]);
      this.organizationOperationTypeForm.get(`OrganizationCommissionNotFranchise`).setValidators([v.min(0), v.max(100)]);
      return;
    }
    this.hint = `Не более ${this.availableOperationTypes.items[index].organizationMaxCommission}% для Франшизы`;
    this.hintNotFranchise = `Не более ${this.availableOperationTypes.items[index].organizationMaxCommissionNotFranchise}% для не Франшизы`;
    this.organizationOperationTypeForm.get(`OrganizationCommission`).setValidators([v.min(0), v.max(this.availableOperationTypes.items[index].organizationMaxCommission)]);
    this.organizationOperationTypeForm.get(`OrganizationCommissionNotFranchise`).setValidators([v.min(0), v.max(this.availableOperationTypes.items[index].organizationMaxCommissionNotFranchise)]);
  }

  getOrganizationOperationTypeList(): void {
    this.apiClient.dictionaries_GetOperationTypes()
      .subscribe(data => {
        data.items.map((x, index) => {
          if (!x.ico) {
            data.items[index].ico = '../../../../assets/img/icons/3-spin-dots.svg';
          }
        });
        this.builder.operation.variables.operationTypes = data;
        this.store.dispatch(new SetOperationTypes(data));
      }, error => {
        this.notify.error(error.message);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
      this.getOrganizationCommissions();
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  updateOperationType(): void {
    this.checkAdminBpaOrganizationId();
    this.submitted = true;
    if (this.organizationOperationTypeForm.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }
    this.apiClient.organization_SetOrganizationCommissionByOperationType({
      commission: Number(this.organizationOperationTypeForm.value.OrganizationCommission),
      commissionNotFranchise: Number(this.organizationOperationTypeForm.value.OrganizationCommissionNotFranchise),
      operationTypeId: this.operationTypeId,
      isActiveCommission: this.organizationOperationTypeForm.value.IsActiveOrganizationCommission,
      organizationId: this.organizationId
    } as OrganizationCommissionOperationTypeRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.actionSuccess);
        this.getOrganizationOperationTypeList();
        this.getOrganizationCommissions();
      }, error => {
      this.notify.error(getErrorMessage(error));
      console.error(error);
    });
  }

  getOrganizationCommissions(): void {
    this.apiClient.organization_GetOrganizationCommissions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.commissions = data;
        this.fillCurrentCommission();
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
