import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {PopupLoaderTypeEnum} from "../../../kernel/enum/popupLoaderTypeEnum.enum";
import {LoggerErrorTypeEnum} from "../../../kernel/ApiClient";
import {InfoDialogItemHelper, IOperationBuilderCommandHelper} from "../../../kernel/models/common.models";
import {StaticDictionariesEnum} from "./static-dictionaries.service";

@Injectable({
  providedIn: 'root',
})
export class BehaviorService {
  public inputHintDetectStatus = new BehaviorSubject<string>(null);
  inputHintDetectChange$ = this.inputHintDetectStatus.asObservable();

  public initCenterStatus = new BehaviorSubject<boolean>(null);
  initCenterChange$ = this.initCenterStatus.asObservable();

  public controlParamsStatus = new BehaviorSubject<boolean>(null);
  controlParamsChange$ = this.controlParamsStatus.asObservable();

  public activeWrapperIdStatus = new BehaviorSubject<string>(null);
  activeWrapperIdChange$ = this.activeWrapperIdStatus.asObservable();

  public mainMenuLastVisibleStatus = new BehaviorSubject<number>(null);
  mainMenuLastVisibleChange$ = this.mainMenuLastVisibleStatus.asObservable();

  // public arrayListenerStatus = new BehaviorSubject<string>(null);
  // arrayListenerChange$ = this.arrayListenerStatus.asObservable();
  // public openClientDialogStatus = new BehaviorSubject<boolean>(null);
  // openClientDialogChange$ = this.openClientDialogStatus.asObservable();

  // public calculateCommissionStatus = new BehaviorSubject<boolean>(null);
  // calculateCommissionChange$ = this.calculateCommissionStatus.asObservable();

  public infoDialogListenerStatus = new BehaviorSubject<InfoDialogItemHelper>(null);
  infoDialogListenerChange$ = this.infoDialogListenerStatus.asObservable();

  public operationBuilderCommandStatus = new BehaviorSubject<IOperationBuilderCommandHelper>(null);
  operationBuilderCommandChange$ = this.operationBuilderCommandStatus.asObservable();

  public removeBroadcastMessagesByTypeStatus = new BehaviorSubject<string>(
    null
  );
  removeBroadcastMessagesByTypeChange$ = this.removeBroadcastMessagesByTypeStatus.asObservable();
  public popupLoaderStatus = new BehaviorSubject<PopupLoaderTypeEnum>(null);
  popupLoaderStatusChange$ = this.popupLoaderStatus.asObservable();

  public getQueueStatus = new BehaviorSubject<boolean>(null);
  getQueueStatusChange$ = this.getQueueStatus.asObservable();

  public settingExpandedStatus = new BehaviorSubject<string>(null);
  settingExpandedChange$ = this.settingExpandedStatus.asObservable();

  public getStaticDictionaryStatus = new BehaviorSubject<StaticDictionariesEnum>(null);
  getStaticDictionaryChange$ = this.getStaticDictionaryStatus.asObservable();

  public initUserDataStatus = new BehaviorSubject<boolean>(null);
  initUserDataChange$ = this.initUserDataStatus.asObservable();

  public ssoHardwareStatus = new BehaviorSubject<boolean>(null);
  ssoHardwareChange$ = this.ssoHardwareStatus.asObservable();
}
