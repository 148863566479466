import {IUserResponse, ITokenResponse, ApiClient, IHardwareData} from '../../ApiClient';
import {Injectable} from '@angular/core';
import {State, Selector, Action, StateContext} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {
  LoginRedirect,
  ResetUser,
  HomeRedirect,
  SetToken,
  SetUser,
  SetAgent,
  SetRoute,
  SetScreenWidth,
  OnLogout, SetPcParam,
} from '../actions/user.actions';
import {RoleProvider} from '../../enum/role-provider.enum';
import {getErrorMessage, isExpectedError} from '../../helpers/data.helper';
import {errorMessages} from '../../constants/errors';
import {NotifyService} from '../../../modules/core/services/notify.service';
import {isLocalEnv} from "../../helpers/env.helper";
import {defaultUrls} from "../../constants/common.constants";
import {HardwareService} from "../../../modules/core/services/hardware.service";

export class User {
  user: IUserResponse;
  token: string;
  expired: number;
  agent: string;
  isMobile: boolean;
  route: string;
  screenWidth: number;
  hardware: IHardwareData;
}

let token = '';
const defaults = {
  user: null,
  token: '',
  expired: 0,
  agent: '',
  isMobile: false,
  route: '',
  screenWidth: 0,
  hardware: null
};

@State<User>({
  name: 'user',
  defaults,
})
@Injectable()
export class UserState {
  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService,
    private hardwareService: HardwareService) {}

  @Selector()
  static getUser(user: User): IUserResponse {
    return user.user;
  }

  @Selector()
  static getExpired(user: User): number {
    return user.expired;
  }

  @Selector()
  static getRoute(user: User): string {
    return user.route;
  }

  @Selector()
  static getScreenWidth(user: User): number {
    return user.screenWidth;
  }

  @Selector()
  static isAuthorized(user: User): boolean {
    return (
      !!(UserState.localRememberMeStatus
        ? UserState.localToken
        : UserState.sessionToken || '') &&
      !!user &&
      !!user.user &&
      !!user.user.id
    );
  }

  @Selector()
  static isOperator(user: User): boolean {
    return (
      !!user && String(user.user.roleName) === String(RoleProvider.Operator)
    );
  }

  @Selector()
  static isAdministratorBPA(user: User): boolean {
    return (
      !!user &&
      String(user.user.roleName) === String(RoleProvider.AdministratorBPA)
    );
  }

  @Selector()
  static isAdministratorNabix(user: User): boolean {
    return (
      !!user &&
      String(user.user.roleName) === String(RoleProvider.AdministratorNabix)
    );
  }

  @Selector()
  static isSuperAdministratorNabix(user: User): boolean {
    return (
      !!user &&
      String(user.user.roleName) ===
        String(RoleProvider.SuperAdministratorNabix)
    );
  }

  @Selector()
  static isAdministratorMTS(user: User): boolean {
    return (
      !!user &&
      String(user.user.roleName) === String(RoleProvider.AdministratorMTS)
    );
  }

  @Selector()
  static isSuperAdministratorMTS(user: User): boolean {
    return (
      !!user &&
      String(user.user.roleName) === String(RoleProvider.SuperAdministratorMTS)
    );
  }
  @Selector()
  static isAllAdmins(user: User): boolean {
    return (
      !!user && (
        String(user.user.roleName) === String(RoleProvider.AdministratorBPA) ||
        String(user.user.roleName) === String(RoleProvider.AdministratorNabix) ||
        String(user.user.roleName) === String(RoleProvider.SuperAdministratorNabix) ||
        String(user.user.roleName) === String(RoleProvider.AdministratorMTS) ||
        String(user.user.roleName) === String(RoleProvider.SuperAdministratorMTS)
      )
    );
  }
  @Selector()
  static isAllNonBPAAdmins(user: User): boolean {
    return (
      !!user && (
        String(user.user.roleName) === String(RoleProvider.AdministratorNabix) ||
        String(user.user.roleName) === String(RoleProvider.SuperAdministratorNabix) ||
        String(user.user.roleName) === String(RoleProvider.AdministratorMTS) ||
        String(user.user.roleName) === String(RoleProvider.SuperAdministratorMTS)
      )
    );
  }
  @Selector()
  static isBPARole(user: User): boolean {
    return (
      !!user && (
        String(user.user.roleName) === String(RoleProvider.Operator) ||
        String(user.user.roleName) === String(RoleProvider.AdministratorBPA)
      )
    );
  }

  @Selector()
  static isMobile(user: User): boolean {
    return user ? !!user.isMobile : false;
  }

  @Selector()
  static hardware(user: User): IHardwareData {
    return user?.hardware ?? null;
  }

  @Action(LoginRedirect)
  LoginRedirect(ctx: StateContext<UserState>) {
    ctx.dispatch(new Navigate(['/auth/login']));
  }

  @Action(OnLogout)
  OnLogout({setState, dispatch}: StateContext<User>, {}) {
    this.apiClient.auth_Logout().subscribe(
      () => {
        setState(defaults);
        this.cleanSessionToken();
        token = null;
        dispatch(new Navigate(['/auth/login']));
      },
      error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      }
    );
  }

  @Action(ResetUser)
  ResetUser({setState, dispatch, getState, patchState}: StateContext<User>) {
    // const hardware = getState().hardware;
    setState(defaults);
    // patchState({ hardware})
    this.cleanSessionToken();
    token = null;
    dispatch(new Navigate(['/auth/login']));

    this.hardwareService.getHardwareInfo(defaultUrls.mtsAgentUrl)
      .subscribe(data => {
        if (!data || data && (
          !data.hardAll)) {
          this.notify.error(errorMessages.agentServiceHardwareError)
        } else {
          this.hardwareService.hardware = data;
          patchState({ hardware: data });
        }
      }, error => {
        this.hardwareService.hardware = null;
        patchState({ hardware: null });
        console.error(error)
      });
  }

  @Action(HomeRedirect)
  HomeRedirect(ctx: StateContext<UserState>) {
    ctx.dispatch(new Navigate(['/home']));
  }

  @Action(SetToken)
  SetToken({setState, patchState, getState}: StateContext<User>, {data}) {
    const response = data as ITokenResponse;
    token = !!response && !!response.value ? response.value : '';
    const expired = !!response && !!response.expired ? response.expired : 0;
    this.sessionToken = token;
    patchState({token, expired});
  }

  @Action(SetScreenWidth)
  SetScreenWidth({patchState, dispatch}: StateContext<User>, {data}) {
    patchState({screenWidth: data});
  }

  @Action(SetAgent)
  SetAgent({patchState, dispatch}: StateContext<User>, {data}) {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      data
    );
    patchState({isMobile, agent: data});
  }

  @Action(SetRoute)
  SetRoute({patchState, dispatch}: StateContext<User>, {data}) {
    patchState({route: data});
  }

  @Action(SetUser)
  SetUser({patchState, dispatch}: StateContext<User>, {user}) {
    patchState({user});
  }

  @Action(SetPcParam)
  SetPcParam({ patchState }: StateContext<User>, { hardware }) {
    patchState({ hardware: hardware ?? null });
  }

  cleanSessionToken() {
    sessionStorage.removeItem('sessionToken');
  }

  set sessionToken(token: string) {
    sessionStorage.setItem('sessionToken', token);
  }

  static get localToken() {
    const data = localStorage.getItem('@@STATE');
    if (!data) {
      return null;
    }
    const temp = JSON.parse(data);
    if (!temp || !temp.user || !temp.user.token) {
      sessionStorage.removeItem('sessionToken');
      localStorage.clear();
      return null;
    }
    return temp.user.token ? temp.user.token : token;
  }

  static get localRememberMeStatus() {
    const data = localStorage.getItem('@@STATE');
    if (!data) {
      return false;
    }
    const temp = JSON.parse(data);
    // if (!temp || !temp.user || !temp.user.user) {
    //   sessionStorage.removeItem('sessionToken');
    //   localStorage.clear();
    //   return false;
    // }
    return !!temp && !!temp.user && temp.user.user
      ? !!temp.user.user.rememberMe
      : false;
  }

  static get user(): IUserResponse {
    const data = localStorage.getItem('@@STATE');
    if (!data) {
      return null;
    }
    const temp = JSON.parse(data);
    if (!temp || !temp.user || !temp.user.user) {
      return null;
    }
    return temp.user.user
  }

  static get sessionToken() {
    const temp = sessionStorage.getItem('sessionToken');
    return temp ? sessionStorage.getItem('sessionToken') : this.localToken;
  }

  set kkmUrl(kkmUrl: string) {
    sessionStorage.setItem('kkmUrl', kkmUrl)
  }

  set mtsAgentUrl(mtsAgentUrl: string) {
    sessionStorage.setItem('mtsAgentUrl', mtsAgentUrl)
  }

  static get kkmUrl() {
    return sessionStorage.getItem('kkmUrl')
  }

  static get mtsAgentUrl() {
    return sessionStorage.getItem('mtsAgentUrl')
  }
}
