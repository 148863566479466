import {addressFields, billTypeRu, inputTypes} from 'src/kernel/constants/common.constants';
import {
  IControlHelper,
  IControlsArrayHelper, IDataSelectedHelper,
  IDictionaries,
  IStepsControlHelper,
} from '../models/common.models';
import {RoleProvider, RoleProviderRU} from '../enum/role-provider.enum';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators as v,
} from '@angular/forms';
import {
  BillingTypeEnum,
  IAddress,
  IClient,
  IClientResponse,
  IErrorMessage, IHardwareData,
  IInvalidFieldInfo,
  IPerson,
  IPhoneNumber,
  ITransfersCreateRequest,
} from '../ApiClient';
import {errorMessages} from "../constants/errors";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function getCurrenciesOnlyAccepted(data: IDictionaries): IDataSelectedHelper[] {
  const arr = ['RUB'];
  const arrResult: IDataSelectedHelper[] = [];
  data.currencies.map(x => {
    if (arr.findIndex(y => x.id === y) > -1) {
      arrResult.push(x);
    }
  });
  return arrResult;
}

export function getCurrencies(data: IDictionaries): IDataSelectedHelper[] {
  return !!data && !!data.currencies ? data.currencies : [];
}

export function getCountries(data: IDictionaries): IDataSelectedHelper[] {
  return !!data && !!data.countries ? data.countries : [];
}

export function getCountriesAddresses(data: IDictionaries): IDataSelectedHelper[] {
  return !!data && !!data.countriesAddresses ? data.countriesAddresses : [];
}

export function getDocuments(data: IDictionaries): IDataSelectedHelper[] {
  return !!data && !!data.documents ? data.documents : [];
}

export function getGenders(data: IDictionaries): IDataSelectedHelper[] {
  return !!data && !!data.gender ? data.gender : [];
}

export function rnd(length) {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );
}

export function rndMax(max) {
  // 3 => 0 1 2
  return Math.floor(Math.random() * Math.floor(max));
}

export function notNullOrUndefined(value: unknown, defVal = false): boolean {
  return !defVal
    ? value !== null && value !== undefined
    : value !== null &&
        value !== undefined &&
        String(value) !== 'string' &&
        !!value;
}
export function nullOrUndefinedOrEmpty(value: unknown): boolean {
  return value === null || value === undefined || value === 'null' || value === 'undefined' || value === '';
}


export function trimAndUpper(str: string): string {
  return !str ? '' : str.replace(/\s/g, '').toUpperCase();
}

export function inRange(val: number, r1: number, r2: number): boolean {
  return val >= r1 && val <= r2;
}

export function convertToKeyboard(
  value: string,
  key1: string,
  key2: string
): string {
  let result = '';
  const temp = Object.assign([], value);
  const arr1 = Object.assign([], key1);
  const arr2 = Object.assign([], key2);
  temp.map(x => {
    const index = arr1.findIndex(y => y === x);
    result =
      index > -1 && arr2[index]
        ? String(result) + String(arr2[index])
        : String(result) + String(x);
  });
  return result;
}

export function checkNullableDate(
  date: Date,
  utc: number,
  abortText: string
): string {
  if (date) {
    return String(date);
  }
  return abortText;
}

export function isBroadcatsRoute(url: string): boolean {
  return url.includes('broadcast-message');
}

export function cleanMessage(str: string): string {
  return str.replace(/\s\s/g, '').replace(/{/g, '').replace(/}/g, '');
}

export function isExpectedError(error: IErrorMessage): boolean {
  return !!error && !!error.statusCode;
}

export function getErrorMessage(error: any): string {
  return isExpectedError(error) ? error.message : errorMessages.serverRequestError;
}

export function getApiErrorMessage(error: IErrorMessage): string {
  return error.message;
}

export const chartColors = [
  '#1CAAFC',
  '#F1A038',
  '#8153CA',
  '#CADD9F',
  '#1F7AC4',
  '#5141C2',
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#546E7A',
  '#26A69A',
  '#D10CE8',
];

export function trimSpaces(val: string): string {
  let temp = String(val);
  temp = temp.replace(/ /gi, '');
  return temp;
}

export function getStartOfDay(date: Date): Date {
  const d1 = new Date(date);
  d1.setHours(0, 0, 0, 0);
  return d1;
}

export function getIdByValue(
  data: IDataSelectedHelper[],
  value: string
): string {
  if (!data) {
    return null;
  }
  const index = data.findIndex(
    x => String(x.id) === String(value) || String(x.label) === String(value)
  );
  return index > -1 ? data[index].id : null;
}

export function getValueById(data: IDataSelectedHelper[], id: string): string {
  if (!data) {
    return null;
  }
  const index = data.findIndex(
    x => String(x.id) === String(id) || String(x.label) === String(id)
  );
  return index > -1 ? data[index].label : null;
}

export function getSelectId(
  value: string,
  arr: IDataSelectedHelper[],
  isArr: boolean
): string | string[] {
  if (!arr || !value) {
    return isArr ? [] : null;
  }
  if (isArr) {
    const result = [];
    const valArr = value.split(', ');
    valArr.map(x => {
      const temp = getSelectId(x, arr, false);
      if (temp) {
        result.push(temp);
      }
    });
    return result;
  }
  const index = arr.findIndex(
    x => String(x.id) === String(value) || String(x.label) === String(value)
  );
  return index > -1 ? arr[index].id : null;
}

export function getSelectIdNullable(
  value: string,
  arr: IDataSelectedHelper[]
): string | null {
  if (!value) {
    return null;
  }
 
  const index = arr.findIndex(
    x => String(x.id) === String(value) || String(x.label) === String(value)
  );
  return index > -1 ? arr[index].id : null;
}

export function formatString(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function pwdWeightAnalyze(value: string): number {
  // Вес пароля устанавливается равным нулю.
  let weight = 0;
  const pwd = value;
  // Если длина пароля менее 6 символов, то работа алгоритма заканчивается и возвращается результат “слишком короткий пароль”.
  if (pwd.length < 6) {
    return 0;
  }
  // Вес пароля увеличиваем на величину 4 * len, где len – длина пароля.
  weight = pwd.length * 4;
  // Компрессия пароля
  let step = 0;
  let compressPwd = '';
  let last = '';
  let arr = [];
  while (step < 4) {
    step++;
    arr = [];
    compressPwd = '';
    switch (step) {
      case 1:
        arr = pwd.match(/.{1,1}/g);
        break;
      case 2:
        arr = pwd.match(/.{1,2}/g);
        break;
      case 3:
        arr = pwd.match(/.{1,3}/g);
        break;
      case 4:
        arr = pwd.match(/.{1,4}/g);
        break;
    }
    last = '';
    // Проводятся попытки сжатия пароля для случаев строк S длинной 2, 3 и 4 символов.
    // Вес пароля уменьшается аналогично на величину len — lenCompress.
    arr.map(x => {
      if (last !== x) {
        last = x;
        compressPwd += x;
      }
    });
    weight = weight - (pwd.length - compressPwd.length);
  }
  // Если пароль содержит не меньше 3 цифр, то увеличить вес на 5.
  weight = pwd.match(/\d/g)?.length >= 3 ? weight + 5 : weight;
  // Если пароль содержит не менее 2 знаков, то увеличить вес на 5.
  weight =
    pwd.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g)?.length >= 2
      ? weight + 5
      : weight;
  // Если пароль содержит буквы как в верхнем так и в нижнем регистрах, то увеличить вес пароля на 10.
  weight =
    pwd.match(/[A-Z]/g)?.length > 0 && pwd.match(/[a-z]/g)?.length > 0
      ? weight + 10
      : weight;
  // Если пароль содержит буквы и цифры, то увеличить вес пароля на 15.
  weight = /[a-zA-Z]/g.test(pwd) && /\d/g.test(pwd) ? weight + 15 : weight;
  // Если пароль содержит знаки и цифры, то увеличить вес на 15.
  weight =
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd) && /\d/g.test(pwd)
      ? weight + 15
      : weight;
  // Если пароль содержит буквы и знаки, то увеличить вес на 15.
  weight =
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd) &&
    /[a-zA-Z]/g.test(pwd)
      ? weight + 15
      : weight;
  // Если пароль состоит только из букв или только из цифр, то уменьшить вес пароля на 10.
  weight =
    (/[a-zA-Z]/g.test(pwd) &&
      !/\d/g.test(pwd) &&
      !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd)) ||
    (!/[a-zA-Z]/g.test(pwd) &&
      /\d/g.test(pwd) &&
      !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd)) ||
    (!/[a-zA-Z]/g.test(pwd) &&
      !/\d/g.test(pwd) &&
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd))
      ? weight - 10
      : weight;

  // Если вес пароля меньше 0, то установить его равным 0. Если больше 100, то установить равным 100.
  // Пароль, вес которого меньше 34, признаётся “слабым”. Если вес от 34 до 67, то пароль относится к категории “хороший”,
  // а если более 67, то пароль считается “отличным”.
  return weight > 100 ? 100 : weight < 0 ? 0 : weight;
}

export function checkDate(d: string): boolean {
  let err = true;
  if (!d) {
    return false;
  }
  err = checkDateArrayNumberErr(d.split(''));
  if (String(d).length === 10) {
    const minDate = new Date(1900, 0, 0);
    const maxDate = new Date(2100, 0, 0);
    const dateTime = d.split('.');
    let date = new Date(
      Date.UTC(
        Number(dateTime[2]),
        Number(dateTime[1]) - 1,
        Number(dateTime[0]),
        0,
        0,
        0
      )
    );
    date = getStartOfDay(date);
    err = checkDateArrayErr(dateTime) || !(date > minDate && date < maxDate);
  }
  return err;
}

export function checkDateArrayNumberErr(arr: string[]): boolean {
  let check = false;
  arr.map((x, index) => {
    if (index !== 2 && index !== 5) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(x) < 0) {
        check = true;
      }
    }
  });
  return check;
}

export function checkDateArrayErr(arr: string[]): boolean {
  if (arr.length !== 3) {
    return true;
  }
  if (Number(arr[1]) > 12 || Number(arr[1]) <= 0) {
    return true;
  }
  const index = [1, 3, 5, 7, 8, 10, 12].findIndex(x => x === Number(arr[1]));
  let maxDay = 30;
  if (index > -1) {
    maxDay = 31;
  } else {
    if (Number(arr[1]) === 2) {
      maxDay = Number(arr[2]) % 4 === 0 ? 29 : 28;
    }
  }
  return Number(arr[0]) > maxDay || Number(arr[0]) <= 0;
}

export function isMobileDevice(): boolean {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function isPhoneInput(type: string): boolean {
  return !!type && String(type).toUpperCase() === inputTypes.tel.toUpperCase();
}
export function isSearchInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.search.toUpperCase()
  );
}
export function isSelectInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.select.toUpperCase()
  );
}
export function isDatepickerInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.datepicker.toUpperCase()
  );
}
export function isStringDateInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.stringdate.toUpperCase()
  );
}
export function isCardInput(type: string): boolean {
  return !!type && String(type).toUpperCase() === inputTypes.card.toUpperCase();
}
export function isNumberInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.number.toUpperCase()
  );
}
export function isTextInput(type: string): boolean {
  return !!type && String(type).toUpperCase() === inputTypes.text.toUpperCase();
}
export function isPasswordInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.password.toUpperCase()
  );
}
export function isTextareaInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.textarea.toUpperCase()
  );
}
export function isCheckboxInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.checkbox.toUpperCase()
  );
}
export function isRadioInput(type: string): boolean {
  return (
    !!type && String(type).toUpperCase() === inputTypes.radio.toUpperCase()
  );
}

export const passportRusVariableArray = [
  'Паспорт гражданина РФ',
  'Паспорт РФ',
  'Паспорт гражданина Российской Федерации',
  'Паспорт Российской Федерации',
  'Паспорт гражданина России',
  'Паспорт России',
];

export const nationalityRusVariableArray = [
  'РФ',
  'Россия',
  'Российская Федерация',
];

export function isPassportRus(value: string): boolean {
  return (
    !!value &&
    passportRusVariableArray.findIndex(
      x => x.toUpperCase() === String(value).toUpperCase()
    ) > -1
  );
}

export function isNationalityRus(value: string): boolean {
  return !!value && notNullOrUndefined(value)
    ? nationalityRusVariableArray.findIndex(
        x => x.toUpperCase() === String(value).toUpperCase()
      ) > -1
    : false;
}

export function equals(
  value1: string,
  value2: string,
  register = false
): boolean {
  return register
    ? String(value1) === String(value2)
    : String(value1) === String(value2) ||
        String(value1).toUpperCase() === String(value2).toUpperCase();
}

export function isBoolean(val: string): boolean {
  return typeof val === 'boolean';
}

export function isNumber(val: string): boolean {
  return typeof val === 'number';
}

export function isString(val: string): boolean {
  return typeof val === 'string';
}

export function getObjectFieldsArray(data: unknown): string[] {
  const arr = [];
  Object.keys(data).forEach(name => {
    arr.push(name);
  });
  return arr;
}

export function getMaxValueByProperty(array: [], property: string): number {
  // eslint-disable-next-line prefer-spread
  return Math.max.apply(
    Math,
    array.map(function (o) {
      return o[property];
    })
  );
}

export function getUserRoleRu(role: string): string {
  if (RoleProviderRU[role]) {
    return RoleProviderRU[role];
  }
  switch (String(role)) {
    case RoleProvider.Operator:
      return RoleProviderRU.Operator;
    case RoleProvider.AdministratorBPA:
      return RoleProviderRU.AdministratorBPA;
    case RoleProvider.AdministratorNabix:
      return RoleProviderRU.AdministratorNabix;
    case RoleProvider.SuperAdministratorNabix:
      return RoleProviderRU.SuperAdministratorNabix;
    case RoleProvider.AdministratorMTS:
      return RoleProviderRU.AdministratorMTS;
    case RoleProvider.SuperAdministratorMTS:
      return RoleProviderRU.SuperAdministratorMTS;
  }
  switch (Number(role)) {
    case 6:
      return RoleProviderRU.Operator;
    case 5:
      return RoleProviderRU.AdministratorBPA;
    case 4:
      return RoleProviderRU.AdministratorMTS;
    case 3:
      return RoleProviderRU.SuperAdministratorMTS;
    case 2:
      return RoleProviderRU.AdministratorNabix;
    case 1:
      return RoleProviderRU.SuperAdministratorNabix;
  }
  return role;
}
export function getUserRole(role: string): string {
  switch (String(role)) {
    case RoleProviderRU.Operator:
      return RoleProvider.Operator;
    case RoleProviderRU.AdministratorBPA:
      return RoleProvider.AdministratorBPA;
    case RoleProviderRU.AdministratorNabix:
      return RoleProvider.AdministratorNabix;
    case RoleProviderRU.SuperAdministratorNabix:
      return RoleProvider.SuperAdministratorNabix;
    case RoleProviderRU.AdministratorMTS:
      return RoleProvider.AdministratorMTS;
    case RoleProviderRU.SuperAdministratorMTS:
      return RoleProvider.SuperAdministratorMTS;
  }
  switch (Number(role)) {
    case 6:
      return RoleProvider.Operator;
    case 5:
      return RoleProvider.AdministratorBPA;
    case 4:
      return RoleProvider.AdministratorMTS;
    case 3:
      return RoleProvider.SuperAdministratorMTS;
    case 2:
      return RoleProvider.AdministratorNabix;
    case 1:
      return RoleProvider.SuperAdministratorNabix;
  }
  return role;
}
export function getUserRoleEnumId(role: string): number {
  switch (String(role)) {
    case RoleProvider.Operator:
      return 5;
    case RoleProvider.AdministratorNabix:
      return 2;
    case RoleProvider.SuperAdministratorNabix:
      return 1;
    case RoleProvider.AdministratorMTS:
      return 4;
    case RoleProvider.SuperAdministratorMTS:
      return 3;
    case RoleProvider.AdministratorBPA:
      return 6;
  }
  return 1;
}

export function mapInvalidFields(
  formGroup: FormGroup,
  fields: IInvalidFieldInfo[]
): FormGroup {
  Object.keys(formGroup.controls).forEach(name => {
    if (formGroup.get(name)) {
      formGroup.get(name)['apiErrors'] = [];
    }
  });
  if (!fields) {
    return formGroup;
  }
  fields.map(x => {
    if (formGroup.get(x.propertyName)) {
      formGroup.get(x.propertyName)['apiErrors'].push(x.errorMessage);
    }
  });
  return formGroup;
}

export function getFullAddress(formGroup: FormGroup, prefix: string): string {
  const address = [];
  if (prefix) {
    addressFields.map((x: {control: string; prefix: string}) => {
      if (
        !!formGroup.value[`${prefix}_${x.control}`] &&
        String(formGroup.value[`${prefix}_${x.control}`]).length > 0
      ) {
        address.push(
          `${x.prefix}${String(formGroup.value[`${prefix}_${x.control}`])}`
        );
      }
    });
  } else {
    addressFields.map((x: {control: string; prefix: string}) => {
      if (
        !!formGroup.value[x.control] &&
        String(formGroup.value[x.control]).length > 0
      ) {
        address.push(
          `${x.prefix}${String(formGroup.value[`${prefix}_${x.control}`])}`
        );
      }
    });
  }
  return address.join(', ');
}

// without any changes
export function createFormGroupByControlsArrayHelper(
  controlArr: IControlsArrayHelper,
  id: string,
  arrayId: string,
  isNew: boolean
): FormGroup {
  const formGroup = new FormGroup({});
  controlArr.controls.map(control => {
    const temp = new FormControl(
      !!control.value ? control.value : null,
      v.compose(control.validators) ?? null
    );
    formGroup.addControl(`${id}_${String(control.name)}`, temp);
  });
  const temp = new FormControl(arrayId, null);
  if (isNew) {
    Object.keys(formGroup.controls).forEach(name => {
      formGroup.get(name).setValue(null);
    });
  }
  temp.markAsDirty();
  formGroup.addControl(`arrayId`, temp);
  return formGroup;
}

export function getValueFromModel(
  value: unknown,
  control: IControlHelper
): unknown {
  if (!notNullOrUndefined(value, true)) {
    return null;
  }
  if (isSelectInput(control.inputType)) {
    value = getValueById(control.selectData, String(value));
  }
  return value;
}

export function getValueForModel(
  value: unknown,
  control: IControlHelper
): string | number | Date | boolean {
  if (!notNullOrUndefined(value, true)) {
    return null;
  }
  if (isSelectInput(control.inputType)) {
    value = getSelectId(String(value), control.selectData, false);
  }
  if (isDatepickerInput(control.inputType)) {
    return getUTCDateTime(value ? new Date(String(value)) : null);
  }
  return String(value);
}

export function getUTCDate(date: Date, withOffset: boolean): Date {
  const offset = -1 * new Date().getTimezoneOffset();
  const temp = new Date(new Date(date).getTime() + (withOffset ? offset * 60 * 1000 : 0));
  const utcDate = new Date(
    Date.UTC(
      temp.getUTCFullYear(),
      temp.getUTCMonth(),
      temp.getUTCDate(),
      0,0,0,0));
  return utcDate;
}

export function getUTCDateTime(
  date: Date,
  offsetHour = 0,
  offsetMin = 0,
  offsetSec = 0,
  offsetMill = 0
): Date {
  if (!date) {
    return null;
  }
  const offset = -1 * new Date().getTimezoneOffset();
  const temp = new Date(date);
  const utcDate = new Date(
    Date.UTC(
      temp.getUTCFullYear(),
      temp.getUTCMonth(),
      temp.getUTCDate(),
      temp.getUTCHours() + offsetHour,
      temp.getUTCMinutes() + offsetMin,
      temp.getUTCSeconds() + offsetSec,
      temp.getUTCMilliseconds() + offsetMill
    )
  );
  return new Date(utcDate.getTime() + offset * 60 * 1000);
}

export function convertToSimpleTypeObject(model: any, res: any): any {
  if (!model || !typeof model) {
    return res;
  }
  if (model instanceof Array) {
    model.map(x => {
      Object.keys(x).forEach(name => {
        if (x[name]) {
          if (typeof x[name] === 'object' && !(model[name] instanceof Date)) {
            const temp = convertToSimpleTypeObject(x[name], res);
            if (temp) {
              Object.keys(temp).forEach(tempName => {
                res[tempName] = temp[tempName];
              });
            }
          } else {
            res[name] = x[name];
          }
        }
      });
    });
  } else {
    Object.keys(model).forEach(name => {
      if (model[name]) {
        if (typeof model[name] === 'object' && !(model[name] instanceof Date)) {
          const temp = convertToSimpleTypeObject(model[name], res);
          if (temp) {
            Object.keys(temp).forEach(tempName => {
              res[tempName] = temp[tempName];
            });
          }
        } else {
          res[name] = model[name];
        }
      }
    });
    Object.keys(res).forEach(name => {
      res[name] = notNullOrUndefined(res[name], true) ? res[name] : null;
    });
    return res;
  }
}

export function fillModelByControlGroupHelper(
  helper: IStepsControlHelper,
  id: string,
  model:
    | IClient
    | IClientResponse
    | IAddress
    | ITransfersCreateRequest
    | IPerson
): IClient | IClientResponse | IAddress | ITransfersCreateRequest | IPerson {
  const index = helper.items.findIndex(x => x.id === id);
  if (index < 0 || !model) {
    return model;
  }
  const modelFieldsArr = [];
  Object.keys(model).forEach(name => {
    modelFieldsArr.push(name);
  });
  if (helper.items[index].controlsArr[0]) {
    helper.items[index].controlsArr[0].controls.map(control => {
      if (modelFieldsArr.findIndex(x => x === control.name) > -1) {
        model[control.name] = getValueForModel(
          helper.formGroup.value[`${id}_${control.name}`],
          control
        );
      }
    });
  }
  return model;
}

export function getControlFromControlArr(
  controlArr: IControlsArrayHelper,
  name: string
): IControlHelper {
  const index = controlArr.controls.findIndex(x => x.name === name);
  return index > -1 ? controlArr.controls[index] : null;
}

export function fillClientOrReceiverPhoneNumbers(
  helper: IStepsControlHelper
): IPhoneNumber[] {
  const result: IPhoneNumber[] = [];
  let parentId = 'phoneNumbers';
  let index = helper.items.findIndex(x => x.id === `phoneNumbers`);
  if (index < 0) {
    index = helper.items.findIndex(x => x.id === `receiverPhoneNumbersInfo`);
    parentId = 'receiverPhoneNumbersInfo';
    if (index < 0) {
      return result;
    }
  }
  helper.items[index].controlsArr.map(controlArr => {
    const arrayItemIndex = helper.formArray.value.findIndex(
      x => x.arrayId === controlArr.arrayId
    );
    if (arrayItemIndex > -1) {
      result.push({
        number: getValueForModel(
          helper.formArray.value[arrayItemIndex][`${parentId}_number`],
          getControlFromControlArr(controlArr, 'number')
        ) as string,
      });
    }
  });
  return result;
}

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (!abstractControl) {
    return false;
  }
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

export function createEmptyAddress(): IAddress {
  return {
    countryCode: '',
    zipCode: '',
    region: '',
    district: '',
    city: '',
    locality: '',
    street: '',
    house: '',
    block: '',
    building: '',
    apartment: '',
    addressFull: '',
  };
}

export function setFormGroupEnabledOrDisabled(
  formGroup: FormGroup,
  forceArr: string[],
  disable: boolean
): FormGroup {
  Object.keys(formGroup.controls).forEach(name => {
    if (forceArr.findIndex(x => x === name) < 0) {
      if (disable) {
        formGroup.get(name).disable();
      } else {
        formGroup.get(name).enable();
      }
    }
  });
  return formGroup;
}

export function enableDisableControl(
  control: AbstractControl,
  disable: boolean
): AbstractControl {
  disable ? control.disable() : control.enable();
  return control;
}

export function removeNotDigits(value: string): string {
  return value.replace(/\D/g, '');
}

export function toPhone(value: string): string {
  if (!value) {
    return value;
  }
  const temp = removeNotDigits(value);
  return temp.length === 10 ? `+7${temp}` : `+${temp}`;
}

export function toPhones(valueArr: IPhoneNumber[]): IPhoneNumber[] {
  if (!valueArr || valueArr.length === 0) {
    return valueArr;
  }
  valueArr.map(x => {
    x.number = toPhone(x.number);
  });
  return valueArr;
}

export function getNotifyDelay(value: string): number {
  const del = Math.floor(value.length / 120) * 60;
  if (del < 5) {
    return 5000;
  }
  return del > 20 ? 20000 : del * 1000;
}

export function randomString(length: number, requireDigit = true, requireLowercase = true, requireUppercase = true, requireSymbols = true) {
  let result = '';
  let arr = [requireDigit ? 0 : -1, requireLowercase ? 0 : -1, requireUppercase ? 0 : -1, requireSymbols ? 0 : -1];
  length = length < arr.filter(x => x === 0).length ? arr.filter(x => x === 0).length : length;
  let chars = [
    'ABCDEFGHJKLMNOPQRSTUVWXYZ', // 26
    'abcdefghijkmnopqrstuvwxyz', // 26
    '0123456789', // 10
    '!@$?_-' // 6
  ];
  let charsLength = [
    26, // 26
    26, // 26
    10, // 10
    6 // 6
  ];
  while (arr.filter(x => x === 0).length > 0) {
    let indexArr = rndMax(4);
    if (arr[indexArr] === 0) {
      let indexChar = rndMax(charsLength[indexArr]);
      result += chars[indexArr].charAt(indexChar);
      arr[indexArr]++;
    }
  }
  while (result.length < length) {
    let indexArr = rndMax(4);
    if (arr[indexArr] > 0) {
      let indexChar = rndMax(charsLength[indexArr]);
      result += chars[indexArr].charAt(indexChar);
      arr[indexArr]++;
    }
  }
  return result;
}

export function normalizeString(str: string): string {
  const temp = `ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyzйцукенгшщзхъфывапролджэячсмитьбюЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮ`;
  let result = '';
  for (var i = 0; i < str.length; i++) {
    if (temp.indexOf(str[i]) > -1) {
      result = result + str[i];
    }
  }
  return result.toUpperCase();
}

export function removeNonASCII(str: string): string {
  return str.replace(/[^\x00-\x7F]/g, "");
}

export function hardwareEmpty(hardware: IHardwareData): boolean {
  return !hardware || (!hardware.hardAll);
}
export function firstCharLowerCase(str: string): string {
  return `${str.charAt(0).toLocaleLowerCase()}${str.slice(1)}`;
}

export function checkDoubleTimeNumbers(value: number): string {
  return value < 10 ? `0${value}` : String(value);
}

export function getFio(ls: string, fn: string, mn: string): string {
  const arr = [];
  if (ls) { arr.push(ls) }
  if (fn) { arr.push(fn) }
  if (mn) { arr.push(mn) }
  return arr.length > 0 ? arr.join(' ') : '';
}

export function workHoursToControlValue(hours: string, withSec = false): string {
  if (!hours) {
    return null;
  }
  const arr = [];
  hours.split(':').map(x => {
    arr.push(x.length < 2 ? `0${x}` : x);
  });
  if (!withSec) {
    arr.splice(arr.length - 1, 1);
  }
  return arr.join('');
}
export function controlValueToWorkHours(value: string): string {
  return `${value.substring(0, 2)}:${value.substring(2,4)}:00`;
}

export function getSelectedLabelForNewAvailable(value: string, array: IDataSelectedHelper[]): string {
  const index = array.findIndex(x => x.label === value);
  if (index > -1) {
    return value;
  }
  return array?.length === 1 ? array[0].label : null;
}

export function getBillTitle(type: BillingTypeEnum): string {
  switch (type) {
    case BillingTypeEnum.FirstCheck:
      return billTypeRu.firstCheck;
    case BillingTypeEnum.SecondCheck:
      return billTypeRu.secondCheck;
    case BillingTypeEnum.RejectFirstCheck:
      return billTypeRu.rejectFirstCheck;
    case BillingTypeEnum.RejectSecondCheck:
      return billTypeRu.rejectSecondCheck;
    case BillingTypeEnum.RejectFirstCheckWithoutCommission:
      return billTypeRu.rejectFirstCheckWithoutCommission;
    case BillingTypeEnum.RejectSecondCheckWithoutCommission:
      return billTypeRu.rejectSecondCheckWithoutCommission;
    case BillingTypeEnum.TestCheck:
      return billTypeRu.testCheck;
    case BillingTypeEnum.OperationAddOrUpdate:
      return billTypeRu.operationAddOrUpdate;
    case BillingTypeEnum.TransferFioChangeCheck:
      return billTypeRu.transferFioChangeCheck;
  }
  return '';
}

export const firstNameArr = [
  'Иван',
  'Василий',
  'Дмитрий',
  'Денис',
  'Игорь',
  'Александр',
  'Роман',
  'Антон',
  'Кирилл',
];

export const lastNameArr = [
  'Иванов',
  'Петров',
  'Сидоров',
  'Денисов',
  'Андреев',
  'Кузнецов',
  'Попов',
  'Егоров',
  'Орлов',
];

export const middleNameArr = [
  'Александрович',
  'Игоревич',
  'Иванович',
  'Денисович',
  'Кириллович',
  'Антонович',
  'Романович',
  'Дмитриевич',
  'Васильевич',
];

export function getRandomFirstName(): string {
  return firstNameArr[Math.floor(Math.random() * firstNameArr.length)];
}
export function getRandomLastName(): string {
  return lastNameArr[Math.floor(Math.random() * lastNameArr.length)];
}
export function getRandomMiddleName(): string {
  return middleNameArr[Math.floor(Math.random() * middleNameArr.length)];
}

export const fileSizeProvider = [
  { en: 'Bytes', ru: 'Байт' },
  { en: 'KB', ru: 'КБ' },
  { en: 'MB', ru: 'МБ' },
  { en: 'GB', ru: 'ГБ' },
  { en: 'TB', ru: 'ТБ' },
  { en: 'PB', ru: 'ПБ' },
  { en: 'EB', ru: 'ЭБ' },
  { en: 'ZB', ru: 'ЗБ' },
  { en: 'YB', ru: 'ЙБ' },
]
