import {Component} from '@angular/core';
import {OperationBuilderService} from "../../services/operation-builder.service";
import {FormGroup} from "@angular/forms";
import {IClientResponse, IOperationTypeItemResponse} from "../../../../kernel/ApiClient";

@Component({
  selector: 'app-operation-client-search',
  templateUrl: './operation-client-search.component.html',
  styleUrls: ['./operation-client-search.component.scss'],
})
export class OperationClientSearchComponent {
  constructor(
    private builder: OperationBuilderService
  ) {}

  get showClientBtn(): boolean {
    return this.builder.variables.operationHelperGroup.value.OperationClientDocumentNumber === this.builder.variables.lastFindDocumentNumber && this.builder.variables.lastFindDocumentNumber?.length > 0;
  }

  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get client(): IClientResponse {
    return this.builder.variables.client;
  }
  get operationHelperGroup(): FormGroup {
    return this.builder.variables.operationHelperGroup;
  }
  get clientName(): string {
    return this.builder.variables.clientName;
  }

  get operationType(): IOperationTypeItemResponse {
    return this.builder.variables.operationTypeEnum;
  }

  get isTransfer(): boolean {
    return this.builder.variables.isTransfer;
  }

  get isClientNeed(): boolean {
    return this.builder.variables.isClientNeed;
  }

  openClientDialog(): void {
    if (!this.isClientNeed) {
      return;
    }
    if (this.builder.variables.client) {
      this.builder.helper.openClientChooseDialog(true);
    } else {
      this.builder.api.onClientSearch();
    }
  }
}
