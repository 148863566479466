import {
  IControlGroupHelper,
  IControlsArrayHelper,
  IDictionaries,
  IStepsControlHelper,
} from '../models/common.models';
import {
  Address, ClientResponse, IAddress,
  IClientResponse,
  IdentificationDocument, IdentificationDocumentFields,
  IIdentificationDocument,
  PersonAddresses,
  PhoneNumber,
} from '../ApiClient';
import {FormArray, FormGroup, Validators as v} from '@angular/forms';
import {
  validateAdult,
  validateCapitalLetter,
  validateFirstLetterCapitalAndOtherAny,
  validateNonDigit,
  validateNonSymbol,
} from '../validators';
import {Guid} from 'guid-typescript';
import {SelectedDataTypeEnum} from '../enum/selected-data-type.enum';
import {
  createEmptyAddress,
  fillClientOrReceiverPhoneNumbers,
  fillModelByControlGroupHelper,
  getControlFromControlArr,
  getCountries, getCountriesAddresses,
  getDocuments,
  getGenders,
  getSelectId,
  getValueForModel,
  toPhones,
} from './data.helper';
import {addOperationStepsFunctions} from "./functions.helper";

export function createClientControlHelper(
  selectedData: IDictionaries
): IStepsControlHelper {
  const result = {
    items: [
      createClientMainInfoHelper(
        'mainInfo',
        'Основные данные отправителя (клиента)',
        selectedData,
        Guid.raw()
      ),
      createClientPhoneNumbersHelper(
        'phoneNumbers',
        'Номер телефона отправителя (клиента)'
      ),
      createClientDocumentsHelper(
        'documents',
        'Документы отправителя (клиента)'
      ),
      createClientOrReceiverAddressHelper(
        'registrationAddress',
        'Адрес регистрации (прописки) отправителя (клиента)',
        selectedData,
        false,
        Guid.raw(),
        true,
        true
      ),
      createClientOrReceiverAddressHelper(
        'residentalAddress',
        'Адрес проживания отправителя (клиента)',
        selectedData,
        true,
        Guid.raw(),
        false,
        true
      ),
      createClientOrReceiverAddressHelper(
        'birthPlaceAddress',
        'Место рождения отправителя (клиента)',
        selectedData,
        true,
        Guid.raw(),
        false,
        true
      ),
    ],
    formGroup: new FormGroup({}),
    formArray: new FormArray([]),
  };
  return addOperationStepsFunctions(result);
}

export function addClientFormArrayItem(
  id: string,
  selectedData: IDictionaries,
  arrayId: string,
  isNew: boolean
): IControlsArrayHelper {
  switch (id) {
    case 'phoneNumbers':
      return createClientPhoneNumbersControls(arrayId);
    case 'documents':
      return createClientDocumentsControls(selectedData, arrayId, isNew);
  }
  return null;
}

export function createClientMainInfoHelper(
  id: string,
  title: string,
  selectedData: IDictionaries,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createClientMainInfoControls(selectedData, arrayId)],
  } as IControlGroupHelper;
}
export function createClientMainInfoControls(
  selectedData: IDictionaries,
  arrayId: string
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        isHide: true,
        inputType: `text`,
        placeholder: `Идентификатор`,
        label: `Идентификатор`,
        name: `clientId`,
        wrapperClass: ``,
        value: null,
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Фамилия`,
        label: `Фамилия`,
        name: `lastName`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: ``,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Имя`,
        label: `Имя`,
        name: `firstName`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: ``,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Отчество`,
        label: `Отчество`,
        name: `middleName`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: ``,
        value: null,
        validators: [
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: getCountriesAddresses(selectedData),
        selectedDataType: SelectedDataTypeEnum.CountriesAddresses,
        contentMaxHeight: 600,
        placeholder: `Страна проживания`,
        label: `Страна проживания`,
        name: `countryOfResidence`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: getCountriesAddresses(selectedData),
        selectedDataType: SelectedDataTypeEnum.CountriesAddresses,
        contentMaxHeight: 600,
        placeholder: `Гражданство`,
        label: `Гражданство`,
        name: `citizenship`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: getGenders(selectedData),
        selectedDataType: SelectedDataTypeEnum.Gender,
        contentMaxHeight: 600,
        onlySelect: true,
        placeholder: `Пол`,
        label: `Пол`,
        name: `gender`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `picker`,
        placeholder: `Дата рождения`,
        label: `Дата рождения`,
        name: `birthDate`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: [v.required, validateAdult()],
      },
    ],
  } as IControlsArrayHelper;
}

export function createClientPhoneNumbersHelper(
  id: string,
  title: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: true,
    isFormGroup: false,
    formArrayBtnText: 'Добавить',
    formArrayBtnTextWidth: 150,
    controlsArr: [],
  } as IControlGroupHelper;
}
export function createClientPhoneNumbersControls(
  arrayId: string
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        isHide: false,
        inputType: `tel`,
        placeholder: `Телефон`,
        label: `Телефон`,
        name: `number`,
        wrapperClass: ``,
        autocomplete: `on`,
        maskPrefix: `+7`,
        mask: ` (000) 000-0000`,
        value: null,
        validators: [v.required],
      },
    ],
  } as IControlsArrayHelper;
}

export function createClientDocumentsHelper(
  id: string,
  title: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: true,
    isFormGroup: false,
    formArrayBtnText: 'Добавить',
    formArrayBtnTextWidth: 150,
    controlsArr: [],
  } as IControlGroupHelper;
}
export function createClientDocumentsControls(
  selectedData: IDictionaries,
  arrayId: string,
  isNew: boolean
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        inputType: `select`,
        selectData: getDocuments(selectedData),
        selectedDataType: SelectedDataTypeEnum.Documents,
        contentMaxHeight: 600,
        placeholder: `Тип документа`,
        label: `Тип документа`,
        name: `type`,
        wrapperClass: ``,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Серия`,
        label: `Серия`,
        name: `series`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: '',
        validators: null,
        readonly: !isNew
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Номер`,
        label: `Номер`,
        name: `number`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        mask: '',
        validators: [v.required],
        readonly: !isNew
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Кем выдан`,
        label: `Кем выдан`,
        name: `issuer`,
        hint: `Указывать в точности, как в документе удостоверяющим личность`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: '',
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Код подразделения`,
        label: `Код подразделения`,
        name: `issuerDepartmentCode`,
        hint: `Обязательно к заполнению для паспорта РФ`,
        mask: `000-000`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: null,
      },
      {
        isInput: true,
        inputType: `picker`,
        placeholder: `Дата выдачи`,
        label: `Дата выдачи`,
        name: `issueDate`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `picker`,
        placeholder: `Дата оконч. срока действия`,
        label: `Дата оконч. срока действия`,
        name: `expiryDate`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: null,
      },
    ],
  } as IControlsArrayHelper;
}

export function createClientOrReceiverAddressHelper(
  id: string,
  title: string,
  selectedData: IDictionaries,
  withCheckbox: boolean,
  arrayId: string,
  isRegistration: boolean,
  isClient: boolean
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [
      createClientAddressControls(
        selectedData,
        withCheckbox,
        arrayId,
        isRegistration,
        isClient
      ),
    ],
  } as IControlGroupHelper;
}
export function createClientAddressControls(
  selectedData: IDictionaries,
  withCheckbox: boolean,
  arrayId: string,
  isRegistration: boolean,
  isClient: boolean
): IControlsArrayHelper {
  const result = {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        inputType: `number`,
        placeholder: `Почтовый индекс`,
        label: `Почтовый индекс`,
        name: `zipCode`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        isSelectExistListener: true,
        inputType: `select`,
        selectData: getCountriesAddresses(selectedData),
        selectedDataType: SelectedDataTypeEnum.CountriesAddresses,
        contentMaxHeight: 600,
        placeholder: `Страна`,
        label: `Страна`,
        name: `countryCode`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Регион`,
        label: `Регион`,
        name: `region`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: '',
        validators: isClient ? [v.required] : null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Район`,
        label: `Район`,
        name: `district`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Город(Поселение)`,
        label: `Город(Поселение)`,
        name: `city`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: '',
        validators: isClient ? [v.required] : null,
      },
      {
        isHide: true,
        isInput: true,
        inputType: `text`,
        placeholder: `Поселение`,
        label: `Поселение`,
        name: `locality`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Улица`,
        label: `Улица`,
        name: `street`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Дом`,
        label: `Дом`,
        name: `house`,
        wrapperClass: `operation-half-item-left-one`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Корпус`,
        label: `Корпус`,
        name: `block`,
        wrapperClass: `operation-half-item-left-two`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Строение`,
        label: `Строение`,
        name: `building`,
        wrapperClass: `operation-half-item-right-one`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Квартира`,
        label: `Квартира`,
        name: `apartment`,
        wrapperClass: `operation-half-item-right-two`,
        value: null,
        mask: '',
        validators: null,
      },
      {
        isInput: true,
        isHide: false,
        inputType: `textarea`,
        placeholder: `Полный адрес`,
        label: `Полный адрес`,
        name: `addressFull`,
        wrapperClass: ``,
        value: null,
        mask: '',
        maxLength: 1000,
        validators: null,
      },
    ],
  } as IControlsArrayHelper;
  if (withCheckbox) {
    result.controls.unshift({
      isCheckbox: true,
      inputType: `radio`,
      placeholder: `Совпадает с адресом регистрации (прописки)`,
      label: `Совпадает с адресом регистрации (прописки)`,
      name: `isConsider`,
      wrapperClass: ``,
      value: false,
      validators: null,
    });
  }
  if (!isClient) {
    result.controls.map(x => {
      x.isHide = x.name !== 'countryCode';
      if (x.name === 'countryCode') {
        x.wrapperClass = '';
      }
    });
  }
  return result;
}

export function createClientPayload(
  helper: IStepsControlHelper,
  selectedDataAll: IDictionaries
): ClientResponse {
  let payload: IClientResponse = {
    clientId: null,
    countryOfResidence: '',
    citizenship: '',
    gender: '',
    birthDate: null,
    phoneNumbers: [],
    documents: [],
    addresses: {
      birthPlaceAddress: createEmptyAddress() as Address,
      registrationAddress: createEmptyAddress() as Address,
      residentalAddress: createEmptyAddress() as Address,
    } as PersonAddresses,
    middleName: '',
    firstName: '',
    lastName: '',
  };
  payload = new ClientResponse(fillModelByControlGroupHelper(
    helper,
    `mainInfo`,
    payload
  ) as IClientResponse);
  payload.addresses = new PersonAddresses({
    residentalAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `residentalAddress`,
      payload.addresses.residentalAddress
    ) as IAddress),
    registrationAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `registrationAddress`,
      payload.addresses.registrationAddress
    ) as IAddress),
    birthPlaceAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `birthPlaceAddress`,
      payload.addresses.birthPlaceAddress
    ) as IAddress)
  });
  toPhones(
    fillClientOrReceiverPhoneNumbers(helper)
  ).map(x => {
    payload.phoneNumbers.push(new PhoneNumber(x));
  });
  fillClientDocuments(
    helper,
    selectedDataAll
  ).map(x => {
    payload.documents.push(new IdentificationDocument({
      type: x.type,
      fields: new IdentificationDocumentFields(x.fields)
    }));
  });
  return new ClientResponse(payload);
}

export function fillClientDocuments(
  helper: IStepsControlHelper,
  selectedDataAll: IDictionaries
): IIdentificationDocument[] {
  const result: IIdentificationDocument[] = [];
  const index = helper.items.findIndex(x => x.id === `documents`);
  if (index < 0) {
    return result;
  }
  helper.items[index].controlsArr.map(controlArr => {
    const arrayItemIndex = helper.formArray.value.findIndex(
      x => x.arrayId === controlArr.arrayId
    );
    if (arrayItemIndex > -1) {
      result.push({
        type: getSelectId(
          helper.formArray.value[arrayItemIndex][`documents_type`],
          selectedDataAll.documents,
          false
        ),
        fields: {
          series: getValueForModel(
            helper.formArray.value[arrayItemIndex][`documents_series`],
            getControlFromControlArr(controlArr, 'series')
          ),
          number: getValueForModel(
            helper.formArray.value[arrayItemIndex][`documents_number`],
            getControlFromControlArr(controlArr, 'number')
          ),
          issueDate: getValueForModel(
            helper.formArray.value[arrayItemIndex][`documents_issueDate`],
            getControlFromControlArr(controlArr, 'issueDate')
          ),
          expiryDate: getValueForModel(
            helper.formArray.value[arrayItemIndex][`documents_expiryDate`],
            getControlFromControlArr(controlArr, 'expiryDate')
          ),
          issuer: getValueForModel(
            helper.formArray.value[arrayItemIndex][`documents_issuer`],
            getControlFromControlArr(controlArr, 'issuer')
          ),
          issuerDepartmentCode: getValueForModel(
            helper.formArray.value[arrayItemIndex][
              `documents_issuerDepartmentCode`
            ],
            getControlFromControlArr(controlArr, 'issuerDepartmentCode')
          ),
        },
      } as IIdentificationDocument);
    }
  });
  return result;
}
