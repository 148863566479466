import {
  IControlGroupHelper,
  IControlHelper,
  IControlsArrayHelper,
  IStepsControlHelper
} from "../../models/common.models";
import {Guid} from "guid-typescript";
import {FormArray, FormGroup, Validators as v} from "@angular/forms";
import {addOperationStepsFunctions} from "../functions.helper";
import {validateFirstLetterCapitalAndOtherAny, validateNonDigit, validateNonSymbol} from "../../validators";

export function createPaymentHubCISCardControlHelperv2(): IStepsControlHelper {
  var result = {
    items: [
      createPaymentHubCISCardControlHelperParamsv2(
        'mainInfo',
        'Основные данные операции',
        Guid.raw()
      ),
    ],
    formGroup: new FormGroup({}),
    formArray: new FormArray([]),
  };
  return addOperationStepsFunctions(result);
}

export function createPaymentHubCISCardControlHelperParamsv2(
  id: string,
  title: string,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createPaymentHubCISCardControlHelperControlsv2(arrayId)],
  } as IControlGroupHelper;
}
export function createPaymentHubCISCardControlHelperControlsv2(arrayId: string): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isHide: true,
        isInput: true,
        inputType: `number`,
        placeholder: `Комиссия`,
        label: `Комиссия`,
        name: `organizationCommission`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        isCash: true,
        inputType: `number`,
        placeholder: `Сумма внесения`,
        label: `Сумма внесения`,
        name: `acceptedAmount`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: "separator.2",
        maskSuffix: " RUB",
        validators: [v.required],
      },
      {
        isPaymentHubArray: true,
        isInput: true,
        inputType: `tel`,
        placeholder: `Телефон`,
        label: `Телефон`,
        name: `id4`,
        wrapperClass: `operation-half-item-right`,
        autocomplete: `on`,
        maskPrefix: `+992`,
        mask: ` 00 000 0000`,
        value: null,
        validators: [v.required, v.maxLength(15)],
      },
      {
        isPaymentHubArray: true,
        isInput: true,
        isCard: true,
        inputType: `text`,
        placeholder: `Номер карты`,
        label: `Номер карты`,
        name: `id1`,
        value: null,
        mask: '0000 0000 0000 0000',
        validators: [v.required, v.minLength(16), v.maxLength(16)],
      },
      {
        isPaymentHubArray: true,
        isInput: true,
        inputType: `text`,
        placeholder: `Фамилия`,
        label: `Фамилия`,
        name: `id2`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isPaymentHubArray: true,
        isInput: true,
        inputType: `text`,
        placeholder: `Имя`,
        label: `Имя`,
        name: `id3`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      }
    ],
  } as IControlsArrayHelper;
}
