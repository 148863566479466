<section class="main-organization-balance-info" *ngIf="!!balances && balances.length > 0">
  <h1 class="main-title">Балансы БПА</h1>
  <div class="balance-info-wrapper">
    <div class="balance-info-item-wrapper" *ngFor="let item of balances; let i=index">
      <div class="balance-info-item-name">{{item.name}}</div>
      <div class="balance-info-item-balance">{{item.balance || 0 | number: "1.2":"ru" }} RUB</div>
      <div class="balance-info-item-account">{{item.balanceAccount}}</div>
      <div class="balance-info-item-date" *ngIf="item.balanceUpdated">{{item.balanceUpdated | dateLocal:'dd.MM.yyyy HH:mm:ss':true:false}}</div>
      <div class="balance-info-item-date" *ngIf="!item.balanceUpdated">Не обновлялся</div>
    </div>
  </div>
  <div class="main-title-download-button-wrapper t-align-center">
    <app-file-select [title]="'Обновить балансы через файл'" [availableTypes]="availableIcoTypes" (fileChange)="fileChanged($event)"></app-file-select>
    <button class="button main" [ngClass]="{'disabled': !file}" (click)="updateBalances()">Подтвердить</button>
  </div>
</section>
