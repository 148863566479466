import { environment } from "src/kernel/environments/environment";
import { evnTypeProvider } from "../constants/common.constants";

export function isLocalEnv(): boolean {
  return environment ? environment.name === evnTypeProvider.local : false;
}

export function isDevEnv(): boolean {
  return environment ? environment.name === evnTypeProvider.dev : false;
}

export function isStageEnv(): boolean {
  return environment ? environment.name === evnTypeProvider.stage : false;
}

export function isProdEnv(): boolean {
  return environment ? environment.name === evnTypeProvider.prod : false;
}
