import {
  ApiClient,
  BillingQueueRemoveRequest,
  BillingTypeEnum,
  IClientResponse,
  IOperationItemFilterResponse,
  ITransferChangeBeneficiaryCheckRequest, ITransferChangeBeneficiaryConfirmRequest,
  ITransferResponse,
  TransferBeneficiary,
  TransferChangeBeneficiaryCheckRequest,
  TransferChangeBeneficiaryConfirmRequest,
  TransfersConfirmRequest, TransferSmsCheckMtsRequest
} from '../../../../kernel/ApiClient';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NotifyService} from '../../../core/services/notify.service';
import {IDataSelectedHelper, IOperationDataHelper} from '../../../../kernel/models/common.models';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {getErrorMessage, getFio, getValueById,} from '../../../../kernel/helpers/data.helper';
import {errorMessages} from '../../../../kernel/constants/errors';
import {createInfoArrayItem, getOperationStatus,} from '../../../../kernel/helpers/operation.helper';
import {DatePipeTypeEnum} from '../../../../kernel/enum/data-type.enum';
import {Select} from '@ngxs/store';
import {DictionaryState} from '../../../../kernel/store/state/dictionary.state';
import {messages} from '../../../../kernel/constants/messages';
import {
  errorFinalStatuses,
  readyForConfirmStatuses,
  successFinalStatuses
} from "../../../../kernel/enum/operation-status.enum";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {CashboxListDialogComponent} from "../../../billing/dialogs/cashbox-list-dialog/cashbox-list-dialog.component";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-change-beneficiary-dialog',
  templateUrl: './change-beneficiary-dialog.component.html',
  styleUrls: ['./change-beneficiary-dialog.component.scss']
})
export class ChangeBeneficiaryDialogComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  subs = new Subscription();

  client: IClientResponse;
  transfer: ITransferResponse;
  transferDb: IOperationItemFilterResponse;

  changeBeneficiaryForm: FormGroup;

  isLoad = false;
  submitted = false;

  step = 1;

  constructor(
    public dialogRef: MatDialogRef<ChangeBeneficiaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client: IClientResponse, transfer: ITransferResponse, transferDb: IOperationItemFilterResponse },
    private apiClient: ApiClient,
    private notify: NotifyService,
    private billing: BillingBuilderService,
    private dialog: MatDialog,
  ) {
    if (!data) {
      this.close();
    }
    this.client = data.client;
    this.transfer = data.transfer;
    this.transferDb = data.transferDb;
  }

  ngOnInit(): void {
    this.createFormControls();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControls(): void {
    if (this.changeBeneficiaryForm) {
      return;
    }
    this.changeBeneficiaryForm = new FormGroup({
      BeneficiaryOld_lastName: new FormControl(this.transfer.beneficiary.lastName),
      BeneficiaryOld_firstName: new FormControl(this.transfer.beneficiary.firstName),
      BeneficiaryOld_middleName: new FormControl(this.transfer.beneficiary.middleName),
      BeneficiaryNew_lastName: new FormControl(this.transfer.beneficiary.lastName),
      BeneficiaryNew_firstName: new FormControl(this.transfer.beneficiary.firstName),
      BeneficiaryNew_middleName: new FormControl(this.transfer.beneficiary.middleName),
      Code: new FormControl(null),
      Phone: new FormControl(this.client.phoneNumbers.length > 0 ? this.client.phoneNumbers[0].number : null)
    });
    this.changeBeneficiaryForm.get(`BeneficiaryOld_lastName`).disable();
    this.changeBeneficiaryForm.get(`BeneficiaryOld_firstName`).disable();
    this.changeBeneficiaryForm.get(`BeneficiaryOld_middleName`).disable();

    this.changeBeneficiaryForm.get(`Phone`).disable();
  }

  close(): void {
    this.dialogRef.close();
  }

  nextStep(): void {
    if (this.step === 1) {
      this.checkAcceptableForChange();
      return;
    }
    if (this.step === 2) {
      this.confirmChangeBeneficiary();
    }
  }

  previousStep(): void {
    if (this.step === 1) {
      this.close();
      return;
    }
    this.step--;
  }

  get forwardBtnDisabled(): boolean {
    return this.step === 1 && this.transfer.beneficiary.lastName === this.changeBeneficiaryForm.value.BeneficiaryNew_lastName &&
      this.transfer.beneficiary.firstName === this.changeBeneficiaryForm.value.BeneficiaryNew_firstName &&
      this.transfer.beneficiary.middleName === this.changeBeneficiaryForm.value.BeneficiaryNew_middleName ||
      this.step === 2 && !this.changeBeneficiaryForm.value.Code;
  }

  checkAcceptableForChange(): void {
    if (this.changeBeneficiaryForm.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }

    const payload: ITransferChangeBeneficiaryCheckRequest = {
      operationId: this.transferDb.id,
      beneficiaryOld: {
        lastName: this.transfer.beneficiary.lastName,
        firstName: this.transfer.beneficiary.firstName,
        middleName: this.transfer.beneficiary.middleName
      } as TransferBeneficiary,
      beneficiaryNew: {
        lastName: this.changeBeneficiaryForm.value.BeneficiaryNew_lastName,
        firstName: this.changeBeneficiaryForm.value.BeneficiaryNew_firstName,
        middleName: this.changeBeneficiaryForm.value.BeneficiaryNew_middleName
      } as TransferBeneficiary
    }

    this.apiClient.transfers_TransferChangeBeneficiaryCheck(payload as TransferChangeBeneficiaryCheckRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.step = 2;
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  confirmChangeBeneficiary(): void {
    this.submitted = true;
    if (this.changeBeneficiaryForm.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }

    const payload: ITransferChangeBeneficiaryConfirmRequest = {
      operationId: this.transferDb.id,
      beneficiaryOld: {
        lastName: this.transfer.beneficiary.lastName,
        firstName: this.transfer.beneficiary.firstName,
        middleName: this.transfer.beneficiary.middleName
      } as TransferBeneficiary,
      beneficiaryNew: {
        lastName: this.changeBeneficiaryForm.value.BeneficiaryNew_lastName,
        firstName: this.changeBeneficiaryForm.value.BeneficiaryNew_firstName,
        middleName: this.changeBeneficiaryForm.value.BeneficiaryNew_middleName
      } as TransferBeneficiary,
      smsCheck: {
        phone: this.client.phoneNumbers.length > 0 ? this.client.phoneNumbers[0].number.replace('+', '') : null,
        code: this.changeBeneficiaryForm.value.Code
      } as TransferSmsCheckMtsRequest
    }

    this.apiClient.transfers_TransferChangeBeneficiaryConfirm(payload as TransferChangeBeneficiaryConfirmRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.beneficiaryChanged);
        this.close();
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
