import {UserLoginRequest, IUserResponse, TokenResponse, IHardwareData} from '../../ApiClient';
export class LoginRedirect {
  static type = '[User] LoginRedirect'
}

export class SetAgent {
  static type = '[User] SetAgent'
  constructor(public data: string) {}
}

export class SetRoute {
  static type = '[User] SetRoute'
  constructor(public data: string) {}
}

export class OnLogout {
  static type = '[User] OnLogout'
  constructor() {}
}

export class SetToken {
  static type = '[User] SetToken'
  constructor(public data: TokenResponse) {}
}

export class SetUser {
  static type = '[User] SetUser';
  constructor(public user: IUserResponse) {}
}

export class SetScreenWidth {
  static type = '[User] SetScreenWidth';
  constructor(public data: number) {}
}

export class ResetUser {
  static type = '[User] ResetUser';
  constructor() {}
}

export class HomeRedirect {
  static type = '[User] HomeRedirect';
  constructor() {}
}

export class SetPcParam {
  static readonly type = '[PersonalArea] SetPcParam'

  constructor(public hardware: IHardwareData) {
  }
}
