import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  ApiClient,
  IUpdateSiteSettingsRequest,
  IUserResponse,
  IUserSettingsRequest,
  SiteSettingsResponse, UpdateSiteSettingsRequest
} from "../../../../../kernel/ApiClient";
import {FormControl, FormGroup} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {BehaviorService} from "../../../../core/services/behavior.service";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../../kernel/constants/messages";
import {getErrorMessage, getUTCDate, getUTCDateTime} from "../../../../../kernel/helpers/data.helper";
import {ITopModalMenu} from "../../../../../kernel/models/common.models";

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss', '../../settings/settings.component.scss']
})
export class SiteSettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Input() id: string

  subs = new Subscription();
  user: IUserResponse;

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: []
  };

  expanded = false;
  submittedSite = false;
  submittedToken = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  siteSettings: SiteSettingsResponse;

  type = 0;

  settingForm: FormGroup;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  ngOnInit() {
    this.getSiteSettings();
    this.createFormControls();
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      this.createFormControls();
    }));

    this.topMenu = {
        currentIndex: 1,
        items: [
          {title: 'Настройки', index: 1},
          // {title: 'Фасадный токен', index: 2}
        ]
      };
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    if (!!this.settingForm) {
      return;
    }

    this.settingForm = new FormGroup({
      DelayCountCheck: new FormControl(null),
      User1CHardwareCompare: new FormControl(false),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  getSiteSettings(): void {
    this.apiClient.setting_GetSiteSettings()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.siteSettings = data;
        this.settingForm.get(`DelayCountCheck`).setValue(this.siteSettings.delayCountCheck);
        this.settingForm.get(`User1CHardwareCompare`).setValue(this.siteSettings.user1CHardwareCompare);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateSiteSettings(): void {
    this.submittedSite = true;
    if (!!this.settingForm.get(`DelayCountCheck`).invalid || !this.settingForm.value.DelayCountCheck ||  this.settingForm.value.DelayCountCheck < 0) {
      return;
    }
    const payload: IUpdateSiteSettingsRequest = {
      delayCountCheck: this.settingForm.value.DelayCountCheck,
      user1CHardwareCompare: this.settingForm.value.User1CHardwareCompare
    };

    this.apiClient.setting_UpdateSiteSettings(payload as UpdateSiteSettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.notify.success(messages.settingsUpdated);
          this.getSiteSettings();
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        });
  }
}
