import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorService} from "../../services/behavior.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PopupLoaderTypeEnum} from "../../../../kernel/enum/popupLoaderTypeEnum.enum";
import {OperationBuilderService} from "../../../mts-operation/services/operation-builder.service";

@Component({
  selector: 'app-popup-loader',
  templateUrl: './popup-loader.component.html',
  styleUrls: ['./popup-loader.component.scss']
})
export class PopupLoaderComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  title: string;
  isActive = false;
  nextCome = false;

  constructor(
    private behavior: BehaviorService,
    private builder: OperationBuilderService
  ) { }

  ngOnInit(): void {
    this.behavior.popupLoaderStatusChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.nextCome = !!data;
        this.changeStateLoader(data);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeStateLoader(title: string): void {
    if (this.isOperationLoaderWithoutReady(title)) {
      this.isActive = false;
      this.title = '';
      return;
    }
    if (!!title) {
      this.title = title;
      this.isActive = true;
    } else {
      setTimeout(() => {
        if (this.nextCome) {
          return;
        }
        this.isActive = false;
        this.title = '';
        }, 500);
    }
  }

  isOperationLoaderWithoutReady(type: string) {
    return !this.builder.variables.isReady && (type === PopupLoaderTypeEnum.ClientSearch ||
      type === PopupLoaderTypeEnum.CalculateCommission ||
      type === PopupLoaderTypeEnum.ReCalculateCommission ||
      type === PopupLoaderTypeEnum.CreateClient ||
      type === PopupLoaderTypeEnum.UpdateClient ||
      type === PopupLoaderTypeEnum.CreateOperation ||
      type === PopupLoaderTypeEnum.UpdateOperation ||
      type === PopupLoaderTypeEnum.GetMtDynamicFields ||
      type === PopupLoaderTypeEnum.ConfirmOperation);
  }

}
