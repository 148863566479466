<div class="dialog-wrapper scrollbar">
  <header class="dialog-header">
    <p class="dialog-title">{{ title }}</p>
    <img
            src="../../../../assets/img/icons/close.svg" (click)="close(isFromHistory)" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper mb-20">
    <div class="client-list-title" *ngFor="let item of docInfo; let i=index">
      <div class="icon-client-wrapper p-relative d-inline-block">
        <img [class]="item.iconClass" [src]="item.icon"/>
      </div>
      <div class="title-client-wrapper d-inline-block">
        {{ item.title }}
      </div>
    </div>
  </div>

  <p class="main-info">{{clientName}}</p>
  <p class="main-sub-info">{{client.birthDate | dateLocal: 'dd.MM.yyyy':true:true}}</p>
  <p class="main-sub-info">{{clientPhone | phonePipe}}</p>
  <p class="main-sub-info mt-10"><strong>Адрес регистрации (прописки):</strong> {{client.addresses?.registrationAddress?.addressFull ?? ''}}</p>
  <p class="main-sub-info mt-5"><strong>Адрес проживания:</strong> {{client.addresses?.residentalAddress?.addressFull ?? ''}}</p>
  <p class="main-sub-info mt-5"><strong>Место рождения:</strong> {{client.addresses?.birthPlaceAddress?.addressFull ?? ''}}</p>
  <p class="main-sub-info mt-5"><strong>Документ:</strong> {{client.documents[0].type}} {{client.documents[0].fields.series}} {{client.documents[0].fields.number}}</p>

  <div class="mt-30 mb-20" *ngIf="!emptyOperations else emptyHistory">
    <app-radio
      [controlInvalid]="isSubmitted"
      [inline]="false"
      name="OperationId"
      [selectData]="availableOperations || []"
      [parentForm]="operationForm"></app-radio>
  </div>

  <ng-template #emptyHistory>
    <div class="empty-list">
      <p class="f-1em">Данный клиент не совершал операции.
        Вы можете нажать
        <strong class="color-red pointer" (click)="setOperation()">Подтвердить</strong>,
        для заполнения данных клиента
      </p>
    </div>
  </ng-template>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main" [ngClass]="{'disabled': !isOperationChoose}"
              [style.width.px]="200" (click)="setOperation()">Подтвердить</button>
      <button class="button unfilled decoration-under" (click)="close(false)" [style.padding-left.px]="0">Другой клиент</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="200"
              (click)="close(false)" [style.margin-right.px]="20">Другой клиент</button>
      <button class="button main f-right" [ngClass]="{'disabled': !isOperationChoose}"
              [style.width.px]="180" (click)="setOperation()">Подтвердить</button>
    </div>
  </footer>

</div>
