import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorService} from "../../services/behavior.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {LoggerErrorTypeEnum} from "../../../../kernel/ApiClient";
import {InfoDialogItemHelper} from "../../../../kernel/models/common.models";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {InfoDialogComponent} from "../../dialogs/info-dialog/info-dialog.component";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-info-dialog-listener',
  templateUrl: './info-dialog-listener.component.html',
  styleUrls: ['./info-dialog-listener.component.scss']
})
export class InfoDialogListenerComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  infoDialogTypes = LoggerErrorTypeEnum;
  infoDialogArr: InfoDialogItemHelper[] = [];

  constructor(
    private behavior: BehaviorService,
    private decimalPipe: DecimalPipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initArray();
    this.behavior.infoDialogListenerChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.openInfoDialog(data);
        }
      })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initArray(): void {
    Object.keys(this.infoDialogTypes).forEach(name => {
      this.infoDialogArr.push({
        type: LoggerErrorTypeEnum[name],
        isActive: false
      })
    });
  }

  getArrIndex(type: LoggerErrorTypeEnum): number {
    return this.infoDialogArr.findIndex(x => x.type === type);
  }

  getTitleForInfoDialog(model: InfoDialogItemHelper): string {
    switch (model.type) {
      case LoggerErrorTypeEnum.LowBalance:
        return `${this.decimalPipe.transform(model.title, '1.2','ru')} RUB`;
      case LoggerErrorTypeEnum.OperationApprovalLowBalance:
        const temp = model.title.split(':');
        return `${this.decimalPipe.transform(temp[temp.length - 1].replace(',', '.'), '1.2','ru')} RUB`;
      default:
        return model.title;
    }
  }

  openInfoDialog(model: InfoDialogItemHelper): void {
    const index = this.getArrIndex(model.type);
    if (index < 0) {
      return;
    }
    if (this.infoDialogArr[index].isActive) {
      return;
    }
    this.infoDialogArr[index].isActive = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.getTitleForInfoDialog(model),
      type: model.type
    };
    const dialog = this.dialog.open(InfoDialogComponent, dialogConfig);
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.infoDialogArr[index].isActive = false;
      });
  }
}
