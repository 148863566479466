
import {FormGroup} from "@angular/forms";
import {Component} from "@angular/core";
import {OperationBuilderService} from "../../services/operation-builder.service";


@Component({
  selector: 'app-operation-confirm-step',
  templateUrl: './operation-confirm-step.component.html',
  styleUrls: ['./operation-confirm-step.component.scss']
})
export class OperationConfirmStepComponent {

  constructor(
    private builder: OperationBuilderService
  ) { }

  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get confirmTitle(): string {
    return this.builder.variables.confirmTitle;
  }
  get amountLabel(): string {
    return this.builder.variables.amountLabel;
  }
  get operationHelperGroup(): FormGroup {
    return this.builder.variables.operationHelperGroup;
  }
  get backAmount(): number {
    return this.builder.variables.backAmount;
  }
  get commissionCurrency(): string {
    return this.builder.variables.commissionCurrency;
  }
}
