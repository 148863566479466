<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">Запрос на привязку ПК к точке</div>
        <img class="modal-button-close" src="../../../../assets/img/icons/close.svg" (click)="toggleModal(null)" alt=""/>
      </div>
      <div class="modal-wrapper" *ngIf="info?.length > 0">
        <div class="proposal-modal">
          <app-info-array [info]="info"></app-info-array>

          <form *ngIf="proposalForm" [formGroup]="proposalForm">

            <app-input inputType="select"
                       placeholder="Выберите точку для привязки"
                       label="Выберите точку для привязки"
                       name="PointId"
                       wrapperClass="inputBox-group mb-30"
                       [selectData]="proposalUserOrganizationPoints"
                       [selectDataAll]="proposalUserOrganizationPoints"
                       [importantIds]="proposal.proposalPointIds"
                       [invalid]="submitted"
                       [hintBottom]="'Список доступных точек для данного сотрудника'"
                       [parentForm]="proposalForm"></app-input>

            <app-input inputType="textarea" placeholder="Ваш комментарий" label="Ваш комментарий" name="Comment" rows="8"
                       [hintBottom]="'Например: Одобрил/отклонил по причине...'"
                       [invalid]="submitted && proposalForm.controls['Comment'].errors" [parentForm]="proposalForm"></app-input>
          </form>
        </div>

        <div class="dialog-btn-wrapper-mobile">
          <button class="button main"
                  [disabled]="proposal.approvedUserId"
                  [style.width.px]="160"
                  [style.height.px]="60" (click)="proposalUpdate(true)">Одобрить</button>
          <button class="button unfilled decoration-under" [style.width.px]="160"
                  [style.height.px]="60"
                  (click)="toggleModal(null)" [style.margin-right.px]="20">Закрыть</button>
        </div>
        <div class="dialog-btn-wrapper-full">
          <button class="button border decoration-under" [style.width.px]="160"
                  [style.height.px]="60"
                  (click)="toggleModal(null)" [style.margin-right.px]="20">Закрыть</button>
          <button class="button main f-right"
                  [disabled]="proposal.approvedUserId"
                  [style.width.px]="160"
                  [style.height.px]="60" (click)="proposalUpdate(true)">Одобрить</button>
        </div>

        <p class="font-b color-main t-align-center mt-20 pointer" [ngClass]="{'disabled opacity-0-5': alreadyRemoved}"
           (click)="proposalUpdate(false)">{{alreadyApproved ? 'Отвязать' : 'Отклонить'}}</p>

      </div>
      <div>
      </div>
    </div>
  </div>
</div>
