import { Directive, OnInit, ElementRef, Input, HostListener } from '@angular/core';
import {NotifyService} from "../../modules/core/services/notify.service";
import {ClipboardEnum} from "../enum/clipboard.enum";

@Directive({
  selector: '[appClipboard]'
})
export class ClipboardDirective implements OnInit {

  @Input() copyText?: string
  @Input() type = 'text'

  constructor(
    private element: ElementRef,
    private notify: NotifyService
  ) {}

  @HostListener("click")
  copyMessage(){
    if (!this.element || !this.element.nativeElement || !this.element.nativeElement.outerText) {
      return
    }
    const val = !!this.copyText ? this.copyText : this.element.nativeElement.outerText
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = val
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
    this.notify.info(ClipboardEnum[this.type])
  }

  ngOnInit() {
    this.element.nativeElement.classList.add('pointer')
  }
}
