import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ICommission
} from "../../../../kernel/ApiClient";
import {IOperationDataHelper} from "../../../../kernel/models/common.models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotifyService} from "../../../core/services/notify.service";
import {createInfoArrayItem, toNumber} from "../../../../kernel/helpers/operation.helper";
import {DatePipeTypeEnum} from "../../../../kernel/enum/data-type.enum";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-operation-commission-list',
  templateUrl: './operation-commission-list.component.html',
  styleUrls: ['./operation-commission-list.component.scss']
})
export class OperationCommissionListComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  commissions: ICommission[] = [];
  info: IOperationDataHelper[] = [];

  isReady = false;

  constructor(
    public dialogRef: MatDialogRef<OperationCommissionListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICommission[],
    private notify: NotifyService,
    private decimalPipe: DecimalPipe
  ) {
    if (!data || data.length === 0) {
      this.close(null, null);
    }
    this.commissions = data;
  }



  ngOnInit(): void {
    this.initCommissionInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(paymentSystemId: string, orgCom: number): void {
    this.dialogRef.close({ paymentSystemId, orgCom });
  }

  setOperationCommission(index: number): void {
    this.close(this.commissions[index].paymentSystemId, this.commissions[index].money.acceptedTotalFee.amount);
  }

  initCommissionInfo(): void {
    this.info = [];
    this.commissions.map(commission => {
      this.info.push({
        title: commission.nameCyrillic,
        array: [
          {
            items: [
              createInfoArrayItem(`Сумма в кассу`, `${this.getAmountFormat(commission.money.acceptedMoney.amount)} ${commission.money.acceptedMoney.currencyCode}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Сумма получения`, `${this.getAmountFormat(commission.money.withdrawMoney.amount)} ${commission.money.withdrawMoney.currencyCode}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Комиссия`, `${this.getAmountFormat(commission.money.acceptedTotalFee.amount)} ${commission.money.acceptedTotalFee.currencyCode}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Сумма перевода`, `${this.getAmountFormat(commission.money.transferMoney.amount)} ${commission.money.transferMoney.currencyCode}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Курс`, `${commission.money.rate} RUB`, DatePipeTypeEnum.Rate),
              createInfoArrayItem(`Итого к оплате`, `${this.getAmountFormat(commission.money.acceptedMoney.amount)} ${commission.money.acceptedMoney.currencyCode}`, DatePipeTypeEnum.Usual),
            ],
          },
        ],
      });
    });
    this.isReady = true;
  }

  getAmountFormat(amount: number): string {
    return this.decimalPipe.transform(
      amount,
      '1.2',
      'ru'
    );
  }
}
