import {CommonModule, registerLocaleData} from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "../core/core.module";
import { AuthAreaComponent } from './pages/auth-area/auth-area.component';
import { AuthLoginComponent } from './pages/auth-login/auth-login.component';
import { AuthRestoreComponent } from './pages/auth-restore/auth-restore.component';
import { AuthForgotComponent } from './pages/auth-forgot/auth-forgot.component';
import {AuthService} from "./services/auth.service";
import {RouterModule} from "@angular/router";
import { AuthHardwareProposalComponent } from './dialogs/auth-hardware-proposal/auth-hardware-proposal.component';
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";

registerLocaleData(localeRU, 'ru');
registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [
    AuthAreaComponent,
    AuthLoginComponent,
    AuthRestoreComponent,
    AuthForgotComponent,
    AuthHardwareProposalComponent
  ],
  entryComponents: [
    AuthHardwareProposalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    CoreModule,
    RouterModule
  ],
  exports: [
    AuthAreaComponent,
    AuthLoginComponent,
    AuthRestoreComponent,
    AuthForgotComponent,
    AuthHardwareProposalComponent
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule {}
