<div class="" [ngClass]="{'open': notifyOpen, 'header-notify-wrapper notify-message-full': isSticky, 'notify-message-mobile': !isSticky}" *ngIf="isAuthorized">
  <div class="header-notify-title-wrapper" *ngIf="isSticky">
    <p class="header-notify-title">Мои уведомления</p>
  </div>
  <div class="header-notify-body-wrapper" *ngIf="!!notifies && notifies.length > 0 else emptyNotify">
    <div class="header-notify-body-item-wrapper" *ngFor="let item of notifies; let i=index">
      <div class="header-notify-body-item" [ngClass]="{'pointer': !!item.url}" (click)="goto(item.url)">
        <div class="header-notify-body-img">
          <img [src]="'../../../../assets/img/icons/' + item.icon">
        </div>
        <div class="header-notify-body-info-wrapper">
          <p class="header-notify-body-time">{{item.created | dateLocal:'HH:mm:ss dd.MM.yyyy'}}</p>
          <p class="header-notify-body">{{item.body}}</p>
          <p class="header-notify-body-time-ago">{{item.timeAgo}}</p>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyNotify>
    <div class="header-notify-body-wrapper">
      <p class="notify-empty">Нет новых уведомлений</p>
    </div>
  </ng-template>
  <div class="left-menu-footer-wrapper t-align-center mt-40 mb-10 small-width" *ngIf="isSticky">
    <p class="footer-info">
      Made by &copy; NABIX, {{ year }} <a href="https://nabix.ru" target="_blank" class="download-title">NABIX.ru</a>
    </p>
    <p>
      <small>
        <button class="button unfilled" (click)="onLogout()">Выйти</button>
      </small><br>
    </p>
  </div>
</div>
