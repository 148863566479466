import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ApiClient,
  EntitySettingKeyStringEnum, EntityTypeEnum,
  IUpdateEntitySettingsRequest,
  IUpdateLimitsRequest,
  IUserAddOrUpdateRequest,
  IUserEntityRelationshipRequest,
  IUserResponse,
  LimitTypeEnum, LimitValueHelper,
  UpdateEntitySettingItemRequest,
  UpdateEntitySettingsRequest,
  UpdateLimitItemRequest,
  UpdateLimitsRequest,
  UserAddOrUpdateRequest,
  UserAvailableDataRequest,
  UserEntityRelationshipRequest
} from "../../../../kernel/ApiClient";
import {UserState} from "../../../../kernel/store/state/user.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {fadeIn, fadeInOut, fadeInOutStagged} from "../../../../kernel/animation";
import {Select, Store} from "@ngxs/store";
import {Observable, Subject, Subscription} from "rxjs";
import {debounceTime, take, takeUntil} from "rxjs/operators";
import {NotifyService} from "../../../core/services/notify.service";
import {errorMessages} from "../../../../kernel/constants/errors";
import {BehaviorService} from "../../../core/services/behavior.service";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {
  controlValueToWorkHours,
  getErrorMessage, getSelectedLabelForNewAvailable,
  getSelectId,
  getUserRole,
  isExpectedError,
  mapInvalidFields,
  workHoursToControlValue
} from "../../../../kernel/helpers/data.helper";
import {messages} from "../../../../kernel/constants/messages";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../kernel/models/common.models";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {GetAvailableData} from "../../../../kernel/store/actions/dictionary.actions";
import {validateTime} from "../../../../kernel/validators";

@Component({
  selector: 'app-users-modal',
  templateUrl: './users-modal.component.html',
  styleUrls: ['./users-modal.component.scss'],
  animations: [fadeInOutStagged, fadeInOut, fadeIn]
})
export class UsersModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  subs = new Subscription();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getUserRoles) availableUserRoles$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getTimeZones) availableTimeZones$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Select(UserState.isAdministratorNabix) isAdministratorNabix$: Observable<boolean>;
  @Select(UserState.isAdministratorBPA) isAdministratorBPA$: Observable<boolean>;
  @Select(UserState.isSuperAdministratorNabix) isSuperAdministratorNabix$: Observable<boolean>;
  @Select(UserState.isAllNonBPAAdmins) isAllNonBPAAdmins$: Observable<boolean>;
  visible = false;

  isAdministratorBpa = false;
  isAdministratorNabix = false;
  isSuperAdministratorNabix = false;
  isAllNonBPAAdmins = false;

  success = false;
  message = '';
  login = '';

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: []
  };

  availableOrganizations: IDataSelectedHelper[];
  availableOrganizationPoints: IDataSelectedHelper[];
  availableUserRoles: IDataSelectedHelper[];
  availableTimeZones: IDataSelectedHelper[];
  user: IUserResponse;
  currentUser: IUserResponse;

  userForm: FormGroup;

  submitted = false;
  submittedRelationships = false;
  submittedSettings = false;
  submittedLimits = false;

  constructor(
    private behavior: BehaviorService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  get organizationId(): string {
    const temp = getSelectId(this.userForm.value.OrganizationId, this.availableOrganizations, false);
    return temp ? String(temp) : null;
  }
  get organizationPointId(): string {
    const temp = getSelectId(this.userForm.value.OrganizationPointId, this.availableOrganizationPoints, false);
    return temp ? String(temp) : null;
  }

  get mscTimeZone(): string {
    const index = this.availableTimeZones.findIndex(x => x.id === '58' || x.label.includes('Москва'));
    return index > -1 ? this.availableTimeZones[index].label : (this.availableTimeZones?.length > 0 ? this.availableTimeZones[0].label : null);
  }

  get isOperator(): boolean {
    return getUserRole(this.userForm.value.Role) === 'Operator';
  }
  get isAdministratorBPA(): boolean {
    return getUserRole(this.userForm.value.Role) === 'AdministratorBPA';
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
    }));
    this.subs.add(this.isAllNonBPAAdmins$.subscribe(isAllNonBPAAdmins => {
      this.isAllNonBPAAdmins = isAllNonBPAAdmins;
    }));
    this.subs.add(this.isAdministratorNabix$.subscribe(user => {
      this.isAdministratorNabix = user;
    }));
    this.subs.add(this.isSuperAdministratorNabix$.subscribe(user => {
      this.isSuperAdministratorNabix = user;
    }));
    this.subs.add(this.isAdministratorBPA$.subscribe(user => {
      this.isAdministratorBpa = user;
    }));
    this.subs.add(this.user$.subscribe(user => {
      this.currentUser = user;
    }));
    this.subs.add(this.availableUserRoles$.subscribe(availableUserRoles => {
      this.availableUserRoles = availableUserRoles;
    }));
    this.subs.add(this.availableTimeZones$.subscribe(availableTimeZones => {
      this.availableTimeZones = availableTimeZones;
    }));
    this.createFormControl();

    if (this.isAdministratorBpa) {
      if (this.availableOrganizations.length==1) {
        this.userForm.controls.OrganizationId.setValue(this.availableOrganizations[0].label)
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

    createFormControl(): void {
      if (this.userForm) {
        return
      }

      this.userForm = new FormGroup({
        LastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        FirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        Patronymic: new FormControl('', [Validators.maxLength(50)]),
        Id: new FormControl(null),
        Phone: new FormControl('', [Validators.required]),
        Email: new FormControl('', [Validators.required]),
        IsBlocked: new FormControl(false),
        Role: new FormControl(!!this.availableUserRoles && this.availableUserRoles.length > 0 ?
          this.availableUserRoles[this.availableUserRoles.length - 1].label : '', [Validators.required]),
        TimeZoneId: new FormControl(this.mscTimeZone, [Validators.required]),
        Inn: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]{10,12}$")]),
        OrganizationId: new FormControl(null),
        OrganizationPointId: new FormControl(null),
        WorkStart: new FormControl(null, [validateTime()]),
        WorkEnd: new FormControl(null, [validateTime()]),
        LimitForTransfer: new FormControl(null, [Validators.min(0)]),
        LimitForDay: new FormControl(null, [Validators.min(0)]),
        LimitForMonth: new FormControl(null, [Validators.min(0)]),
      });

      this.userForm.controls.OrganizationId.valueChanges
        .pipe(debounceTime(500),
          takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
           if (this.isOperator && data) {
            this.getAvailableOrganizationPoints();
          } else {
            this.availableOrganizationPoints = [];
            this.userForm.controls.OrganizationPointId.disable();
          }
        });

      this.userForm.controls.Role.valueChanges
        .pipe(debounceTime(500),
          takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          if (this.isOperator) {
            this.userForm.controls.OrganizationId.enable();
            this.userForm.controls.OrganizationId.setValidators(this.user ? null : [Validators.required]);
            this.userForm.controls.OrganizationPointId.setValidators(this.user ? null : [Validators.required]);
            this.getAvailableOrganizationPoints();
          } else {
            this.userForm.controls.OrganizationPointId.setValidators(null);
            this.userForm.controls.OrganizationPointId.setErrors(null);
            this.userForm.controls.OrganizationPointId.setValue(null);
            this.userForm.controls.OrganizationPointId.disable();
            if (this.isAdministratorBPA) {
              this.userForm.controls.OrganizationId.enable();
              this.userForm.controls.OrganizationId.setValidators(this.user ? null : [Validators.required]);
            } else {
              this.userForm.controls.OrganizationId.setValidators(null);
              this.userForm.controls.OrganizationId.setErrors(null);
              this.userForm.controls.OrganizationId.setValue(null);
              this.userForm.controls.OrganizationId.disable();
            }
          }
        });
    }

  inputKeyDownChanged(event: any): void {
    event.preventDefault();
  }

    getAvailableOrganizationPoints(): void {
    if (!this.organizationId) {
      return;
    }
      this.apiClient.users_GetAvailableData({
        type: UserAvailableDataEnum.OrganizationPoint,
        organizationId: this.organizationId
      } as UserAvailableDataRequest)
        .pipe(take(1))
        .subscribe(arr => {
          this.availableOrganizationPoints = arr as IDataSelectedHelper[];
          this.userForm.controls.OrganizationPointId.enable();
          this.userForm.controls.OrganizationPointId.setValue(getSelectedLabelForNewAvailable(this.userForm.value.OrganizationPointId, this.availableOrganizationPoints));
        });
    }

    checkFormControls(): void {
      if (this.user) {
        this.userForm.controls.LastName.setValue(this.user.lastName);
        this.userForm.controls.FirstName.setValue(this.user.firstName);
        this.userForm.controls.Patronymic.setValue(this.user.patronymic);
        this.userForm.controls.Inn.setValue(this.user.inn);
        this.userForm.controls.Id.setValue(this.user.id);
        this.userForm.controls.Phone.setValue(this.user.phone);
        this.userForm.controls.Email.setValue(this.user.email);
        this.userForm.controls.IsBlocked.setValue(this.user.isBlocked);
        const role = this.availableUserRoles.find(x => String(x.id) === String(this.user.roleName) ||
          String(x.label) === String(this.user.roleName) ||
          String(x.role) === String(this.user.roleName));
        this.userForm.controls.Role.setValue(role && role.label);
        const timeZoneId = this.availableTimeZones.find(x => String(x.id) === String(this.user.timeZoneId) ||
          String(x.label) === String(this.user.timeZoneId) ||
          String(x.role) === String(this.user.timeZoneId));
        this.userForm.controls.TimeZoneId.setValue(timeZoneId && timeZoneId.label);
      } else {
        this.userForm.controls.LastName.setValue('');
        this.userForm.controls.FirstName.setValue('');
        this.userForm.controls.Patronymic.setValue('');
        this.userForm.controls.Inn.setValue(null);
        this.userForm.controls.Id.setValue(null);
        this.userForm.controls.Phone.setValue('');
        this.userForm.controls.Email.setValue('');
        this.userForm.controls.IsBlocked.setValue(false);
        this.userForm.controls.Role.setValue(!!this.availableUserRoles && this.availableUserRoles.length > 0 ?
          this.availableUserRoles[this.availableUserRoles.length - 1].label : '');
        this.userForm.controls.TimeZoneId.setValue(this.mscTimeZone);
        this.userForm.controls.OrganizationId.setValue(null);
        this.userForm.controls.OrganizationPointId.setValue(null);
        this.userForm.controls.WorkStart.setValue(null);
        this.userForm.controls.WorkEnd.setValue(null);
        this.userForm.controls.LimitForTransfer.setValue(null);
        this.userForm.controls.LimitForDay.setValue(null);
        this.userForm.controls.LimitForMonth.setValue(null);
      }
      this.userForm.controls.OrganizationId.setValidators(this.user ? null : [Validators.required]);
      this.userForm.controls.OrganizationPointId.setValidators(this.user ? null : [Validators.required]);

      this.topMenu = this.isOperator || this.isAdministratorBPA ?
        {
          currentIndex: 1,
          items: this.isOperator ?
            [{title: 'Основные данные', index: 1}, {title: 'Настройки', index: 2}, {title: 'Лимиты', index: 3}, {title: 'Привязки', index: 4}] :
            [{title: 'Основные данные', index: 1}, {title: 'Настройки', index: 2}, {title: 'Привязки', index: 4}]
        } :
        {
          currentIndex: 1,
          items: [{title: 'Основные данные', index: 1}, {title: 'Настройки', index: 2}]
        };
    }

  arrOrUpdateUser(): void {
      this.submitted = true;
      if (this.userForm.invalid) {
        this.notify.error(errorMessages.needFixErrorToContinue);
        return;
      }
      const payload: IUserAddOrUpdateRequest = {
        id: !!this.user ? this.user.id : null,
        lastName: this.userForm.value.LastName,
        firstName: this.userForm.value.FirstName,
        patronymic: this.userForm.value.Patronymic,
        phone: this.userForm.value.Phone,
        email: this.userForm.value.Email,
        inn: this.userForm.value.Inn,
        isBlocked: this.userForm.value.IsBlocked,
        role: getUserRole(this.userForm.value.Role),
        timeZoneId: Number(getSelectId(this.userForm.value.TimeZoneId, this.availableTimeZones, false))
      };
      this.apiClient.users_AddOrUpdateUser(payload as UserAddOrUpdateRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (this.user) {
              this.notify.success(messages.userUpdated);
              this.toggleModal(null);
              this.user = data.user;
            } else {
              this.user = data.user;
              if (this.isOperator) {
                this.setOrRemoveEntityRelationship(false, true, this.organizationPointId);
              }
              if (this.isAdministratorBPA) {
                this.setOrRemoveEntityRelationship(true, true, this.organizationId);
              }
              this.success = true;
              this.message = data.message;
              this.login = data.user.phone;
              this.notify.success(messages.userCreated);
            }
            this.store.dispatch(new GetAvailableData({ type: UserAvailableDataEnum.User }));
        },
          (error) => {
            if (isExpectedError(error)) {
              this.userForm = mapInvalidFields(this.userForm, error.invalidFields);
              this.notify.error(error.message);
            } else {
              this.notify.error(errorMessages.serverRequestError);
            }
            console.error(error);
          });
    }

    resetPasswordFromAdmin(): void {
      if (!this.user ||
        !this.isAdministratorNabix &&
        !this.isSuperAdministratorNabix) {
        return;
      }
      this.apiClient.auth_FromAdminResetPassword(this.user.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.success = true;
            this.message = data.message;
            this.login = this.user.phone;
            this.notify.success(messages.passwordReset);
          },
          (error) => {
            this.notify.error(getErrorMessage(error));
            console.error(error);
          });
    }

  toggleModal(currentUser: IUserResponse) {
    this.userForm.controls.OrganizationPointId.disable();
    this.success = false;
    this.user = currentUser as IUserResponse;
    if (!this.user?.relationships) {
      this.getUserRelationShips();
    }
    if (this.user) {
      this.getUserLimits();
      this.getUserSettings();
    }
    this.submitted = false;
    this.submittedRelationships = false;
    this.submittedSettings = false;
    this.submittedLimits = false;
    this.topMenu.currentIndex = 1;
    this.modalClosed.emit(this.visible);
    this.visible = !this.visible;
    this.checkFormControls();
  }

  setDisabled(name: string, set: boolean): void {
    if (set) {
      this.userForm.controls[name].disable()
    } else {
      this.userForm.controls[name].enable()
    }
  }

  setOrRemoveEntityRelationship(isOrg: boolean, state: boolean, entityId : any = null): void {
    this.submittedRelationships = true;
    if (!this.user) {
      this.notify.error(errorMessages.createOperatorFirst)
      return;
    }
    const temp = state ? (isOrg ? this.organizationId : this.organizationPointId) : entityId;
    if (!temp) {
      this.notify.error(isOrg ? errorMessages.chooseBpa : errorMessages.choosePoint);
      if (isOrg) {
        this.userForm.controls.OrganizationId.setErrors({ required: true });
      }
      this.userForm.controls.OrganizationPointId.setErrors({ required: true });
      return;
    }
    const payload: IUserEntityRelationshipRequest = {
      userId: this.user.id,
      entityId: temp,
      state
    };
    (isOrg ?
    this.apiClient.organization_SetUserOrganizations(payload as UserEntityRelationshipRequest) :
    this.apiClient.organizationPoint_SetUserOrganizationPoints(payload as UserEntityRelationshipRequest))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getUserRelationShips();
        this.getUserSettings();
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  setOrRemoveUserOrganizationPointsRelationships(state: boolean): void {    
    if(!this.organizationId){
      this.notify.error(errorMessages.chooseBpa);
      this.userForm.controls.OrganizationId.setErrors({ required: true });
      return;
    }

    const payload: IUserEntityRelationshipRequest = {
      userId: this.user.id,
      entityId: this.organizationId,
      state
    };
    this.apiClient.organization_SetUserOrganizationPointsByOrganization(payload as UserEntityRelationshipRequest)
    .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.getUserRelationShips();
          this.getUserSettings();
        }, error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        });
  }

  getUserRelationShips(): void {
    if (!this.user) {
      return;
    }
    this.apiClient.users_GetUserRelationShips(this.user.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.user.relationships = data;
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  getUserLimits(): void {
    if (!this.user) {
      return;
    }
    this.apiClient.limit_GetUserLimits(this.user.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.user.userLimits = data;
        this.userForm.get('LimitForTransfer').setValue(this.user.userLimits.limitForOperation?.value?.maxValue ?? null);
        this.userForm.get('LimitForDay').setValue(this.user.userLimits.limitForDay?.value?.maxValue ?? null);
        this.userForm.get('LimitForMonth').setValue(this.user.userLimits.limitForMonth?.value?.maxValue ?? null);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateUserLimits(): void {
    this.submittedLimits = true;
    if (!this.user) {
      this.notify.error(errorMessages.createOperatorFirst)
      return;
    }
    if (
      this.userForm.get('LimitForTransfer').invalid ||
      this.userForm.get(`LimitForDay`).invalid ||
      this.userForm.get(`LimitForMonth`).invalid ||
      !this.userForm.get('LimitForTransfer').value ||
      !this.userForm.get('LimitForDay').value ||
      !this.userForm.get('LimitForMonth').value
    ) {
      if (!this.userForm.get('LimitForTransfer').value) {
        this.userForm.get('LimitForTransfer').setErrors({required: true});
      }
      if (!this.userForm.get('LimitForDay').value) {
        this.userForm.get('LimitForDay').setErrors({required: true});
      }
      if (!this.userForm.get('LimitForMonth').value) {
        this.userForm.get('LimitForMonth').setErrors({required: true});
      }
      this.notify.error(errorMessages.needFixErrorToUpdateSetting);
      return;
    }
    // if (this.userForm.get('LimitForTransfer').value > this.userForm.get('LimitForDay').value) {
    //   this.userForm.get('LimitForTransfer').setErrors({customError: errorMessages.limitTransferDayMinMaxError});
    //   this.notify.error(errorMessages.needFixErrorToUpdateSetting);
    //   return;
    // }
    const payload: IUpdateLimitsRequest = {
      alsoUpdateClients: false,
      entityId: this.user.id,
      entityType: EntityTypeEnum.User,
      limitForOperation: { minValue: 0, maxValue: this.userForm.get('LimitForTransfer').value} as LimitValueHelper,
      limitForDay: { minValue: 0, maxValue: this.userForm.get('LimitForDay').value} as LimitValueHelper,
      limitForMonth: { minValue: 0, maxValue: this.userForm.get('LimitForMonth').value} as LimitValueHelper,
      isActive: true
    };
    // if (payload.items.length == 0) {
    //   this.notify.warning(errorMessages.limitsDidNotChanged);
    //   return;
    // }
    this.apiClient.limit_UpdateLimits(payload as UpdateLimitsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  getUserSettings(): void {
    if (!this.user) {
      return;
    }
    this.apiClient.setting_GetUserSettings(this.user.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.user.userSettings = data;
        this.userForm.get('WorkStart').setValue(workHoursToControlValue(this.user.userSettings.workDayStartInfo?.value ?? null));
        this.userForm.get('WorkEnd').setValue(workHoursToControlValue(this.user.userSettings.workDayEndInfo?.value ?? null));
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateUserSettings(): void {
    this.submittedSettings = true;
    if (!this.user) {
      this.notify.error(errorMessages.createOperatorFirst)
      return;
    }
    if (
      this.userForm.get('WorkStart').invalid ||
      this.userForm.get(`WorkEnd`).invalid ||
      !this.userForm.get('WorkStart').value ||
      !this.userForm.get('WorkEnd').value
    ) {
      if (!this.userForm.get('WorkStart').value) {
        this.userForm.get('WorkStart').setErrors({required: true});
      }
      if (!this.userForm.get('WorkEnd').value) {
        this.userForm.get('WorkEnd').setErrors({required: true});
      }
      this.notify.error(errorMessages.needFixErrorToUpdateSetting);
      return;
    }
    const payload: IUpdateEntitySettingsRequest = {
      entityId: this.user.id,
      entityType: EntityTypeEnum.User,
      items: []
    };
    if (workHoursToControlValue(this.user.userSettings.workDayStartInfo.value) !== this.userForm.get('WorkStart').value) {
      payload.items.push({
        settingId: this.user.userSettings.workDayStartInfo.id,
        keyString: EntitySettingKeyStringEnum.WorkDayStart,
        value: controlValueToWorkHours(this.userForm.get('WorkStart').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (workHoursToControlValue(this.user.userSettings.workDayEndInfo.value) !== this.userForm.get('WorkEnd').value) {
      payload.items.push({
        settingId: this.user.userSettings.workDayEndInfo.id,
        keyString: EntitySettingKeyStringEnum.WorkDayEnd,
        value: controlValueToWorkHours(this.userForm.get('WorkEnd').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (payload.items.length == 0) {
      this.notify.warning(errorMessages.settingsDidNotChange);
      return;
    }
    this.apiClient.setting_UpdateEntitySettingsASync(payload as UpdateEntitySettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
