<div class="page-wrapper">
  <p class="page-title">Профиль</p>
  <app-organizations *ngIf="isAllNonBPAAdmins" [id]="'organizations'"></app-organizations>
  <app-organization-points *ngIf="isAllAdmins" [id]="'organization-points'"></app-organization-points>
  <app-users *ngIf="isAllAdmins" [id]="'users'"></app-users>
  <app-hardware-proposals *ngIf="isAllAdmins" [id]="'hardware-proposals'"></app-hardware-proposals>
  <app-client-limits [id]="'client-limits'"></app-client-limits>
  <app-user-settings [id]="'user-settings'"></app-user-settings>
  <app-operation-settings *ngIf="isAllNonBPAAdmins" [id]="'operation-settings'"></app-operation-settings>
  <app-operation-crypto-keys *ngIf="isAllNonBPAAdmins" [id]="'operation-crypto-keys-settings'"></app-operation-crypto-keys>
  <app-site-settings *ngIf="isAllNonBPAAdmins" [id]="'site-settings'"></app-site-settings>
  <app-hardware-settings *ngIf="isOperator" [id]="'hardware-settings'"></app-hardware-settings>
  <app-operation-billing *ngIf="isAllNonBPAAdmins" [id]="'operation-billing'"></app-operation-billing>
  <app-organization-operation-types *ngIf="isAllNonBPAAdmins" [id]="'organization-operation-types'"></app-organization-operation-types>
  <app-organization-operation-type-commission *ngIf="isAllAdmins" [id]="'organization-operation-type-commission'"></app-organization-operation-type-commission>
</div>
