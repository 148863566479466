import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {errorMessages} from "../../../../../kernel/constants/errors";
import {NotifyService} from "../../../../core/services/notify.service";
import {
  ApiClient,
  BillingInfoResponse,
  BillingTypeEnum,
  IUpdateBillingInfoRequest, UpdateBillingInfoRequest
} from "../../../../../kernel/ApiClient";
import {takeUntil} from "rxjs/operators";
import {getErrorMessage, getFio, getValueById} from "../../../../../kernel/helpers/data.helper";
import {IDataSelectedHelper, IOperationDataHelper} from "../../../../../kernel/models/common.models";
import {createInfoArrayItem} from "../../../../../kernel/helpers/operation.helper";
import {DatePipeTypeEnum} from "../../../../../kernel/enum/data-type.enum";
import {Select} from "@ngxs/store";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {messages} from "../../../../../kernel/constants/messages";
import {BehaviorService} from "../../../../core/services/behavior.service";
import {answerPipeTransform} from "../../../../../kernel/pipes/pipe.helper";

@Component({
  selector: 'app-operation-billing',
  templateUrl: './operation-billing.component.html',
  styleUrls: ['./operation-billing.component.scss']
})
export class OperationBillingComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(DictionaryState.getCurrencies) currencies$: Observable<IDataSelectedHelper[]>;
  @Input() id: string
  subs = new Subscription();
  isLoad = false;
  expanded = false;
  isFirst = true;
  submitted = false;

  billingForm: FormGroup;

  billingInfo: BillingInfoResponse;
  info: IOperationDataHelper[] = [];
  currencies: IDataSelectedHelper[] = [];

  checkTypes = BillingTypeEnum;

  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService,
    private behavior: BehaviorService
  ) { }

  ngOnInit(): void {
    this.billingForm = new FormGroup({
      TransferNumber: new FormControl(null, [v.required])
    });
    this.subs.add(
      this.currencies$.subscribe(currencies => (this.currencies = currencies))
    );
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (!this.expanded) {
      this.submitted = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  getBillingInfo(): void {
    this.submitted = true;
    if (this.billingForm.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }
    this.apiClient.billing_GetOperationBillInfo(this.billingForm.value.TransferNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.billingInfo = data;
        this.initBillingInfo();
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  initBillingInfo(): void {
    this.info = [];
    this.info.push({
      title: `Данные об операции`,
      array: [
        {
          items: [
            createInfoArrayItem(`Тип`, this.billingInfo.operation.operationType.title, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Статус`, this.billingInfo.operation.transferStatus, DatePipeTypeEnum.OperationStatus),
            createInfoArrayItem(`Номер операции`, this.billingInfo.operation.transferNum, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Номер в платежной системе`, this.billingInfo.operation.paymentSystemTransferNum, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Дата`, this.billingInfo.operation.created, DatePipeTypeEnum.DateTime),
            createInfoArrayItem(`Дата подтверждения`, this.billingInfo.operation.confirmationSentAt, DatePipeTypeEnum.DateTime),
          ],
        },
      ],
    });
    this.info.push({
      title: `Данные об операторе`,
      array: [
        {
          items: [
            createInfoArrayItem(`ФИО`, `${getFio(this.billingInfo.operation.user.lastName, this.billingInfo.operation.user.firstName, this.billingInfo.operation.user.patronymic)}`, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Номер телефона`, this.billingInfo.operation.user.phone, DatePipeTypeEnum.Phone),
            createInfoArrayItem(`Почта`, this.billingInfo.operation.user.email, DatePipeTypeEnum.Usual ),
            createInfoArrayItem(`ИНН`, this.billingInfo.operation.user.inn, DatePipeTypeEnum.Usual),
          ],
        },
      ],
    });
    this.info.push({
      title: `Платежные данные`,
      array: [
        {
          items: [
            createInfoArrayItem(`Валюта внесения`, getValueById(this.currencies, this.billingInfo.operation.acceptedMoneyCurrencyCode ), DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Сумма в кассу`, this.billingInfo.operation.acceptedMoney + this.billingInfo.operation.organizationFee, DatePipeTypeEnum.Cash),
            createInfoArrayItem(`Валюта выдачи`, getValueById( this.currencies, this.billingInfo.operation.withdrawMoneyCurrencyCode), DatePipeTypeEnum.Usual ),
            createInfoArrayItem(`Сумма выдачи`, this.billingInfo.operation.withdrawMoney, DatePipeTypeEnum.Cash),
            createInfoArrayItem(`Валюта комиссии`, getValueById(this.currencies, this.billingInfo.operation.acceptedTotalFeeCurrencyCode), DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Сумма комиссии`, this.billingInfo.operation.acceptedTotalFee, DatePipeTypeEnum.Cash),
            createInfoArrayItem(`Предчек`, answerPipeTransform(this.billingInfo.operation.firstBill), DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Чек`, answerPipeTransform(this.billingInfo.operation.secondBill), DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Предчек отмены`, answerPipeTransform(this.billingInfo.operation.firstRejectBill), DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Чек отмены`, answerPipeTransform(this.billingInfo.operation.secondRejectBill), DatePipeTypeEnum.Usual),
          ],
        },
      ],
    });
  }

  updateBillInfo(type: BillingTypeEnum, isRemove: boolean): void {
    const payload: IUpdateBillingInfoRequest = {
      type,
      operationId: this.billingInfo.operation.id,
      isRemove
    }
    this.isLoad = true;
    this.apiClient.billing_UpdateBillingInfo(payload as UpdateBillingInfoRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.actionSuccess);
        this.getBillingInfo();
        this.isLoad = false;
      }, error => {
        this.isLoad = false;
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
