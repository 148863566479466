<div class="dialog-wrapper">
  <header class="dialog-header" [ngClass]="{'pb-0': type === 0}">
    <p class="dialog-title">{{ title }}</p>
<!--    <img *ngIf="type !== 0"-->
<!--      src="/assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">-->
  </header>

  <div class="dialog-body-wrapper" [ngClass]="{'mb-20': type !== 0}">
      <div class="load-wrapper">
        <mat-spinner *ngIf="type === 0" [diameter]="50"></mat-spinner>
        <img *ngIf="type !== 0" [src]="imgSrc" alt="">
      </div>
      <p class="load-info" [style.max-width.px]="600">{{message}}</p>

    <div class="t-align-center" *ngIf="type !== 0">
      <button class="button main" (click)="goto('operations')"
              [style.width.px]="225">Список операций</button>
    </div>

    <div class="load-final-info">
      <ng-container *ngIf="type === 1">
        <p class="load-info mt-20 mb-20">
          Передайте, пожалуйста, клиенту Чек и сообщите, что его необходимо сохранить до окончания операции.
        </p>
        <p class="load-info mt-20 mb-20">Проявите Заботу о своем клиенте, предложите ему оплатить другие услуги!</p>
<!--        <p class="load-info mb-10">Нажмите <strong class="pointer" (click)="close(false)">сюда</strong> чтобы создать еще одну операцию для этого клиента</p>-->
      </ng-container>
      <ng-container *ngIf="type === -1">
        <p class="load-info mt-20 mb-10">При подтверждении операции возникли ошибки</p>
      </ng-container>
    </div>
  </div>

  <footer *ngIf="type !== 0">
    <div class="dialog-btn-wrapper-mobile t-align-center">
      <button class="button main"
              (click)="goto('home')"
              [style.width.px]="180">Главная</button>
      <button class="button unfilled decoration-under" (click)="close()"
              [style.width.px]="215" [style.padding-left.px]="0">Новая операция</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="215"
              (click)="close()" [style.margin-right.px]="20">Новая операция</button>
      <button class="button main f-right"
              (click)="goto('home')"
              [style.width.px]="180">Главная</button>
    </div>
  </footer>
</div>

