import { Injectable } from '@angular/core';
import {isCalculateCommissionParamsCompared, mapFeeToCommission} from "../../../kernel/helpers/operation.helper";
import {
  getErrorMessage,
  getSelectId
} from "../../../kernel/helpers/data.helper";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {environment} from "../../../kernel/environments/environment";
import {PopupLoaderTypeEnum} from "../../../kernel/enum/popupLoaderTypeEnum.enum";
import {ApiClient, CalculateOrganizationOperationCommissionRequest, ICommission, IFee} from "../../../kernel/ApiClient";
import {OperationVariableService} from "./operation-variable.service";
import {OperationHelperService} from "./operation-helper.service";
import {BehaviorService} from "../../core/services/behavior.service";
import {NotifyService} from "../../core/services/notify.service";
import {OperationHelperControlService} from "./operation-helper-control.service";
import {ICommissionApiHelper, ICommissionHelper, IDataSelectedHelper} from "../../../kernel/models/common.models";
import {MtDynamicService} from "../../../kernel/mt-dynamic/services/mt-dynamic.service";
import {OperationApiService} from "./operation-api.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";

@Injectable({
  providedIn: 'root',
})
export class OperationCommissionService {
  constructor(
    private variables: OperationVariableService,
    private helper: OperationHelperService,
    private helperControl: OperationHelperControlService,
    private behavior: BehaviorService,
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {
  }

  calculateCommissionListener(operationIndex: number): void {
    const arr = this.variables.isTransfer ? [
      { value: null, name: 'mainInfo_countryCode', isNumber: false },
      { value: null, name: 'mainInfo_withdrawCurrency', isNumber: false },
      { value: 0, name: 'mainInfo_withdrawAmount', isNumber: true }
    ] : [
      { value: 0, name: 'mainInfo_acceptedAmount', isNumber: true }
    ];

    arr.map(x => {
      if (!!this.variables.operationInfoArray[operationIndex].formGroup.get(x.name)) {
        this.variables.operationInfoArray[operationIndex].formGroup.get(x.name).markAsDirty();
        this.variables.operationInfoArray[operationIndex].formGroup.get(x.name).valueChanges
          .pipe(debounceTime(100),
            takeUntil(this.variables.ngUnsubscribe)
          ).subscribe(d => {
          if (x.value != d && !x.isNumber || Number(x.value) != Number(d) && x.isNumber) {
            x.value = x.isNumber ? Number(d) : d;
            this.variables.isNeedCalculateCommission = true;
          }
        });
      }
    });
  }

  paymentSystemListener(operationIndex: number): void {
    this.variables.operationInfoArray[operationIndex].formGroup
      .get(`mainInfo_paymentSystemId`)
      .valueChanges.subscribe(data => {
      const comIndex = this.variables.commissions.findIndex(
        x => x.operationIndex === operationIndex
      );
      if (comIndex > -1) {
        this.variables.commissions.splice(comIndex, 1);
      }
      if (data) {
        const step = this.helperControl.getControlHelper(
          `mainInfo_paymentSystemId`,
          null,
          operationIndex
        );
        if (step) {
          const control = this.helperControl.getControlByStep(
            step,
            this.variables.operationInfoArray,
            this.variables.clientHelper
          );
          const value = getSelectId(
            data,
            control.selectData,
            false
          ) as string;
          this.setCommissionForOperationIndexByPaymentSystemId(
            operationIndex,
            value
          );
          this.behavior.operationBuilderCommandStatus.next({
            type: BuilderCommandEnum.GetMTDynamicFields,
            model: {
              paymentSystemId: value
            }
          });
        }
      }
    });
  }

  recalculateAllCommissions(): void {
    if (!this.variables.isReady || !this.variables.isTransfer) {
      return;
    }
    this.variables.operationInfoArray.map((operation, operationIndex) => {
      if (this.variables.commissionSub) {
        this.behavior.popupLoaderStatus.next(null);
        this.variables.commissionSub.unsubscribe();
      }
      this.variables.blockAllActions = true;
      // this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.CalculateCommission);
      this.apiClient.commissions_CalculateCommissionsV3(
          String(
            getSelectId(
              operation.formGroup.value.mainInfo_countryCode,
              this.variables.selectedDataAll.countries,
              false
            )
          ),
          this.variables.isCommissionAcceptedAmount ? Number(operation.formGroup.value.mainInfo_acceptedAmount) : null,
          String(
            getSelectId(
              operation.formGroup.value.mainInfo_acceptedCurrency,
              this.variables.selectedDataAll.currencies,
              false
            )
          ),
          this.variables.isCommissionAcceptedAmount ? null : Number(operation.formGroup.value.mainInfo_withdrawAmount),
          String(
            getSelectId(
              operation.formGroup.value.mainInfo_withdrawCurrency,
              this.variables.selectedDataAll.currencies,
              false
            )
          )
        )
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(
          fee => {
            const data = mapFeeToCommission(fee);
            this.behavior.popupLoaderStatus.next(null);
            this.variables.blockAllActions = false;
            // this.openCommissionListDialog(data as ICommission[]);
            this.variables.operationInfoArray[operationIndex].commissions = data;

            const cords = this.variables.operationInfoArray[operationIndex].paymentCord
              .cords;
            const selectData: IDataSelectedHelper[] = [];
            if (data) {
              data.map(x => {
                selectData.push({
                  id: x.paymentSystemId,
                  label: x.nameCyrillic,
                });
              });
            }
            this.variables.operationInfoArray[operationIndex].items[cords[0]].controlsArr[
              cords[1]
              ].controls[cords[2]].selectData = selectData;
            const value = getSelectId(
              this.variables.operationInfoArray[operationIndex].formGroup.value[
                `mainInfo_paymentSystemId`
                ],
              selectData,
              false
            ) as string;
            if (value) {
              const comIndex = this.variables.commissions.findIndex(
                x => x.operationIndex === operationIndex
              );
              if (comIndex > -1) {
                this.variables.commissions.splice(comIndex, 1);
                this.setCommissionForOperationIndexByPaymentSystemId(
                  operationIndex,
                  value
                );
              }
            } else {
              this.variables.operationInfoArray[operationIndex].formGroup
                .get('mainInfo_commissionId')
                .setValue(null);
              this.variables.operationInfoArray[operationIndex].formGroup
                .get('mainInfo_paymentSystemId')
                .setValue(null);
            }
          },
          error => {
            this.behavior.popupLoaderStatus.next(null);
            this.variables.blockAllActions = false;
            this.notify.error(getErrorMessage(error));
            console.error(error);
          }
        );
    });
  }

  preCalculateCommission(mainInfo_withdrawAmount: number, mainInfo_acceptedAmount: number, operationIndex: number): void {
    this.calculateCommission(
      this.variables.operationInfoArray[operationIndex].formGroup.value.mainInfo_countryCode,
      this.variables.operationInfoArray[operationIndex].formGroup.value.mainInfo_acceptedCurrency,
      mainInfo_withdrawAmount,
      this.variables.operationInfoArray[operationIndex].formGroup.value.mainInfo_withdrawCurrency,
      mainInfo_acceptedAmount,
      operationIndex
    );
  }

  blockCommissionFields(operationIndex: number, isBlock: boolean): void {
    this.blockCommissionField(operationIndex, isBlock, 'withdrawAmount');
    // this.blockCommissionField(operationIndex, isBlock, 'acceptedAmount');
    this.blockCommissionField(operationIndex, isBlock, 'countryCode');
    this.blockCommissionField(operationIndex, isBlock, 'withdrawCurrency');
  }

  blockCommissionField(operationIndex: number, isBlock: boolean, name: string): void {
    if (this.variables.operationInfoArray[operationIndex].getControl('mainInfo', name, null)) {
      this.variables.operationInfoArray[operationIndex].getControl('mainInfo', name, null).readonly = isBlock;
    }
  }

  calculateOrganizationCommission(
    amount: number,
    operationIndex: number): void {
    if (!this.variables.isReady || !amount) {
      return;
    }
    if (this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`)) {
      this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`).setValue(null);
    }
    this.variables.blockCommission = true;
    this.blockCommissionFields(operationIndex, true);
    this.apiClient.transfers_CalculationOrganizationCommission({
      amount,
      operationTypeId: this.variables.operationTypeEnum.id
    } as CalculateOrganizationOperationCommissionRequest)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.variables.isNeedCalculateCommission = false;
        this.variables.blockCommission = false;
        this.blockCommissionFields(operationIndex, false);
        if (this.variables.commissions[operationIndex]) {

          let comIndex = this.variables.commissions[operationIndex].items.findIndex(x => x.isOrg);
          if (comIndex > -1) {
            this.variables.commissions[operationIndex].items[comIndex].value = data;
            this.variables.commissions[operationIndex].orgCom = data;
          }

          comIndex = this.variables.commissions[operationIndex].items.findIndex(x => x.isWithdraw);
          if (comIndex > -1) {
            this.variables.commissions[operationIndex].items[comIndex].value = amount;
            this.variables.commissions[operationIndex].total = amount;
          }
        } else {
          this.variables.commissions.push({
            items: [
              {
                title: 'Сумма пополнения',
                value: amount,
                currency: environment.mainCurrencyId,
                isWithdraw: true
              },
              {
                title: 'Комиссия БПА',
                value: data,
                currency: environment.mainCurrencyId,
                isOrg: true
              },
            ],
            total: amount,
            orgCom: data,
            currency: environment.mainCurrencyId,
            operationIndex,
            commissionId: null
          });
        }
        if (this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`)) {
          this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`).setValue(data);
        }
      }, error => {
        if (this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`)) {
          this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_organizationCommission`).setValue(null);
        }
        this.variables.isNeedCalculateCommission = false;
        this.variables.blockCommission = false;
        this.blockCommissionFields(operationIndex, false);
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  calculateCommission(
    mainInfo_countryCode: string,
    mainInfo_acceptedCurrency: string,
    mainInfo_withdrawAmount: number,
    mainInfo_withdrawCurrency: string,
    mainInfo_acceptedAmount: number,
    operationIndex: number
  ): void {
    if (!this.variables.isReady || (!mainInfo_withdrawAmount && !mainInfo_acceptedAmount) || (mainInfo_withdrawAmount && mainInfo_acceptedAmount)) {
      return;
    }
    const d = {
      mainInfo_countryCode, mainInfo_acceptedCurrency, mainInfo_withdrawAmount, mainInfo_withdrawCurrency, mainInfo_acceptedAmount
    }
    if (isCalculateCommissionParamsCompared(this.variables.commissionCalculateParams, d)) {
      this.variables.commissionCalculateParams = d;
      this.variables.commissionCalculateParamsCountCompare++;
      if (this.variables.commissionCalculateParamsCountCompare < 5) {
        return;
      } else {
        this.variables.commissionCalculateParamsCountCompare = 0;
      }
    }
    this.variables.commissionCalculateParams = d;
    if (this.variables.commissionSub) {
      this.behavior.popupLoaderStatus.next(null);
      this.variables.commissionSub.unsubscribe();
    }
    this.variables.blockCommission = true;
    this.blockCommissionFields(operationIndex, true);
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.CalculateCommission);
    this.variables.isCommissionAcceptedAmount = !!Number(mainInfo_acceptedAmount);
    this.variables.commissionSub = this.apiClient
      .commissions_CalculateCommissionsV3(
        String(
          getSelectId(
            mainInfo_countryCode,
            this.variables.selectedDataAll.countries,
            false
          )
        ),
        mainInfo_acceptedAmount ? Number(mainInfo_acceptedAmount) : null,
        String(
          getSelectId(
            mainInfo_acceptedCurrency,
            this.variables.selectedDataAll.currencies,
            false
          )
        ),
        mainInfo_withdrawAmount ? Number(mainInfo_withdrawAmount) : null,
        String(
          getSelectId(
            mainInfo_withdrawCurrency,
            this.variables.selectedDataAll.currencies,
            false
          )
        )
      )
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        fee => {
          const data = mapFeeToCommission(fee);
          this.behavior.popupLoaderStatus.next(null);
          this.variables.operationInfoArray[operationIndex].commissions = data;
          if (
            this.variables.operationInfoArray[operationIndex].formGroup.get(
              `mainInfo_paymentSystemId`
            )
          ) {
            if (data && data.length > 0) {
              this.variables.operationInfoArray[operationIndex].formGroup
                .get(`mainInfo_paymentSystemId`)
                .enable();
            } else {
              this.variables.operationInfoArray[operationIndex].formGroup
                .get(`mainInfo_paymentSystemId`)
                .disable();
            }
          }
          const cords = this.variables.operationInfoArray[operationIndex].paymentCord
            .cords;
          const selectData: IDataSelectedHelper[] = [];
          if (data) {
            data.map(x => {
              selectData.push({
                id: x.paymentSystemId,
                label: x.nameCyrillic,
              });
            });
          }
          this.variables.operationInfoArray[operationIndex].items[cords[0]].controlsArr[
            cords[1]
            ].controls[cords[2]].selectData = selectData;
          const value = getSelectId(
            this.variables.operationInfoArray[operationIndex].formGroup.value[
              `mainInfo_paymentSystemId`
              ],
            selectData,
            false
          ) as string;
          if (value) {
            const comIndex = this.variables.commissions.findIndex(
              x => x.operationIndex === operationIndex
            );
            if (comIndex > -1) {
              this.variables.commissions.splice(comIndex, 1);
              this.setCommissionForOperationIndexByPaymentSystemId(
                operationIndex,
                value
              );
            }
          } else {
            this.helper.openCommissionListDialog(data as ICommissionApiHelper[], operationIndex);
            this.variables.operationInfoArray[operationIndex].formGroup
              .get('mainInfo_commissionId')
              .setValue(null);
            this.variables.operationInfoArray[operationIndex].formGroup
              .get('mainInfo_paymentSystemId')
              .setValue(null);
          }
          this.variables.isNeedCalculateCommission = false;
          setTimeout(() => {
            this.variables.blockCommission = false;
            this.blockCommissionFields(operationIndex, false);
          }, 1000);
        },
        error => {
          this.variables.isNeedCalculateCommission = true;
          this.behavior.popupLoaderStatus.next(null);
          this.notify.error(getErrorMessage(error));
          console.error(error);
          setTimeout(() => {
            this.variables.blockCommission = false;
            this.blockCommissionFields(operationIndex, false);
          }, 1000);
        }
      );
  }

  setCommissionForOperationIndexByPaymentSystemId(
    operationIndex: number,
    paymentSystemId: string
  ): void {
    this.variables.isNeedCalculateCommission = false;
    const comIndex = this.variables.operationInfoArray[
      operationIndex
      ].commissions.findIndex(x => x.paymentSystemId === paymentSystemId);
    if (comIndex > -1) {
      const temp = this.variables.operationInfoArray[operationIndex].commissions[
        comIndex
        ];
      if (this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_commission`)) {
        this.variables.operationInfoArray[operationIndex].formGroup.get(`mainInfo_commission`).setValue(temp.money.acceptedTotalFee.amount);
      }
      this.variables.commissions.push({
        items: [
          {title: 'Система', value: temp.nameCyrillic},
          {
            title: 'Сумма получения',
            value: temp.money.withdrawMoney.amount,
            currency: temp.money.withdrawMoney.currencyCode,
          },
          {
            title: 'Комиссия СДП',
            value: temp.money.acceptedTotalFee.amount,
            currency: temp.money.acceptedTotalFee.currencyCode,
          },
          {
            title: 'Сумма перевода',
            value: temp.money.transferMoney.amount,
            currency: temp.money.transferMoney.currencyCode,
          },
          {
            title: 'Комиссия БПА',
            value: 0,
            currency: temp.money.transferMoney.currencyCode,
            isOrg: true
          },
          {title: 'Курс', value: temp.money.rate, currency: 'RUB', isRate: true},
        ],
        total: temp.money.acceptedMoney.amount,
        orgCom: 0,
        currency: temp.money.acceptedMoney.currencyCode,
        operationIndex,
        commissionId: this.variables.operationInfoArray[operationIndex].commissions[
          comIndex
          ].commissionId,
      });
      this.variables.commissionCalculateParams.mainInfo_acceptedAmount = this.variables.operationInfoArray[operationIndex].commissions[comIndex].money.acceptedMoney.amount;
      this.variables.commissionCalculateParams.mainInfo_withdrawAmount = this.variables.operationInfoArray[operationIndex].commissions[comIndex].money.withdrawMoney.amount;
      this.variables.operationInfoArray[operationIndex].formGroup
        .get(`mainInfo_acceptedAmount`)
        .setValue(
          this.variables.operationInfoArray[operationIndex].commissions[comIndex].money
            .acceptedMoney.amount, { emitEvent : false }
        );
      this.variables.operationInfoArray[operationIndex].formGroup
        .get(`mainInfo_withdrawAmount`)
        .setValue(
          this.variables.operationInfoArray[operationIndex].commissions[comIndex].money
            .withdrawMoney.amount, { emitEvent : false }
        );
      this.variables.operationInfoArray[operationIndex].formGroup
        .get(`mainInfo_commissionId`)
        .setValue(
          this.variables.operationInfoArray[operationIndex].commissions[comIndex]
            .commissionId, { emitEvent : false }
        );
      this.calculateOrganizationCommission(
        this.variables.operationInfoArray[operationIndex].formGroup.value.mainInfo_acceptedAmount -
        this.variables.operationInfoArray[operationIndex].formGroup.value.mainInfo_commission,
        operationIndex
      );
    }
  }
}
