import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {
  ApiClient,
   HardwareProposalOrganizationPointUpdateRequest,
  IHardwareProposalOrganizationPointItemResponse,
  IUserResponse
} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorService} from "../../../core/services/behavior.service";
import {NotifyService} from "../../../core/services/notify.service";
import {getSelectId, getUserRole, isExpectedError, mapInvalidFields} from "../../../../kernel/helpers/data.helper";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../kernel/constants/messages";
import {errorMessages} from "../../../../kernel/constants/errors";
import {IDataSelectedHelper, IOperationDataHelper} from "../../../../kernel/models/common.models";
import {createInfoArrayItem} from "../../../../kernel/helpers/operation.helper";
import {DatePipeTypeEnum} from "../../../../kernel/enum/data-type.enum";

@Component({
  selector: 'app-hardware-proposals-modal',
  templateUrl: './hardware-proposals-modal.component.html',
  styleUrls: ['./hardware-proposals-modal.component.scss']
})
export class HardwareProposalsModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() proposalStateChange: EventEmitter<any> = new EventEmitter()
  subs = new Subscription();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getAvailableOrganizationPoints) availableOrganizationPoints$: Observable<IDataSelectedHelper[]>;
  visible = false;

  availableOrganizationPoints: IDataSelectedHelper[];
  proposalUserOrganizationPoints: IDataSelectedHelper[] = [];

  proposal: IHardwareProposalOrganizationPointItemResponse;

  proposalForm: FormGroup;
  submitted = false;

  info: IOperationDataHelper[] = [];

  constructor(
    private behavior: BehaviorService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  get pointId(): string {
    const pointId = this.proposalForm.value.PointId
    if (!pointId) {
      return null
    }
    const index = this.availableOrganizationPoints.findIndex(x => x.label === pointId)
    return index > -1 ? this.availableOrganizationPoints[index].id : null
  }

  get alreadyApproved(): boolean {
    return !!this.proposal.approvedUserId;
  }
  get alreadyRemoved(): boolean {
    return !!this.proposal.removedUserId;
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizationPoints$.subscribe(availableOrganizationPoints => {
      this.availableOrganizationPoints = availableOrganizationPoints;
    }));
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControl(): void {
    if (this.proposalForm) {
      return;
    }
    this.proposalForm = new FormGroup({
      Comment: new FormControl(''),
      PointId: new FormControl(null)
    });
  }

  resetFormControls(): void {
    if (!this.proposalForm) {
      return;
    }
    this.proposalForm.get(`Comment`).setValue(null);
    this.proposalForm.get(`PointId`).setValue(this.availableOrganizationPoints[0]?.label);
  }

  proposalUpdate(isApproved: boolean): void {
    if (isApproved && this.proposal.approvedUserId || !isApproved && this.proposal.removedUserId) {
      this.notify.error(errorMessages.alreadyExist);
      return;
    }
    if (isApproved && !this.pointId) {
      this.notify.error(errorMessages.choosePoint);
      return;
    }
    this.submitted = true;
    this.apiClient.hardware_UpdateHardwareProposalOrganizationPoint({
      id: this.proposal.id,
      isApproved,
      comment: this.proposalForm.value.Comment,
      pointId: this.pointId
    } as HardwareProposalOrganizationPointUpdateRequest).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (this.alreadyApproved || this.alreadyRemoved) {
          this.notify.success(isApproved ? messages.pointUpdateApproved : messages.pointUpdateRemoved);
        } else {
          this.notify.success(isApproved ? messages.proposalUpdateApproved : messages.proposalUpdateRemoved);
        }
        this.proposalStateChange.next();
        this.toggleModal(null);
      }, error => {
        this.notify.error(error.message)
        console.error(error)
      })
  }

  toggleModal(proposal: IHardwareProposalOrganizationPointItemResponse) {
    if (!!proposal) {
      this.proposal = proposal;
      this.submitted = false;
      this.modalClosed.emit(this.visible);
      this.visible = !this.visible;
      this.resetFormControls();
      this.initProposalInfo();
      this.checkProposalPointIds();
      if (this.proposal.approvedUserId || this.proposal.removedUserId) {
        this.proposalForm.controls.PointId.disable();
      } else {
        this.proposalForm.controls.PointId.enable();
      }
    } else {
      this.visible = false;
    }
  }

  checkProposalPointIds(): void {
    this.proposalUserOrganizationPoints = [];
    if (this.proposal.proposalPointIds?.length > 0) {
      this.availableOrganizationPoints.map(x => {
        if (this.proposal.proposalPointIds.findIndex(y => y === x.id) > -1) {
          this.proposalUserOrganizationPoints.push(x);
        }
      });
      this.proposalForm.controls.PointId.setValue(this.proposalUserOrganizationPoints[0].label);
    } else {
      this.proposalUserOrganizationPoints = this.availableOrganizationPoints;
      this.proposalForm.controls.PointId.setValue(this.availableOrganizationPoints[0].label);
    }
  }

  initProposalInfo(): void {
    this.info = [];
    this.info.push({
      title: `Информация о заявке`,
      array: [
        {
          items: [
            createInfoArrayItem(
              `Заявку создал`,
              `${this.proposal.proposalUser.lastName} ${this.proposal.proposalUser.firstName} ${this.proposal.proposalUser.patronymic}` ,
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem( `Телефон`, this.proposal.proposalUser.phone, DatePipeTypeEnum.Phone),
            createInfoArrayItem(`Дата создания`, this.proposal.created, DatePipeTypeEnum.DateTime),
            createInfoArrayItem(`ПИН`, this.proposal.pin, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Идентификатор ЦП`, this.proposal.cpu, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Идентификатор жесткого диска`, this.proposal.hard, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Идентификатор материнской платы`, this.proposal.motherBoard, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Идентификатор дисков`, this.proposal.hardAll, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Учетная запись`, this.proposal.userName, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Имя компьютера`, this.proposal.machineName, DatePipeTypeEnum.Usual),
            createInfoArrayItem(`Комментарий`, this.proposal.proposalComment, DatePipeTypeEnum.Usual),
          ],
        },
      ],
    });
    if (this.proposal.removedUserId) {
      this.info.push({
        title: `Информация о решении`,
        array: [
          {
            items: [
              createInfoArrayItem(`Заявку отклонил`, `${this.proposal.removedUser.lastName} ${this.proposal.removedUser.firstName} ${this.proposal.removedUser.patronymic}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Телефон`, this.proposal.removedUser.phone, DatePipeTypeEnum.Phone),
              createInfoArrayItem(`Комментарий`, this.proposal.removedComment, DatePipeTypeEnum.Usual),
            ],
          },
        ],
      });
    }
    if (this.proposal.approvedUserId) {
      this.info.push({
        title: `Информация о решении`,
        array: [
          {
            items: [
              createInfoArrayItem(`Заявку одобрил`, `${this.proposal.approvedUser.lastName} ${this.proposal.approvedUser.firstName} ${this.proposal.approvedUser.patronymic}`, DatePipeTypeEnum.Usual),
              createInfoArrayItem(`Телефон`, this.proposal.approvedUser.phone, DatePipeTypeEnum.Phone),
              createInfoArrayItem(`Комментарий`, this.proposal.approvedComment, DatePipeTypeEnum.Usual),
            ],
          },
        ],
      });
    }
  }
}
