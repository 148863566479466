import { Pipe, PipeTransform } from '@angular/core';
import {OperationStatusEnum} from "../enum/operation-status.enum";
import {getOperationStatus} from "../helpers/operation.helper";

@Pipe({
  name: 'operationStatus'
})
export class OperationStatusPipe implements PipeTransform {

  transform(data: any): any {
    return getOperationStatus(data).status;
  }
}
