import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {Navigate} from "@ngxs/router-plugin";
import {ICards} from "../../../../kernel/models/common.models";
import {UserState} from "../../../../kernel/store/state/user.state";
import {IHardwareData, IOperationTypeResponse} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {OperationVariableService} from "../../../mts-operation/services/operation-variable.service";

@Component({
  selector: 'app-main-operations',
  templateUrl: './main-operations.component.html',
  styleUrls: ['./main-operations.component.scss']
})
export class MainOperationsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(DictionaryState.getOperationTypes) operationTypes$: Observable<IOperationTypeResponse>;

  subs = new Subscription();
  operationTypes: IOperationTypeResponse;
  showIndex = 1;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.subs.add(this.operationTypes$.subscribe(operationTypes => {
      this.operationTypes = operationTypes;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackByFn(i, item) {
    return item.id
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/operation/${url}/1`]))
  }
}
