import {Injectable} from "@angular/core";
import {
  GetAvailableData,
  SetCountries,
  SetCountriesAddresses,
  SetCurrencies,
  SetDbCountries,
  SetOperationCryptoKeys,
  SetOperationTypes,
  SetOrganizationTypes,
  SetPaymentTypes,
  SetServices,
  SetTimeZones,
  SetUserRoles
} from "../../../kernel/store/actions/dictionary.actions";
import {UserAvailableDataEnum} from "../../../kernel/enum/user-available-data";
import {ApiClient} from "../../../kernel/ApiClient";
import {Store} from "@ngxs/store";
import {BehaviorService} from "./behavior.service";
import {NotifyService} from "./notify.service";
import {GlobalBuilderService} from "./global-builder.service";
import {takeUntil} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class StaticDictionariesService {
  isRequestSendToDictionary = false;

  constructor(
    private builder: GlobalBuilderService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService,
    private notify: NotifyService
  ) {
  }

  getAvailable(type: UserAvailableDataEnum): void { this.store.dispatch(new GetAvailableData({ type })); }
  getOrganizationTypes(): void { this.store.dispatch(new SetOrganizationTypes()); }
  getAvailableSets(): void {
    this.getAvailable(UserAvailableDataEnum.User);
    this.getAvailable(UserAvailableDataEnum.OrganizationPoint);
    this.getAvailable(UserAvailableDataEnum.Organization);
  }
  getUserRoles(): void {
    this.apiClient.dictionaries_GetUserRoles()
      .subscribe(data => {
        this.store.dispatch(new SetUserRoles(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getCountries(): void {
    this.apiClient.dictionaries_GetCountries()
      .subscribe(data => {
        this.store.dispatch(new SetCountries(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getCountriesAddresses(): void {
    this.apiClient.dictionaries_GetCountriesAddresses()
      .subscribe(data => {
        this.store.dispatch(new SetCountriesAddresses(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getCurrencies(): void {
    this.apiClient.dictionaries_GetCurrencies()
      .subscribe(data => {
        this.store.dispatch(new SetCurrencies(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getTimeZones(): void {
    this.apiClient.dictionaries_GetTimeZones()
      .subscribe(data => {
        this.store.dispatch(new SetTimeZones(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getServices(): void {
    this.apiClient.dictionaries_GetServices()
      .subscribe(data => {
        this.store.dispatch(new SetServices(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getOperationCountries(): void {
    this.apiClient.dictionaries_GetOperationCountries()
      .subscribe(data => {
        this.store.dispatch(new SetDbCountries(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getOperationTypes(): void {
    this.apiClient.dictionaries_GetOperationTypes()
      .subscribe(data => {
        data.items.map((x, index) => {
          if (!x.ico) {
            data.items[index].ico = '../../../../assets/img/icons/3-spin-dots.svg';
          }
        });
        this.builder.operation.variables.operationTypes = data;
        this.store.dispatch(new SetOperationTypes(data));
        this.builder.operation.helper.resetAllForNewOperation(true);
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getOperationCryptoKeys(): void {
    this.apiClient.dictionaries_GetOperationCryptoKeys()
      .subscribe(data => {
        this.store.dispatch(new SetOperationCryptoKeys(data));
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }
  getPaymentSystems(): void {
    this.apiClient.dictionaries_GetPaymentSystems()
      .subscribe(data => {
        this.store.dispatch(new SetPaymentTypes(data));
        this.builder.operation.helper.resetAllForNewOperation(true);
      }, error => {
        this.isRequestSendToDictionary = false;
        this.notify.error(error.message);
      });
  }

  getStaticDictionaries(type: StaticDictionariesEnum): void {
    if (!type) {
      if (this.isRequestSendToDictionary) {
        return;
      }
      this.isRequestSendToDictionary = true;
      this.getAvailableSets();
      this.getOrganizationTypes();
      this.getUserRoles();
      this.getCountries();
      this.getCountriesAddresses();
      this.getCurrencies();
      this.getTimeZones();
      this.getServices();
      this.getOperationCountries();
      this.getOperationTypes();
      this.getOperationCryptoKeys();
      this.getPaymentSystems();
      return;
    }

    switch (type) {
      case StaticDictionariesEnum.User:
        this.getAvailable(UserAvailableDataEnum.User);
        break;
      case StaticDictionariesEnum.OrganizationPoint:
        this.getAvailable(UserAvailableDataEnum.OrganizationPoint);
        break;
      case StaticDictionariesEnum.Organization:
        this.getAvailable(UserAvailableDataEnum.Organization);
        break;
      case StaticDictionariesEnum.OrganizationType:
        this.getOrganizationTypes();
        break;
      case StaticDictionariesEnum.UserRole:
        this.getUserRoles();
        break;
      case StaticDictionariesEnum.Country:
        this.getCountries();
        break;
      case StaticDictionariesEnum.CountryAddress:
        this.getCountriesAddresses();
        break;
      case StaticDictionariesEnum.Currency:
        this.getCurrencies();
        break;
      case StaticDictionariesEnum.TimeZone:
        this.getTimeZones();
        break;
      case StaticDictionariesEnum.Service:
        this.getServices();
        break;
      case StaticDictionariesEnum.OperationCountry:
        this.getOperationCountries();
        break;
      case StaticDictionariesEnum.OperationType:
        this.getOperationTypes();
        break;
      case StaticDictionariesEnum.OperationCryptoKey:
        this.getOperationCryptoKeys();
        break;
      case StaticDictionariesEnum.PaymentSystem:
        this.getPaymentSystems();
        break;
    }
  }

  staticDictionaryListener(): void {
    this.behavior.getStaticDictionaryChange$
      .subscribe(data => {
        if (!!data) {
          this.getStaticDictionaries(data);
        }
      });
  }
}

export enum StaticDictionariesEnum {
  User = 'User',
  OrganizationPoint = 'OrganizationPoint',
  Organization = 'Organization',
  OrganizationType = 'OrganizationType',
  UserRole = 'UserRole',
  Country = 'Country',
  CountryAddress = 'CountryAddress',
  Currency = 'Currency',
  TimeZone = 'TimeZone',
  Service = 'Service',
  OperationCountry = 'OperationCountry',
  OperationType = 'OperationType',
  OperationCryptoKey = 'OperationCryptoKey',
  PaymentSystem = 'PaymentSystem'
}
