<p class="operation-block-title">Данные операции</p>
<ng-container *ngIf="transferFields.length > 0 else choosePaymentSystemFirstly">
  <app-operation-dynamic-data-item-fields  [fields]="topLevelFields"
  ></app-operation-dynamic-data-item-fields>

  <app-operation-dynamic-data-item-step [fields]="transferFields"
  ></app-operation-dynamic-data-item-step>
</ng-container>

<ng-template #choosePaymentSystemFirstly>
  <h4 class="color-red t-align-center mt-35">Расчитайте комиссию и выберите платежную систему</h4>
</ng-template>
