import { Injectable } from '@angular/core';
import {Guid} from "guid-typescript";
import {addClientFormArrayItem} from "../../../kernel/helpers/client.helper";
import {createFormGroupByControlsArrayHelper, getValueFromModel} from "../../../kernel/helpers/data.helper";
import {OperationVariableService} from "./operation-variable.service";
import {OperationListenerService} from "./operation-listener.service";
import {OperationHelperControlService} from "./operation-helper-control.service";
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {addOperationFormArrayItem} from "../../../kernel/helpers/operation.helper";

@Injectable({
  providedIn: 'root',
})
export class OperationArrayService {
  constructor(
    private variables: OperationVariableService,
    private helperControl: OperationHelperControlService,
    private listener: OperationListenerService
  ) {
  }

  getItem(operationIndex: number): IStepsControlHelper {
    return operationIndex > -1 ? this.variables.operationInfoArray[operationIndex] : this.variables.clientHelper
  }

  addFormArrayItem(stepId: string, model = null, operationIndex = -1): void {
    const arrayId = Guid.raw();
    const isNew = !model || model === null;
    const index = this.getItem(operationIndex).items.findIndex(x => x.id === stepId);
    const tempControlArr = operationIndex > -1 ?
      addOperationFormArrayItem(stepId, this.variables.selectedDataAll, arrayId) :
      addClientFormArrayItem(stepId, this.variables.selectedDataAll, arrayId, isNew);
    const tempFormGroup = createFormGroupByControlsArrayHelper(tempControlArr, stepId, arrayId, isNew);

    tempControlArr.controls.map((control, controlIndex) => {
      this.helperControl.addControlHelperArrayItem({
        fullName: `${stepId}_${control.name}`,
        name: control.name,
        arrayId: arrayId,
        operationIndex: -1,
        itemIndex: index,
        controlArrIndex: this.getItem(operationIndex).items[index].controlsArr.length,
        controlIndex,
        parentName: this.getItem(operationIndex).items[index].id,
      });
    });
    this.getItem(operationIndex).items[index].controlsArr.push(tempControlArr);
    this.getItem(operationIndex).formArray.push(tempFormGroup);
    if (operationIndex === -1) {
      this.listener.clientFormArrayListener(arrayId);
    }
    if (model) {
      const i = this.getItem(operationIndex).getArrayIndex(arrayId);
      this.getItem(operationIndex).items[index].controlsArr[
      this.getItem(operationIndex).items[index].controlsArr.length - 1
        ].controls.map(control => {
        if (
          !!model[control.name] &&
          !!this.getItem(operationIndex).formArray
            .at(i)
            .get(`${stepId}_${control.name}`)
        ) {
          this.getItem(operationIndex).formArray
            .at(i)
            .get(`${stepId}_${control.name}`)
            .setValue(getValueFromModel(model[control.name], control));
        }
      });
    }
  }

  removeFormArrayItem(stepId: string, arrayId: string, operationIndex = -1): void {
    const index = this.getItem(operationIndex).items.findIndex(x => x.id === stepId);
    const controlArrIndex = this.getItem(operationIndex).items[
      index
      ].controlsArr.findIndex(x => x.arrayId === arrayId);
    const formArrayIndex = this.getItem(operationIndex).formArray.value.findIndex(
      x => x.arrayId === arrayId
    );
    if (controlArrIndex > -1 && formArrayIndex > -1) {
      this.getItem(operationIndex).items[index].controlsArr.splice(controlArrIndex, 1);
      this.getItem(operationIndex).formArray.removeAt(formArrayIndex);
    }
    this.helperControl.removeItemByArrayId(arrayId);
  }
}
