import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiClient, IBillingWaitOperationsResponse, LoggerErrorTypeEnum} from "../../../../kernel/ApiClient";
import {NotifyService} from "../../services/notify.service";
import {takeUntil} from "rxjs/operators";
import {getErrorMessage} from "../../../../kernel/helpers/data.helper";
import {HomeRedirect, ResetUser} from "../../../../kernel/store/actions/user.actions";
import {Store} from "@ngxs/store";
import {OperationInfoComponent} from "../../../operations-list/dialogs/operation-info/operation-info.component";

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  title: string;
  message: string;
  btnText: string;
  ico: string;
  maxWidth: number;
  btnWidth: number;
  type: LoggerErrorTypeEnum;

  columns = LoggerErrorTypeEnum;

  forBilling: IBillingWaitOperationsResponse;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, type: LoggerErrorTypeEnum},
    private apiClient: ApiClient,
    private notify: NotifyService,
    private store: Store
  ) {
    if (!data) {
      this.close(false);
    }
    this.title = data.title;
    this.type = data.type;
  }

  ngOnInit(): void {
    switch (this.type) {
      case LoggerErrorTypeEnum.TokenExpired:
        this.message = 'Необходимо перезайти в систему для продолжения Вашей работы';
        this.ico = '../../../../assets/img/icons/warning-ic.svg';
        this.btnText = 'Выйти';
        this.maxWidth = 320;
        this.btnWidth = 150;
        break;
      case LoggerErrorTypeEnum.TimeForNotForbiddenOperationCreateWithoutFiscal:
        this.message = 'Распечатайте чеки на ККТ вашей точки';
        this.ico = '../../../../assets/img/icons/warning-ic.svg';
        this.btnText = 'Главная';
        this.maxWidth = 320;
        this.btnWidth = 150;
        this.getBillingForWait();
        break;
      case LoggerErrorTypeEnum.LowBalance:
      case LoggerErrorTypeEnum.OperationApprovalLowBalance:
        this.message = this.title === '0,00 RUB' ? `Ваш баланс равен <strong>${this.title}</strong>` : `Ваш баланс близок к нулю. Осталось всего <strong>${this.title}</strong>`;
        this.title = 'Баланс минимален',
        this.ico = '../../../../assets/img/icons/warning-ic.svg';
        this.btnText = 'Понятно';
        this.maxWidth = 320;
        this.btnWidth = 150;
        break;
      case LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance:
        this.message = this.title;
        this.title = 'Баланс минимален',
        this.ico = '../../../../assets/img/icons/error-ic.svg';
        this.btnText = 'Понятно';
        this.maxWidth = 320;
        this.btnWidth = 150;
        break;
      case LoggerErrorTypeEnum.OperationTypeForbiddenForOrganization:
        this.message = this.title;
        this.title = 'Операция недоступна',
        this.ico = '../../../../assets/img/icons/error-ic.svg';
        this.btnText = 'Понятно';
        this.maxWidth = 320;
        this.btnWidth = 150;
        break;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getBillingForWait(): void {
    this.apiClient.billing_GetOperationsReadyForBilling()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.forBilling = data;
        if (this.forBilling?.totalCount === 0) {
          this.dialogRef.close(true);
        }
      }, error => {
        this.notify.error(getErrorMessage(error));
      });
  }

  openOperationInfo(transferId: string): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.data = transferId;
    const dialog = this.dialog.open(OperationInfoComponent, dialogConfig)
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.getBillingForWait();
      })
  }

  onLogout(): void {
    this.apiClient.auth_Logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new ResetUser());
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
  }

  close(success: boolean): void {
    switch (this.type) {
      case LoggerErrorTypeEnum.TokenExpired:
        if (success) {
          this.onLogout();
        }
        break;
      case LoggerErrorTypeEnum.TimeForNotForbiddenOperationCreateWithoutFiscal:
      case LoggerErrorTypeEnum.OperationTypeForbiddenForOrganization:
        this.store.dispatch(new HomeRedirect());
        break;
    }
    this.dialogRef.close(success);
  }
}
