import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {
  ApiClient, EntityTypeEnum,
  HardwareProposalFilterEnum,
  ILimitsResponseOfClientResponse, IUpdateLimitsRequest, IUserResponse, LimitValueHelper, UpdateLimitsRequest,
} from "../../../../../kernel/ApiClient";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {Select, Store} from "@ngxs/store";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ITopModalMenu} from "../../../../../kernel/models/common.models";
import { errorMessages } from '../../../../../kernel/constants/errors';
import {messages} from "../../../../../kernel/constants/messages";
import {getErrorMessage} from "../../../../../kernel/helpers/data.helper";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-client-limits',
  templateUrl: './limits.component.html',
  styleUrls: ['./limits.component.scss', '../../settings/settings.component.scss', '../../../../core/pages/main-head/main-head.component.scss']
})
export class LimitsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAllNonBPAAdmins) isAllNonBPAAdmins$: Observable<boolean>;
  @Input() id: string
  requestSub: any;
  subs = new Subscription();

  clientLimitsResponse: ILimitsResponseOfClientResponse;
  isAllNonBPAAdmins = false;
  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;
  isDesc = true;
  submitted = false;

  columns = HardwareProposalFilterEnum;
  sortField = HardwareProposalFilterEnum.Created;

  searchForm: FormGroup;
  userLimitsForm: FormGroup;
  userLimitsOperationTypesFormArray: FormArray;
  userLimitsCountryFormArray: FormArray;

  clientName: string;
  phoneNumber: string;

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: []
  };

  constructor(
    private fb: FormBuilder,
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get documentNumber(): string {
    return this.searchForm.value.DocumentNumber;
  }

  get clientNameFormatted(): string {
    if (this.clientLimitsResponse) {
      const arr = [];
      if (this.clientLimitsResponse.entity.lastName) { arr.push(this.clientLimitsResponse.entity.lastName) }
      if (this.clientLimitsResponse.entity.firstName) { arr.push(this.clientLimitsResponse.entity.firstName) }
      if (this.clientLimitsResponse.entity.middleName) { arr.push(this.clientLimitsResponse.entity.middleName) }
      return arr.join(' ');
    }
    return '';
  }

  ngOnInit() {
    this.subs.add(this.isAllNonBPAAdmins$.subscribe(isAllNonBPAAdmins => this.isAllNonBPAAdmins = isAllNonBPAAdmins));
    this.createFormControls();
    this.createUserFormControl();
    this.topMenu = {
      currentIndex: 1,
      items: [{title: 'Информация', index: 1}, {title: 'Изменить', index: 2}]
    };
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    this.searchForm = new FormGroup({
      DocumentNumber: new FormControl(null)
    });
    this.searchForm.get(`DocumentNumber`).valueChanges
      .pipe(
        debounceTime(1000),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.searchClientByNumber();
      });
  }

  createUserFormControl(): void {
    this.userLimitsForm = new FormGroup({
      OperationLimit: new FormControl(null, [Validators.required]),
      DayLimit: new FormControl(null, [Validators.required]),
      MonthLimit: new FormControl(null, [Validators.required]),
      IsActiveLimits: new FormControl(false, [Validators.required])
    });
  }

  fillUserLimitControls(): void {
    this.userLimitsForm.get(`OperationLimit`).setValue(this.clientLimitsResponse.limitForOperation.value.maxValue);
    this.userLimitsForm.get(`DayLimit`).setValue(this.clientLimitsResponse.limitForDay.value.maxValue);
    this.userLimitsForm.get(`MonthLimit`).setValue(this.clientLimitsResponse.limitForMonth.value.maxValue);
    this.userLimitsForm.get(`IsActiveLimits`).setValue(this.clientLimitsResponse.isActiveLimits);
  }

  fillUserLimitFormArray(): void {
    this.userLimitsOperationTypesFormArray = new FormArray([]);
    this.clientLimitsResponse.operationsLimits.map(x => {
      this.userLimitsOperationTypesFormArray.push(
        this.fb.group({
          operationLimit: new FormControl(x.limitForOperation.value.maxValue, [Validators.required]),
          dayLimit: new FormControl(x.limitForDay.value.maxValue, [Validators.required]),
          monthLimit: new FormControl(x.limitForMonth.value.maxValue, [Validators.required])
        })
      )
    });
    this.userLimitsCountryFormArray = new FormArray([]);
    this.clientLimitsResponse.countriesLimits.map(x => {
      this.userLimitsCountryFormArray.push(
        this.fb.group({
          monthLimit: new FormControl(x.limitForMonth.value, [Validators.required])
        })
      )
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  searchClientByNumber(): void {
    if (this.documentNumber?.length < 8) {
      return;
    }
    this.checkRequestSub();
    this.requestSub = this.apiClient.clients_GetClientLimits(this.documentNumber).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.submitted = false;
        this.topMenu.currentIndex = 1;
        this.clientLimitsResponse = data;
        this.clientName = this.clientNameFormatted;
        this.phoneNumber = this.clientLimitsResponse.entity.phoneNumbers?.length > 0 ? this.clientLimitsResponse.entity.phoneNumbers[0].number : '';
        this.fillUserLimitControls();
        this.fillUserLimitFormArray();
      }, error => {
        this.notify.error(error.message)
        console.error(error)
      });
  }

  updateLimits(): void {
    this.submitted = true;
    if (this.userLimitsForm.invalid || this.userLimitsOperationTypesFormArray.invalid || this.userLimitsCountryFormArray.invalid) {
      this.notify.error(errorMessages.needFixErrorToContinue);
      return;
    }
    const payload: IUpdateLimitsRequest = {
      alsoUpdateClients: false,
      entityId: this.clientLimitsResponse.entity.clientId,
      entityType: EntityTypeEnum.Client,
      limitForOperation: { minValue: 0, maxValue: this.userLimitsForm.get('OperationLimit').value} as LimitValueHelper,
      limitForDay: { minValue: 0, maxValue: this.userLimitsForm.get('DayLimit').value} as LimitValueHelper,
      limitForMonth: { minValue: 0, maxValue: this.userLimitsForm.get('MonthLimit').value} as LimitValueHelper,
      isActive: this.userLimitsForm.get(`IsActiveLimits`).value
    };
    this.apiClient.limit_UpdateLimits(payload as UpdateLimitsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}

