import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  ApiClient,
  HardwareData,
  IHardwareData, IKKMKeyExistResponse,
  IKKMKeyRequest,
  IUserResponse,
  KKMKeyRequest
} from "../../../../../kernel/ApiClient";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {getErrorMessage} from "../../../../../kernel/helpers/data.helper";
import {isKkm} from "../../../../../kernel/helpers/user.helper";
import {takeUntil} from "rxjs/operators";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-hardware-settings',
  templateUrl: './hardware-settings.component.html',
  styleUrls: ['./hardware-settings.component.scss', '../../settings/settings.component.scss']
})
export class HardwareSettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(UserState.hardware) hardware$: Observable<IHardwareData>;
  @Input() id: string

  subs = new Subscription();
  requestSub: any;

  user: IUserResponse;
  hardware: IHardwareData;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;

  hardwareForm: FormGroup;

  submitted = false;
  keyInfo: IKKMKeyExistResponse;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get isKkm(): boolean {
    return isKkm(this.user?.organization);
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      this.checkKey();
    }));
    this.subs.add(this.hardware$.subscribe(hardware => {
      this.hardware = hardware;
      this.checkKey();
    }));
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  checkKey(): void {
    if (this.isFirst && this.isKkm && this.hardware) {
      this.isFirst = false;
      this.checkKeyExist();
    }
  }

  createFormControls(): void {
    this.hardwareForm = new FormGroup({
      AgentUrl: new FormControl(null, [v.required]),
      KkmUrl: new FormControl(null)
    });
  }

  ngOnDestroy(): void {
    this.checkRequestSub();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe();
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  checkKeyExist(): void {
    this.apiClient.kKM_CheckExistKKmKey(this.hardware as HardwareData)
      .subscribe(
        data => {
          this.keyInfo = data;
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }
  getKkmKeyForPc(): void {
    this.apiClient.kKM_GetKKMKey({
      cpu: this.hardware.cpu,
      hard: this.hardware.hard,
      motherBoard: this.hardware.motherBoard,
      hardAll: this.hardware.hardAll,
      userName: this.hardware.userName,
      machineName: this.hardware.machineName,
      canGenerate: true
    } as KKMKeyRequest)
      .subscribe(
        data => {
          this.keyInfo.key = data;
        },
        error => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  updateHardwareSetting(): void {
    // this.submitted = true;
    // if (this.topMenu.currentIndex === 1 && this.operationTypeForm.invalid ||
    //   this.topMenu.currentIndex === 2 && this.paymentSystemForm.invalid ||
    //   this.topMenu.currentIndex === 3 && this.countryForm.invalid) {
    //   this.notify.error(errorMessages.needFixErrorToContinue);
    //   return;
    // }
    // this.checkRequestSub();
    // this.requestSub = this.apiClientEntityUpdateObservable
    //   .subscribe(
    //     () => {
    //       this.notify.success(messages.dataChanged);
    //     },
    //     error => {
    //       this.notify.error(getErrorMessage(error));
    //       console.error(error);
    //     }
    //   );
  }
}
