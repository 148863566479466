import {
  ICountriesResponse,
  IDataSelectedHelperOfInteger,
  IDataSelectedHelperOfString,
  IMtsService, IOperationTypeResponse, IPaymentSystemResponse, IUserAvailableDataRequest
} from '../../ApiClient';
import {UserAvailableDataEnum} from "../../enum/user-available-data";
export class SetUserRoles {
  static type = '[Dictionary] SetUserRoles'
  constructor(public data: IDataSelectedHelperOfInteger[]) {}
}
export class SetOrganizationTypes {
  static type = '[Dictionary] SetOrganizationTypes'
  constructor() {}
}
export class SetTimeZones {
  static type = '[Dictionary] SetTimeZones'
  constructor(public data: IDataSelectedHelperOfInteger[]) {}
}
export class SetCountries {
  static type = '[Dictionary] SetCountries'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetCountriesAddresses {
  static type = '[Dictionary] SetCountriesAddresses'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetCurrencies {
  static type = '[Dictionary] SetCurrencies'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetServicePoints {
  static type = '[Dictionary] SetServicePoints'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetServices {
  static type = '[Dictionary] SetServices'
  constructor(public data: IMtsService[]) {}
}
export class SetDbCountries {
  static type = '[Dictionary] SetDbCountries'
  constructor(public data: ICountriesResponse) {}
}
export class SetOperationTypes {
  static type = '[Dictionary] SetOperationTypes'
  constructor(public data: IOperationTypeResponse) {}
}
export class SetOperationCryptoKeys {
  static type = '[Dictionary] SetOperationCryptoKeys'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetPaymentTypes {
  static type = '[Dictionary] SetPaymentTypes'
  constructor(public data: IPaymentSystemResponse) {}
}
export class SetAvailableUsers {
  static type = '[Dictionary] SetAvailableUsers'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class GetAvailableData {
  static type = '[Dictionary] SetAvailableUsers'
  constructor(public data: IUserAvailableDataRequest) {}
}
export class ResetDictionaries {
  static type = '[Dictionary] ResetDictionaries'
}
