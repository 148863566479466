<div class="expand" [ngClass]="{'open': expanded}" id="organizationOperationTypeSettings">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">ПРИВЯЗКИ ОПЕРАЦИЙ К БПА</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.min-height.px]="350" *ngIf="organizationOperationTypeForm">

      <app-input inputType="select"
                 placeholder="БПА"
                 label="БПА"
                 name="OrganizationId"
                 wrapperClass="operation-half-item-left"
                 [contentMaxHeight]="600"
                 [selectData]="availableOrganizations || []"
                 [selectDataAll]="availableOrganizations || []"
                 [parentForm]="organizationOperationTypeForm"></app-input>
      <app-input inputType="select"
                 placeholder="Тип операции"
                 label="Тип операции"
                 name="OperationTypeId"
                 wrapperClass="operation-half-item-right"
                 [contentMaxHeight]="600"
                 [selectData]="availableOperationTypesArr || []"
                 [selectDataAll]="availableOperationTypesArr || []"
                 [parentForm]="organizationOperationTypeForm"></app-input>

      <div class="organization-operation-type-relationship-button-wrapper mb-20">
        
        <div (click)="updateOrganizationOperationTypes(true)" class="download-title">Привязать все операции</div>
        <div (click)="updateOrganizationOperationType(true)" class="download-title">Привязать к БПА</div>
        
      </div>
      <div class="organization-operation-type-relationship-button-wrapper">
        <div (click)="updateOrganizationOperationTypes(false)" class="download-title">Отвязать все операции</div>
        <div (click)="updateOrganizationOperationType(false)" class="download-title">Отвязать от БПА</div>
      </div>

      <div class="t-align-left organization-operation-types-wrapper" *ngIf="!!organizationOperationTypes && organizationOperationTypes.totalCount > 0 else emptyOrgOperationTypeList">
        <div class="organization-operation-type-item" *ngFor="let operationType of organizationOperationTypes.items; let positionIndex=index">
          <span class="download-title cur-def" [style.margin-left.px]="20">{{operationType.title}}</span>
          <button class="button unfilled" (click)="removeOrganizationOperationType(operationType.id)">Отвязать</button>
        </div>
      </div>

      <ng-template #emptyOrgOperationTypeList>
        <div class="empty-list">
          <p>{{
            organizationId ? 'По Вашему запросу не найдено привязок по типам операций...' : 'Пока что нет ни одной привязки по типам операций...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
