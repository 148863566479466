import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {ApiClient, IUserResponse, IUserSettingsRequest, UserSettingsRequest} from "../../../../../kernel/ApiClient";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {Select, Store} from "@ngxs/store";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {SetUser} from "../../../../../kernel/store/actions/user.actions";
import {isExpectedError, mapInvalidFields} from "../../../../../kernel/helpers/data.helper";
import {errorMessages} from "../../../../../kernel/constants/errors";
import {messages} from "../../../../../kernel/constants/messages";
import {pwdMinLength} from "../../../../../kernel/validators";
import {BehaviorService} from "../../../../core/services/behavior.service";
import {BroadcastMessage} from "../../../../../kernel/enum/broadcast-message.enum";

@Component({
  selector: 'app-user-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Input() id: string

  subs = new Subscription();
  user: IUserResponse;

  expanded = false;
  submitted = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  type = 0;

  settingForm: FormGroup;
  oldPassword: FormControl;
  newPassword: FormControl;
  reNewPassword: FormControl;

  navs = ['Настройки', 'Смена пароля']

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get isNewPasswordCompared(): boolean {
    return !!this.settingForm &&
      (!!this.settingForm.value.NewPassword && !!this.settingForm.value.ReNewPassword &&
      this.settingForm.value.NewPassword === this.settingForm.value.ReNewPassword ||
        !this.settingForm.value.NewPassword && !this.settingForm.value.ReNewPassword)
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      this.createFormControls();
    }));
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    if (!!this.settingForm) {
      return;
    }

    this.oldPassword = new FormControl(null, [Validators.required, pwdMinLength()]);
    this.newPassword = new FormControl(null, [pwdMinLength()]);
    this.reNewPassword = new FormControl(null, [pwdMinLength()]);

    this.settingForm = new FormGroup({
      OldPassword: this.oldPassword,
      NewPassword: this.newPassword,
      ReNewPassword: this.reNewPassword
    });
    this.settingForm.valueChanges
      .pipe(
        debounceTime(500),
        filter((d: any) => d.NewPassword && d.ReNewPassword),
        map((d) => ({
          newPassword: d.NewPassword,
          reNewPassword: d.ReNewPassword
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {

        let err = {};
        let hasError = false;
        if (!!this.settingForm.controls.NewPassword.hasError('pwdMinError')) {
          err['pwdMinError'] = true;
          hasError = true;
        }
        if (!!d.newPassword && !!d.reNewPassword &&
          d.newPassword !== d.reNewPassword) {
          err['pwdDontMatch'] = true;
          hasError = true;
        }
        this.settingForm.controls.NewPassword.setErrors(hasError ? err : null);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  updateUserInfo(): void {
    this.submitted = true;
    if (!!this.settingForm.invalid || !this.isNewPasswordCompared) {
      return;
    }
    const payload: IUserSettingsRequest = {
      type: this.type,
      oldPassword: this.settingForm.value.OldPassword,
      newPassword: this.settingForm.value.NewPassword,
      reNewPassword: this.settingForm.value.ReNewPassword
    };
    this.apiClient.users_UpdateUserSettings(payload as UserSettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new SetUser(data.user));
          this.notify.success(messages.settingsUpdated);
          if (this.type === 1) {
            this.behavior.removeBroadcastMessagesByTypeStatus.next(BroadcastMessage.TemporaryPassword);
          }
        },
        (error) => {
            if (isExpectedError(error)) {
              this.settingForm = mapInvalidFields(this.settingForm, error.invalidFields);
              this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        });
  }

  trackByFn(i, item) {
    return item.id
  }

  changeType(type: number): void {
    this.type = type;
    this.settingForm.get(`NewPassword`).setErrors(null);
    this.settingForm.get(`ReNewPassword`).setErrors(null);
    if (this.type === 0) {
      this.settingForm.get(`NewPassword`).setValidators([pwdMinLength()]);
      this.settingForm.get(`ReNewPassword`).setValidators([pwdMinLength()]);
    }
    if (this.type === 1) {
      this.settingForm.get(`NewPassword`).setValidators([Validators.required, pwdMinLength()]);
      this.settingForm.get(`ReNewPassword`).setValidators([Validators.required, pwdMinLength()]);
    }
  }
}
