<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Информация об операции</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <ng-container *ngIf="isReady else loadOperation">
    <div class="dialog-body-wrapper operation-info-wrapper">
      <div class="operation-info-body-wrapper mb-30">
        <div class="operation-info-body">
          <app-info-array [info]="info"></app-info-array>
        </div>
      </div>
    </div>

    <footer>
      <div class="dialog-btn-wrapper-mobile t-align-center">
        <button class="button main" [disabled]="load || transferErr || clientErr" (click)="getOperation()">Проверить статус</button>
        <ng-container *ngIf="!transferErr && !clientErr && isOperator">
          <button class="button unfilled decoration-under" [disabled]="load || transferErr || clientErr" (click)="confirmOperation()" *ngIf="!operationConfirmed && !operationConfirming">Подтвердить</button>
          <button class="button unfilled decoration-under" (click)="createCheck(checkTypes.FirstCheck)" [disabled]="load || transferErr || clientErr">Распечатать предчек</button>
          <button class="button unfilled decoration-under" (click)="createCheck(checkTypes.SecondCheck)" [disabled]="load || transferErr || clientErr || transferDb?.secondBill">Распечатать чек</button>
          <button class="button unfilled decoration-under" [disabled]="load || transferErr || clientErr" (click)="changeBeneficiary()">Изменить получателя</button>
          <button class="button unfilled decoration-under" [disabled]="load || transferErr || clientErr" (click)="createCheck(checkTypes.TransferFioChangeCheck)">Распечатать чек об измении получателя</button>
          <button class="button unfilled decoration-under" [disabled]="load || transferErr || clientErr || true" (click)="rejectOperation()">Отменить операцию</button>
        </ng-container>
        <button class="button unfilled decoration-under" (click)="close()" [style.padding-left.px]="0">Закрыть</button>
      </div>
      <div class="dialog-btn-wrapper-full">
        <div>
          <button class="button border decoration-under" [style.width.px]="140"
                  [style.margin-right.px]="20" (click)="close()">Закрыть</button>
          <button class="button main f-right" [disabled]="load || transferErr || clientErr"
                  [style.width.px]="230" (click)="getOperation()">Проверить статус</button>
        </div>
        <ng-container *ngIf="!transferErr && !clientErr && isOperator">
          <div class="mt-30">
            <button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr" (click)="createCheck(checkTypes.FirstCheck)">Распечатать предчек</button>
            <button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr || transferDb?.secondBill" (click)="createCheck(checkTypes.SecondCheck)">Распечатать чек</button>
          </div>
          <div class="mt-30" *ngIf="isAvailableRejectionBills">
            <button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr" (click)="createCheck(checkTypes.RejectFirstCheck)">Распечатать предчек отмены</button>
            <button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr || transferDb?.secondRejectBill" (click)="createCheck(checkTypes.RejectSecondCheck)">Распечатать чек отмены</button>
          </div>
          <div class="">
            <!--<button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr" (click)="changeBeneficiary()">Изменить получателя</button>-->
            <button class="button unfilled decoration-under d-block m-auto" (click)="changeBeneficiary()">Изменить получателя</button>
            <button class="button unfilled decoration-under d-block m-auto" (click)="rejectOperation()" [disabled]="load || transferErr || clientErr || true">Отменить операцию</button>
          </div>
          <div class="">
            <!--<button class="button unfilled decoration-under d-block m-auto" [disabled]="load || transferErr || clientErr" (click)="changeBeneficiary()">Изменить получателя</button>-->
            <button class="button unfilled decoration-under d-block m-auto" (click)="createCheck(checkTypes.TransferFioChangeCheck)">Распечатать чек об измении получателя</button>
          </div>
          <div class="t-align-center" *ngIf="!operationConfirmed && !operationConfirming">
            <button class="button unfilled decoration-under"
                    [disabled]="load || transferErr || clientErr"
                    [style.width.px]="230" (click)="confirmOperation()">Подтвердить</button>

          </div>
        </ng-container>
      </div>
    </footer>
  </ng-container>

  <ng-template #loadOperation>
    <div class="load-wrapper">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <p class="load-info">Пожалуйста, подождите...</p>
  </ng-template>
</div>
