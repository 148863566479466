<div class="dialog-wrapper" [style.max-width.px]="maxWidth">
  <header class="dialog-header">
    <p class="dialog-title">Внимание!</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close(false)" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper">
    <div class="info-dialog">
      <p class="info-dialog--title">{{title}}</p>
    </div>
    <div class="info-dialog">
      <img class="info-dialog--img" [src]="ico">
    </div>
    <div class="info-dialog">
      <p class="info-dialog--message" [innerHTML]="message | safeHtml"></p>
    </div>

    <div *ngIf="type === columns.TimeForNotForbiddenOperationCreateWithoutFiscal && forBilling?.totalCount > 0" class="info-scroll">

      <div class="for-billing-table" *ngFor="let item of forBilling.operations; let i=index">
        <div class="for-billing-table-item">
          <div class="for-billing-name">{{item.operationType.title}}</div>
          <div class="for-billing-number">{{item.transferNum}}</div>
          <div class="for-billing-sum">{{item.acceptedTotalMoney | number: "1.2":"ru"}} {{item.acceptedTotalMoneyCurrencyCode}}</div>
          <div class="for-billing-info download-title" (click)="openOperationInfo(item.transferId)">Подробнее</div>
        </div>
      </div>

    </div>

  </div>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main"
              [style.width.px]="btnWidth" (click)="close(true)">{{btnText}}</button>
      <button class="button unfilled decoration-under" (click)="close(false)" [style.padding-left.px]="0">Закрыть</button>
    </div>
    <div class="dialog-btn-wrapper-full" [style.padding-bottom.px]="20">
      <button class="button border decoration-under" [style.width.px]="135"
              (click)="close(false)" [style.margin-right.px]="20">Закрыть</button>
      <button class="button main f-right"
              [style.width.px]="btnWidth" (click)="close(true)">{{btnText}}</button>
    </div>
  </footer>

</div>
