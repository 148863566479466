import {Injectable} from '@angular/core';
import {BillingVariablesService} from "./billing-variables.service";
import {NotifyService} from "../../core/services/notify.service";
import {
  ApiClient,
  BillingTypeEnum,
  CreateBillQueueItemRequest, CreatePreBillRequest,
  ICreateBillQueueItemRequest
} from "../../../kernel/ApiClient";
import {takeUntil} from "rxjs/operators";
import {getErrorMessage} from "../../../kernel/helpers/data.helper";
import {messages} from "../../../kernel/constants/messages";

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private apiClient: ApiClient,
    private variables: BillingVariablesService,
    private notify: NotifyService
  ) {
  }

  createBillQueueItem(type: BillingTypeEnum, operationId :string, body: CreatePreBillRequest): void {
    this.apiClient.queue_CreateBillQueueItem({ type, operationId, body } as CreateBillQueueItemRequest)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.checkAddToQueue);
      }, error => {
        this.notify.error(getErrorMessage(error));
      });
  }
}
