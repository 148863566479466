import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {
  ApiClient,
  IOrganizationFilterRequest,
  IOrganizationFilterResponse,
  IPaginatorRequest,
  IUserResponse,
  OrganizationFilterEnum,
  OrganizationFilterRequest,
  OrganizationTypeEnum,
  PaginatorRequest
} from "../../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {Guid} from "guid-typescript";
import {FormControl, FormGroup} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {createPaginator} from "../../../../../kernel/helpers/paginator.helper";
import {debounceTime, takeUntil} from "rxjs/operators";
import {PaginatorComponent} from "../../../../core/pages/paginator/paginator.component";
import {IDataSelectedHelper} from "../../../../../kernel/models/common.models";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss', '../../settings/settings.component.scss']
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getOrganizationTypes) availableOrganizationTypes$: Observable<IDataSelectedHelper[]>;
  @ViewChild('paginatorComp') paginatorComp: PaginatorComponent;
  @Input() id: string

  availableOrganizationTypes: IDataSelectedHelper[] = [];

  subs = new Subscription();
  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;
  requestSub: any;

  organizationResponse: IOrganizationFilterResponse;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  searchForm: FormGroup;

  isDesc = true;
  columns = OrganizationFilterEnum;
  sortField = OrganizationFilterEnum.BalanceTransfer;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get isNeedFilterReset(): boolean {
    return this.searchForm && (
      !!this.searchForm.value.Search ||
      !!this.searchForm.value.OnlyActive ||
      !!this.searchForm.value.OrganizationTypeId
    )
  }

  get organizationTypeId(): OrganizationTypeEnum {
    const index = this.availableOrganizationTypes.findIndex(x => x.label === this.searchForm.value.OrganizationTypeId);
    if (index < 0) {
      return null;
    }
    switch (this.availableOrganizationTypes[index].id) {
      case '1':
        return OrganizationTypeEnum.KKM;
      case '2':
        return OrganizationTypeEnum.OneC;
    }
    return null;
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizationTypes$.subscribe(availableOrganizationTypes => {
      this.availableOrganizationTypes = availableOrganizationTypes;
    }));
    this.paginator = createPaginator(5);
    this.createFormControls();
    this.getFilteredOrganizations();
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    this.searchForm = new FormGroup({
      Search: new FormControl(null),
      OnlyActive: new FormControl(false),
      OrganizationTypeId: new FormControl(null)
    });
  }

  resetAll(): void {
    this.isDesc = true;
    this.sortField = OrganizationFilterEnum.BalanceTransfer;
    this.paginator.page = 1;
    this.searchForm.controls.Search.setValue(null);
    this.searchForm.controls.OnlyActive.setValue(false);
    this.searchForm.controls.OrganizationTypeId.setValue(null);
    this.getFilteredOrganizations();
  }

  ngOnDestroy(): void {
    this.checkRequestSub();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  valueChangeListener(): void {
    this.searchForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe),
        debounceTime(500))
      .subscribe(data => {
        this.getFilteredOrganizations();
      })
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false
      this.getFilteredOrganizations()
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  modalClosed(event: any) {
    if (!!event) {
      this.getFilteredOrganizations();
    }
  }

  setSort(type: OrganizationFilterEnum): void {
    this.isDesc = this.sortField !== type ? false : !this.isDesc
    this.sortField = type
    this.getFilteredOrganizations();
  }

  getFilteredOrganizations(): void {
    this.checkRequestSub()
    const payload: IOrganizationFilterRequest = {
      paginator: this.paginator as PaginatorRequest,
      isDesc: this.isDesc,
      query: this.searchForm.value.Search,
      onlyActive: this.searchForm.value.OnlyActive,
      type: this.organizationTypeId,
      column: this.sortField
    };
    this.requestSub = this.apiClient.organization_GetFilteredOrganizations(payload as OrganizationFilterRequest).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.organizationResponse = data as IOrganizationFilterResponse;
        this.paginatorComp.initType(this.organizationResponse?.totalCount ?? 0);
      }, error => {
        this.notify.error(error.message)
        console.error(error)
      });
    if (this.valueChangeFirst) {
      this.valueChangeFirst = false
      this.valueChangeListener()
    }
  }

  userChanged(): void {
    this.getFilteredOrganizations();
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredOrganizations();
  }
}
