<ng-container *ngFor="let control of fields">
  <p class="operation-block-title" *ngIf="(control.type === 'object' || control.type === 'array') && !control.hasOnlyObjectsOrArrays && !control.parentHidden">{{control.title}}</p>
  <app-operation-dynamic-data-item-fields *ngIf="control.children.length > 0 && !control.parentHidden"
                                        [fields]="control.children"
                                        [typeArrayId]="control.typeArrayId"
                                        [path]="control.controlName"
  ></app-operation-dynamic-data-item-fields>

  <app-operation-dynamic-data-item-step *ngIf="control.children.length > 0 && !control.parentHidden"
                                        [fields]="control.children"
                                        [typeArrayId]="control.typeArrayId"
  ></app-operation-dynamic-data-item-step>
</ng-container>
