import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {HeaderComponent} from "../../../core/pages/header/header.component";
import { OrganizationsComponent } from '../settings-block/organizations/organizations.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;
  @Select(UserState.isAdministratorMTS) isAdministratorMTS$: Observable<boolean>;
  @Select(UserState.isSuperAdministratorMTS) isSuperAdministratorMTS$: Observable<boolean>;
  @Select(UserState.isAdministratorNabix) isAdministratorNabix$: Observable<boolean>;
  @Select(UserState.isSuperAdministratorNabix) isSuperAdministratorNabix$: Observable<boolean>;
  @Select(UserState.isAdministratorBPA) isAdministratorBPA$: Observable<boolean>;
  @Select(UserState.isAllAdmins) isAllAdmins$: Observable<boolean>;
  @Select(UserState.isAllNonBPAAdmins) isAllNonBPAAdmins$: Observable<boolean>;

  subs = new Subscription();

  isAllAdmins = false;
  isAdministratorBPA = false;
  isAllNonBPAAdmins = false;
  isOperator = false;

  constructor() { }

  ngOnInit(): void {
    this.subs.add(this.isAllAdmins$.subscribe(isAllAdmins => { this.isAllAdmins = isAllAdmins }));
    this.subs.add(this.isAdministratorBPA$.subscribe(isAdministratorBPA => { this.isAdministratorBPA = isAdministratorBPA }));
    this.subs.add(this.isAllNonBPAAdmins$.subscribe(isAllNonBPAAdmins => { this.isAllNonBPAAdmins = isAllNonBPAAdmins }));
    this.subs.add(this.isOperator$.subscribe(isOperator => { this.isOperator = isOperator }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
