import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {
  ApiClient,
  EntityTypeEnum,
  EntitySettingKeyStringEnum,
  IOrganizationAddOrUpdateRequest,
  IOrganizationResponse,
  IUpdateEntitySettingsRequest,
  IUserResponse,
  OrganizationAddOrUpdateRequest,
  UpdateEntitySettingItemRequest,
  UpdateEntitySettingsRequest
} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorService} from "../../../core/services/behavior.service";
import {NotifyService} from "../../../core/services/notify.service";
import {
  controlValueToWorkHours,
  getErrorMessage,
  getSelectId,
  getUserRole,
  isExpectedError,
  mapInvalidFields,
  workHoursToControlValue
} from "../../../../kernel/helpers/data.helper";
import {takeUntil} from "rxjs/operators";
import {messages} from "../../../../kernel/constants/messages";
import {errorMessages} from "../../../../kernel/constants/errors";
import {fadeIn, fadeInOut, fadeInOutStagged} from "../../../../kernel/animation";
import {GetAvailableData} from "../../../../kernel/store/actions/dictionary.actions";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {IDataSelectedHelper, ITopModalMenu} from "../../../../kernel/models/common.models";

@Component({
  selector: 'app-organizations-modal',
  templateUrl: './organizations-modal.component.html',
  styleUrls: ['./organizations-modal.component.scss'],
  animations: [fadeInOutStagged, fadeInOut, fadeIn]
})
export class OrganizationsModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  subs = new Subscription();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getOrganizationTypes) availableOrganizationTypes$: Observable<IDataSelectedHelper[]>;
  visible = false;

  topMenu: ITopModalMenu = {
    currentIndex: 1,
    items: [{title: 'Основные данные', index: 1}, {title: 'Настройки', index: 2}]
  };

  availableOrganizationTypes: IDataSelectedHelper[];
  organization: IOrganizationResponse;

  organizationForm: FormGroup;

  submitted = false;
  submittedSettings = false;

  constructor(
    private behavior: BehaviorService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  get firstOrganizationType(): string {
    return !!this.availableOrganizationTypes && this.availableOrganizationTypes.length > 0 ?
      this.availableOrganizationTypes[0].label : null;
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizationTypes$.subscribe(availableOrganizationTypes => {
      this.availableOrganizationTypes = availableOrganizationTypes;
    }));
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControl(): void {
    if (this.organizationForm) {
      return
    }

    this.organizationForm = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      Description: new FormControl(''),
      CertificateName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      BpId: new FormControl('', [Validators.required]),
      BpIdNotFranchise: new FormControl('', [Validators.required]),
      PlaceAddress: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      LegalAddress: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      Inn: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]{10,12}$")]),
      IsBlocked: new FormControl(false),
      Phone: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      OrganizationTypeId: new FormControl(this.firstOrganizationType, [Validators.required]),
      OrganizationNotifyLowBalance: new FormControl(null, [Validators.min(0)]),
      TimeForNotForbiddenOperationCreateWithoutFiscal: new FormControl(null, [Validators.min(0)]),
      MaxKkm: new FormControl(null, [Validators.min(0)]),
      TaxNds: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      Department: new FormControl(null),
      BalanceAccount: new FormControl(null, [Validators.required]),
      IncomingBalance: new FormControl(null, [Validators.required]),
      AgentCode: new FormControl(null, [Validators.required]),
      SpName: new FormControl(null, [Validators.required]),
      AgreementInfo: new FormControl(null, [Validators.required]),
      IsEPO: new FormControl(false),
    })
  }

  checkFormControls(): void {
    if (this.organization) {
      this.organizationForm.controls.Name.setValue(this.organization.name);
      this.organizationForm.controls.Description.setValue(this.organization.description);
      this.organizationForm.controls.CertificateName.setValue(this.organization.certificateName);
      this.organizationForm.controls.BpId.setValue(this.organization.bpId);
      this.organizationForm.controls.BpIdNotFranchise.setValue(this.organization.bpIdNotFranchise);
      this.organizationForm.controls.PlaceAddress.setValue(this.organization.placeAddress);
      this.organizationForm.controls.LegalAddress.setValue(this.organization.legalAddress);
      this.organizationForm.controls.Inn.setValue(this.organization.inn);
      this.organizationForm.controls.IsBlocked.setValue(this.organization.isBlocked);
      this.organizationForm.controls.Email.setValue(this.organization.email);
      this.organizationForm.controls.Phone.setValue(this.organization.phone);
      this.organizationForm.controls.BalanceAccount.setValue(this.organization.balanceAccount);
      this.organizationForm.controls.IncomingBalance.setValue(this.organization.incomingBalance);
      this.organizationForm.controls.AgentCode.setValue(this.organization.agentCode);
      this.organizationForm.controls.SpName.setValue(this.organization.spName);
      this.organizationForm.controls.AgreementInfo.setValue(this.organization.agreementInfo);
      this.organizationForm.controls.IsEPO.setValue(this.organization.isEPO);
      const organizationType = this.availableOrganizationTypes.find(x => String(x.id) === String(this.organization.organizationType) ||
        String(x.id) === String(this.organization.organizationType.id));
      this.organizationForm.controls.OrganizationTypeId.setValue(organizationType?.label ?? null);
    } else {
     this.resetFormControls();
    }
  }

  resetFormControls(): void {
    this.organizationForm.controls.Name.setValue(null);
    this.organizationForm.controls.Description.setValue(null);
    this.organizationForm.controls.CertificateName.setValue(null);
    this.organizationForm.controls.BpId.setValue(null);
    this.organizationForm.controls.BpIdNotFranchise.setValue(null);
    this.organizationForm.controls.PlaceAddress.setValue(null);
    this.organizationForm.controls.LegalAddress.setValue(null);
    this.organizationForm.controls.Inn.setValue(null);
    this.organizationForm.controls.IsBlocked.setValue(false);
    this.organizationForm.controls.Email.setValue(null);
    this.organizationForm.controls.OrganizationNotifyLowBalance.setValue(null);
    this.organizationForm.controls.TimeForNotForbiddenOperationCreateWithoutFiscal.setValue(null);
    this.organizationForm.controls.MaxKkm.setValue(null);
    this.organizationForm.controls.TaxNds.setValue(null);
    this.organizationForm.controls.Department.setValue(null);
    this.organizationForm.controls.Phone.setValue('');
    this.organizationForm.controls.BalanceAccount.setValue(null);
    this.organizationForm.controls.IncomingBalance.setValue(null);
    this.organizationForm.controls.AgentCode.setValue(null);
    this.organizationForm.controls.SpName.setValue(null);
    this.organizationForm.controls.AgreementInfo.setValue(null);
    this.organizationForm.controls.OrganizationTypeId.setValue(this.firstOrganizationType);
    this.organizationForm.controls.IsEPO.setValue(false);
  }

  addOrUpdateOrganization(): void {
    this.submitted = true;
    if (this.organizationForm.invalid) {
      return;
    }

    const payload: IOrganizationAddOrUpdateRequest = {
      id: !!this.organization ? this.organization.id : null,
      name: this.organizationForm.value.Name,
      description: this.organizationForm.value.Description,
      isBlocked: this.organizationForm.value.IsBlocked,
      certificateName: this.organizationForm.value.CertificateName,
      bpId: this.organizationForm.value.BpId,
      bpIdNotFranchise: this.organizationForm.value.BpIdNotFranchise,
      typeId: Number(getSelectId(this.organizationForm.value.OrganizationTypeId, this.availableOrganizationTypes, false)),
      placeAddress: this.organizationForm.value.PlaceAddress,
      legalAddress: this.organizationForm.value.LegalAddress,
      inn: this.organizationForm.value.Inn,
      balanceAccount: this.organizationForm.value.BalanceAccount,
      incomingBalance: Number(this.organizationForm.value.IncomingBalance),
      agentCode: this.organizationForm.value.AgentCode,
      spName: this.organizationForm.value.SpName,
      agreementInfo: this.organizationForm.value.AgreementInfo,
      email: this.organizationForm.value.Email,
      phone: this.organizationForm.value.Phone,
      isEPO: this.organizationForm.value.IsEPO,
    };
    
    (this.organization ?
      this.apiClient.organization_UpdateOrganization(payload as OrganizationAddOrUpdateRequest) :
      this.apiClient.organization_CreateOrganization(payload as OrganizationAddOrUpdateRequest))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
          if (this.organization) {
            this.notify.success(messages.orgUpdated)
          } else {
            this.notify.success(messages.orgCreated)
          }
          this.organization = data as IOrganizationResponse;

          this.organizationForm.get('OrganizationNotifyLowBalance').setValue(this.organization.organizationSettings.organizationNotifyLowBalance);
          this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').setValue(this.organization.organizationSettings.timeForNotForbiddenOperationCreateWithoutFiscal);
          this.organizationForm.get('MaxKkm').setValue(this.organization.organizationSettings.maxKkm);
          this.organizationForm.get('TaxNds').setValue(this.organization.organizationSettings.taxNds);
          this.organizationForm.get('Department').setValue(this.organization.organizationSettings.department);

          this.store.dispatch(new GetAvailableData({ type: UserAvailableDataEnum.Organization }));
        },
        (error) => {
          if (isExpectedError(error)) {
            this.organizationForm = mapInvalidFields(this.organizationForm, error.invalidFields);
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        });
  }

  toggleModal(organization: IOrganizationResponse) {
    this.organization = organization as IOrganizationResponse;
    this.submitted = false;
    this.submittedSettings = false;
    if (this.organization) {
      this.getOrganizationSettings();
    }
    this.topMenu.currentIndex = 1;
    this.modalClosed.emit(this.visible);
    this.visible = !this.visible;
    this.checkFormControls();
  }

  getOrganizationSettings(): void {
    if (!this.organization) {
      return;
    }
    this.apiClient.setting_GetOrganizationSettings(this.organization.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.organization.organizationSettings = data;
        this.organizationForm.get('OrganizationNotifyLowBalance').setValue(this.organization.organizationSettings.organizationNotifyLowBalance);
        this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').setValue(this.organization.organizationSettings.timeForNotForbiddenOperationCreateWithoutFiscal);
        this.organizationForm.get('MaxKkm').setValue(this.organization.organizationSettings.maxKkm);
        this.organizationForm.get('TaxNds').setValue(this.organization.organizationSettings.taxNds);
        this.organizationForm.get('Department').setValue(this.organization.organizationSettings.department);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  updateOrganizationSettings(): void {
    this.submittedSettings = true;
    if (!this.organization) {
      this.notify.error(errorMessages.createOrganizationFirst)
      return;
    }
    if (
      this.organizationForm.get('OrganizationNotifyLowBalance').invalid ||
      this.organizationForm.get(`TimeForNotForbiddenOperationCreateWithoutFiscal`).invalid ||
      this.organizationForm.get(`MaxKkm`).invalid ||
      this.organizationForm.get(`TaxNds`).invalid ||
      this.organizationForm.get(`Department`).invalid ||
      (!this.organizationForm.get('OrganizationNotifyLowBalance').value && this.organizationForm.get('OrganizationNotifyLowBalance').value !== 0) ||
      (!this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').value && this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').value !== 0) ||
      (!this.organizationForm.get('MaxKkm').value && this.organizationForm.get('MaxKkm').value !== 0) ||
      (!this.organizationForm.get('TaxNds').value && this.organizationForm.get('TaxNds').value !== 0)
    ) {
      if (!this.organizationForm.get('OrganizationNotifyLowBalance').value) {
        this.organizationForm.get('OrganizationNotifyLowBalance').setErrors({required: true});
      }
      if (!this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').value) {
        this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').setErrors({required: true});
      }
      if (!this.organizationForm.get('MaxKkm').value && this.organizationForm.get('MaxKkm').value !== 0) {
        this.organizationForm.get('MaxKkm').setErrors({required: true});
      }
      if (!this.organizationForm.get('TaxNds').value && this.organizationForm.get('TaxNds').value !== 0) {
        this.organizationForm.get('TaxNds').setErrors({required: true});
      }
      this.notify.error(errorMessages.needFixErrorToUpdateSetting);
      return;
    }
    const payload: IUpdateEntitySettingsRequest = {
      entityId: this.organization.id,
      entityType: EntityTypeEnum.Organization,
      items: []
    };
    if (this.organization.organizationSettings.organizationNotifyLowBalance !== this.organizationForm.get('OrganizationNotifyLowBalance').value) {
      payload.items.push({
        settingId: this.organization.organizationSettings.organizationNotifyLowBalanceInfo.id,
        keyString: EntitySettingKeyStringEnum.OrganizationNotifyLowBalance,
        value: String(this.organizationForm.get('OrganizationNotifyLowBalance').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (this.organization.organizationSettings.timeForNotForbiddenOperationCreateWithoutFiscal !== this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').value) {
      payload.items.push({
        settingId: this.organization.organizationSettings.timeForNotForbiddenOperationCreateWithoutFiscalInfo.id,
        keyString: EntitySettingKeyStringEnum.TimeForNotForbiddenOperationCreateWithoutFiscal,
        value: String(this.organizationForm.get('TimeForNotForbiddenOperationCreateWithoutFiscal').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (this.organization.organizationSettings.maxKkm !== this.organizationForm.get('MaxKkm').value) {
      payload.items.push({
        settingId: this.organization.organizationSettings.maxKkmInfo.id,
        keyString: EntitySettingKeyStringEnum.MaxKkm,
        value: String(this.organizationForm.get('MaxKkm').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (this.organization.organizationSettings.taxNds !== this.organizationForm.get('TaxNds').value) {
      payload.items.push({
        settingId: this.organization.organizationSettings.taxNdsInfo.id,
        keyString: EntitySettingKeyStringEnum.TaxNds,
        value: String(this.organizationForm.get('TaxNds').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (this.organization.organizationSettings.department !== this.organizationForm.get('Department').value) {
      payload.items.push({
        settingId: this.organization.organizationSettings.departmentInfo.id,
        keyString: EntitySettingKeyStringEnum.BillDepartment,
        value: String(this.organizationForm.get('Department').value)
      } as UpdateEntitySettingItemRequest);
    }
    if (payload.items.length == 0) {
      this.notify.warning(errorMessages.settingsDidNotChange);
      return;
    }
    this.apiClient.setting_UpdateEntitySettingsASync(payload as UpdateEntitySettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.settingsUpdated)
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
