<div class="expand" [ngClass]="{'open': expanded}" id="operationSettings">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКИ ОПЕРАЦИЙ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.min-height.px]="250">
      <div class="t-align-center mt-20 mb-20">
        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="changeSettingsType(item.index)" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>
      </div>

      <ng-container *ngIf="topMenu.currentIndex === 1">
        <div class="t-align-center">
          <app-input [inputType]="'select'"
                     placeholder="Тип операции"
                     label="Тип операции"
                     name="Type"
                     wrapperClass="operation-half-item-left"
                     [contentMaxHeight]="600"
                     [selectData]="availableOperationTypesArr || []"
                     [selectDataAll]="availableOperationTypesArr || []"
                     [controlInvalid]="submitted || submittedAllClients"
                     [parentForm]="operationTypeForm"></app-input>
          <div
            (click)="updateForAllClients()"
            class="download-title t-align-center"
          >Присвоить всем клиентам</div>
        </div>

        <app-input inputType="text"
                   label="Название"
                   name="Title"
                   width="full"
                   wrapperClass="operation-half-item-left"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>
        <app-input inputType="text"
                   label="Описание"
                   name="Description"
                   width="full"
                   wrapperClass="operation-half-item-right"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

        <app-input inputType="text"
                   label="Service Code"
                   name="ServiceCode"
                   width="full"
                   wrapperClass="operation-half-item-left"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

        <app-input inputType="text"
                   label="Service Code для не франшизы"
                   name="ServiceCodeNotFranchise"
                   width="full"
                   wrapperClass="operation-half-item-right"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

        

        <app-input [inputType]="'select'"
                   [controlInvalid]="submitted"
                   placeholder="Ключ шифрования"
                   label="Ключ шифрования"
                   name="OperationCryptoKeyId"
                   wrapperClass="operation-half-item-left"
                   [contentMaxHeight]="600"
                   [selectData]="availableOperationCryptoKeys || []"
                   [selectDataAll]="availableOperationCryptoKeys || []"
                   [parentForm]="operationTypeForm"></app-input>

        <app-input inputType="text"
                   label="Идентификации клиента, начиная с"
                   hint="Сумма, начиная с которой требуется идентификация клиента"
                   name="MinClientSum"
                   width="full"
                   wrapperClass="operation-half-item-right"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

        
         <app-checkbox
                   [wrapperClass]="operation-half-item-right"
                   [parentForm]="operationTypeForm"
                   name="IsNeedEncryption"
                   label="Шифровать карту"
                   [controlInvalid]="submitted"
                   ></app-checkbox>
                   
                  

        <h2>Франшиза</h2>

        <app-input inputType="text"
                   label="Лимит на операцию"
                   name="ClientLimitForOperation"
                   width="full"
                   wrapperClass="operation-item-one-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>
        <app-input inputType="text"
                   label="Лимит на день"
                   name="ClientLimitForDay"
                   width="full"
                   wrapperClass="operation-item-two-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>
        <app-input inputType="text"
                   label="Лимит на месяц"
                   name="ClientLimitForMonth"
                   width="full"
                   wrapperClass="operation-item-three-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

        <app-input inputType="text"
                   label="Верхняя комиссия"
                   hint="Максимально возможная дополнительная комиссия БПА"
                   name="OrganizationMaxCommission"
                   width="full"
                   wrapperClass="operation-item-three-third"
                   mask="separator.2"
                   maskSuffix=" %"
                   [controlInvalid]="submitted"
                   [parentForm]="operationTypeForm"></app-input>

          <h2>Не франшиза</h2>

          <app-input inputType="text"
                     label="Лимит на операцию"
                     name="ClientLimitForOperationNotFranchise"
                     width="full"
                     wrapperClass="operation-item-one-third"
                     mask="separator.2"
                     maskSuffix=" RUB"
                     [controlInvalid]="submitted"
                     [parentForm]="operationTypeForm"></app-input>
          <app-input inputType="text"
                     label="Лимит на день"
                     name="ClientLimitForDayNotFranchise"
                     width="full"
                     wrapperClass="operation-item-two-third"
                     mask="separator.2"
                     maskSuffix=" RUB"
                     [controlInvalid]="submitted"
                     [parentForm]="operationTypeForm"></app-input>
          <app-input inputType="text"
                     label="Лимит на месяц"
                     name="ClientLimitForMonthNotFranchise"
                     width="full"
                     wrapperClass="operation-item-three-third"
                     mask="separator.2"
                     maskSuffix=" RUB"
                     [controlInvalid]="submitted"
                     [parentForm]="operationTypeForm"></app-input>

          <app-input inputType="text"
                     label="Верхняя комиссия"
                     hint="Максимально возможная дополнительная комиссия БПА"
                     name="OrganizationMaxCommissionNotFranchise"
                     width="full"
                     wrapperClass="operation-item-three-third"
                     mask="separator.2"
                     maskSuffix=" %"
                     [controlInvalid]="submitted"
                     [parentForm]="operationTypeForm"></app-input>

        <div class="mt-20 mb-10">
          <div class="operation-item-one-third">
            <app-checkbox
              [wrapperClass]="'inputBox-group'"
              [parentForm]="operationTypeForm"
              name="IsActiveLimits"
              label="Лимиты активны"
              [controlInvalid]="submitted"
            ></app-checkbox>
          </div>
          <div class="operation-item-two-third">
            <app-checkbox
              [wrapperClass]="'inputBox-group'"
              [parentForm]="operationTypeForm"
              name="IsMinClientSumActive"
              label="Идентификация клиента"
              [controlInvalid]="submitted"
            ></app-checkbox>
          </div>
          <!-- <div class="operation-item-three-third">
            <app-checkbox
              [wrapperClass]="'inputBox-group'"
              [parentForm]="operationTypeForm"
              name="IsActiveOrganizationCommission"
              label="Активность верхней комиссии"
              [controlInvalid]="submitted"
            ></app-checkbox>
          </div> -->
        </div>

        <div class="operation-type-footer-setting">
          <div class="t-align-center">
            <div [style.margin-bottom.px]="26" [style.margin-top.px]="26">
              <app-file-select [title]="'Выберите лого'" [availableTypes]="availableIcoTypes" (fileChange)="fileChanged($event)"></app-file-select>
            </div>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="topMenu.currentIndex === 2">
        <div class="t-align-center">
          <app-input [inputType]="'select'"
                     placeholder="Платежная система"
                     label="Платежная система"
                     name="Type"
                     wrapperClass="operation-half-item-left"
                     [contentMaxHeight]="600"
                     [selectData]="availablePaymentSystemsArr || []"
                     [selectDataAll]="availablePaymentSystemsArr || []"
                     [controlInvalid]="submitted"
                     [parentForm]="paymentSystemForm"></app-input>
        </div>

        <app-input inputType="tel"
                   label="Телефон"
                   name="SupportPhone"
                   autocomplete="off"
                   maskPrefix="+7"
                   mask="(000) 000 0000"
                   width="full"
                   wrapperClass="operation-item-one-third"
                   [controlInvalid]="submitted"
                   [parentForm]="paymentSystemForm"></app-input>
        <app-input inputType="text"
                   label="Сайт"
                   name="SupportSite"
                   width="full"
                   wrapperClass="operation-item-two-third"
                   [controlInvalid]="submitted"
                   [parentForm]="paymentSystemForm"></app-input>
        <app-input inputType="text"
                   label="Email"
                   name="SupportEmail"
                   width="full"
                   wrapperClass="operation-item-three-third"
                   [controlInvalid]="submitted"
                   [parentForm]="paymentSystemForm"></app-input>
      </ng-container>

      <ng-container *ngIf="topMenu.currentIndex === 3">
        <div class="t-align-center">
          <app-input [inputType]="'select'"
                     placeholder="Страна получения"
                     label="Страна получения"
                     name="Type"
                     wrapperClass="operation-half-item-left"
                     [contentMaxHeight]="600"
                     [selectData]="availableCountriesArr || []"
                     [selectDataAll]="availableCountriesArr || []"
                     [controlInvalid]="submitted || submittedAllClients"
                     [parentForm]="countryForm"></app-input>
          <div class="mt-20 mb-20">
            <div (click)="fillCountriesFromApi()" class="download-title operation-half-item-left mt-10 mb-10">Проинициализировать страны</div>
            <div (click)="updateForAllClients()" class="download-title operation-half-item-right mt-10 mb-10">Присвоить всем клиентам</div>
          </div>
        </div>

        <app-input inputType="text"
                   label="Лимит на операцию"
                   name="ClientLimitForOperation"
                   width="full"
                   wrapperClass="operation-item-one-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="countryForm"></app-input>
        <app-input inputType="text"
                   label="Лимит на день"
                   name="ClientLimitForDay"
                   width="full"
                   wrapperClass="operation-item-two-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="countryForm"></app-input>
        <app-input inputType="text"
                   label="Лимит на месяц"
                   name="ClientLimitForMonth"
                   width="full"
                   wrapperClass="operation-item-three-third"
                   mask="separator.2"
                   maskSuffix=" RUB"
                   [controlInvalid]="submitted"
                   [parentForm]="countryForm"></app-input>
        <app-checkbox
          [wrapperClass]="'inputBox-group'"
          [parentForm]="countryForm"
          name="IsActiveLimits"
          label="Лимиты активны"
          [controlInvalid]="submitted"
        ></app-checkbox>

      </ng-container>

      <ng-container *ngIf="topMenu.currentIndex === 4 && !!availableOperationTypesArr">
        <div class="operation-type-positions-wrapper">
          <div class="mb-10" *ngFor="let operationType of availableOperationTypesArr; let positionIndex=index">
            <button class="button border" [ngClass]="{'disabled': operationType.position === 1}" (click)="moveOperationType(positionIndex, -1)" [style.margin-right.px]="10">↑</button>
            <button class="button border" [ngClass]="{'disabled': operationType.position === availableOperationTypesArr.length}" (click)="moveOperationType(positionIndex, 1)">↓</button>
            <span class="download-title cur-def" [style.margin-left.px]="20">{{operationType.position}}. {{operationType.label}} <small>({{operationType.role}})</small></span>
          </div>
        </div>
      </ng-container>

      <div class="t-align-center mt-50 pb-30">
        <button class="button main" [ngClass]="{'disabled': isLoad}" (click)="updateEntity()">Обновить</button>
      </div>

    </div>
  </div>
</div>
