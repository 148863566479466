import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {ApiClient} from "../../../../kernel/ApiClient";
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Navigate} from "@ngxs/router-plugin";
import {BehaviorService} from "../../../core/services/behavior.service";
import {OperationBuilderService} from "../../services/operation-builder.service";
import {IBottomMenuHelper} from "../../../../kernel/models/common.models";
import {isDevEnv, isLocalEnv} from "../../../../kernel/helpers/env.helper";
import {BuilderCommandEnum} from "../../../../kernel/enum/builder-command.enum";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {BillSourceEnum} from "../../../../kernel/enum/billSourceEnum.enum";
import {environment} from "../../../../kernel/environments/environment";
import {IMtJsonSchemaResponse, IMtUiSchemaResponse} from "../../../../kernel/mt-dynamic/models/mt-dynamic.models";

@Component({
  selector: 'app-mts-operation',
  templateUrl: './mts-operation.component.html',
  styleUrls: ['./mts-operation.component.scss']
})
export class MtsOperationComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;

  subs = new Subscription();

  constructor(
    public builder: OperationBuilderService,
    private store: Store,
    private apiClient: ApiClient,
    private route: ActivatedRoute,
    private behavior: BehaviorService,
    private billing: BillingBuilderService
  ) {
    this.billing.variables.source = BillSourceEnum.OperationPage;
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async params => {
        if (!params || !params.operationType) {
          this.store.dispatch(new Navigate(['/home']));
        }
        if (this.builder.variables.operationType != params.operationType) {
          this.builder.variables.editOperationId = params.id;
          this.builder.variables.operationType = params.operationType;
          await this.builder.defaultState(params.id, params.operationType);
        }
      });
  }

  get isReady(): boolean {
    return this.builder.variables.isReady;
  }
  get helperStep(): number {
    return this.builder.variables.helperStep;
  }
  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get backBtnTitle(): string {
    return this.builder.variables.backBtnTitle;
  }
  get currentStepTitle(): string {
    return this.builder.variables.currentStepTitle;
  }
  get forwardBtnTitle(): string {
    return this.builder.variables.forwardBtnTitle;
  }
  get nextBtnDisabled(): boolean {
    return this.builder.validation.nextBtnDisabled;
  }
  get blockAllActions(): boolean {
    return this.builder.variables.blockAllActions;
  }
  get bottomMenu(): IBottomMenuHelper[] {
    return this.builder.variables.bottomMenu;
  }

  get isReadyForInit(): boolean {
    return this.builder.variables.operationTypes?.items?.length > 0;
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(
      this.isAuthorized$.subscribe((isAuthorized) => {
        if (!isAuthorized) {
          this.store.dispatch(new Navigate(['/home']));
        }
      })
    );
    this.subs.add(
      this.isOperator$.subscribe((isOperator) => {
        if (!isOperator) {
          this.store.dispatch(new Navigate(['/home']));
        }
      })
    );
    await this.builder.api.operationApprovalForInit(environment.countRequestAccessTry);
  }

  fillOperationByTestData(): void {
    if (isLocalEnv() || isDevEnv()) {
      this.builder.init.fillByTestDataOperation();
    }
  }

  ngOnDestroy(): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.DefaultStateVariable
    });
    this.behavior.popupLoaderStatus.next(null);
    this.subs.unsubscribe();
  }
}
