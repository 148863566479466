<div class="expand" [ngClass]="{'open': expanded}" id="operationSettings">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg" alt="point">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКИ КОМИССИЙ БПА</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg" alt="arrow">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.min-height.px]="250" *ngIf="organizationOperationTypeForm">

      <div>
        <div>
          <app-input *ngIf="!isAdministratorBPA"
                  [inputType]="'select'"
                  placeholder="БПА"
                  label="БПА"
                  name="OrganizationId"
                  wrapperClass="operation-item-one-third"
                  [contentMaxHeight]="600"
                  [selectData]="availableOrganizations || []"
                  [selectDataAll]="availableOrganizations || []"
                  [controlInvalid]="submitted"
                  [parentForm]="organizationOperationTypeForm"></app-input>
        </div>
        
        <div>
        <app-input [inputType]="'select'"
                   placeholder="Тип операции"
                   label="Тип операции"
                   name="OperationTypeId"
                   wrapperClass="operation-item-one-third"
                   [contentMaxHeight]="600"
                   [selectData]="availableOperationTypesArr || []"
                   [selectDataAll]="availableOperationTypesArr || []"
                   [controlInvalid]="submitted"
                   [parentForm]="organizationOperationTypeForm"></app-input>       

          <app-input inputType="text"
                     label="Комиссия франшизы"
                     name="OrganizationCommission"
                     width="full"
                     wrapperClass="operation-item-two-third"
                     mask="separator.2"
                     maskSuffix=" %"
                     [controlInvalid]="submitted"
                     [parentForm]="organizationOperationTypeForm"></app-input>
          <app-input inputType="text"
                     label="Комиссия не франшизы"
                     name="OrganizationCommissionNotFranchise"
                     width="full"
                     wrapperClass="operation-item-three-third"
                     mask="separator.2"
                     maskSuffix=" %"
                     [controlInvalid]="submitted"
                     [parentForm]="organizationOperationTypeForm"></app-input>
      </div>
      <div>
        <p>{{hint}}</p>
        <p>{{hintNotFranchise}}</p>
      </div>

        <!-- <div class="operation-half-item-left mt-20">
          <app-checkbox
            [wrapperClass]="'inputBox-group'"
            [parentForm]="organizationOperationTypeForm"
            name="IsActiveOrganizationCommission"
            label="Активность комиссии БПА"
            [controlInvalid]="submitted"
          ></app-checkbox>
        </div> -->

        

        <div class="t-align-center mt-50 pb-30">
          <button class="button main" (click)="updateOperationType()">Обновить</button>
        </div>

      </div>
    </div>
  </div>
</div>
