import { Pipe, PipeTransform } from '@angular/core';
import {fileSizeProvider} from "../helpers/data.helper";

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number, decimals: number, zeroLable = true): any {
    if (bytes === 0) {
      return zeroLable ? '0 Байт' : ''
    }
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + fileSizeProvider[i].ru

  }
}
