import {
  IBottomMenuHelper, ICalculateCommissionParams, ICommissionApiHelper, ICommissionHelper,
  IControlGroupHelper,
  IControlsArrayHelper, IDataSelectedHelper,
  IDictionaries,
  IOperationDataItemHelper,
  IOperationStatusHelper,
  IOperationStepItem,
  IStepsControlHelper,
} from '../models/common.models';
import {Guid} from 'guid-typescript';
import {FormArray, FormGroup, Validators as v} from '@angular/forms';
import {
  validateCapitalLetter,
  validateFirstLetterCapitalAndOtherAny,
  validateNonDigit,
  validateNonSymbol,
  validatePhone
} from '../validators';
import {
  createEmptyAddress,
  fillClientOrReceiverPhoneNumbers,
  fillModelByControlGroupHelper,
  getControlFromControlArr,
  getCountries,
  getCurrencies, getCurrenciesOnlyAccepted,
  getSelectId,
  getValueForModel,
  toPhones,
} from './data.helper';
import {SelectedDataTypeEnum} from '../enum/selected-data-type.enum';
import {
  Address,
  IAddress, ICommissionAmounts, IFee,
  IPerson,
  ITransfersCreateRequest,
  Person,
  PersonAddresses,
  PhoneNumber, TransfersCreateRequest,
} from '../ApiClient';
import {createClientOrReceiverAddressHelper} from './client.helper';
import {
  OperationStatusEnum,
  operationStatusesHelper,
} from '../enum/operation-status.enum';
import {DatePipeTypeEnum} from '../enum/data-type.enum';
import {addOperationStepsFunctions} from "./functions.helper";
import { ConditionalExpr } from '@angular/compiler';

export function isOperationRoute(route: string): boolean {
  return route.includes('operation') && !route.includes('operations');
}

export function addOperationFormArrayItem(
  id: string,
  selectedData: IDictionaries,
  arrayId: string
): IControlsArrayHelper {
  switch (id) {
    case 'receiverPhoneNumbersInfo':
      return createReceiverPhoneNumbersControls(arrayId);
  }
  return null;
}

export function createOperationControlHelper(
  selectedData: IDictionaries
): IStepsControlHelper {
  const result = {
    items: [
      createOperationMainInfo(
        'mainInfo',
        'Основные данные операции',
        selectedData,
        Guid.raw()
      ),
/*
      createReceiverInfo(
        'receiverInfo',
        'Информация о получателе',
        selectedData,
        Guid.raw()
      ),
      createReceiverPhoneNumbers(
        'receiverPhoneNumbersInfo',
        'Номера телефонов получателя',
        selectedData,
        Guid.raw()
      ),
      createClientOrReceiverAddressHelper(
        'registrationAddress',
        'Адрес регистрации (прописки) получателя',
        selectedData,
        false,
        Guid.raw(),
        true,
        false
      ),
      createClientOrReceiverAddressHelper(
        'residentalAddress',
        'Адрес проживания получателя',
        selectedData,
        true,
        Guid.raw(),
        false,
        false
      ),
      createClientOrReceiverAddressHelper(
        'birthPlaceAddress',
        'Место рождения получателя',
        selectedData,
        true,
        Guid.raw(),
        false,
        false
      ),*/
    ],
    formGroup: new FormGroup({}),
    formArray: new FormArray([]),
    commissions: [],
    paymentCord: {
      count: 3,
      cords: [0, 0, 0],
    },
  };
  return addOperationStepsFunctions(result);
}

export function createOperationMainInfo(
  id: string,
  title: string,
  selectedData: IDictionaries,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createOperationMainInfoControls(selectedData, arrayId)],
  } as IControlGroupHelper;
}
export function createOperationMainInfoControls(
  selectedData: IDictionaries,
  arrayId: string
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      // {
      //   isInput: true,
      //   isHide: true,
      //   inputType: `text`,
      //   placeholder: `Идентификатор клиента`,
      //   label: `Идентификатор клиента`,
      //   name: `clientId`,
      //   wrapperClass: ``,
      //   value: null,
      //   validators: null,
      // },
      {
        isInput: true,
        inputType: `select`,
        selectData: [],
        selectedDataType: SelectedDataTypeEnum.PaymentSystems,
        contentMaxHeight: 600,
        onlySelect: true,
        placeholder: `Платежная система`,
        label: `Платежная система`,
        name: `paymentSystemId`,
        disable: true,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: getCountries(selectedData),
        selectedDataType: SelectedDataTypeEnum.Countries,
        contentMaxHeight: 600,
        placeholder: `Страна получения`,
        label: `Страна получения`,
        name: `countryCode`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: getCurrenciesOnlyAccepted(selectedData),
        selectedDataType: SelectedDataTypeEnum.CurrenciesOnlyAccepted,
        contentMaxHeight: 600,
        placeholder: `Валюта внесения`,
        label: `Валюта внесения`,
        name: `acceptedCurrency`,
        wrapperClass: `operation-half-item-left`,
        onlyVisible: true,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        isCash: true,
        readonly: true,
        inputType: `number`,
        placeholder: `Сумма внесения`,
        label: `Сумма внесения`,
        name: `acceptedAmount`,
        wrapperClass: `operation-half-item-right`,
        value: null,
        mask: "separator.2",
        maskSuffix: " RUB",
        validators: null
      },
      {
        isInput: true,
        inputType: `select`,
        selectData: [],
        selectedDataType: SelectedDataTypeEnum.Currencies,
        contentMaxHeight: 600,
        placeholder: `Валюта выдачи`,
        label: `Валюта выдачи`,
        name: `withdrawCurrency`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        isCash: true,
        inputType: `number`,
        placeholder: `Сумма выдачи`,
        label: `Сумма выдачи`,
        name: `withdrawAmount`,
        wrapperClass: `operation-half-item-right`,
        mask: "separator.2",
        maskSuffix: " RUB",
        value: null,
        validators: [v.required],
      },
      {
        isInput: true,
        isHide: true,
        inputType: `text`,
        placeholder: `Идентификатор комиссии`,
        label: `Идентификатор комиссии`,
        name: `commissionId`,
        wrapperClass: ``,
        value: null,
        validators: [v.required],
      },
      {
        isHide: true,
        isInput: true,
        inputType: `number`,
        placeholder: `Комиссия`,
        label: `Комиссия`,
        name: `commission`,
        value: null,
        validators: [v.required],
      },
    ],
  } as IControlsArrayHelper;
}

export function createReceiverInfo(
  id: string,
  title: string,
  selectedData: IDictionaries,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createReceiverInfoControls(selectedData, arrayId)],
  } as IControlGroupHelper;
}
export function createReceiverInfoControls(
  selectedData: IDictionaries,
  arrayId: string
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Фамилия`,
        label: `Фамилия`,
        name: `lastName`,
        wrapperClass: ``,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Имя`,
        label: `Имя`,
        name: `firstName`,
        wrapperClass: ``,
        value: null,
        validators: [
          v.required,
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Отчество`,
        label: `Отчество`,
        name: `middleName`,
        wrapperClass: ``,
        value: null,
        validators: [
          validateFirstLetterCapitalAndOtherAny(),
          validateNonSymbol(),
          validateNonDigit(),
        ],
      },
      {
        isInput: true,
        inputType: `text`,
        placeholder: `Номер IBAN`,
        label: `Номер IBAN (обязательно для PGPara)`,
        name: `beneficiaryAccountNumber`,
        wrapperClass: ``,
        value: null,
        validators: [

        ],
      },
    ],
  } as IControlsArrayHelper;
}

export function createReceiverPhoneNumbers(
  id: string,
  title: string,
  selectedData: IDictionaries,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: true,
    isFormGroup: false,
    formArrayBtnText: 'Добавить',
    formArrayBtnTextWidth: 150,
    controlsArr: [],
  } as IControlGroupHelper;
}
export function createReceiverPhoneNumbersControls(
  arrayId: string
): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isDefaultInfoArray: true,
        isInput: true,
        isHide: false,
        inputType: `tel`,
        placeholder: `Телефон`,
        label: `Телефон`,
        name: `number`,
        wrapperClass: ``,
        autocomplete: `on`,
        value: null,
        validators: [v.required, v.minLength(7), v.maxLength(16), validatePhone()],
      },
    ],
  } as IControlsArrayHelper;
}

export function createOperationPayload(
  clientId: string,
  helper: IStepsControlHelper,
  selectedDataAll: IDictionaries
): TransfersCreateRequest {
  let payload: ITransfersCreateRequest = {
    clientId,
    paymentSystemId: '',
    commissionId: '',
    countryCode: '',
    beneficiaryAccountNumber: '',
    beneficiary: {
      phoneNumbers: [],
      firstName: '',
      lastName: '',
      middleName: '',
      addresses: {
        birthPlaceAddress: createEmptyAddress() as Address,
        registrationAddress: createEmptyAddress() as Address,
        residentalAddress: createEmptyAddress() as Address,
      } as PersonAddresses,
    } as Person,
  };
  payload = new TransfersCreateRequest(fillModelByControlGroupHelper(
    helper,
    `mainInfo`,
    payload
  ) as ITransfersCreateRequest);
  payload.beneficiaryAccountNumber = helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`).value;
  payload.beneficiary = new Person(fillModelByControlGroupHelper(
    helper,
    `receiverInfo`,
    payload.beneficiary
  ) as IPerson);

  payload.beneficiary.addresses = new PersonAddresses({
    residentalAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `residentalAddress`,
      payload.beneficiary.addresses.residentalAddress
    ) as IAddress),
    birthPlaceAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `birthPlaceAddress`,
      payload.beneficiary.addresses.birthPlaceAddress
    ) as IAddress),
    registrationAddress: new Address(fillModelByControlGroupHelper(
      helper,
      `registrationAddress`,
      payload.beneficiary.addresses.registrationAddress
    ) as IAddress)
  });
   toPhones(
    fillClientOrReceiverPhoneNumbers(helper)
  ).map(x => {
     payload.beneficiary.phoneNumbers.push(new PhoneNumber(x));
  });
  return new TransfersCreateRequest(payload);
}

export function fillReceiverAddress(
  helper: IStepsControlHelper,
  selectedDataAll: IDictionaries
): IAddress[] {
  const result: IAddress[] = [];
  const index = helper.items.findIndex(x => x.id === `documents`);
  if (index < 0) {
    return result;
  }
  helper.items[index].controlsArr.map(controlArr => {
    const arrayItemIndex = helper.formArray.value.findIndex(
      x => x.arrayId === controlArr.arrayId
    );
    if (arrayItemIndex > -1) {
      result.push({
        countryCode: getSelectId(
          helper.formArray.value[arrayItemIndex][
            `receiverCountryInfo_countryCode`
          ],
          selectedDataAll.countries,
          false
        ) as string,
        addressFull: getValueForModel(
          helper.formArray.value[arrayItemIndex][
            `receiverCountryInfo_countryCode`
          ],
          getControlFromControlArr(controlArr, 'countryCode')
        ) as string,
      });
    }
  });
  return result;
}

export function createBottomMenu(): IBottomMenuHelper[] {
  return [
    {
      id: 'stepper',
      title: '',
      img: '../../../../assets/img/icons/grid-numbers.svg',
    },
    {
      id: 'info',
      title: 'Операция',
      img: '../../../../assets/img/icons/doc-with-rub.svg',
    },
    {
      id: 'commission',
      title: 'Комиссия',
      img: '../../../../assets/img/icons/wallet.svg',
    },
  ];
}

export function createOperationSteps(): IOperationStepItem[] {
  return [
    {step: 1, title: 'Общая информация'},
    {step: 2, title: 'Создание операции'},
    {step: 3, title: 'Подтверждение операции'},
  ];
}

export function getOperationStatus(status: string): IOperationStatusHelper {
  switch (status.toUpperCase()) {
    case OperationStatusEnum.CREATED:
      return operationStatusesHelper.created;
    case OperationStatusEnum.PROCESSED:
      return operationStatusesHelper.processed;
    case OperationStatusEnum.PROCESSING:
      return operationStatusesHelper.processing;
    case OperationStatusEnum.NOT_FOUND:
      return operationStatusesHelper.notFound;
    case OperationStatusEnum.TIMEOUT_RECEIVE:
      return operationStatusesHelper.timeoutReceive;
    case OperationStatusEnum.REJECTED:
      return operationStatusesHelper.rejected;
    case OperationStatusEnum.EXECUTED:
      return operationStatusesHelper.executed;
    case OperationStatusEnum.PAID:
      return operationStatusesHelper.paid;
  }
  return operationStatusesHelper.empty;
}

export function getOperationStatusSelectedData(): IDataSelectedHelper[] {
  const arr: IDataSelectedHelper[] = [];
  Object.keys(operationStatusesHelper).forEach(name => {
    if (name != 'empty') {
      arr.push({
        id: name.toUpperCase(),
        label: operationStatusesHelper[name].status,
      });
    }
  });
  return arr;
}

export function createInfoArrayItem(
  title: string,
  value: any,
  type: DatePipeTypeEnum
): IOperationDataItemHelper {
  return {
    title,
    value,
    isPhone: type === DatePipeTypeEnum.Phone,
    isCash: type === DatePipeTypeEnum.Cash,
    isDate: type === DatePipeTypeEnum.Date,
    isDateTime: type === DatePipeTypeEnum.DateTime,
    isYesOrNo: type === DatePipeTypeEnum.YesOrNo,
    isUsual: type === DatePipeTypeEnum.Usual,
    isOperationStatus: type === DatePipeTypeEnum.OperationStatus,
    isRate: type === DatePipeTypeEnum.Rate,
    isWarning: type === DatePipeTypeEnum.Warning,
  };
}

export function isCalculateCommissionParamsCompared(
  val1: ICalculateCommissionParams,
  val2: ICalculateCommissionParams): boolean {
  if (!val1 || !val2) {
    return false;
  }
  return val1.mainInfo_countryCode === val2.mainInfo_countryCode &&
  val1.mainInfo_acceptedCurrency === val2.mainInfo_acceptedCurrency &&
    toNumber(String(val1.mainInfo_withdrawAmount)).toFixed(2) === toNumber(String(val2.mainInfo_withdrawAmount)).toFixed(2) &&
    toNumber(String(val1.mainInfo_acceptedAmount)).toFixed(2) === toNumber(String(val2.mainInfo_acceptedAmount)).toFixed(2) &&
  val1.mainInfo_withdrawCurrency === val2.mainInfo_withdrawCurrency;
}

export function toNumber(val: string): number {
  if (isNaN(Number(val)) || !val) {
    return 0;
  }
  return Number(val);
}

export const operationtitleBottomArr = [
  { char: '0', title: 'операций'},
  { char: '1', title: 'операция'},
  { char: '2', title: 'операции'},
  { char: '3', title: 'операции'},
  { char: '4', title: 'операции'},
  { char: '5', title: 'операций'},
  { char: '6', title: 'операций'},
  { char: '7', title: 'операций'},
  { char: '8', title: 'операций'},
  { char: '9', title: 'операций'}
]

export function mapFeeToCommission(data: IFee[]): ICommissionApiHelper[] {
  const arr: ICommissionApiHelper[] = [];
  data.map(fee => {
    fee.commissions.map(com => {
      arr.push({
        paymentSystemId: com.paymentSystemId,
        nameCyrillic: com.nameCyrillic,
        commissionId: com.commissionId,
        countryCode: com.countryCode,
        money: com.money as ICommissionAmounts,
        deliveryType: fee.deliveryType,
        deliveryNameCyrillic: fee.deliveryNameCyrillic,
        deliveryNameLat: fee.deliveryNameLat,
      } as ICommissionApiHelper);
    });
  })
  return arr;
}
