import { Injectable } from '@angular/core';
import {OperationVariableService} from "./operation-variable.service";
import {FormControl, FormGroup, Validators as v} from "@angular/forms";
import {onlyTrue} from "../../../kernel/validators";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngxs/store";
import {getRandomFirstName, getRandomLastName, getRandomMiddleName} from "../../../kernel/helpers/data.helper";

@Injectable({
  providedIn: 'root',
})
export class OperationInitService {
  constructor(
    private variables: OperationVariableService,
    private store: Store
  ) {
  }

  createOperationHelperFormControls(): void {
    this.variables.operationHelperGroup = new FormGroup({
      OperationClientDocumentType: new FormControl(null),
      OperationClientDocumentSeries: new FormControl(null),
      OperationClientDocumentNumber: new FormControl(null),
      CashboxAmount: new FormControl(null, [v.required]),
      DataCorrect: new FormControl(null, [v.required, onlyTrue()]),
    });

    this.variables.operationHelperGroup.get(`DataCorrect`)
      .valueChanges
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          this.variables.isFirstConfirmData = true;
        }
      });
  }
  resetOperationHelperFormControls(): void {
    if (!this.variables.operationHelperGroup) {
      this.createOperationHelperFormControls();
    }
    this.variables.operationHelperGroup.get(`OperationClientDocumentType`).setValue(null);
    this.variables.operationHelperGroup.get(`OperationClientDocumentSeries`).setValue(null);
    this.variables.operationHelperGroup.get(`OperationClientDocumentNumber`).setValue(null);
    this.variables.operationHelperGroup.get(`CashboxAmount`).setValue(null);
    this.variables.operationHelperGroup.get(`DataCorrect`).setValue(null);
  }

  fillByTestDataOperation(): void {
    // this.variables.operationHelperGroup.get(`OperationClientDocumentNumber`).setValue('4157491596');
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`mainInfo_countryCode`).setValue('Таджикистан');
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`mainInfo_withdrawCurrency`).setValue('Российский рубль');
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`mainInfo_withdrawAmount`).setValue('500');
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`receiverInfo_lastName`).setValue(getRandomLastName());
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`receiverInfo_firstName`).setValue(getRandomFirstName());
    this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`receiverInfo_middleName`).setValue(getRandomMiddleName());
    this.variables.operationInfoArray[this.variables.operationIndex].formArray.controls.map(formGroup => {
      if (formGroup.get(`receiverPhoneNumbersInfo_number`)) {
        formGroup.get(`receiverPhoneNumbersInfo_number`).setValue(`+79037164512`);
      }
    });
  }
}
