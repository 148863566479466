<div #leftMenu>
  <app-left-menu
    *ngIf="isAuthorized$ | async"
    [leftMenuOpen]="leftMenuOpen"
    (stateChange)="leftMenuOpen = $event"></app-left-menu>
</div>
<div #notifyBody>
  <app-notify-menu #notifyComp [isSticky]="true"></app-notify-menu>
</div>

<header>
  <div class="nav">
    <div class="logo-wrapper pointer" routerLink="home">
      <img  src="../../assets/img/mts-pay.png" alt="MTS PAY" class="nav-logo-mts">
    </div>

    <ng-container *ngIf="isAuthorized$ | async">
      <div class="mobile-menu">
        <!-- <div class="bell-wrapper" (click)="changeNotifyState()" #notifyBellMobile>
          <img src="/assets/img/icons/bell.svg" class="nav-bellIco">
          <sup class="nav-bellCounter pulse" *ngIf="!!notifies && notifies.length > 0">{{notifies.length}}</sup>
        </div> -->
        <div class="hamburger-wrapper" id="hamburger" #hamburger>
          <p class="hamburger" (click)="leftMenuOpen = !leftMenuOpen">|||</p>
        </div>
      </div>

      <div class="menu-full">
        <div class="nav-list-wrapper">
          <ul class="nav-list">
            <li *ngFor="let item of navs; let i=index; trackBy:trackByFn"
                class="nav-item"
                [routerLink]="item.link" (click)="goto(item.link)"
                routerLinkActive="router-link active">
              {{ item.name }}
            </li>
          </ul>
        </div>

        <div class="nav-account-wrapper">
          <content class="nav-accountBox">
            <div class="nav-bell" (click)="changeNotifyState()" #notifyBell>
              <img src="../../../../assets/img/icons/bell.svg" class="nav-bellIco">
              <sup class="nav-bellCounter pulse" *ngIf="!!notifies && notifies.length > 0"
                   >{{notifies.length}}</sup>
            </div>
            <div class="nav-account">
              <img [src]="avatar" alt="user" class="nav-accountAvatar">
              <div class="nav-accountInfo">
                <p class="nav-accountName">{{ user.lastName }} {{ user.firstName }}</p>
                <p class="nav-accountPhone">{{ user.phone | phonePipe }}</p>
                <p class="nav-accountOrg">
                  {{ user.roleName | rolePipe }}
                </p>
                <p class="nav-accountOrg" *ngIf="isOperator || isAdministratorBPA">
                  {{ isAdministratorBPA ? user.organization.name : user.organizationPoint.name }}</p>
              </div>
            </div>
          </content>
        </div>
        <div class="nav-logout-wrapper">
          <ul class="nav-list">
            <li class="nav-item router-link active" (click)="onLogout()">Выйти</li>
          </ul>
        </div>
      </div>
    </ng-container>

  </div>
</header>
<div [style.height.px]="80"></div>
