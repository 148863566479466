import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserState} from '../../../../kernel/store/state/user.state';
import {Observable, Subject, Subscription} from 'rxjs';
import {Select} from '@ngxs/store';
import {ApiClient, IOperationStatsResponse, IUserResponse, LoggerErrorTypeEnum,} from '../../../../kernel/ApiClient';
import {takeUntil} from 'rxjs/operators';
import {NotifyService} from '../../services/notify.service';
import * as moment from "moment";
import {InfoDialogComponent} from "../../dialogs/info-dialog/info-dialog.component";
import {DecimalPipe} from "@angular/common";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BehaviorService} from "../../services/behavior.service";

@Component({
  selector: 'app-main-head',
  templateUrl: './main-head.component.html',
  styleUrls: ['./main-head.component.scss'],
})
export class MainHeadComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(UserState.isBPARole) isBPARole$: Observable<boolean>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;

  user: IUserResponse;
  subs = new Subscription();
  isBPARole = false;
  isOperator = false;
  today: any;
  summary: IOperationStatsResponse;
  fifthColumnTitle: string;

  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService,
    private decimalPipe: DecimalPipe,
    private dialog: MatDialog,
    private behavior: BehaviorService,
  ) {

  }

  get turnoverCashOperations(): number {
    return this.biTurnoverCashOperations + this.monoTurnoverCashOperations;
  }
  get fifthColumn(): number {
    return this.isBPARole ? this.organizationBalance : this.turnoverCashOperations;
  }
  get organizationBalance(): number {
    return this.summary?.organizationBalance ?? 0;
  }
  get averageCashOperations(): number {
    return this.summary?.average ?? 0;
  }
  get biTurnoverCashOperations(): number {
    return this.summary?.biTurnover ?? 0;
  }
  get countOperations(): number {
    return this.summary?.count ?? 0;
  }
  get lastActivityOperations(): Date {
    return this.summary?.lastActivity ?? null;
  }
  get monoTurnoverCashOperations(): number {
    return this.summary?.monoTurnover ?? 0;
  }

  ngOnInit() {
    this.getSummary();
    this.subs.add(this.user$.subscribe(user => { this.user = user }));
    this.subs.add(this.isOperator$.subscribe(isOperator => { this.isOperator = isOperator }));
    this.subs.add(this.isBPARole$.subscribe(isBPARole => {
      this.isBPARole = isBPARole;
      this.fifthColumnTitle = this.isBPARole ? 'Баланс' : 'Оборот';
    }));
    const offset = (new Date()).getTimezoneOffset();
    this.today = moment().add(offset, 'minutes');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getSummary(): void {
    this.apiClient
      .transfers_GetDayStats()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.summary = data;
          if (this.isBPARole && this.summary.organizationBalance < this.summary.organizationMinBalance) {
            this.behavior.infoDialogListenerStatus.next({
              type: LoggerErrorTypeEnum.LowBalance,
              title: String(this.summary.organizationBalance)
            });
          }
        },
        error => {
          console.error(error);
          this.notify.error(error.message);
        }
      );
  }
}
