import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {fadeInOut, fadeInOutUp, fadeInOutUpStagged} from "../../../../kernel/animation";
import {Subject, Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import {getIdByValue, rnd} from "../../../../kernel/helpers/data.helper";
import {BehaviorService} from "../../services/behavior.service";
import {YesOrNoEnum} from "../../../../kernel/enum/yes-or-no.enum";
import {IDataSelectedHelper} from "../../../../kernel/models/common.models";

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged],
})
export class RadioComponent implements OnInit, OnDestroy, AfterViewInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() label: string;
  @Input() name = '';
  @Input() wrapperClass = '';
  @Input() hintBottom = '';
  @Input() parentForm?: FormGroup;
  @Input() state = false;
  @Input() isLink = false;
  @Input() selectData: IDataSelectedHelper[] = [];
  @Input() selectDataType = '';
  @Input() hint = '';
  @Input() hintShift = 0;
  @Input() inline = false;
  @Input() readonly = false;
  @Output() stateChange = new EventEmitter<boolean>();
  @Input() invalid = null;
  @Input() controlInvalid = null;
  @Input() nowrap = false;
  @Input() showRole = false;
  @Input() isRequired = false;

  id = rnd(10);
  wrapperId = `input_wrapper_${rnd(10)}`;

  subs = new Subscription();

  get isReactive(): boolean {
    return !!this.parentForm;
  }

  get value(): any {
    return this.isReactive ? this.parentForm.value[this.name] : this.state;
  }

  get currentControl(): any {
    return this.parentForm && this.parentForm.controls[this.name];
  }

  get errorCount(): number {
    return !!this.currentControl && !!this.currentControl.errors
      ? Object.keys(this.currentControl.errors).length
      : 0;
  }

  get apiErrorCount(): number {
    return !!this.currentControl && !!this.currentControl.apiErrors
      ? this.currentControl.apiErrors.length
      : 0;
  }

  get setInvalidClass(): boolean {
    return (
      (this.apiErrorCount > 0 || this.errorCount > 0) && this.controlInvalid
    );
  }

  constructor(private behavior: BehaviorService) {}

  ngOnInit() {
    if (!this.selectData || this.selectData.length === 0) {
      this.selectData = [
        { id: 'true', label: 'Да' },
        { id: 'false', label: 'Нет' },
      ];
    }
    this.wrapperClass = `radioInput-wrapper ${this.wrapperClass} ${
      this.hint ? 'input-wrapper-hint' : ''
    }`;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.checkHint();
    const id = getIdByValue(this.selectData, this.value);
    const radio = document.getElementById(`input_${this.id}_${id}`);
    if (radio) {
      radio.setAttribute('checked', '');
    }
  }

  checkHint(): void {
    if (this.hint) {
      this.behavior.inputHintDetectStatus.next(this.wrapperId);
    }
  }

  dataChange(): void {
    this.stateChange.emit(this.isReactive ? this.parentForm.value[this.name] : this.state);
  }

  isChecked(index: number): boolean {
    if (!this.currentControl) {
      return false;
    }
    if (
      String(this.currentControl.value).toUpperCase() === YesOrNoEnum.Yes.toUpperCase() ||
      String(this.currentControl.value).toUpperCase() === YesOrNoEnum.No.toUpperCase()
    ) {
      return (
        (/TRUE/i.test(String(this.currentControl.value).toUpperCase()) && index === 0) ||
        (/FALSE/i.test(String(this.currentControl.value).toUpperCase()) && index === 1)
      );
    }
    return this.currentControl.value === this.selectData[index].id;
  }

  changed(event: any): void {
    if (this.readonly) {
      return;
    }
    if (
      String(event).toUpperCase() === YesOrNoEnum.Yes.toUpperCase() ||
      String(event).toUpperCase() === YesOrNoEnum.No.toUpperCase()
    ) {
      this.parentForm.controls[this.name].setValue(/TRUE/i.test(String(event).toUpperCase()));
    } else {
      this.parentForm.controls[this.name].setValue(event);
    }
    this.stateChange.emit(this.parentForm.value[this.name]);
  }
}

