export enum PopupLoaderTypeEnum {
  ClientSearch = 'Поиск клиента',
  CalculateCommission = 'Расчет комиссии',
  ReCalculateCommission = 'Пересчет комиссии',
  CreateClient = 'Создание клиента',
  UpdateClient = 'Обновление клиента',
  CreateOperation = 'Создание операции',
  UpdateOperation = 'Обновление операции',
  ConfirmOperation = 'Подтверждение операции',
  OperationInfo = 'Получении информации об операции',
  BillingPrintProgress = 'Печать выбранных чеков',
  GetMtDynamicFields = 'Получение полей',
}
