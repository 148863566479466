import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {IMtDynamicFiledHelper} from "../../../../../kernel/mt-dynamic/models/mt-dynamic.models";
import {OperationBuilderService} from "../../../services/operation-builder.service";
import {FormControl, FormGroup, Validators, Validators as v} from "@angular/forms";
import {Guid} from "guid-typescript";

@Component({
  selector: 'app-operation-dynamic-data-item-step',
  templateUrl: './operation-dynamic-data-item-step.component.html',
  styleUrls: ['./operation-dynamic-data-item-step.component.scss', '../../mts-operation/mts-operation.component.scss']
})
export class OperationDynamicDataItemStepComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() fields: IMtDynamicFiledHelper[] | undefined = [];
  @Input() typeArrayId: string | undefined;

  constructor(
    private builder: OperationBuilderService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
