import { Pipe, PipeTransform } from '@angular/core';
import {convertPhone} from "../helpers/user.helper";

@Pipe({
  name: 'phonePipe'
})
export class PhonePipe implements PipeTransform {

  transform(phone: any): any {
    return convertPhone(phone);
  }
}
