import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ApiClient,
  IClient,
  IFindTransfersResponse,
} from '../../../../kernel/ApiClient';
import {NotifyService} from '../../../core/services/notify.service';
import {errorMessages} from '../../../../kernel/constants/errors';
import {IClientDocument, IDataSelectedHelper} from '../../../../kernel/models/common.models';
import {getFio} from "../../../../kernel/helpers/data.helper";

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  isSubmitted = false;
  isFromHistory = false;
  docInfo = [];
  client: IClient;
  history: IFindTransfersResponse;
  document: IClientDocument;
  availableOperations: IDataSelectedHelper[] = [];
  // title = 'Выберите операцию из списка ниже';
  title = 'Найден клиент';

  operationForm: FormGroup;
  operationId: FormControl;

  constructor(
    public dialogRef: MatDialogRef<ClientsListComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      client: IClient;
      history: IFindTransfersResponse;
      document: IClientDocument;
      isFromHistory: boolean
    },
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {
    this.client = data.client;
    this.history = data.history;
    this.document = data.document;
    this.isFromHistory = data.isFromHistory;
    this.initDocInfo();
  }

  get clientName(): string {
    return this.client ? getFio(this.client.lastName, this.client.firstName, this.client.middleName) : '';
  }

  get emptyOperations(): boolean {
    return !this.availableOperations || this.availableOperations.length === 0;
  }

  get isOperationChoose(): boolean {
    return (
      !!this.operationForm &&
      ((!!this.operationForm.value.OperationId && !this.emptyOperations) ||
        this.emptyOperations)
    );
  }

  get clientPhone(): string {
    return !!this.client &&
      !!this.client.phoneNumbers &&
      !!this.client.phoneNumbers[0]
      ? this.client.phoneNumbers[0].number
      : '';
  }

  get clientDocumentTitle(): string {
    return this.document.series
      ? `${this.document.series} ${this.document.number}`
      : `${this.document.number}`;
  }

  ngOnInit() {
    this.availableOperations = [];
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initDocInfo(): void {
    this.setDocInfo(
      `Есть совпадения по: ${this.clientDocumentTitle}`,
      `../../assets/img/icons/exclamation.svg`
    );
    return;
    this.setDocInfo(
      `Необходимо выбрать операцию`,
      `../../assets/img/icons/check-mark.svg`
    );
    this.setDocInfo(
      `Это сэкономит 4 минуты вашего времени`,
      `../../assets/img/icons/smile.svg`
    );
  }

  setDocInfo(title: string, icon: string): void {
    this.docInfo.push({title, icon});
  }

  createFormControl(): void {
    this.availableOperations.push({
      id: 'new-operation',
      label: 'Новый получатель'
    });
    this.history.history.map(x => {
      this.availableOperations.push({
        id: x.transferId,
        label:
        getFio(x.beneficiary.lastName, x.beneficiary.firstName, x.beneficiary.middleName) +
          ' - ' +
          String(x.money.withdrawMoney.amount) +
          ' ' +
          x.money.withdrawMoney.currencyCode,
      });
    });
    this.operationId = new FormControl('new-operation');
    this.operationForm = new FormGroup({
      OperationId: this.operationId,
    });
  }

  setOperation(): void {
    this.isSubmitted = true;
    if (!this.isOperationChoose) {
      this.notify.error(errorMessages.chooseOperationFromList);
      return;
    }
    this.close(true);
  }

  close(isConfirm: boolean): void {
    if (!this.history || !this.history.history) {
      return this.dialogRef.close({
        operation: null,
        empty: this.emptyOperations,
        isConfirm,
      });
    }
    const index = this.history.history.findIndex(
      x => x.transferId === this.operationForm.value.OperationId
    );
    this.dialogRef.close({
      operation: index > -1 ? this.history.history[index] : null,
      empty: this.emptyOperations,
      isConfirm,
    });
  }
}
