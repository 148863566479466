import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IHardwareData} from "../../../kernel/ApiClient";

@Injectable({
  providedIn: 'root',
})
export class HardwareService {
  public hardware: IHardwareData;

  constructor(
    private http: HttpClient
  ) {
  }

  getHardwareInfo(mtsAgentUrlLink: string): Observable<IHardwareData> {
    return this.http.get(`${mtsAgentUrlLink}/Hardware`);
  }

  getServiceVersionInfo(mtsAgentUrlLink: string): Observable<any> {
    return this.http.get(`${mtsAgentUrlLink}/Version`)
  }
}
