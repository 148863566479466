<div class="client-search-wrapper p-relative" *ngIf="currentStep === 1" [formGroup]="operationHelperGroup" [ngClass]="{'disabled': !isClientNeed}">
  <app-input name="OperationClientDocumentNumber"
             label="Серия и номер"
             placeholder="Серия и номер"
             inputType="text"
             [parentForm]="operationHelperGroup"
             [readonly]="!isClientNeed"
             autocomplete="off"
             wrapperClass="document-number-wrapper full"></app-input>
  <p class="client-search-info">Серию и номер паспорта писать слитно, без пробелов</p>
  <div class="client-transfer-history-title" (click)="openClientDialog()" [ngClass]="{'active': client || showClientBtn}">{{client ? 'История переводов' : 'Найти клиента'}}</div>
  <p class="text-info" [style.margin.px]="0">
    Если вы введете здесь серию и номер документа клиента,
    который уже пользовался нашим сервисом,
    мы автоматически заполним все его данные для вашего удобства
  </p>
  <div class="client-edit-btn-wrapper" [ngClass]="{'one': !client, 'two': client}">
    <div class="client-edit-btn" (click)="isClientNeed && builder.helper.openClientEditDialog()" [ngClass]="{'disabled': !isClientNeed}">{{clientName}}</div>
    <div class="client-edit-btn" (click)="isClientNeed && builder.helper.createAnotherClient()" *ngIf="client" [ngClass]="{'disabled': !isClientNeed}">Создать нового клиента</div>
  </div>
</div>

<div class="client-disable-msg-wrapper" *ngIf="operationType.isMinClientSumActive && !isTransfer">
  <div class="client-disable-msg">
    Обратите внимание, что <strong> необходимо указывать</strong> <strong class="color-red"> клиента</strong> для суммы платежа
    <strong> от</strong> <strong class="color-red"> {{operationType.minClientSum | number: "1.2":"ru"}} RUB</strong>.
  </div>
</div>
