<div class="operation-stepper-wrapper" id="operation_stepper_wrapper">
  <div class="operation-stepper">
    <p class="operation-info-title">{{title}}</p>
    <div class="operation-img-wrapper">
      <img [src]="logo">
    </div>
    <ol class="operation-stepper-list full">
      <li *ngFor="let item of operationSteps; let i=index" [ngClass]="{
            'operation-stepper-item': true,
            'active': currentStep === item.step,
            'filled': currentStep > item.step
          }" (click)="goto(item.step)">
          <span class="operation-stepper-link" [ngClass]="{'last-step': i === operationSteps.length - 1}">
            <span class="operation-stepper-title">
              {{ item.title }}
            </span>
          </span>
      </li>
    </ol>
  </div>
</div>
