import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Guid} from "guid-typescript";
import {FormControl, FormGroup} from "@angular/forms";
import {NotifyService} from "../../../../core/services/notify.service";
import {debounceTime, map, take, takeUntil} from "rxjs/operators";
import {Select, Store} from "@ngxs/store";
import {createPaginator} from "../../../../../kernel/helpers/paginator.helper";
import {getSelectedLabelForNewAvailable, getSelectId, getUserRole} from "../../../../../kernel/helpers/data.helper";
import {
  ApiClient,
  IPaginatorRequest,
  IUserFilterRequest, IUserFilterResponse, IUserResponse,
  PaginatorRequest, UserAvailableDataRequest,
  UserFilterEnum,
  UserFilterRequest, UserRoleEnum
} from "../../../../../kernel/ApiClient";
import {UserState} from "../../../../../kernel/store/state/user.state";
import {DictionaryState} from "../../../../../kernel/store/state/dictionary.state";
import {PaginatorComponent} from "../../../../core/pages/paginator/paginator.component";
import {UserAvailableDataEnum} from "../../../../../kernel/enum/user-available-data";
import {IDataSelectedHelper} from "../../../../../kernel/models/common.models";
import {BehaviorService} from "../../../../core/services/behavior.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss', '../../settings/settings.component.scss']
})

export class UsersComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getUserRoles) availableUserRoles$: Observable<IDataSelectedHelper[]>;
  @ViewChild('paginatorComp') paginatorComp: PaginatorComponent;
  @Input() id: string

  availableUserRoles: IDataSelectedHelper[] = [];
  availableOrganizations: IDataSelectedHelper[] = [];
  availableOrganizationPoints: IDataSelectedHelper[] = [];

  subs = new Subscription();
  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;
  requestSub: any;

  userResponse: IUserFilterResponse;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  searchForm: FormGroup;

  searchType = 0;

  columns = UserFilterEnum;
  sortField: UserFilterEnum = UserFilterEnum.FullName;
  isDesc = true;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  get isNeedFilterReset(): boolean {
    return this.searchForm && (
      !!this.searchForm.value.Search ||
      !!this.searchForm.value.OnlyActive ||
      !!this.searchForm.value.OrganizationId ||
      !!this.searchForm.value.OrganizationPointId ||
      !!this.searchForm.value.Roles
    )
  }

  get organizationId(): string {
    const index = this.availableOrganizations.findIndex(x => x.label === this.searchForm.value.OrganizationId);
    return index > -1 ? this.availableOrganizations[index].id : null;
  }
  get organizationPointId(): string {
    const index = this.availableOrganizationPoints.findIndex(x => x.label === this.searchForm.value.OrganizationPointId);
    return index > -1 ? this.availableOrganizationPoints[index].id : null;
  }

  ngOnInit() {
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
    }));
    this.subs.add(this.availableUserRoles$.subscribe(availableUserRoles => {
      this.availableUserRoles = availableUserRoles;
    }));
    this.paginator = createPaginator(5);
    this.createFormControls();
    this.getFilteredUsers();
    this.behavior.settingExpandedChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (!!data) {
          if (data != this.id) {
            this.expanded = false;
          }
        }
      });
  }

  createFormControls(): void {
    this.searchForm = new FormGroup({
      Search: new FormControl(null),
      OnlyActive: new FormControl(false),
      OrganizationId: new FormControl(null),
      OrganizationPointId: new FormControl(null),
      Roles: new FormControl(null),
    });

    this.searchForm.controls.OrganizationPointId.disable();

    this.searchForm.controls.OrganizationId.valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.OrganizationPoint,
            organizationId: this.organizationId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableOrganizationPoints = arr as IDataSelectedHelper[];
              this.searchForm.controls.OrganizationPointId.enable();
              this.searchForm.controls.OrganizationPointId.setValue(null);
            });
        } else {
          this.availableOrganizationPoints = [];
          this.searchForm.controls.OrganizationPointId.disable();
        }
      });
  }

  resetAll(): void {
    this.isDesc = true;
    this.paginator.page = 1;
    this.searchForm.controls.Search.setValue(null);
    this.searchForm.controls.OnlyActive.setValue(false);
    this.searchForm.controls.OrganizationId.setValue(null);
    this.searchForm.controls.OrganizationPointId.setValue(null);
    this.searchForm.controls.Roles.setValue(null);
    this.searchForm.controls.OrganizationPointId.disable();
    this.getFilteredUsers();
  }

  ngOnDestroy(): void {
    this.checkRequestSub();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  valueChangeListener(): void {
    this.searchForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe),
        debounceTime(500))
      .subscribe(data => {
        this.getFilteredUsers();
      })
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false
      this.getFilteredUsers()
    }
    if (this.expanded) {
      this.behavior.settingExpandedStatus.next(this.id);
    }
  }

  modalClosed(event: any) {
    if (!!event) {
      this.getFilteredUsers();
    }
  }

  getFilteredUsers(): void {
    this.checkRequestSub()
    const roles = getSelectId(this.searchForm.value.Roles, this.availableUserRoles, true) as string[];
    const rolesArr = [];
    roles.map(x => {
      rolesArr.push(UserRoleEnum[getUserRole(x)]);
    });
    const payload: IUserFilterRequest = {
      paginator: this.paginator as PaginatorRequest,
      column: this.sortField,
      isDesc: this.isDesc,
      query: this.searchForm.value.Search,
      onlyActive: this.searchForm.value.OnlyActive,
      organizationId: this.organizationId,
      organizationPointId: this.organizationPointId,
      roles: rolesArr
    };
    this.requestSub = this.apiClient.users_GetFilteredUsers(payload as UserFilterRequest).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.userResponse = data as IUserFilterResponse;
      this.paginatorComp.initType(this.userResponse?.totalCount ?? 0);
    }, error => {
      this.notify.error(error.message)
      console.error(error)
    });
    if (this.valueChangeFirst) {
      this.valueChangeFirst = false
      this.valueChangeListener()
    }
  }

  userChanged(): void {
    this.getFilteredUsers();
  }

  setSort(type: UserFilterEnum): void {
    this.isDesc = this.sortField !== type ? false : !this.isDesc
    this.sortField = type
    this.getFilteredUsers();
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredUsers();
  }
}
