import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IKkmResponseData, IUnitListResponse, kkmOperationType} from '../../../kernel/models/billing/kkm.models';
import {BillingVariablesService} from "./billing-variables.service";
import {take, takeUntil} from "rxjs/operators";
import {
  ApiClient,
  BillingTypeEnum,
  CreateBillingRequest,
  ICreateBillingRequest,
  ICreatePreBillRequest
} from "../../../kernel/ApiClient";
import {NotifyService} from "../../core/services/notify.service";
import {getBillTitle, getErrorMessage} from "../../../kernel/helpers/data.helper";
import {isDevEnv, isLocalEnv} from "../../../kernel/helpers/env.helper";
import {BehaviorService} from "../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {BillSourceEnum} from "../../../kernel/enum/billSourceEnum.enum";

@Injectable({
  providedIn: 'root',
})
export class KkmService {
  constructor(
    private apiClient: ApiClient,
    private http: HttpClient,
    private notify: NotifyService,
    private variables: BillingVariablesService,
    private behavior: BehaviorService
  ) {
  }

  getListOfCashbox(): Observable<IUnitListResponse> {
    return this.http.post(this.variables.kkmUrl,
      {
        Command: kkmOperationType.List,
        NumDevice: 0,
        InnKkm: null,
        Active: null,
        OnOff: null,
        OFD_Error: null,
        OFD_DateErrorDoc: null,
        FN_DateEnd: null,
        FN_MemOverflowl: null,
        FN_IsFiscal: null
      }) as Observable<IUnitListResponse>;
  }

  checkQueueStatus(): void {
    this.variables.isWorkLikeKkmQueueIsLoad = true;
    if (this.variables.isWorkLikeKkmQueueSub) {
      this.variables.isWorkLikeKkmQueueSub.unsubscribe();
    }
    this.variables.isWorkLikeKkmQueueSub = this.getListOfCashbox()
      .pipe(take(1))
      .subscribe(data => {
        this.variables.isWorkLikeKkmQueueIsLoad = false;
        this.variables.isWorkLikeKkmQueue = data?.ListUnit?.length <= 0;

      }, error => {
        this.variables.isWorkLikeKkmQueueIsLoad = false;
        this.variables.isWorkLikeKkmQueue = true;
        console.error(error);
      });
  }

  // Создать запрос в ккм
  createRequestToKKM(payload: any, kkmUrl: string): Observable<any> {
    return this.http.post(kkmUrl, payload)
  }

  // Создаем чек и предчек
  printBill(
    bill: any,
    helper: ICreateBillingRequest
  ): void {
    this.apiClient.billing_CheckBillForPrint(helper as CreateBillingRequest)
      .pipe(take(1))
      .subscribe(data => {
        this.http.post(this.variables.kkmUrl, bill.payload)
          .subscribe(res => {
            const response = res as IKkmResponseData
            this.initRezult(response, bill.payload.idCommand, helper)
          }, error => {
            this.notify.error(getErrorMessage(error));
            console.error(error);
          });
      }, error => {
        this.notify.error(getErrorMessage(error));
      });
  }

  // Инициализаця результата выполнения операции
  initRezult(response: IKkmResponseData, id: string, helper: ICreateBillingRequest): void {
    if (isLocalEnv() || isDevEnv()) {
      this.devCheck(response, id, helper);
    } else {
      this.prodCheck(response, id, helper);
    }
  }

  prodCheck(response: IKkmResponseData, id: string, helper: ICreateBillingRequest): void {
    if (response.Status === 0) {
      this.notify.success(`${getBillTitle(helper.type)} успешно распечатан`);
      if (helper.type === BillingTypeEnum.TestCheck) {
        return;
      }
      this.apiClient.billing_CreateBilling(helper as CreateBillingRequest)
        .pipe(take(1))
        .subscribe(data => {
          this.finalizeOperationBill(helper);
        }, error => {
          this.notify.error(getErrorMessage(error));
        });
      return;
    }
    if (response.Status === 1 || response.Status === 4) {
      this.notify.warning(response.Error);
      this.checkRezult(id, kkmOperationType.GetRezult, helper);
      return;
    }
    this.notify.error(response.Error);
  }

  devCheck(response: IKkmResponseData, id: string, helper: ICreateBillingRequest): void {
    this.notify.success(`${getBillTitle(helper.type)} успешно распечатан`);
    if (helper.type === BillingTypeEnum.TestCheck) {
      return;
    }
    this.apiClient.billing_CreateBilling(helper as CreateBillingRequest)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.finalizeOperationBill(helper);
      }, error => {
        this.notify.error(getErrorMessage(error));
      });
  }

  finalizeOperationBill(helper: ICreateBillingRequest): void {
    if (helper.isQueue) {
      this.variables.toPrint.splice(0, 1);
      this.behavior.getQueueStatus.next(true);
      this.behavior.operationBuilderCommandStatus.next({
        type: BuilderCommandEnum.PrintNextInQueueToPrint
      });
    }
    this.notify.info('Сделана запись о печати');
    if (this.variables.source === BillSourceEnum.OperationPage) {
      this.behavior.operationBuilderCommandStatus.next({
        type: BuilderCommandEnum.PrintNextInQueueToPrint,
        model: { type: helper.type }
      });
    }
  }

  // Проверка результата операции
  checkRezult(id: string, command: string, helper: ICreateBillingRequest): void {
    const request = {
      Command: command,
      IdCommand: id
    }
    this.http.post(this.variables.kkmUrl, request)
      .subscribe(res => {
        const response = res as IKkmResponseData;
        this.initRezult(response, id, helper);
      }, error => {
        this.notify.error(getErrorMessage(error));
        console.error(error)
      })
  }
}
