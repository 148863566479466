import { NotifyMessageService } from 'src/modules/core/services/notify-message.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {IBroadcastMessage, IHeaderNotify} from "../../../../kernel/models/common.models";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {ApiClient, IUserResponse} from "../../../../kernel/ApiClient";
import * as moment from "moment";
import {navItems} from "../../../../kernel/constants/common.constants";
import {Navigate} from "@ngxs/router-plugin";
import {NotifyService} from "../../services/notify.service";
import {takeUntil} from "rxjs/operators";
import {OnLogout, ResetUser} from "../../../../kernel/store/actions/user.actions";
import {isExpectedError} from "../../../../kernel/helpers/data.helper";
import {errorMessages} from "../../../../kernel/constants/errors";
import {BroadcastMessage, BroadcastMessageEnum} from "../../../../kernel/enum/broadcast-message.enum";
import {timer} from "rxjs/internal/observable/timer";
import {BehaviorService} from "../../services/behavior.service";
import {getTimeAgo} from '../../../../kernel/helpers/notify.helper';

@Component({
  selector: 'app-notify-menu',
  templateUrl: './notify-menu.component.html',
  styleUrls: ['./notify-menu.component.scss']
})
export class NotifyMenuComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() isSticky: boolean;
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;

  subs = new Subscription();
  year: number;
  logos = {
    usual: '/assets/img/nav/nabix-logo-big.svg',
    newYear: '/assets/img/nav/nabix-new-year-logo-small.svg',
  }

  isAuthorized = false;
  timerStopped = true;
  timerSubscribe: any;

  constructor(
    private notifyMessages: NotifyMessageService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient,
    private behavior: BehaviorService
  ) { }

  get notifies(): IHeaderNotify[] {
    return this.notifyMessages.notifies;
  }

  get notifyOpen(): boolean {
    return this.notifyMessages.notifyOpen;
  }

  get notifyEmpty(): boolean {
    return !this.notifies || this.notifies.length === 0;
  }

  ngOnInit(): void {
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => { this.isAuthorized = isAuthorized }));
    this.initTimer();
    this.year = moment().year();
    this.behavior.removeBroadcastMessagesByTypeChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.removeNotifyByType(data);
      });
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]));
    this.notifyMessages.notifyOpen = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isExistInNotify(id: string): boolean {
    if (this.notifyEmpty) {
      return false;
    }
    return this.notifies.findIndex(x => x.id === id) > -1;
  }

  analyzeBroadcastMessage(data: IBroadcastMessage): void {
    if (!data) {
      return;
    }
    if (data.type === BroadcastMessage.Logout ||
      data.typeStr === BroadcastMessage.Logout) {
      this.store.dispatch(new ResetUser());
      this.store.dispatch(new Navigate(['/auth/login']));
    }
    if (this.isExistInNotify(data.id)) {
      return;
    }
    this.initTimer();
    if (data.type === BroadcastMessage.TemporaryPassword ||
      data.typeStr === BroadcastMessage.TemporaryPassword) {
      this.notifies.unshift({
        id: data.id,
        icon: 'settings.svg',
        title: data.title,
        body: data.body,
        created: data.created,
        timeAgo: '',
        url: 'settings',
        type: BroadcastMessageEnum.TemporaryPassword,
        typeStr: BroadcastMessage.TemporaryPassword
      });
    }
    this.notifyMessages.notifyOpen = true;
  }

  removeNotifyByType(type: string): void {
    if (this.notifyEmpty || !type) {
      this.notifyMessages.notifyOpen = false;
      return;
    }
    const index = this.notifies.findIndex(x => x.type === type || x.typeStr === type);
    if (index < 0) {
      return;
    }
    this.notifies.splice(index, 1);
    this.removeNotifyByType(type);
  }

  onLogout(): void {
    this.store.dispatch(new OnLogout());
    this.notifyMessages.notifyOpen = false;
  }

  getTimeAgo(): string {
    if (this.notifyEmpty) {
      this.stopTimer();
      return;
    }
    const dateNow = moment();
    this.notifies.map(x => {
      x.timeAgo = getTimeAgo(dateNow, x.created);
    });
  }

  initTimer(): void {
    if (this.timerStopped === false) { return }
    this.timerStopped = false
    this.timerSubscribe = timer(0, 30 * 1000).subscribe(t => {
      this.getTimeAgo();
    });
  }

  stopTimer(): void {
    if (this.timerStopped === false) {
      this.timerSubscribe.unsubscribe()
      this.timerStopped = true
    }
  }
}
