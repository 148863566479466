<div class="expand" [ngClass]="{'open': expanded}" id="userSettings">


  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/settings.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">НАСТРОЙКИ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-title">Настройки пользователя</p>

    <div class="menu-nav-wrapper">
      <div *ngFor="let item of navs; let i=index"
           class="menu-nav-item" (click)="changeType(i)"
           [ngClass]="{'active': type === i}">
        {{item}}
      </div>
    </div>

    <form class="user-settings-wrapper" [formGroup]="settingForm" autocomplete="off">

      <ng-container *ngIf="type === 0"></ng-container>
      <ng-container *ngIf="type === 1">
        <app-input
          inputType="password"
          placeholder="Новый пароль"
          label="Новый пароль"
          name="NewPassword"
          wrapperClass="inputBox-group half-left-user-setting"
          autocomplete="new-password"
          hint="Минимальная длина пароля - 6 символов"
          [controlInvalid]="submitted"
          [parentForm]="settingForm"></app-input>

        <app-input
          inputType="password"
          placeholder="Подтверждение нового пароля"
          label="Подтверждение нового пароля"
          name="ReNewPassword"
          wrapperClass="inputBox-group half-right-user-setting"
          autocomplete="new-password"
          hint="Минимальная длина пароля - 6 символов"
          [controlInvalid]="submitted"
          [parentForm]="settingForm"></app-input>
      </ng-container>

      <div class="inputBox-group half-left-user-setting temp-settings"></div>
      <app-input
        inputType="password"
        placeholder="Пароль"
        label="Пароль"
        name="OldPassword"
        wrapperClass="inputBox-group half-right-user-setting"
        autocomplete="new-password"
        [controlInvalid]="submitted"
        [parentForm]="settingForm"></app-input>

      <div class="t-align-center mt-50 pb-30">
        <button class="button main"
                [ngClass]="{'disabled': !!settingForm.invalid || !isNewPasswordCompared}"
                (click)="updateUserInfo()">Обновить</button>
      </div>
    </form>
  </div>
</div>
