import {Injectable} from '@angular/core';
import {IControlHelper} from "../../../kernel/models/common.models";
import {Validators as v} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class OperationFieldBuilderService {

  constructor(
  ) {
  }

  public cardField(): IControlHelper {
    return {
      isInput: true,
      isCard: true,
      inputType: `text`,
      placeholder: `Номер карты`,
      label: `Номер карты`,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      mask: '0000 0000 0000 0000',
      validators: [v.required, v.minLength(16), v.maxLength(16)],
    };
  }

  public accountField(label: string): IControlHelper {
    return {
      isInput: true,
      inputType: `text`,
      placeholder: label,
      label,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      validators: [v.required],
    };
  }

  public agreementField(): IControlHelper {
    return {
      isInput: true,
      inputType: `text`,
      placeholder: `Номер договора`,
      label: `Номер договора`,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      validators: [v.required],
    };
  }

  public phoneField(maskPrefix: string, mask: string): IControlHelper {
    return {
      isInput: true,
      inputType: `tel`,
      placeholder: `Телефон`,
      label: `Телефон`,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      autocomplete: `on`,
      maskPrefix,
      mask,
      value: null,
      validators: [v.required],
    };
  }

  public phoneOrAccountField(): IControlHelper {
    return {
      isInput: true,
      inputType: `text`,
      placeholder: `Номер телефона или лицевого счета`,
      label: `Номер телефона или лицевого счета`,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      validators: [v.required],
    };
  }

  public phoneOrAccountYotaField(): IControlHelper {
    return {
      isInput: true,
      inputType: `text`,
      placeholder: `Номер телефона или лицевого счета`,
      label: `Номер телефона или лицевого счета`,
      name: `id1`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      validators: [v.required, v.minLength(10), v.maxLength(17)],
    };
  }

  public cardDateField(): IControlHelper {
    return {
      isInput: true,
      inputType: `picker`,
      placeholder: `Валидна до`,
      label: `Валидна до`,
      name: `cardExpiry`,
      wrapperClass: `operation-half-item-right`,
      value: null,
      validators: [v.required],
    };
  }

  public payerNameField(): IControlHelper {
    return {
      isInput: true,
      inputType: `text`,
      placeholder: `ФИО`,
      label: `ФИО`,
      name: `payerName`,
      wrapperClass: `operation-half-item-left`,
      value: null,
      validators: [v.required],
    };
  }
}
