import { SetOperationCryptoKeys } from './../../../../kernel/store/actions/dictionary.actions';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {UserState} from '../../../../kernel/store/state/user.state';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {
  GetAvailableData,
  ResetDictionaries,
  SetCountries, SetCountriesAddresses,
  SetCurrencies, SetDbCountries, SetOperationTypes, SetOrganizationTypes, SetPaymentTypes,
  SetServices,
  SetTimeZones,
  SetUserRoles,
} from '../../../../kernel/store/actions/dictionary.actions';
import {catchError, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {ApiClient, IUserResponse, HardwareData, UserLoginSsoRequest} from '../../../../kernel/ApiClient';
import {BehaviorService} from '../../../core/services/behavior.service';
import {
  ResetUser,
  SetAgent, SetPcParam,
  SetRoute,
  SetScreenWidth, SetToken, SetUser,
} from '../../../../kernel/store/actions/user.actions';
import {UserAvailableDataEnum} from '../../../../kernel/enum/user-available-data';
import {getErrorMessage, isExpectedError} from '../../../../kernel/helpers/data.helper';
import {errorMessages} from '../../../../kernel/constants/errors';
import {NotifyService} from '../../../core/services/notify.service';
import {MatDialog} from '@angular/material/dialog';
import {TemporaryPasswordComponent} from '../../../core/dialogs/temporary-password/temporary-password.component';
import {ActivatedRoute, NavigationStart, Router, RouterEvent} from '@angular/router';
import * as signalR from '@aspnet/signalr';
import {environment} from '../../../../kernel/environments/environment';
import {IBroadcastMessage, IDictionaries} from '../../../../kernel/models/common.models';
import {BroadcastMessage} from '../../../../kernel/enum/broadcast-message.enum';
import {HeaderComponent} from '../../../core/pages/header/header.component';
import {Guid} from 'guid-typescript';
import * as moment from 'moment';
import {messages} from '../../../../kernel/constants/messages';
import {HubConnection} from '@aspnet/signalr';
import {HardwareService} from "../../../core/services/hardware.service";
import {defaultUrls} from "../../../../kernel/constants/common.constants";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {OperationBuilderService} from "../../../mts-operation/services/operation-builder.service";
import {GlobalBuilderService} from "../../../core/services/global-builder.service";
import {isLocalEnv} from "../../../../kernel/helpers/env.helper";
import {StaticDictionariesService} from "../../../core/services/static-dictionaries.service";
import {MtDynamicService} from "../../../../kernel/mt-dynamic/services/mt-dynamic.service";
import {mockMTDynamicResponse} from "../../../../kernel/mt-dynamic/services/mt-dynamic.service.spec";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.isBPARole) isBPARole$: Observable<boolean>;
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getDictionaries) selectedDataAll$: Observable<IDictionaries>;

  @ViewChild('header') headerComp: HeaderComponent;

  subs = new Subscription();
  title = 'MTSWebClient';
  screenWidth = 0;

  // notificationConnection: signalR.HubConnection;
  private notificationConnection: HubConnection;

  user: IUserResponse;
  isRequestSendToTemporary = false;
  isRequestSendToHub = false;
  temporaryActive = false;
  isAuthorized = false;
  isBPARole = false;

  @HostListener('window:resize')
  onResize(): void {
    setTimeout(() => {
      this.initScreen(window.innerWidth);
      this.initCenter();
      this.store.dispatch(new SetAgent(navigator.userAgent));
      this.checkOperationBars();
    }, 100);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.checkOperationBars();
  }

  get token(): string {
    return UserState.localRememberMeStatus
      ? UserState.localToken
      : UserState.sessionToken || '';
  }

  constructor(
    private builder: GlobalBuilderService,
    private apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService,
    private notify: NotifyService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private hardwareService: HardwareService,
    private staticDictionariesService: StaticDictionariesService,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((val: RouterEvent) => {
        this.store.dispatch(new SetRoute(val.url));
      });
    this.behavior.initUserDataChange$
      .subscribe(data => {
        if (!!data) {
          this.initUserData();
        }
      })
  }

  initUserData(): void {
    this.isAuthorized = !!this.token && !!this.user && !!this.user.id;
    if (this.isAuthorized) {
      this.initSignalR();
      this.staticDictionariesService.getStaticDictionaries(null);
      this.checkTemporary();
    } else {
      this.staticDictionariesService.isRequestSendToDictionary = false;
      this.isRequestSendToTemporary = false;
      this.isRequestSendToHub = false;
      this.store.dispatch(new ResetDictionaries());
      setTimeout(() => {
        this.initCenter();
      }, 100);
    }
  }

  ngOnInit(): void {
    this.staticDictionariesService.staticDictionaryListener();
    this.builder.commandListener();
    this.subs.add(this.isBPARole$.subscribe(isBPARole => { this.isBPARole = isBPARole }));
    this.subs.add(this.selectedDataAll$.subscribe(selectedDataAll => {
      this.builder.operation.variables.selectedDataAll = selectedDataAll;
    }));
    this.setHardware();
    this.subs.add(
      this.user$.subscribe(user => {
        this.user = user;
        this.initUserData();
      })
    );
    setTimeout(() => {
      this.initCenter();
      this.initScreen(window.innerWidth);
    }, 1000);
    this.behavior.initCenterChange$.subscribe(data => {
      if (data) {
        this.initCenter();
      }
    });
    this.behavior.inputHintDetectChange$.subscribe(wrapperId => {
      if (wrapperId) {
        setTimeout(() => {
          this.setHintStyle(
            document.getElementById('hint-main-wrapper'),
            document.getElementById(wrapperId),
            wrapperId
          );
        }, 100);
      }
    });
  }

  initScreen(screenWidth: number): void {
    this.store.dispatch(new SetScreenWidth(screenWidth));
    this.screenWidth = screenWidth;
    this.initHints();
  }

  checkOperationBars(): void {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const temp = document.getElementById(`operation_main_wrapper`);
    this.setOperationMargin(`operation_stepper_wrapper`, top, temp);
    this.setOperationMargin(`operation_commission_wrapper`, top, temp);
  }

  setOperationMargin(id: string, top: number, temp: HTMLElement): void {
    const el = document.getElementById(id);
    if (!el || !temp) {
      return;
    }
    const margin =
      window.innerHeight >= el.offsetHeight
        ? top
        : top + Number(window.innerHeight) - el.offsetHeight - 80;
    // if (margin + el.offsetHeight <= temp.offsetHeight) {
    //   margin = margin - (margin + el.offsetHeight - temp.offsetHeight)
    // }
    // if (id === 'operation_stepper_wrapper') {
    //   console.log({margin, elH: el.offsetHeight, winH: temp.offsetHeight})
    // }
    if (margin + Number(el.offsetHeight) <= temp.offsetHeight || margin === 0) {
      el.setAttribute('style', margin > 0 ? `margin-top: ${margin}px` : '');
    }
  }

  initCenter(): void {
    const arr = [
      {wrapper: 'auth', el: 'auth_right_content'},
      {wrapper: 'support_page', el: 'support_wrapper'},
    ];
    const mainContainer = document.getElementById('main_container');
    const mainContainerHeight = mainContainer ? mainContainer.offsetHeight : 0;
    arr.map(x => {
      const wrapper = document.getElementById(x.wrapper);
      const el = document.getElementById(x.el);
      if (!!wrapper && !!el) {
        const wrapperHeight = wrapper.offsetHeight;
        const elHeight = el.offsetHeight as number;
        let newStyleEl = '';
        if (
          wrapperHeight > elHeight + 10 ||
          mainContainerHeight > elHeight + 10
        ) {
          newStyleEl =
            'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);';
        }
        el.setAttribute('style', newStyleEl);
      }
    });
  }

  initHints(): void {
    const wrappersHint = document.getElementsByClassName('input-wrapper-hint');
    if (!wrappersHint || wrappersHint.length === 0) {
      return;
    }
    const wrapperEl = document.getElementById('hint-main-wrapper');
    for (let i = 0; i <= wrappersHint.length - 1; i++) {
      this.setHintStyle(
        wrapperEl,
        wrappersHint[i] as HTMLElement,
        wrappersHint[i].getAttribute('id')
      );
    }
  }

  setHintStyle(
    tempWrapperEl: HTMLElement,
    el: HTMLElement,
    wrapperId: string
  ): void {
    if (!el) {
      return;
    }
    // Получим координаты элемента для подсказки
    // const wrapperElAll = tempWrapperEl || el;
    // const widthWrapper = el.offsetWidth;
    const heightWrapper = el.offsetHeight as number;
    // // Получим границы элемента с тегом body
    // const bodyRect = document.body.getBoundingClientRect();
    // Получим границы элемента wrapper
    const elemRectWrapper = el.getBoundingClientRect();
    // const elemRectItem = wrapperElAll.getBoundingClientRect();
    // // Полуми координаты элемента wrapper по смещению
    // const offsetY = elemRectItem.bottom;
    const offsetX = elemRectWrapper.right as number;
    // Получим элемент hint
    const hintEl = document.getElementById(`hint_${wrapperId}`) as HTMLElement;
    const wrapperEl = document.getElementById(`${wrapperId}`);
    // Получим смещение
    let shift = Number(hintEl.getAttribute('hintShift'));
    shift = !!shift || shift > -1 ? shift : 0;
    if (!hintEl) {
      return;
    }
    // Получим высоту элемента hint
    const heightHint =
      hintEl.offsetHeight === 0 ? 30 : (hintEl.offsetHeight as number);
    const heightLabel = wrapperEl.getElementsByTagName('label')[0]
      .offsetHeight as number;
    const yMargin = (heightWrapper - heightHint + heightLabel) / 2;
    // Получаем стрелочку
    const arrowEl = document.getElementById(`arrow_hint_${wrapperId}`);
    // Определяем стили
    // const oldStyleEl = hintEl.getAttribute('style');
    // const oldStyleArrow = hintEl.getAttribute('style');
    let newStyleEl: string;
    let newStyleArrow: string;
    let newStyleWrapper: string;
    const dialog = document.getElementById(`dialog_input_wrapper`);
    if (offsetX + 270 > this.screenWidth || dialog) {
      if (el.offsetTop > Number(hintEl.offsetHeight) + 10) {
        newStyleEl = `position: absolute; width: 100%; top: ${
          0 - heightHint
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }px; max-width: ${elemRectWrapper.width}px; display: block;`;
        newStyleArrow = `margin: 0 auto; bottom: -5px; left: 0px;`;
      } else {
        newStyleEl = `position: absolute; width: 100%; bottom: ${
          0 - (Number(hintEl.offsetHeight) + 10)
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }px; max-width: ${elemRectWrapper.width}px; display: block;`;
        newStyleArrow = `margin: 0 auto; top: -5px; left: 0px;`;
      }
      newStyleWrapper = `position: relative;`;
    } else {
      newStyleEl = `position: absolute; top: ${
        Number(el.offsetTop) + yMargin
      }px; left: ${offsetX + 20 + shift}px; max-width: ${
        this.screenWidth - (offsetX + 20)
      }px; display: block;`;
      newStyleArrow = `margin: auto 0; left: -5px; top: 0px;`;
      newStyleWrapper = 'position: unset;';
    }

    hintEl.setAttribute('style', newStyleEl);
    arrowEl.setAttribute('style', newStyleArrow);
    wrapperEl.setAttribute('style', newStyleWrapper);
    // if (isFirst) {
    //   isFirst = false;
    //   this.setHintStyle(tempWrapperEl, el, wrapperId, isFirst);
    // }
  }

  checkTemporary(): void {
    if (this.isRequestSendToTemporary) {
      return;
    }
    this.isRequestSendToTemporary = true;
    this.apiClient.auth_CheckTemporaryPassword().subscribe(
      data => {
        if (data) {
          if (!this.temporaryActive) {
            this.temporaryActive = true;
            const dialog = this.dialog.open(TemporaryPasswordComponent);
            dialog.afterClosed().subscribe(data => {
              this.temporaryActive = false;
              if (!data) {
                this.headerComp.notifyComp.analyzeBroadcastMessage({
                  id: Guid.raw(),
                  created: moment().toDate(),
                  title: 'Пароль',
                  type: '4',
                  typeStr: BroadcastMessage.TemporaryPassword,
                  body: messages.goAndChangeTemporaryPassword,
                });
              } else {
                this.headerComp.notifyComp.removeNotifyByType(
                  BroadcastMessage.TemporaryPassword
                );
              }
            });
          }
        }
      },
      error => {
        this.isRequestSendToTemporary = false;
        this.notify.error(getErrorMessage(error));
        console.error(error);
      }
    );
  }

  initSignalR(): void {
    if (this.isRequestSendToHub) {
      return;
    }
    this.isRequestSendToHub = true;
    const tokenValue = this.token;
    this.notificationConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/notificationHub`, {
        logger: 6, //None
        accessTokenFactory: () => {
          return tokenValue;
        },
      })
      .build();

    this.notificationConnection.on(
      'broadcastMessage',
      (data: IBroadcastMessage) => {
        this.headerComp.notifyComp.analyzeBroadcastMessage(data);
      }
    );
    this.notificationConnection
      .start()
      .then(() => console.log('Notify is connected'))
      .catch(err => {
        this.isRequestSendToHub = false;
        console.error(err);
      });
  }

  //TODO add emergency urls
  setHardware(): void {
    this.hardwareService.getHardwareInfo(defaultUrls.mtsAgentUrl)
      .subscribe(data => {
        if (!data || data && (
          !data.hardAll)) {
          this.notify.error(errorMessages.agentServiceHardwareError)
        } else {
          this.hardwareService.hardware = data;
          this.store.dispatch(new SetPcParam(data));
          this.behavior.ssoHardwareStatus.next(true);
        }
      }, error => {
        this.hardwareService.hardware = null;
        this.store.dispatch(new SetPcParam(null));
        this.behavior.ssoHardwareStatus.next(true);
        console.error(error)
      });
  }

  // TODO add agent version
  // // Установка параметров из службы для ПК
  // setAgentParams() {
  //   if (userIsNabix(this.user)) {
  //     this.data.getServiceVersionInfo(getMtsAgentUrlLinkByUser(this.user, this.emergencyMtsAgentUrlLink))
  //       .subscribe(data => {
  //         this.data.addOrUpdateServcieParams({
  //           version: data.version,
  //           certExpired: null
  //         }).subscribe(res => { })
  //       }, error => {
  //         console.error(error)
  //       })
  //   } else {
  //
  //     let temp = {};
  //     let req = false;
  //     if (!!this.emergencyMtsAgentUrlLink) {
  //       temp['serviceVersion'] = this.data.getServiceVersionInfo(getMtsAgentUrlLinkByUser(this.user, this.emergencyMtsAgentUrlLink));
  //       req = true;
  //     }
  //     if (!!this.user.organization && this.user.organization.certificateName && !!this.issuerNames) {
  //       temp['serviceExpiration'] = this.data.getCertInfo({
  //         certificateName: this.user.organization.certificateName,
  //         issuerNames: this.issuerNames
  //       }, getMtsAgentUrlLinkByUser(this.user, this.emergencyMtsAgentUrlLink));
  //       req = true;
  //     }
  //     if (req) {
  //       forkJoin(temp)
  //         .subscribe((data: any) => {
  //           this.data.addOrUpdateServcieParams({
  //             version: data.serviceVersion.version,
  //             certExpired: data.serviceExpiration.expired
  //           }).subscribe(res => { })
  //         }, error => {
  //           console.error(error)
  //         });
  //     }
  //   }
  // }
}
