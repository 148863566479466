<ng-container *ngIf="isReady && isReadyForInit else fullLoader">
  <div class="operation-test-data" (click)="fillOperationByTestData()"></div>
  <div class="operation-wrapper">

    <div class="operation-helper-full-wrapper stepper" [ngClass]="{'mobile-active': helperStep === 1, 'mobile-hide': helperStep !== 1}">
      <div class="operation-bottom-menu-content-wrapper">
        <app-stepper></app-stepper>
      </div>
    </div>

    <div class="operation-main-wrapper" id="operation_main_wrapper" [ngClass]="{'mobile-active': helperStep === 2, 'mobile-hide': helperStep !== 2}">
      <div class="operation-bottom-menu-content-wrapper">
        <p class="operation-step-title" [ngClass]="{'mb-0': currentStep === 2}">{{ currentStep }}. {{ currentStepTitle }}</p>

        <div class="operation-main" [ngClass]="{'pt-0': currentStep === 2}">

          <app-operation-client-search></app-operation-client-search>
          <app-operation-data-step></app-operation-data-step>
          <app-operation-create-step></app-operation-create-step>
          <app-operation-confirm-step></app-operation-confirm-step>

          <div class="operation-button-wrapper-mobile mt-40">
            <button class="button main" [disabled]="nextBtnDisabled || blockAllActions"
                    [style.width.px]="currentStep === 2 ? 225 : 150" (click)="builder.stepper.gotoStep(currentStep + 1, 1)">{{forwardBtnTitle}}</button>
            <button
              [disabled]="blockAllActions"
              class="button unfilled decoration-under mt-10"
              *ngIf="currentStep !== 1"
              (click)="builder.stepper.gotoStep(currentStep - 1, -1)">{{backBtnTitle}}</button>
          </div>
          <div class="operation-button-wrapper-full mt-40">
            <button
              [disabled]="blockAllActions"
              *ngIf="currentStep !== 1"
              class="button unfilled decoration-under"
              [style.width.px]="currentStep === 2 ? 225 : 150"
              (click)="builder.stepper.gotoStep(currentStep - 1, -1)">{{backBtnTitle}}</button>
            <button
              [disabled]="nextBtnDisabled || blockAllActions"
              class="button main f-right"
              (click)="builder.stepper.gotoStep(currentStep + 1, 1)">{{forwardBtnTitle}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="operation-helper-full-wrapper commission" [ngClass]="{'mobile-active': helperStep === 3, 'mobile-hide': helperStep !== 3}">
      <div class="operation-bottom-menu-content-wrapper">
        <app-commission></app-commission>
      </div>
    </div>

  </div>

  <div class="operation-menu-wrapper">
    <div class="operation-menu-item-wrapper" *ngFor="let item of bottomMenu; let i=index" (click)="builder.variables.helperStep = i + 1">
      <img [src]="item.img">
      <p class="operation-menu-item-title" [ngClass]="{'active': helperStep === i + 1}">{{item.title}}</p>
    </div>
  </div>

</ng-container>

<ng-template #fullLoader>
  <div class="operation-spinner-wrapper">
    <div class="operation-spinner">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <p class="operation-spinner-title">Пожалуйста, подождите...</p>
  </div>
</ng-template>
