import {Injectable} from '@angular/core';
import {OperationBuilderService} from "../../mts-operation/services/operation-builder.service";
import {BillingBuilderService} from "../../billing/services/billing-builder.service";
import {IOperationBuilderCommandHelper} from "../../../kernel/models/common.models";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {BehaviorService} from "./behavior.service";
import {BillingTypeEnum} from "../../../kernel/ApiClient";

@Injectable({
  providedIn: 'root',
})
export class GlobalBuilderService {
  constructor(
    public operation: OperationBuilderService,
    public billing: BillingBuilderService,
    private behavior: BehaviorService
  ) {
  }

  commandListener(): void {
    this.behavior.operationBuilderCommandChange$
      .subscribe(async data => {
        await this.executeCommand(data);
      });
  }
  async executeCommand(data: IOperationBuilderCommandHelper): Promise<void> {
    if (!data) {
      return;
    }
    switch (data.type) {
      case BuilderCommandEnum.AddFormArrayItem:
        this.operation.array.addFormArrayItem(data.model.stepId, data.model.model, data.model.operationIndex);
        break;
      case BuilderCommandEnum.RemoveFormArrayItem:
        this.operation.array.removeFormArrayItem(data.model.stepId, data.model.arrayId, data.model.operationIndex);
        break;
      case BuilderCommandEnum.OnClientSearch:
        this.operation.api.onClientSearch();
        break;
      case BuilderCommandEnum.DefaultState:
        await this.operation.defaultState(data?.model?.editOperationId, data?.model?.operationType);
        break;
      case BuilderCommandEnum.DefaultStateVariable:
        await this.operation.variables.defaultState(data?.model?.editOperationId, data?.model?.operationType);
        break;
      case BuilderCommandEnum.InitOperationsStatusTimer:
        await this.operation.api.initOperationsStatusTimer();
        break;
      case BuilderCommandEnum.StopOperationsStatusTimer:
        await this.operation.api.stopOperationsStatusTimer();
        break;
      case BuilderCommandEnum.CreateBill:
        await this.billing.helper.openBillPreviewDialog(data?.model?.payload, data?.model?.prePayload);
        break;
      case BuilderCommandEnum.CreateBillQueueItem:
        await this.billing.bill.createBillQueueItem(data?.model?.type, data?.model?.operationId, data?.model?.body);
        break;
      case BuilderCommandEnum.PrintNextInQueueToPrint:
        await this.billing.kkmQueue.printNextInQueueToPrint();
        break;
      case BuilderCommandEnum.CheckCashboxCountBeforeBilling:
        await this.billing.checkCashboxCountBeforeBilling(data?.model?.payload);
        break;
      case BuilderCommandEnum.PrintBill:
        await this.billing.kkm.printBill(data?.model?.bill, data?.model?.helper);
        break;
      case BuilderCommandEnum.CalculateOrganizationCommission:
        await this.operation.commission.calculateOrganizationCommission(data?.model?.amount, data?.model?.operationIndex);
        break;
      case BuilderCommandEnum.FinalizeOperationBillVariable:
        if (data?.model?.type === BillingTypeEnum.SecondCheck ||
          data?.model?.type === BillingTypeEnum.RejectSecondCheck ||
          data?.model?.type === BillingTypeEnum.RejectSecondCheckWithoutCommission) {
          this.operation.variables.secondBillPrintOrQueue = true;
        } else {
          this.operation.variables.firstBillPrintOrQueue = true;
        }
        break;
      case BuilderCommandEnum.GetMTDynamicFields:
        this.operation.api.getMTDynamicFields(data?.model?.paymentSystemId);
        break;
    }
  }
}
