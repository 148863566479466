<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Информация об операции</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <ng-container *ngIf="!isLoad else loadOperation">
    <div class="dialog-body-wrapper operation-info-wrapper" *ngIf="changeBeneficiaryForm">
      <div class="operation-info-body-wrapper mb-30" *ngIf="step === 1">
      <h4>Текущий получатель</h4>
        <app-input
          inputType="text"
          placeholder="Фамилия"
          label="Фамилия"
          name="BeneficiaryOld_lastName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
        <app-input
          inputType="text"
          placeholder="Имя"
          label="Имя"
          name="BeneficiaryOld_firstName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
        <app-input
          inputType="text"
          placeholder="Отчество"
          label="Отчество"
          name="BeneficiaryOld_middleName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
      <h4>Новый получатель</h4>
        <app-input
          inputType="text"
          placeholder="Фамилия"
          label="Фамилия"
          name="BeneficiaryNew_lastName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
        <app-input
          inputType="text"
          placeholder="Имя"
          label="Имя"
          name="BeneficiaryNew_firstName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
        <app-input
          inputType="text"
          placeholder="Отчество"
          label="Отчество"
          name="BeneficiaryNew_middleName"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
      </div>

      <div class="operation-info-body-wrapper mb-30" *ngIf="step === 2">
        <h4>Подтверждение</h4>
        <app-input inputType="text"
                   placeholder="Номер телефона"
                   label="Номер телефона"
                   name="Phone"
                   width="full"
                   maskPrefix="+"
                   mask="0(000)-000-0000"
                   [controlInvalid]="submitted"
                   [parentForm]="changeBeneficiaryForm"></app-input>

        <app-input
          inputType="text"
          placeholder="0000"
          label="Код из СМС"
          name="Code"
          width="full"
          [parentForm]="changeBeneficiaryForm"></app-input>
      </div>
    </div>

    <footer class="pt-15 pb-15" *ngIf="changeBeneficiaryForm">
      <div class="dialog-btn-wrapper-mobile">
        <button class="button main"
                [style.width.px]="185" (click)="nextStep()">{{step === 1 ? 'Продолжить' : 'Подтвердить'}}</button>
        <button class="button unfilled decoration-under" (click)="previousStep()" [style.padding-left.px]="0" [disabled]="forwardBtnDisabled">{{step === 1 ? 'Отмена' : 'Назад'}}</button>
      </div>
      <div class="dialog-btn-wrapper-full">
        <button class="button border decoration-under" [style.width.px]="175"
                (click)="previousStep()" [style.margin-right.px]="20">{{step === 1 ? 'Отмена' : 'Назад'}}</button>
        <button class="button main f-right"
                [style.width.px]="185" (click)="nextStep()" [disabled]="forwardBtnDisabled">{{step === 1 ? 'Продолжить' : 'Подтвердить'}}</button>
      </div>
    </footer>
  </ng-container>

  <ng-template #loadOperation>
    <div class="load-wrapper">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <p class="load-info">Пожалуйста, подождите...</p>
  </ng-template>
</div>
