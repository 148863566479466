import {CommonModule, registerLocaleData} from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '../core/core.module';
import {RouterModule} from '@angular/router';
import {OperationInfoComponent} from './dialogs/operation-info/operation-info.component';
import {OperationsComponent} from './pages/operations/operations.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ChangeBeneficiaryDialogComponent } from './dialogs/change-beneficiary-dialog/change-beneficiary-dialog.component';

registerLocaleData(localeRU, 'ru');
registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [
    OperationsComponent,
    OperationInfoComponent,
    ChangeBeneficiaryDialogComponent
  ],
  entryComponents: [
    OperationInfoComponent,
    ChangeBeneficiaryDialogComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        CoreModule,
        RouterModule,
        MatProgressSpinnerModule,
    ],
  exports: [
    OperationsComponent,
    OperationInfoComponent,
    ChangeBeneficiaryDialogComponent
  ],
  providers: [
  ]
})
export class OperationsListModule {}
