import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {getNotifyDelay} from "../../../kernel/helpers/data.helper";

@Injectable()
export class NotifyService {
  inDev = 'В разработке';

  constructor(private toastr: ToastrService) {}

  inDevelopment(): void {
    this.toastr.warning(this.inDev, null, {
      timeOut: 10000
    });
  }
  success(message: string, title = null, delay = null): void {
    if (!message || message.length === 0) {
      return;
    }
    this.toastr.success(message, title, { timeOut : (delay ? delay : getNotifyDelay(message)) });
  }
  info(message: string, title = null, delay = null): void {
    if (!message || message.length === 0) {
      return;
    }
    this.toastr.info(message, title, { timeOut : (delay ? delay : getNotifyDelay(message)) });
  }
  warning(message: string, title = null, delay = null): void {
    if (!message || message.length === 0) {
      return;
    }
    this.toastr.warning(message, title, { timeOut : (delay ? delay : getNotifyDelay(message)) });
  }
  error(message: string, title = null, delay = null): void {
    if (!message || message.length === 0) {
      return;
    }
    this.toastr.error(message, title, { timeOut : (delay ? delay : getNotifyDelay(message)) });
  }
}

export function notifySettings(): any {
  return {
    timeOut: 5000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    maxOpened: 20,
    enableHtml: true,
    toastClass: 'notify-wrapper',
    iconClasses: {
      error: 'notify-error',
      info: 'notify-info',
      success: 'notify-success',
      warning: 'notify-warning',
    },
  };
}
