import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
// ngxs plugins
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsStoragePluginModule, STORAGE_ENGINE} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import {ToastrModule} from 'ngx-toastr';
import {environment} from 'src/kernel/environments/environment';

import {AppComponent} from './pages/app/app.component';
import {NotifyService, notifySettings} from '../core/services/notify.service';
import {AppRoutingModule} from '../app-routing.module';
import {BaseUrlInterceptor} from '../../kernel/interceptors/api.interceptors';
import {MyStorageEngine} from '../../kernel/storage.engine';
import {API_BASE_URL, ApiClient} from '../../kernel/ApiClient';
import {isLocalEnv} from '../../kernel/helpers/env.helper';
import {UserState} from '../../kernel/store/state/user.state';
import {PersonalAreaComponent} from './pages/personal-area/personal-area.component';
import {HomeComponent} from './pages/home/home.component';
import {SupportComponent} from './pages/support/support.component';
import {DictionaryState} from '../../kernel/store/state/dictionary.state';
import {OperationModule} from '../mts-operation/operation.module';
import {CoreModule} from '../core/core.module';
import {MY_FORMATS} from '../../kernel/helpers/data.helper';
import {AuthModule} from "../auth/auth.module";
import {SettingsModule} from "../settings/settings.module";
import {OperationsListModule} from "../operations-list/operations-list.module";
import {BillingModule} from "../billing/billing.module";
import { SsoAuthComponent } from './pages/sso-auth/sso-auth.component';
import {MtDynamicService} from "../../kernel/mt-dynamic/services/mt-dynamic.service";

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

registerLocaleData(localeRU, 'ru');
registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PersonalAreaComponent,
    SupportComponent,
    SsoAuthComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([UserState, DictionaryState], {
      developmentMode: isLocalEnv(),
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(notifySettings()),
    CoreModule,
    BillingModule,
    AuthModule,
    OperationModule,
    SettingsModule,
    OperationsListModule
  ],
  // entryComponents: [tempWrapperEl, RegisterCodeDialogComponent],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {provide: API_BASE_URL, useValue: environment.apiUrl},
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    {
      provide: STORAGE_ENGINE,
      useClass: MyStorageEngine,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ApiClient,
    MtDynamicService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
