import { Injectable } from '@angular/core';
import {Guid} from "guid-typescript";
import {addClientFormArrayItem} from "../../../kernel/helpers/client.helper";
import {
  createFormGroupByControlsArrayHelper, getFullAddress,
  getSelectId,
  getValueFromModel
} from "../../../kernel/helpers/data.helper";
import {OperationVariableService} from "./operation-variable.service";
import {DocumentTypeEnum, DocumentTypeRuEnum} from "../../../kernel/enum/document-type.enum";
import {ValidationErrors, Validators as v} from "@angular/forms";
import {DecimalPipe} from "@angular/common";
import {IDataSelectedHelper, IStepsControlHelper} from "../../../kernel/models/common.models";
import {addressFields, operationAddressesArray} from "../../../kernel/constants/common.constants";
import {OperationHelperControlService} from "./operation-helper-control.service";
import {BehaviorService} from "../../core/services/behavior.service";

@Injectable({
  providedIn: 'root',
})
export class OperationListenerHelperService {
  constructor(
    private variables: OperationVariableService,
    private helperControl: OperationHelperControlService,
    private decimalPipe: DecimalPipe,
    private behavior: BehaviorService,
  ) {
  }

  fill_documentType(data: string, arrayId: string, index: number): void {
    const documentSeries = this.helperControl.getControlHelper(
      `documents_series`,
      arrayId,
      -1
    );
    const documentNumber = this.helperControl.getControlHelper(
      `documents_number`,
      arrayId,
      -1
    );
    const documentExpired = this.helperControl.getControlHelper(
      `documents_expiryDate`,
      arrayId,
      -1
    );

    const temp = getSelectId(
      data,
      this.variables.selectedDataAll.documents,
      false
    );
    let hideSeries = false;
    let hideExpire = false;
    let validatorSeries = null;
    let validatorExpired = null;
    let documentNumberWrapper = 'operation-half-item-right';
    if (
      data === DocumentTypeRuEnum.PassportRUS ||
      temp === DocumentTypeEnum.PassportRUS
    ) {
      this.variables.clientHelper.items[documentSeries.itemIndex].controlsArr[
        documentSeries.controlArrIndex
        ].controls[documentSeries.controlIndex].mask = '0000';
      this.variables.clientHelper.items[documentNumber.itemIndex].controlsArr[
        documentNumber.controlArrIndex
        ].controls[documentNumber.controlIndex].mask = '000000';
      hideExpire = true;
      validatorSeries = [v.required];
      this.variables.clientHelper.formArray.controls[index]
        .get('documents_expiryDate')
        .setValue(null);
    } else {
      if (
        data === DocumentTypeRuEnum.ForeignPassportRUS ||
        temp === DocumentTypeEnum.ForeignPassportRUS
      ) {
        this.variables.clientHelper.items[documentSeries.itemIndex].controlsArr[
          documentSeries.controlArrIndex
          ].controls[documentSeries.controlIndex].mask = '00';
        this.variables.clientHelper.items[documentNumber.itemIndex].controlsArr[
          documentNumber.controlArrIndex
          ].controls[documentNumber.controlIndex].mask = '0000000';
        validatorSeries = [v.required];
        validatorExpired = [v.required];
      } else {
        hideSeries = true;
        this.variables.clientHelper.items[documentSeries.itemIndex].controlsArr[
          documentSeries.controlArrIndex
          ].controls[documentSeries.controlIndex].mask = '';
        this.variables.clientHelper.items[documentNumber.itemIndex].controlsArr[
          documentNumber.controlArrIndex
          ].controls[documentNumber.controlIndex].mask = '';
        documentNumberWrapper = '';
        validatorExpired = [v.required];
        this.variables.clientHelper.formArray.controls[index]
          .get('documents_series')
          .setValue(null);
      }
    }
    this.variables.clientHelper.items[documentSeries.itemIndex].controlsArr[
      documentSeries.controlArrIndex
      ].controls[documentSeries.controlIndex].isHide = hideSeries;
    this.variables.clientHelper.items[documentExpired.itemIndex].controlsArr[
      documentExpired.controlArrIndex
      ].controls[documentExpired.controlIndex].isHide = hideExpire;
    this.variables.clientHelper.items[documentNumber.itemIndex].controlsArr[
      documentNumber.controlArrIndex
      ].controls[
      documentNumber.controlIndex
      ].wrapperClass = documentNumberWrapper;
    this.variables.clientHelper.items[documentSeries.itemIndex].controlsArr[
      documentSeries.controlArrIndex
      ].controls[
      documentSeries.controlIndex
      ].validators = validatorSeries;
    this.variables.clientHelper.items[documentExpired.itemIndex].controlsArr[
      documentExpired.controlArrIndex
      ].controls[
      documentExpired.controlIndex
      ].validators = validatorExpired;
    this.variables.clientHelper.formArray.controls[index]
      .get('documents_series')
      .setValidators(validatorSeries);
    this.variables.clientHelper.formArray.controls[index]
      .get('documents_expiryDate')
      .setValidators(validatorExpired);
  }

  fill_backAmountError(data: number): ValidationErrors {
    if (!data) {
      return {
        required: true,
        minBackError: {
          error: `Укажите сумму не менее ${this.decimalPipe.transform(
            this.variables.commissionTotal,
            '1.2',
            'ru'
          )} ${this.variables.commissionCurrency}`,
        },
      };
    }
    if (Number(data) < this.variables.commissionTotal) {
      return {
        minBackError: {
          error: `Укажите сумму не менее ${this.decimalPipe.transform(
            this.variables.commissionTotal,
            '1.2',
            'ru'
          )} ${this.variables.commissionCurrency}`,
        },
      };
    }
    return null;
  }

  fill_currentNonDbClientFIO(d: {
    mainInfo_lastName: string,
    mainInfo_firstName: string,
    mainInfo_middleName: string,
  }): void {
    const arr = [];
    if (d.mainInfo_lastName) { arr.push(d.mainInfo_lastName) }
    if (d.mainInfo_firstName) { arr.push(d.mainInfo_firstName) }
    if (d.mainInfo_middleName) { arr.push(d.mainInfo_middleName) }
    this.variables.currentNonDbClientFIO = arr.join(' ');
  }

  fill_commissionFormByCountryId(helper: IStepsControlHelper, d: {
    mainInfo_countryCode: string,
    mainInfo_acceptedCurrency: string,
    mainInfo_withdrawCurrency: string,
    mainInfo_withdrawAmount: string
  }): void {
    const countryId = getSelectId(d.mainInfo_countryCode, this.variables.selectedDataAll.countries, false);
    if (countryId) {
      if (d.mainInfo_countryCode) {
        helper.formGroup.get(`mainInfo_withdrawCurrency`).enable();
      } else {
        helper.formGroup.get(`mainInfo_withdrawCurrency`).disable();
      }
      if (d.mainInfo_withdrawCurrency) {
        helper.formGroup.get(`mainInfo_acceptedAmount`).enable();
        helper.formGroup.get(`mainInfo_withdrawAmount`).enable();
      } else {
        helper.formGroup.get(`mainInfo_acceptedAmount`).disable();
        helper.formGroup.get(`mainInfo_withdrawAmount`).disable();
      }
    }
  }

  fill_withdrawAmountCurrencySuffix(helper: IStepsControlHelper): void {
    const currencyId = getSelectId(helper.formGroup.value.mainInfo_withdrawCurrency, this.variables.selectedDataAll.currencies, false);
    if (!currencyId) {
      return;
    }
    helper.getControl('mainInfo', 'withdrawAmount', null).maskSuffix = ` ${currencyId}`;
  }

  fill_infoByMainInfoCountry(helper: IStepsControlHelper, data: string): void {
    operationAddressesArray.map(x => {
      if (helper.formGroup.get(`${x}_countryCode`)) {
        helper.formGroup.get(`${x}_countryCode`).setValue(data);
      }
    });

    if (data) {
      const countryCodeIndex =
        (helper.getControl('mainInfo', 'countryCode', null).selectData as IDataSelectedHelper[])
          .findIndex(x => x.id === data || x.label === data);

      if (countryCodeIndex > -1) {
        const temp = helper.getControl('mainInfo', 'countryCode', null).selectData[countryCodeIndex].role;
        if (temp?.length > 0) {
          const arr = String(temp).split(',');
          const arrResult: IDataSelectedHelper[] = [];
          this.variables.selectedDataAll.currencies.map(x => {
            if (arr.findIndex(y => x.id === y) > -1) {
              arrResult.push(x);
            }
          });
          helper.getControl('mainInfo', 'withdrawCurrency', null).selectData = arrResult;
          if (!getSelectId(helper.formGroup.value.mainInfo_withdrawCurrency, arrResult, false)) {
            helper.formGroup.get(`mainInfo_withdrawCurrency`).setValue(null);
            helper.formGroup.get(`mainInfo_withdrawAmount`).setValue(null);
            if (this.variables.commissionSub) {
              this.variables.commissionSub.unsubscribe();
              this.behavior.popupLoaderStatus.next(null);
            }
          }
        }
      }
    }

    return;

    if (data === 'США' || data === 'Мексика') {
      if (data === 'США') {
        operationAddressesArray.map(x => {
          helper.getControl(x, 'countryCode', null).wrapperClass = '';
          helper.getControl(x, 'region', null).wrapperClass = 'operation-half-item-left';
          helper.getControl(x, 'region', null).isHide = false;
          helper.getControl(x, 'district', null).wrapperClass = 'operation-half-item-right';
          helper.getControl(x, 'district', null).isHide = false;
          helper.getControl(x, 'city', null).isHide = true;
        });
      }
      if (data === 'Мексика') {
        operationAddressesArray.map(x => {
          helper.getControl(x, 'countryCode', null).wrapperClass = 'operation-half-item-left';
          helper.getControl(x, 'region', null).wrapperClass = 'operation-half-item-right';
          helper.getControl(x, 'region', null).isHide = false;
          helper.getControl(x, 'district', null).wrapperClass = 'operation-half-item-left';
          helper.getControl(x, 'district', null).isHide = false;
          helper.getControl(x, 'city', null).wrapperClass = 'operation-half-item-right';
          helper.getControl(x, 'city', null).isHide = false;
        });
      }
    } else {
      operationAddressesArray.map(x => {
        helper.getControl(x, 'countryCode', null).wrapperClass = '';
        helper.getControl(x, 'region', null).isHide = true;
        helper.getControl(x, 'district', null).isHide = true;
        helper.getControl(x, 'city', null).isHide = true;
      });
    }
  }

  fill_isConsiderAddress(helper: IStepsControlHelper, data: boolean, addressPrefix: string): void {
    if (data) {
      addressFields.map(x => {
        helper.formGroup
          .get(`${addressPrefix}_${x.control}`)
          .setValue(
            helper.formGroup.value[
              `registrationAddress_${x.control}`
              ]
          );
      });
    }
  }

  fill_checkIsConsiderAddressAllFields(helper: IStepsControlHelper): void {
    this.fill_isConsiderAddress(helper, helper.formGroup.value.residentalAddress_isConsider, 'residentalAddress');
    this.fill_isConsiderAddress(helper, helper.formGroup.value.birthPlaceAddress_isConsider, 'birthPlaceAddress');
  }

  fill_isConsiderAddressAllFields(helper: IStepsControlHelper, addressPrefix: string): void {
    if (helper.formGroup.get(`${addressPrefix}_isConsider`)) {
      let compare = true;
      addressFields.map(x => {
        if (
          helper.formGroup.value[
            `registrationAddress_${x.control}`
            ] !==
          helper.formGroup.value[`${addressPrefix}_${x.control}`]
        ) {
          compare = false;
        }
      });
      if (
        compare !==
        helper.formGroup.value[`${addressPrefix}_isConsider`]
      ) {
        helper.formGroup
          .get(`${addressPrefix}_isConsider`)
          .setValue(compare);
      }
    }
    if (helper.formGroup.get(`${addressPrefix}_addressFull`)) {
      helper.formGroup
        .get(`${addressPrefix}_addressFull`)
        .setValue(getFullAddress(helper.formGroup, addressPrefix));
    }
  }

  fill_isClientNeed(): void {
    if (this.variables.operationTypeEnum.isMinClientSumActive && !!this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`mainInfo_acceptedAmount`)) {
      this.variables.isClientNeed = this.variables.operationTypeEnum.minClientSum <= Number(this.variables.operationInfoArray[this.variables.operationIndex].formGroup.get(`mainInfo_acceptedAmount`).value);
    }
  }

  setValidator_BeneficiaryAccountNumber(helper: IStepsControlHelper, paymentSytemId: string): void {
    if (!helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`)) {
      return;
    }

    const payId = this.variables.operationInfoArray[this.variables.operationIndex].commissions.find((comission) => comission.nameCyrillic === paymentSytemId)?.paymentSystemId;

    if (payId === "72127e1a-08b5-4f53-b222-e1da3896ed1f") {
      helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`).setValidators([v.required]);
      helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`).updateValueAndValidity();
    }
    else {
      helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`).setValidators(null);
      helper.formGroup.get(`receiverInfo_beneficiaryAccountNumber`).updateValueAndValidity();
    }
  }
}
