import { Injectable } from '@angular/core';
import {errorMessages} from "../../../kernel/constants/errors";
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {FormGroup} from "@angular/forms";
import {OperationVariableService} from "./operation-variable.service";
import {NotifyService} from "../../core/services/notify.service";
import {OperationHelperService} from "./operation-helper.service";
import {OperationApiService} from "./operation-api.service";
import {Location} from "@angular/common";
import {OperationValidationService} from "./operation-validation.service";
import {MtDynamicService} from "../../../kernel/mt-dynamic/services/mt-dynamic.service";

@Injectable({
  providedIn: 'root',
})
export class OperationStepperService {
  constructor(
    private variables: OperationVariableService,
    private helper: OperationHelperService,
    private validation: OperationValidationService,
    private notify: NotifyService,
    private api: OperationApiService,
    private location: Location,
    private mtDynamic: MtDynamicService
  ) {
  }
  async gotoStep(step: number, increment: number, force = false, first = false): Promise<void> {
    if (step < 1 || this.variables.blockAllActions) {
      return;
    }
    this.mtDynamic.refillFirstStep();

    this.setStepSubmitted(first);

    if (!this.validation.canReachTargetStep(step, first) && !force) {
      await this.gotoStep(this.validation.firstInvalidStep, 0, true, first);
      this.notify.error(errorMessages.needFixErrorToContinue, null, 15000);
      return;
    }
    if (this.variables.canGoToOnlyConfirm && step < 3) {
      this.notify.error(errorMessages.afterCreateCantBeEditOperation);
      return;
    }
    if (step < 2 && this.variables.isFirstConfirmData) {
      this.variables.operationHelperGroup.get(`DataCorrect`).setValue(false);
    }
    if (step === 2) {
      this.helper.createInfo();
    }
    if (step === 3 && this.variables.showCreateOperationBtn) {
      this.api.createOrUpdateClient();
      return;
    }
    if (step === 4 && this.validation.confirmStepIsValid) {
      this.api.confirmOperation(this.validation.confirmStepIsValid);
    }
    if (step > this.variables.operationSteps.length) {
      return;
    }
    this.variables.currentStep = step;
    const index = this.variables.bottomMenu.findIndex(x => x.id === 'stepper');
    if (index > -1) {
      this.variables.bottomMenu[index].title = `${this.variables.currentStep}. ${this.variables.currentStepTitle}`;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.helper.rewriteLocation();
    return;
  }

  setStepSubmitted(first = false): void {
    if (first) {
      return;
    }
    if (this.variables.currentStep === 1) {
      this.variables.operationInfoArray.map(x => x.items.map(y => y.isSubmitted = true));
      this.variables.clientHelper.items.map(x => x.isSubmitted = true);
      return;
    }
  }
}
