<div class="dialog-wrapper" [style.max-width.px]="650">
  <header class="dialog-header">
    <p class="dialog-title">Предварительный просмотр</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper">

    <div class="bill-preview-wrapper" *ngIf="!isLoad else loadBill">
      <div class="check-divider">ПРЕДВАРИТЕЛЬНЫЙ ПРОСМОТР</div>
      <div class="check-wrapper" *ngFor="let item of bill.preview; let i=index">
        <ng-container *ngIf="item.type === 'Full'">
          <div class="w-50 d-inline-block t-align-left word-break-all">{{item.title}}</div>
          <div class="w-50 d-inline-block t-align-right word-break-all" *ngIf="!!item.value">{{item.value}}</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Center'">
          <div class="w-100 d-inline-block t-align-center">{{item.title}}</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Empty'">
          <div class="check-empty">-------------------------------------------------------------------------------------------------</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Left'">
          <div class="w-100 d-inline-block t-align-left">{{item.title}}</div>
        </ng-container>
      </div>
      <div class="check-divider">ДАННЫЕ ККТ</div>
      <div class="check-wrapper font-b word-break-all" *ngFor="let item of bill.fiscalInfo; let i=index">
        <ng-container *ngIf="item.type === 'Full'">
          <div class="w-50 d-inline-block t-align-left word-break-all">{{item.title}}</div>
          <div class="w-50 d-inline-block t-align-right word-break-all" *ngIf="!!item.value">{{item.value}}</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Center'">
          <div class="w-100 d-inline-block t-align-center">{{item.title}}</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Empty'">
          <div class="check-empty">-------------------------------------------------------------------------------------------------</div>
        </ng-container>
        <ng-container *ngIf="item.type === 'Left'">
          <div class="w-100 d-inline-block t-align-left">{{item.title}}</div>
        </ng-container>
      </div>
    </div>

    <ng-template #loadBill>
      <div class="load-wrapper" *ngIf="isLoad">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="t-align-center mb-50 mt-30">Создаю чек...</div>
    </ng-template>

  </div>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main"
              [style.width.px]="185" (click)="printBill()">На печать</button>
      <button class="button unfilled decoration-under" (click)="queueBill()" [style.padding-left.px]="0">В очередь</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="175"
              (click)="queueBill()" [style.margin-right.px]="20">В очередь</button>
      <button class="button main f-right"
              [style.width.px]="185" (click)="printBill()">На печать</button>
    </div>


  </footer>

</div>
