<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header"  [ngClass]="{'pb-0': !!operationCryptoKey}">
        <div class="modal-title">{{operationCryptoKey ? 'Обновление данных' : 'Добавление ключа'}}</div>
        <img class="modal-button-close" src="../../../../assets/img/icons/close.svg" (click)="toggleModal(null)" alt=""/>
      </div>
      <div class="modal-subtitle" *ngIf="operationCryptoKey">{{operationCryptoKey.name}}</div>
      <div class="modal-wrapper" *ngIf="operationCryptoKeyForm" [style.min-height.px]="280">
        <div [formGroup]="operationCryptoKeyForm">         

            <app-input inputType="text"
                       placeholder="Название"
                       label="Название"
                       name="Name"
                       width="full"
                       
                       [controlInvalid]="submitted"
                       [parentForm]="operationCryptoKeyForm"></app-input> 
                       
                       <div class="operation-type-footer-setting">
                        <div class="t-align-center">
                          <div [style.margin-bottom.px]="26" [style.margin-top.px]="26">     
            <app-file-select [title]="'Выберите ключ'" [availableTypes]="availableIcoTypes" (fileChange)="fileChanged($event)"></app-file-select>
          </div>
        </div>
        <div *ngIf="operationCryptoKey" class="crypto-key-relationship-button-wrapper">
          <div (click)="deleteRelatedOperationTypes()" class="download-title">Отвязать все типы операций</div>
          <div (click)="deleteKey()" class="download-title">Удалить ключ</div>
        </div>
      </div>

            <div class="modal-button-wrapper t-align-center" [style.margin-top.px]="20">

              <button class="button border" [@fadeInOut] [style.margin.px]="10"
                      (click)="toggleModal(null)">Отменить</button>
              <button class="button main" [style.margin.px]="10"
                      [ngClass]="{'disabled': !!operationCryptoKeyForm.invalid}"                     
                      (click)="addOrUpdateOperationCryptoKey()"
                      [@fadeInOut]>{{operationCryptoKey ? 'Обновить' : 'Создать'}}</button>
            </div>
            
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
