<ng-container *ngIf="isUrlCorrect">
  <p class="main-title">Форма восстановления пароля</p>
  <p class="auth-subtitle">Укажите Ваш новый пароль, адрес электронной почты и номер телефона</p>

  <div class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">

    <app-input [controlInvalid]="isSubmit"
               name="Phone"
               label="Мобильный телефон"
               placeholder=""
               inputType="tel"
               [parentForm]="authGroup"
               autocomplete="off"
               [isRequired]="true"
               maskPrefix="+7"
               mask="(000) 000 0000"
               [wrapperClass]="'mb-30'"></app-input>

    <app-input [controlInvalid]="isSubmit"
               name="Email"
               label="Электронная почта"
               placeholder=""
               inputType="text"
               [parentForm]="authGroup"
               autocomplete="off"
               [isRequired]="true"
               [wrapperClass]="'mb-30'"></app-input>

    <app-input [idInput]="'passwordInput'"
               inputType="password"
               placeholder="Новый пароль"
               label="Новый пароль"
               name="NewPassword"
               wrapperClass="mb-30"
               autocomplete="on"
               [controlInvalid]="isSubmit"
               [isRequired]="true"
               [parentForm]="authGroup"></app-input>

    <app-input [idInput]="'passwordInput'"
               inputType="password"
               placeholder="Подтверждение нового пароля"
               label="Подтверждение нового пароля"
               name="RepeatNewPassword"
               wrapperClass="mb-30"
               autocomplete="on"
               [controlInvalid]="isSubmit"
               [isRequired]="true"
               [parentForm]="authGroup"></app-input>



    <div class="btn-wrapper-mobile">
      <button class="button main" [ngClass]="{'disabled': !isRestoreCorrect}"
              [style.width.px]="190" (click)="onRestore()">Восстановить</button>
      <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
    </div>
    <div class="btn-wrapper-full">
      <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
      <button class="button main f-right" [ngClass]="{'disabled': !isRestoreCorrect}"
              [style.width.px]="190" (click)="onRestore()">Восстановить</button>
    </div>

  </div>

</ng-container>
