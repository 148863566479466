<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Информация о клиенте</p>
    <img
            src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper mb-20 operation-client-dialog p-relative" id="dialog_input_wrapper" *ngIf="clientHelper">

    <div class="client-test-data" (click)="fillByTestDataClient()"></div>

    <div class="operation-block-wrapper" *ngFor="let item of clientHelper.items; let ngControlGroupIndex=index">
      <p class="operation-block-title" *ngIf="item.title"> {{item.title }}</p>
      <div class="operation-block-items-wrapper" *ngFor="let controlArr of item.controlsArr">
        <ng-container *ngIf="item.isFormGroup">
          <ng-container *ngFor="let control of controlArr.controls; let controlIndex">
            <app-input
              *ngIf="control.isInput && !control.isHide" [inputType]="control.inputType"
              [isSelectExistListener]="control.isSelectExistListener"
              [onlySelect]="control.onlySelect" [readonly]="control.readonly"
              [placeholder]="control.placeholder" [label]="control.label"
              [name]="item.id + '_' + control.name" [mask]="control.mask" [onlyVisible]="control.onlyVisible"
              [maskPrefix]="control.maskPrefix" [maskSuffix]="control.maskSuffix" [wrapperClass]="control.wrapperClass"
              [contentMaxHeight]="control.contentMaxHeight" [selectData]="control.selectData || []" [selectDataAll]="control.selectData || []"
              [hint]="control.hint" [parentForm]="clientHelper.formGroup" [maxLength]="control.maxLength"
              [controlInvalid]="item.isSubmitted">
            </app-input>
            <app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                       [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                       [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="clientHelper.formGroup"></app-radio>
            <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                          [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                          [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="clientHelper.formGroup"></app-checkbox>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="item.isFormArray">
          <ng-container *ngFor="let control of controlArr.controls; let ngControlIndex=index">
            <app-input
              *ngIf="control.isInput && !control.isHide" [inputType]="control.inputType"
              [onlySelect]="control.onlySelect" [readonly]="control.readonly"
              [placeholder]="control.placeholder" [label]="control.label"
              [name]="item.id + '_' + control.name" [mask]="control.mask" [onlyVisible]="control.onlyVisible"
              [maskPrefix]="control.maskPrefix" [maskSuffix]="control.maskSuffix" [wrapperClass]="control.wrapperClass"
              [contentMaxHeight]="control.contentMaxHeight" [selectData]="control.selectData || []" [selectDataAll]="control.selectData || []"
              [hint]="control.hint" [parentForm]="clientHelper.formArray.controls[clientHelper.getArrayIndex(controlArr.arrayId)]" [maxLength]="control.maxLength"
              [controlInvalid]="item.isSubmitted">
            </app-input>
            <app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                       [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                       [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="clientHelper.formArray.controls[clientHelper.getArrayIndex(controlArr.arrayId)]"></app-radio>
            <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                          [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                          [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="clientHelper.formArray.controls[clientHelper.getArrayIndex(controlArr.arrayId)]"></app-checkbox>
          </ng-container>
          <div class="operation-array-button-wrapper">
            <button
              class="button unfilled decoration-under"
              (click)="removeFormArrayItem(item.id, controlArr.arrayId)"
              [style.padding-right.px]="0"
            >Удалить</button>
          </div>
        </ng-container>

      </div>
      <div class="operation-array-button-wrapper" *ngIf="item.isFormArray">
        <button
          class="button unfilled decoration-under"
          (click)="addFormArrayItem(item.id)"
          [style.padding-right.px]="0"
        >Добавить</button>
      </div>
    </div>
  </div>

  <footer>
    <div class="dialog-btn-one-wrapper">
      <button class="button main"
              [style.width.px]="180" (click)="close()">Подтвердить</button>
    </div>
  </footer>

</div>
