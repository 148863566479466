import {Observable, Subject, Subscription} from "rxjs";
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PaginatorComponent} from "../../../core/pages/paginator/paginator.component";
import {
  ApiClient,
  BillingQueueFilterRequest,
  BillingQueueRemoveRequest, BillingTypeEnum,
  IBillingQueueFilterRequest,
  IBillingQueueRemoveRequest,
  IBillingQueueResponse,
  IPaginatorRequest, IUserResponse,
  PaginatorRequest, UserAvailableDataRequest
} from "../../../../kernel/ApiClient";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Select, Store} from "@ngxs/store";
import {Guid} from "guid-typescript";
import {createPaginator} from "../../../../kernel/helpers/paginator.helper";
import {debounceTime, take, takeUntil} from "rxjs/operators";
import {IDataSelectedHelper} from "../../../../kernel/models/common.models";
import {BuilderCommandEnum} from "../../../../kernel/enum/builder-command.enum";
import {NotifyService} from "../../../core/services/notify.service";
import {BillingBuilderService} from "../../services/billing-builder.service";
import {BehaviorService} from "../../../core/services/behavior.service";
import {messages} from "../../../../kernel/constants/messages";
import {getErrorMessage, getSelectedLabelForNewAvailable, getSelectId} from "../../../../kernel/helpers/data.helper";
import {BillSourceEnum} from "../../../../kernel/enum/billSourceEnum.enum";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {UserState} from "../../../../kernel/store/state/user.state";
import { isKkm } from "../../../../kernel/helpers/user.helper";
import {RoleProvider} from "../../../../kernel/enum/role-provider.enum";


@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  protected ngUnsubscribeArray: Subject<void> = new Subject<void>();
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  // @Select(UserState.hardware) hardware$: Observable<IHardwareData>;
  subs = new Subscription();

  @ViewChild('paginatorComp') paginatorComp: PaginatorComponent;

  queue: IBillingQueueResponse;
  isLoad = false;
  requestSub = null;

  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;

  user: IUserResponse;
  isBPARole = false;
  isKkm = false;
  searchForm: FormGroup;
  checkedQueueFormArray: FormArray;
  onlyView = true;
  selectedAll = false;
  availableCashbox: IDataSelectedHelper[] = [];
  availableOrganizations: IDataSelectedHelper[] = [];
  availableOrganizationPoints: IDataSelectedHelper[] = [];
  availableUsers: IDataSelectedHelper[] = [];

  billTypes = BillingTypeEnum;

  constructor(
    private behavior: BehaviorService,
    private fb: FormBuilder,
    private apiClient: ApiClient,
    private store: Store,
    private notify: NotifyService,
    private billing: BillingBuilderService
  ) {
    this.billing.variables.defaultQueueState();
    this.billing.variables.source = BillSourceEnum.QueuePage;
  }

  get isOneOrganization(): boolean {
    return this.availableOrganizations?.length === 1;
  }

  get isOneOrganizationPoint(): boolean {
    return this.availableOrganizationPoints?.length === 1;
  }

  get isOneOperator(): boolean {
    return this.availableUsers?.length === 1;
  }

  get organizationId(): string {
    const index = this.availableOrganizations.findIndex(x => x.label === this.searchForm.value.OrganizationId);
    return index > -1 ? this.availableOrganizations[index].id : null;
  }
  get organizationPointId(): string {
    const index = this.availableOrganizationPoints.findIndex(x => x.label === this.searchForm.value.OrganizationPointId);
    return index > -1 ? this.availableOrganizationPoints[index].id : null;
  }
  get operatorId(): string {
    const index = this.availableUsers.findIndex(x => x.label === this.searchForm.value.OperatorId);
    return index > -1 ? this.availableUsers[index].id : null;
  }

  get isNeedFilterReset(): boolean {
    return this.searchForm && !!this.searchForm.value.TransferNum;
  }

  get countChosen(): number {
    return this.checkedQueueFormArray ?
      this.checkedQueueFormArray.value.filter(x => x.checked).length : 0;
  }

  get breakAllAction(): boolean {
    return this.billing.variables.breakAllQueueAction;
  }

  ngOnInit(): void {
    this.paginator = createPaginator(8);
    this.isLoad = true;
    this.subs.add((this.availableOrganizations$.subscribe(availableOrganizations => { this.availableOrganizations = availableOrganizations })));
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.isKkm = isKkm(this.user.organization);
        this.isBPARole = (
          String(this.user.roleName) === String(RoleProvider.Operator) ||
          String(this.user.roleName) === String(RoleProvider.AdministratorBPA)
        );
        this.initMenu();
      }
    }));
  }

  initMenu(): void {
    if (this.searchForm) {
      return;
    }
    if (this.isBPARole && this.isKkm) {
      this.billing.variables.isWorkLikeKkmQueueIsLoad = false;
      this.billing.variables.isWorkLikeKkmQueue = true;
      this.billing.kkm.getListOfCashbox()
        .pipe(take(1))
        .subscribe(data => {
          this.billing.variables.isWorkLikeKkmQueueIsLoad = false;
          this.billing.variables.isWorkLikeKkmQueue = data?.ListUnit?.length <= 0;
          this.onlyView = data?.ListUnit?.length <= 0;
          this.availableCashbox = [];
          this.billing.variables.listUnit = data.ListUnit;
          data.ListUnit.map((x, index) => {
            this.availableCashbox.push({
              id: String(x.NumDevice),
              label: `${x.IdTypeDevice}` + (x.INN ? ` - ИНН: ${x.INN}` : ''),
              role: String(x.TypeDevice)
            });
          });
          this.createFormControls();
        }, error => {
          this.createFormControls();
          console.error(error);
        });
    } else {
      this.onlyView = false;
      this.createFormControls();
    }
    this.behavior.getQueueStatusChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.getFilteredQueue();
        }
      });
  }

  createFormControls(): void {
    this.searchForm = new FormGroup({
      TransferNum: new FormControl(''),
      NumDevice: new FormControl(this.availableCashbox?.length > 0 ? this.availableCashbox[0].label : null),
      OrganizationId: new FormControl(null),
      OrganizationPointId: new FormControl(null),
      OperatorId: new FormControl(null),
    });
    if (this.isKkm) {
      this.fillNumDevice();
      if (this.billing.variables.isWorkLikeKkmQueue) {
        this.searchForm.get(`NumDevice`).disable();
      } else {
        this.searchForm.controls.NumDevice.valueChanges
          .pipe(
            debounceTime(100),
            takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.fillNumDevice();
          });
      }
    }
    this.searchForm.get(`OrganizationId`).valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.OrganizationPoint,
            organizationId: this.organizationId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableOrganizationPoints = arr as IDataSelectedHelper[];
              this.searchForm.controls.OrganizationPointId.enable();
              const temp = getSelectedLabelForNewAvailable(this.searchForm.value.OrganizationPointId, this.availableOrganizationPoints);
              if (!temp) {
                this.searchForm.controls.OperatorId.setValue(null);
              }
              this.searchForm.controls.OrganizationPointId.setValue(temp);
            });
        } else {
          this.availableOrganizationPoints = [];
          this.searchForm.controls.OrganizationPointId.disable();
        }
      });
    this.searchForm.get(`OrganizationPointId`).valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.User,
            organizationPointId: this.organizationPointId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableUsers = arr as IDataSelectedHelper[];
              this.searchForm.controls.OperatorId.enable();
              this.searchForm.controls.OperatorId.setValue(getSelectedLabelForNewAvailable(this.searchForm.value.OperatorId, this.availableUsers));
            });
        } else {
          this.availableUsers = [];
          this.searchForm.controls.OperatorId.disable();
        }
      });
    if (this.isOneOrganization) {
      this.searchForm.get(`OrganizationId`).setValue(this.availableOrganizations[0].label);
    } else {
      this.searchForm.controls.OrganizationPointId.disable();
    }
    this.searchForm.controls.OperatorId.disable();
    this.getFilteredQueue();
  }

  fillNumDevice(): void {
    this.billing.variables.numDevice = this.searchForm.value.NumDevice ?
      Number(getSelectId(this.searchForm.value.NumDevice, this.availableCashbox, false)) : 0;
    const index = this.billing.variables.listUnit.findIndex(x => String(x.NumDevice) === String(this.billing.variables.numDevice));
    this.billing.variables.currentKkt = index > -1 ? this.billing.variables.listUnit[index] : null;
  }

  initFormArray(): void {
    this.checkedQueueFormArray = new FormArray([]);
    this.queue.items.map(x => {
      this.checkedQueueFormArray.push(
        this.fb.group({
          queueId: new FormControl(x.id),
          type: new FormControl(x.type),
          operationId: new FormControl(x.operationId),
          checked: new FormControl(false)
        })
      )
    });
    this.ngUnsubscribeArray.next();
    this.ngUnsubscribeArray.complete();
    this.checkedQueueFormArray.controls.map((x, index) => {
      this.checkedQueueFormArray.controls[index].get(`checked`).valueChanges
        .pipe(debounceTime(100), takeUntil(this.ngUnsubscribeArray))
        .subscribe(() => {
          this.selectedAll = this.checkedQueueFormArray.value.findIndex(x => !x.checked) < 0;
        });
    })
  }

  printAllSelected(isFull: boolean): void {
    this.billing.kkmQueue.printAllSelected(isFull, isFull ? this.queue.totalCount : this.countChosen, this.checkedQueueFormArray);
  }

  resetAll(): void {
    this.searchForm.get(`TransferNum`).setValue(``);
    this.getFilteredQueue();
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  get filteredPayload(): IBillingQueueFilterRequest {
    return {
      paginator: this.paginator as PaginatorRequest,
      query: null,
      isDesc: false,
      sortColumn: null,
      dateFrom: null,
      dateTo: null,
      transferNum: String(this.searchForm?.value?.TransferNum),
      organizationId: this.organizationId,
      organizationPointId: this.organizationPointId,
      userId: this.operatorId,
    };
  }

  getFilteredQueue(): void {
    this.checkRequestSub();
    this.requestSub = this.apiClient.queue_GetFilteredQueue(this.filteredPayload as BillingQueueFilterRequest).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.queue = data as IBillingQueueResponse;
        this.paginatorComp.initType(this.queue?.totalCount ?? 0);
        this.isLoad = false;
        this.initFormArray();
      }, error => {
        this.isLoad = false;
        this.notify.error(error.message);
        console.error(error);
      });
  }

  ngOnDestroy(): void {
    this.billing.variables.defaultQueueState();
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.DefaultStateVariable
    });
    this.behavior.popupLoaderStatus.next(null);
    this.checkRequestSub();
    // this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredQueue();
  }

  clickAll(): void {
    const state = this.selectedAll;
    this.checkedQueueFormArray.controls.map((x, index) => {
      this.checkedQueueFormArray.controls[index].get(`checked`).setValue(!state);
    })
  }

  removeAllSelected(): void {
    if (this.billing.variables.breakAllQueueAction) {
      return;
    }
    this.billing.variables.breakAllQueueAction = true;
    const payload: IBillingQueueRemoveRequest = {
      queueIds: []
    };
    this.checkedQueueFormArray.value.map(x => {
      if (x.checked) {
        payload.queueIds.push(x.queueId);
      }
    });
    this.apiClient.queue_RemoveBillQueueItems(payload as BillingQueueRemoveRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.elementsRemoved);
        this.getFilteredQueue();
        this.billing.variables.breakAllQueueAction = false;
      }, error => {
        this.billing.variables.breakAllQueueAction = false;
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  removeAll(): void {
    if (this.billing.variables.breakAllQueueAction) {
      return;
    }
    this.billing.variables.breakAllQueueAction = true;
    const payload: IBillingQueueRemoveRequest = {
      queueIds: [],
      filterParams: this.filteredPayload as BillingQueueFilterRequest
    };
    this.checkedQueueFormArray.value.map((x:any) => {
        payload.queueIds.push(x.queueId);
    });
    this.apiClient.queue_RemoveBillQueueItems(payload as BillingQueueRemoveRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.elementsRemoved);
        this.getFilteredQueue();
        this.billing.variables.breakAllQueueAction = false;
      }, error => {
        this.billing.variables.breakAllQueueAction = false;
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }

  removeItem(id: string): void {
    if (this.billing.variables.breakAllQueueAction) {
      return;
    }
    this.billing.variables.breakAllQueueAction = true;
    const payload: IBillingQueueRemoveRequest = {
      queueIds: [id]
    };
    this.apiClient.queue_RemoveBillQueueItems(payload as BillingQueueRemoveRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.notify.success(messages.elementsRemoved);
        this.getFilteredQueue();
        this.billing.variables.breakAllQueueAction = false;
      }, error => {
        this.billing.variables.breakAllQueueAction = false;
        this.notify.error(getErrorMessage(error));
        console.error(error);
      });
  }
}
