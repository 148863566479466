import {Component, OnInit} from '@angular/core';
import {
  IMtDynamicFiledHelper,IMtSchemaResponse,
} from "../../../../kernel/mt-dynamic/models/mt-dynamic.models";
import {OperationBuilderService} from "../../services/operation-builder.service";

@Component({
  selector: 'app-operation-dynamic-data-step',
  templateUrl: './operation-dynamic-data-step.component.html',
  styleUrls: ['./operation-dynamic-data-step.component.scss', '../mts-operation/mts-operation.component.scss']
})
export class OperationDynamicDataStepComponent implements OnInit {

  constructor(
    private builder: OperationBuilderService,
  ) {
  }

  get transferFields(): IMtDynamicFiledHelper[] | undefined {
    return this.builder.variables.transferFields;
  }

  get topLevelFields(): IMtDynamicFiledHelper[] {
    return this.transferFields.filter(x => x.children.length == 0 && x.type !== 'object' && x.type !== 'array');
  }

  ngOnInit(): void {
  }
}
