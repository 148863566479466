<div [class]="wrapperClass" [id]="wrapperId" (mouseenter)="checkHint()">
  <label [id]="'label_' + wrapperId" *ngIf="!!label">{{label}}<span class="color-red" *ngIf="isRequired">*</span></label>
  <div [ngClass]="{'t-align-center mb-15': inline}">
    <div
      *ngFor="let item of selectData; let i=index"
      [ngClass]="{'input-drop': readonly, 'd-block': !inline, 'd-inline-block': inline, 'p-relative mb-5': item.role && showRole}"
      [style.margin-right.px]="i !== selectData.length - 1 && inline ? 15 : 0">
      <input [ngClass]="{'cur-drop': readonly, 'pointer': !readonly, 'invalid': setInvalidClass}"
             [name]="'radio_' + id"
             class="radioInputTeste2e"
             data-cy="add-account-radio-btn"
             [id]="'input_' + id + '_' + item.id"
             (change)="changed(item.id)"
             type="radio"
             [readonly]="readonly"
             [value]="item.id"
             [checked]="isChecked(i)"
             [disabled]="readonly ? true : null"
      >
      <label [ngClass]="{'cur-drop': readonly}"
             [for]="'input_' + id + '_' + item.id"
             class="radioLabel">
        {{ item.label }}
      </label>
      <span class="radio-label-role" *ngIf="item.role && showRole">{{item.role}}</span>
    </div>
  </div>

  <app-control-validator [parentForm]="parentForm" [name]="name" [showErrors]="controlInvalid" [nowrap]="nowrap" [fromComponent]="'radio'"></app-control-validator>

  <div class="input-hint" [id]="'hint_' + wrapperId" [attr.hintShift]="hintShift" *ngIf="!!hint">
    <div class="input-hint-container">
      <span class="hint-arrow" [id]="'arrow_hint_' + wrapperId"></span>
      <span class="input-hint-text">{{ hint }}</span>
    </div>
  </div>
</div>
