<ng-container *ngIf="currentStep === 3">

  <p class="text-info">{{confirmTitle}}</p>

  <app-input name="givenSum" width="full"
             [label]="amountLabel"
             wrapperClass="inputBox-group"
             [parentForm]="operationHelperGroup"
             name="CashboxAmount"
             mask="separator"
             inputType="number"
             [autofocus]="true"
             [controlInvalid]="true"></app-input>
  <p class="font-b">
    Сдача: {{backAmount | number: '1.2':'ru'}} {{commissionCurrency}}
  </p>
</ng-container>
