<div class="operation-commission-wrapper" id="operation_commission_wrapper">
  <div class="operation-commission">
    <p class="operation-info-title">
      Информация об операции
    </p>

    <ng-container *ngFor="let commission of commissions;let commissionIndex=index">
      <article class="multi transfer-total-row" *ngFor="let item of commission.items; let i=index">
        <p class="transfer-total-row-title">{{ item.title }}</p>
        <p class="transfer-total-row-value" *ngIf="item.currency else titleCommissionItem">
          <span class="transfer-total-row-num" *ngIf="!item.isRate">{{ item.value || 0 | number: "1.2":"ru" }}</span>
          <span class="transfer-total-row-num" *ngIf="item.isRate">{{ item.value || 0 | number: "1.4":"ru" }}</span>
          <span class="currencySign">{{item.currency || 'RUB'}}</span>
        </p>
        <ng-template #titleCommissionItem>
          <p class="transfer-total-row-value">
            <span class="transfer-total-row-num">{{ item.value}}</span>
          </p>
        </ng-template>
      </article>
    </ng-container>
    <article class="multi transfer-total-row transfer-total-hr">
      <p class="transfer-total-row-title">Итого к оплате</p>
      <p class="transfer-total-row-value">
        <span class="transfer-total-row-num">{{ commissionTotal || 0 | number: "1.2":"ru" }}</span>
        <span class="currencySign">{{!!commissions && !!commissions[0] ? commissions[0].currency : 'RUB'}}</span>
      </p>
    </article>

    <div class="commission-hint">
      Обратите внимание, что для расчета комиссии сначала необходимо выбрать
      <strong class="color-red">Страну получения</strong>, <strong class="color-red">Валюту выдачи</strong> и
      <strong class="color-red">Сумму выдачи</strong>,
      затем необходимо выбрать <strong class="color-red">Платежную систему</strong> из предложенного списка
    </div>

    <div class="commission-hint mt-20" *ngIf="isTransfer && isCountryCodeTUR">
      Обратите внимание, что <strong class="color-red">номер IBAN (Номер счета получателя)</strong>
      нужно заполнять только при переводе в <strong class="color-red">Турцию</strong>
    </div>

  </div>
</div>
