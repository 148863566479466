import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ICommissionHelper} from '../../../../kernel/models/common.models';
import {OperationBuilderService} from "../../services/operation-builder.service";
import {getIdByValue} from "../../../../kernel/helpers/data.helper";

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: [
    './commission.component.scss',
    '../mts-operation/mts-operation.component.scss',
  ],
})
export class CommissionComponent {
  constructor(
    private builder: OperationBuilderService
  ) {
  }

  get commissionTotal(): number {
    return this.builder.variables.commissionTotal;
  }
  get commissions(): ICommissionHelper[] {
    return this.builder.variables.commissions;
  }

  get isTransfer(): boolean {
    return this.builder.variables.isTransfer;
  }

  get isCountryCodeTUR(): boolean {
    return getIdByValue(this.builder.variables.selectedDataAll.countriesAddresses, this.builder.variables.operationInfoArray[this.builder.variables.operationIndex].formGroup.value['mainInfo_countryCode']) === 'TUR';
  }
}
