import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {UserState} from "../../../../kernel/store/state/user.state";
import {
  ApiClient,
  IOperationFilterResponse,
  IPaginatorRequest,
  IUserResponse,
  OperationFilterEnum,
  OperationFilterRequest,
  PaginatorRequest,
  UserAvailableDataRequest
} from "../../../../kernel/ApiClient";
import {DictionaryState} from "../../../../kernel/store/state/dictionary.state";
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, map, take, takeUntil} from "rxjs/operators";
import * as moment from 'moment';
import {
  getErrorMessage,
  getSelectedLabelForNewAvailable,
  getSelectId,
  getUTCDateTime
} from "../../../../kernel/helpers/data.helper";
import {NotifyService} from "../../../core/services/notify.service";
import {Guid} from "guid-typescript";
import {createPaginator} from "../../../../kernel/helpers/paginator.helper";
import {getOperationStatusSelectedData, operationtitleBottomArr} from "../../../../kernel/helpers/operation.helper";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {OperationInfoComponent} from "../../dialogs/operation-info/operation-info.component";
import {PaginatorComponent} from "../../../core/pages/paginator/paginator.component";
import {UserAvailableDataEnum} from "../../../../kernel/enum/user-available-data";
import {environment} from "../../../../kernel/environments/environment";
import {IDataSelectedHelper} from "../../../../kernel/models/common.models";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {BillSourceEnum} from "../../../../kernel/enum/billSourceEnum.enum";
import { saveAs as importedSaveAs } from 'file-saver'

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;
  @Select(UserState.isAllNonBPAAdmins) isAllNonBPAAdmins$: Observable<boolean>;
  @Select(DictionaryState.getAvailableOrganizations) availableOrganizations$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCountries) countries$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCurrencies) currenciesAll$: Observable<IDataSelectedHelper[]>;
  @ViewChild('paginatorComp') paginatorComp: PaginatorComponent;

  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;
  operations: IOperationFilterResponse;

  columns = OperationFilterEnum;
  sortField: OperationFilterEnum = OperationFilterEnum.Created;
  isDesc = true;

  subs = new Subscription();
  availableOrganizations: IDataSelectedHelper[] = [];
  availableOrganizationPoints: IDataSelectedHelper[] = [];
  availableUsers: IDataSelectedHelper[] = [];
  countries: IDataSelectedHelper[] = [];
  currenciesAll: IDataSelectedHelper[] = [];
  currencies: IDataSelectedHelper[] = [];
  statuses: IDataSelectedHelper[] = [];

  user: IUserResponse;
  isLoad = false;
  downloadInProgress = false;
  filterExpand = false;
  isOperator = false;
  submitted = true;
  dateFromMin: Date;
  dateFromMax: Date;
  dateToMin: Date;
  dateToMax: Date;

  operationFilterForm: FormGroup;

  constructor(
    private dialog: MatDialog,
    private apiClient: ApiClient,
    private notify: NotifyService,
    private billing: BillingBuilderService
  ) {
    this.billing.variables.source = BillSourceEnum.OperationInfoPage;
    this.defaultMinMaxDate();
  }

  get today(): Date {
    const temp = moment();
    return  new Date(temp.year(), temp.month(), temp.date());
  }

  get payload(): OperationFilterRequest {
    return {
      paginator: this.paginator as PaginatorRequest,
      paymentSystemId: null,
      transferNum: this.fv.TransferNumber,
      transferStatus: getSelectId(this.fv.Status, this.statuses, false),
      sumFrom: !!this.fv.SumFrom ? Number(this.fv.SumFrom) : null,
      sumTo: !!this.fv.SumTo ? Number(this.fv.SumTo) : null,
      acceptedMoneyCurrencyCode: environment.mainCurrencyId,
      withdrawMoneyCurrencyCode: getSelectId(this.fv.WithdrawCurrency, this.currencies, false),
      organizationId: this.organizationId,
      organizationPointId: this.organizationPointId,
      userId: this.operatorId,
      country: getSelectId(this.fv.Country, this.countries, false),
      dateFrom: getUTCDateTime(this.fv.DateFrom),
      dateTo: getUTCDateTime(this.fv.DateTo, 23, 59, 59, 999),
      column: this.sortField,
      isDesc: this.isDesc,
      query: ''
    } as OperationFilterRequest;
  }

  get fv(): any {
    return this.operationFilterForm && this.operationFilterForm.value;
  }

  get f(): any {
    return this.operationFilterForm && this.operationFilterForm.controls;
  }

  get isOneOrganization(): boolean {
    return this.availableOrganizations?.length === 1;
  }

  get isOneOrganizationPoint(): boolean {
    return this.availableOrganizationPoints?.length === 1;
  }

  get isOneOperator(): boolean {
    return this.availableUsers?.length === 1;
  }

  get isOneCurrency(): boolean {
    return this.currencies?.length === 1;
  }

  get organizationId(): string {
    const index = this.availableOrganizations.findIndex(x => x.label === this.operationFilterForm.value.OrganizationId);
    return index > -1 ? this.availableOrganizations[index].id : null;
  }
  get organizationPointId(): string {
    const index = this.availableOrganizationPoints.findIndex(x => x.label === this.operationFilterForm.value.OrganizationPointId);
    return index > -1 ? this.availableOrganizationPoints[index].id : null;
  }
  get operatorId(): string {
    const index = this.availableUsers.findIndex(x => x.label === this.operationFilterForm.value.OperatorId);
    return index > -1 ? this.availableUsers[index].id : null;
  }

  get isNeedFilterReset(): boolean {
    return !!this.operationFilterForm &&
      (!!this.operationFilterForm.value.TransferNumber ||
        !!this.operationFilterForm.value.Country ||
        !!this.operationFilterForm.value.Status ||
        !!this.operationFilterForm.value.SumFrom ||
        !!this.operationFilterForm.value.SumTo ||
        !!this.operationFilterForm.value.DateFrom ||
        !!this.operationFilterForm.value.DateTo ||
        (!!this.operationFilterForm.value.OrganizationId && !this.isOneOrganization) ||
        (!!this.operationFilterForm.value.OrganizationPointId && !this.isOneOrganizationPoint) ||
        (!!this.operationFilterForm.value.OperatorId && !this.isOneOperator) ||
        !!this.operationFilterForm.value.PaymentSystemId ||
        (!!this.operationFilterForm.value.WithdrawCurrency && !this.isOneCurrency));
  }

  ngOnInit(): void {
    this.statuses = getOperationStatusSelectedData();
    this.paginator = createPaginator(5);
    this.subs.add(this.isOperator$.subscribe(isOperator => {
      this.isOperator = isOperator;
    }));
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
    }));
    this.subs.add(this.availableOrganizations$.subscribe(availableOrganizations => {
      this.availableOrganizations = availableOrganizations;
    }));
    this.subs.add(this.countries$.subscribe(countries => {
      this.countries = countries;
    }));
    this.subs.add(this.currenciesAll$.subscribe(currenciesAll => {
      this.currenciesAll = currenciesAll;
      this.currencies = currenciesAll;
    }));
    this.createFormControls();
  }

  defaultMinMaxDate(): void {
    this.dateFromMin = new Date(1900, 0, 0);
    const temp = moment();
    this.dateFromMax = new Date(temp.year(), temp.month(), temp.date());
    this.dateToMin = new Date(1900, 0, 0);
    this.dateToMax = new Date(2100, 0, 0);
  }

  createFormControls(): void {
    this.operationFilterForm = new FormGroup({
      TransferNumber: new FormControl(null),
      OrganizationId: new FormControl(null),
      OrganizationPointId: new FormControl(null),
      OperatorId: new FormControl(null),
      Country: new FormControl(null),
      Status: new FormControl(null),
      SumFrom: new FormControl(null),
      SumTo: new FormControl(null),
      DateFrom: new FormControl(this.today),
      DateTo: new FormControl(null),
      PaymentSystemId: new FormControl(null),
      AcceptedCurrency: new FormControl(null),
      WithdrawCurrency: new FormControl(null)
    });

    this.valueChanges();
    if (this.isOneOrganization) {
      this.operationFilterForm.get(`OrganizationId`).setValue(this.availableOrganizations[0].label);
    } else {
      this.operationFilterForm.controls.OrganizationPointId.disable();
    }
    this.operationFilterForm.controls.OperatorId.disable();
    this.getFilteredOperations();
  }

  resetAll(): void {
    this.paginator.page = 1;
    this.operationFilterForm.get(`TransferNumber`).setValue(null);
    if (!this.isOneOrganization) {
      this.operationFilterForm.get(`OrganizationId`).setValue(null);
    }
    if (!this.isOneOrganizationPoint) {
      this.operationFilterForm.get(`OrganizationPointId`).setValue(null);
    }
    if (!this.isOneOperator) {
      this.operationFilterForm.get(`OperatorId`).setValue(null);
    }
    if (!this.isOneCurrency) {
      this.operationFilterForm.get(`WithdrawCurrency`).setValue(null);
    }
    this.operationFilterForm.get(`Country`).setValue(null);
    this.operationFilterForm.get(`Status`).setValue(null);
    this.operationFilterForm.get(`SumFrom`).setValue(null);
    this.operationFilterForm.get(`SumTo`).setValue(null);
    this.operationFilterForm.get(`DateFrom`).setValue(null);
    this.operationFilterForm.get(`DateTo`).setValue(null);
    this.operationFilterForm.get(`PaymentSystemId`).setValue(null);
    this.operationFilterForm.get(`AcceptedCurrency`).setValue(null);
    this.operationFilterForm.get(`WithdrawCurrency`).setValue(null);
    this.defaultMinMaxDate();
    this.getFilteredOperations();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  valueChanges(): void {
    // this.operationFilterForm.get(`SumFrom`).valueChanges
    //   .pipe(debounceTime(1500),
    //     takeUntil(this.ngUnsubscribe))
    //   .subscribe(() => {
    //     if (this.fv.SumFrom >= this.fv.SumTo && !!this.fv.SumFrom) {
    //       this.operationFilterForm.controls.SumTo.setValue(null);
    //     }
    //   });
    // this.operationFilterForm.get(`SumTo`).valueChanges
    //   .pipe(debounceTime(500),
    //     takeUntil(this.ngUnsubscribe))
    //   .subscribe(() => {
    //     if (this.fv.SumTo <= this.fv.SumFrom && !!this.fv.SumTo) {
    //       this.operationFilterForm.controls.SumFrom.setValue(null);
    //     }
    //   });
    this.operationFilterForm.valueChanges
      .pipe(
        debounceTime(500),
        filter((d: any) => d.DateFrom || d.DateTo),
        map((d) => ({
          DateFrom: d.DateFrom,
          DateTo: d.DateTo
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        this.dateToMin = d?.DateFrom ?? new Date(1900, 0, 0);
        this.dateFromMax = d?.DateTo ?? new Date(2100, 0, 0);
      });

    this.operationFilterForm.get(`OrganizationId`).valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.OrganizationPoint,
            organizationId: this.organizationId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableOrganizationPoints = arr as IDataSelectedHelper[];
              this.operationFilterForm.controls.OrganizationPointId.enable();
              const temp = getSelectedLabelForNewAvailable(this.operationFilterForm.value.OrganizationPointId, this.availableOrganizationPoints);
              if (!temp) {
                this.operationFilterForm.controls.OperatorId.setValue(null);
              }
              this.operationFilterForm.controls.OrganizationPointId.setValue(temp);
            });
        } else {
          this.availableOrganizationPoints = [];
          this.operationFilterForm.controls.OrganizationPointId.disable();
        }
      });
    this.operationFilterForm.get(`OrganizationPointId`).valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.apiClient.users_GetAvailableData({
            type: UserAvailableDataEnum.User,
            organizationPointId: this.organizationPointId
          } as UserAvailableDataRequest)
            .pipe(take(1))
            .subscribe(arr => {
              this.availableUsers = arr as IDataSelectedHelper[];
              this.operationFilterForm.controls.OperatorId.enable();
              this.operationFilterForm.controls.OperatorId.setValue(getSelectedLabelForNewAvailable(this.operationFilterForm.value.OperatorId, this.availableUsers));
            });
        } else {
          this.availableUsers = [];
          this.operationFilterForm.controls.OperatorId.disable();
        }
      });
    this.operationFilterForm.get(`Country`).valueChanges
      .pipe(debounceTime(500),
        takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        const index = this.countries.findIndex(x => x.label === data);
        if (index > -1) {
          const arr = String(this.countries[index].role).split(',');
          const arrResult: IDataSelectedHelper[] = [];
          this.currenciesAll.map(x => {
            if (arr.findIndex(y => x.id === y) > -1) {
              arrResult.push(x);
            }
          });
          this.currencies = arrResult;
          if (!getSelectId(this.operationFilterForm.value.WithdrawCurrency, this.currencies, false)) {
            this.operationFilterForm.get(`WithdrawCurrency`).setValue(this.isOneCurrency ? this.currencies[0].label : null);
          }
        } else {
          this.currencies = this.currenciesAll;
        }
      });
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredOperations();
  }

  setSort(type: OperationFilterEnum): void {
    this.isDesc = this.sortField !== type ? false : !this.isDesc
    this.sortField = type
    this.getFilteredOperations();
  }

  getFilteredOperations(): void {
    this.apiClient.transfers_GetFilteredTransfers(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.operations = data;
          this.paginatorComp.initType(this.operations?.totalCount ?? 0);
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
  }

  download(): void {
    if (!this.downloadInProgress) {
      this.downloadInProgress = true;

      this.apiClient.transfers_GetFilteredTransfersReport(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          data => {
            const byteCharacters = atob(data.file.fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: data.file.contentType});

            importedSaveAs(blob, data.file.fileDownloadName ?? 'Отчет')
            this.downloadInProgress = false;
          },
          (error) => {
            this.downloadInProgress = false;
            this.notify.error(getErrorMessage(error));
            console.error(error);
          },
        );
    }
  }

  openOperationInfo(transferId: string): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.data = transferId;
    const dialog = this.dialog.open(OperationInfoComponent, dialogConfig)
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.getFilteredOperations();
      })
  }

  get isEmpty(): boolean {
    return !this.operations || (this.operations && this.operations.operations && this.operations.operations.length === 0)
  }

  get titleBottom(): string {
    if (this.isEmpty) {
      return ''
    }
    const lastChar = String(this.operations.totalCount).substr(String(this.operations.totalCount).length - 1)
    const index = operationtitleBottomArr.findIndex(x => x.char === lastChar)
    return index > -1 ? operationtitleBottomArr[index].title : ''
  }
}
