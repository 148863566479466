import {NotifyMessageService} from 'src/modules/core/services/notify-message.service';
import {UserState} from 'src/kernel/store/state/user.state';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {ApiClient, IUserResponse} from '../../../../kernel/ApiClient';
import {navItems} from '../../../../kernel/constants/common.constants';
import {Navigate} from '@ngxs/router-plugin';
import {BehaviorService} from '../../services/behavior.service';
import {NotifyMenuComponent} from '../notify-menu/notify-menu.component';
import {IHeaderNotify} from '../../../../kernel/models/common.models';
import {timer} from "rxjs/internal/observable/timer";
import {getTimeAgo, timeConversion} from "../../../../kernel/helpers/notify.helper";
import * as moment from "moment";
import {takeUntil} from "rxjs/operators";
import {ResetUser} from "../../../../kernel/store/actions/user.actions";
import {getErrorMessage} from "../../../../kernel/helpers/data.helper";
import {NotifyService} from "../../services/notify.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;
  @Select(UserState.isAdministratorBPA) isAdministratorBPA$: Observable<boolean>;
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @ViewChild('notifyComp') notifyComp: NotifyMenuComponent;

  subs = new Subscription();
  avatar = '/assets/img/nav/avatar.svg';
  leftMenuOpen = false;
  isOperator = false;
  isAdministratorBPA = false;
  expiredTime: string;

  user: IUserResponse;
  isAuthorized = false;
  navs = [];

  year: number;
  logos = {
    usual: '/assets/img/nav/nabix-logo-big.svg',
    newYear: '/assets/img/nav/nabix-new-year-logo-small.svg',
  };

  @ViewChild('leftMenu', {static: false}) leftMenu: ElementRef;
  @ViewChild('hamburger', {static: false}) hamburger: ElementRef;
  @ViewChild('notifyBody', {static: false}) notifyBody: ElementRef;
  @ViewChild('notifyBell', {static: false}) notifyBell: ElementRef;
  // @ViewChild('notifyBellMobile', { static: false }) notifyBellMobile: ElementRef;
  @HostListener('document:click', ['$event.target'])
  private clickOutside(targetElement) {
    let inside =
      (!!this.leftMenu &&
        this.leftMenu.nativeElement.contains(targetElement)) ||
      (!!this.hamburger &&
        this.hamburger.nativeElement.contains(targetElement));
    if (!inside) {
      this.leftMenuOpen = false;
    }
    inside =
      (!!this.notifyBody &&
        this.notifyBody.nativeElement.contains(targetElement)) ||
      (!!this.notifyBell &&
        this.notifyBell.nativeElement.contains(targetElement));
    if (!inside && window.innerWidth > 1100) {
      this.notifyMessages.notifyOpen = false;
    }
  }

  constructor(
    private notifyMessages: NotifyMessageService,
    private store: Store,
    private behavior: BehaviorService,
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {}

  get notifies(): IHeaderNotify[] {
    return this.notifyMessages.notifies;
  }

  get notifyOpen(): boolean {
    return this.notifyMessages.notifyOpen;
  }

  get notifyEmpty(): boolean {
    return !this.notifies || this.notifies.length === 0;
  }

  ngOnInit() {
    this.subs.add(this.isOperator$.subscribe(isOperator => { this.isOperator = isOperator }));
    this.subs.add(this.isAdministratorBPA$.subscribe(isAdministratorBPA => { this.isAdministratorBPA = isAdministratorBPA }));
    this.subs.add(
      this.isAuthorized$.subscribe(isAuthorized => {
        this.isAuthorized = isAuthorized;
        if (!this.isAuthorized) {
          this.notifyMessages.notifies = [];
        }
      })
    );
    this.subs.add(
      this.user$.subscribe(user => {
        this.user = user;
        if (user) {
          this.navs = [];
          const role = user.roleName;
          navItems.map(x => {
            if (
              (x.roles &&
                x.roles.length > 0 &&
                x.roles.findIndex(y => y === role) > -1) ||
              x.roles.length === 0
            ) {
              this.navs.push(x);
            }
          });
        }
      })
    );
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]));
    return;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackByFn(i, item) {
    return item.id;
  }

  changeNotifyState(): void {
    this.notifyMessages.notifyOpen = !this.notifyMessages.notifyOpen;
  }

  onLogout(): void {
    this.apiClient.auth_Logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new ResetUser());
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
  }
}
