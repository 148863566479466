<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header"  [ngClass]="{'pb-0': !!organizationPoint}">
        <div class="modal-title">{{organizationPoint ? 'Обновление данных' : 'Создание Точки'}}</div>
        <img class="modal-button-close" src="../../../../assets/img/icons/close.svg" (click)="toggleModal(null)" alt=""/>
      </div>
      <div class="modal-subtitle" *ngIf="organizationPoint">{{organizationPoint.name}}</div>
      <div>
        <div *ngFor="let item of topMenu.items; let i=index"
             (click)="topMenu.currentIndex = item.index" [style.padding-left.px]="24" [ngClass]="{ 'color-main': topMenu.currentIndex === item.index, 'opacity-0-5': topMenu.currentIndex !== item.index }" class="font-b pointer d-inline-block mt-10 mb-10">{{item.title}}</div>
      </div>
      <div class="modal-wrapper" *ngIf="organizationPointForm" [style.min-height.px]="606">
        <div [formGroup]="organizationPointForm">
          <div *ngIf="topMenu.currentIndex === 1">

            <app-input inputType="text"
                       placeholder="Название"
                       label="Название"
                       name="Name"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="Point Code"
                       label="Point Code"
                       name="PointCode"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="TerminalId"
                       label="TerminalId"
                       name="TerminalId"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="Point Of Sales Id"
                       label="Point Of Sales Id"
                       name="PointOfSalesId"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted"
                       placeholder="Часовой пояс"
                       label="Часовой пояс"
                       name="TimeZoneId"
                       wrapperClass="operation-half-item-left"
                       [contentMaxHeight]="600"
                       [selectData]="availableTimeZones || []"
                       [selectDataAll]="availableTimeZones || []"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted"
                       placeholder="БПА"
                       label="БПА"
                       name="OrganizationId"
                       wrapperClass="operation-half-item-right"
                       [contentMaxHeight]="600"
                       [selectData]="availableOrganizations || []"
                       [selectDataAll]="availableOrganizations || []"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="Номер телефона"
                       label="Номер телефона"
                       name="Phone"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       maskPrefix="+7"
                       mask="(000)-000-0000"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="Email"
                       label="Email"
                       name="Email"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="textarea"
                       placeholder="Фактический адрес"
                       label="Фактический адрес"
                       name="PlaceAddress"
                       width="full"
                       rows="3"
                       wrapperClass="operation-half-item-left"
                       [controlInvalid]="submitted"
                       [maxLength]="200"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="textarea"
                       placeholder="Юридический адрес"
                       label="Юридический адрес"
                       name="LegalAddress"
                       width="full"
                       rows="3"
                       wrapperClass="operation-half-item-right"
                       [controlInvalid]="submitted"
                       [maxLength]="200"
                       [parentForm]="organizationPointForm"></app-input>

            <div class="operation-half-item-left">
              <app-checkbox
              [wrapperClass]="'inputBox-group'"
              [parentForm]="organizationPointForm"
              name="IsBlocked"
              label="Заблокировать точку"
              [controlInvalid]="submitted"
            ></app-checkbox>
            </div>
            
            <div class="operation-half-item-right">
              <app-checkbox
              [wrapperClass]="'inputBox-group'"
              [parentForm]="organizationPointForm"
              name="IsFranchise"
              label="Франшиза"
              [controlInvalid]="submitted"
            ></app-checkbox>
            </div>
            


            <div class="modal-button-wrapper t-align-center">

              <button class="button border" [@fadeInOut] [style.margin.px]="10"
                      (click)="toggleModal(null)">Отменить</button>
              <button class="button main" [style.margin.px]="10"
                      [ngClass]="{'disabled': !!organizationPointForm.invalid}"
                      (click)="addOrUpdateOrganization()"
                      [@fadeInOut]>{{organizationPoint ? 'Обновить' : 'Создать'}}</button>
            </div>
          </div>

          <div *ngIf="topMenu.currentIndex === 2">
            <app-input inputType="text"
                       placeholder="08:00"
                       label="Начало рабочего дня"
                       name="WorkStart"
                       width="full"
                       wrapperClass="operation-half-item-left"
                       mask="00:00"
                       [checkTime]="true"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="20:00"
                       label="Конец рабочего дня"
                       name="WorkEnd"
                       width="full"
                       wrapperClass="operation-half-item-right"
                       mask="00:00"
                       [controlInvalid]="submittedSettings"
                       [parentForm]="organizationPointForm"></app-input>
            <div (click)="updateOrganizationPointSettings()" class="download-title t-align-center">Обновить настройки</div>
          </div>

          <div *ngIf="topMenu.currentIndex === 3">
            <app-input inputType="text"
                       placeholder="15000"
                       label="Лимит на перевод"
                       name="LimitForTransfer"
                       width="full"
                       wrapperClass="operation-item-one-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="300000"
                       label="Лимит на рабочий день"
                       name="LimitForDay"
                       width="full"
                       wrapperClass="operation-item-two-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="organizationPointForm"></app-input>

            <app-input inputType="text"
                       placeholder="3000000"
                       label="Лимит на месяц"
                       name="LimitForMonth"
                       width="full"
                       wrapperClass="operation-item-three-third"
                       mask="separator.2"
                       maskSuffix=" RUB"
                       [controlInvalid]="submittedLimits"
                       [parentForm]="organizationPointForm"></app-input>

            <div (click)="updateOrganizationPointLimits()" class="download-title t-align-center">Обновить лимиты</div>
          </div>

          <div *ngIf="organizationPoint && topMenu.currentIndex === 4">
            <ng-container *ngIf="organizationPoint && hardwareOrganizationPoints && hardwareOrganizationPoints.totalCount > 0 else emptyList">
              <div class="table-wrapper-flex hardware-table" *ngFor="let item of hardwareOrganizationPoints.items">
                <div class="table-header hardware-columns">
                  <div class="hardware-name-row item hardware-title">Копмьютер/Пользователь</div>
                  <div class="hardware-name-row item hardware-value">{{ item.machineName }} {{ item.userName }}</div>
                  <div class="hardware-btn-row item hardware-remove"><span class="font-b color-main pointer" (click)="removeHardwareFromPoint(item)">Отвязать</span></div>
                </div>
                <div class="table-body hardware-table">
                  <div class="table-columns hardware-columns">
                  <div class="hardware-name-row item hardware-title">Идентификатор ЦП</div>
                  <div class="hardware-name-row item hardware-value">{{ item.cpu }}</div>
                  <div class="hardware-btn-row item hardware-remove"></div>
                </div>
                <div class="table-columns hardware-columns">
                  <div class="hardware-name-row item hardware-title">Идентификатор диска</div>
                  <div class="hardware-name-row item hardware-value">{{ item.hard }}</div>
                  <div class="hardware-btn-row item hardware-remove"></div>
                </div>
                <div class="table-columns hardware-columns">
                  <div class="hardware-name-row item hardware-title">Идентификатор дисков</div>
                  <div class="hardware-name-row item hardware-value" appClipboard>{{ item.hardAll }}</div>
                  <div class="hardware-btn-row item hardware-remove"></div>
                </div>
                <div class="table-columns hardware-columns">
                  <div class="hardware-name-row item hardware-title">Идентификатор платы</div>
                  <div class="hardware-name-row item hardware-value">{{ item.motherBoard }}</div>
                  <div class="hardware-btn-row item hardware-remove"></div>
                </div>
              </div>
              </div>
            </ng-container>
          </div>

         

        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
