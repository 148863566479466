<div class="page-wrapper" id="support_page">
  <div class="support-wrapper" id="support_wrapper">
    <div class="support">
      <p class="purple font-b">Появились вопросы?</p>
      <ng-container *ngIf="phoneArr && phoneArr.length > 0">
        <!-- <p>Позвоните по телефону</p>
        <p *ngFor="let phone of phoneArr; let i=index"><a class="download-title" [href]="'tel:' + phone">{{phone | phonePipe}}</a></p> -->
        <p>Напишите нам</p>
      </ng-container>
      <p><a class="download-title" href="mailto:mtspay@mtsbank.ru">mtspay@mtsbank.ru</a></p>
      <p>Мы всегда рады Вам помочь!</p>
      <div class="t-align-center">
        <button class="button unfilled decoration-under" (click)="createTestCheck()">Тестовый чек</button>
      </div>
    </div>
  </div>
</div>
