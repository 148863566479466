import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {IDictionaries, IStepsControlHelper,} from '../../../../kernel/models/common.models';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorService} from "../../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../../kernel/enum/builder-command.enum";
import {getRandomFirstName, getRandomLastName, getRandomMiddleName, rnd} from "../../../../kernel/helpers/data.helper";

@Component({
  selector: 'app-operation-second-step',
  templateUrl: './operation-client-step.component.html',
  styleUrls: [
    './operation-client-step.component.scss',
    '../../pages/mts-operation/mts-operation.component.scss',
  ],
})
export class OperationClientStepComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  clientHelper: IStepsControlHelper
  selectedDataAll: IDictionaries
  searchNumber: string

  constructor(
    public dialogRef: MatDialogRef<OperationClientStepComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientHelper: IStepsControlHelper
      selectedDataAll: IDictionaries
      searchNumber: string
    },
    private behavior: BehaviorService
    ) {
    if (!data) {
      this.close();
    }
    this.clientHelper = data.clientHelper;
    this.selectedDataAll = data.selectedDataAll;
    this.searchNumber = data.searchNumber;
  }

  ngOnInit(): void {
    this.clientHelper.items.map((item, index) => {
      if (
        item.isFormArray &&
        (!item.controlsArr || item.controlsArr.length === 0)
      ) {
        this.addFormArrayItem(item.id);
      }
    });
    if (this.searchNumber) {
      const searchReg = /^(\d{4})(\d{6})$/.exec(this.searchNumber);
      if (searchReg) {
        this.clientHelper.formArray.controls.map(formGroup => {
          if (formGroup.get(`documents_series`)) { formGroup.get(`documents_series`).setValue(searchReg[1]); }
          if (formGroup.get(`documents_number`)) { formGroup.get(`documents_number`).setValue(searchReg[2]); }
        });
      }
    }
  }

  addFormArrayItem(stepId: string): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.AddFormArrayItem,
      model: { stepId, model: null, operationIndex: -1 }
    });
  }

  removeFormArrayItem(stepId: string, arrayId: string): void {
    this.behavior.operationBuilderCommandStatus.next({
      type: BuilderCommandEnum.RemoveFormArrayItem,
      model: { stepId, arrayId, operationIndex: -1 }
    });
  }

  close(): void {
    this.dialogRef.close(this.clientHelper);
  }

  fillByTestDataClient(): void {
    this.clientHelper.formGroup.get(`mainInfo_lastName`).setValue(getRandomLastName());
    this.clientHelper.formGroup.get(`mainInfo_firstName`).setValue(getRandomFirstName());
    this.clientHelper.formGroup.get(`mainInfo_middleName`).setValue(getRandomMiddleName());
    // this.clientHelper.formGroup.get(`mainInfo_lastName`).setValue('Серпухов');
    // this.clientHelper.formGroup.get(`mainInfo_firstName`).setValue('Антов');
    // this.clientHelper.formGroup.get(`mainInfo_middleName`).setValue('Семенович');
    this.clientHelper.formGroup.get(`mainInfo_countryOfResidence`).setValue('Россия');
    this.clientHelper.formGroup.get(`mainInfo_citizenship`).setValue('Россия');
    this.clientHelper.formGroup.get(`mainInfo_gender`).setValue('Мужской');
    this.clientHelper.formGroup.get(`mainInfo_birthDate`).setValue(new Date('1991-03-04T00:00:00'));
    this.clientHelper.formGroup.get(`registrationAddress_zipCode`).setValue('331451');
    this.clientHelper.formGroup.get(`registrationAddress_countryCode`).setValue('Россия');
    this.clientHelper.formGroup.get(`registrationAddress_region`).setValue('Москва');
    this.clientHelper.formGroup.get(`registrationAddress_city`).setValue('Москва');
    this.clientHelper.formGroup.get(`registrationAddress_street`).setValue('Чепаева');
    this.clientHelper.formGroup.get(`registrationAddress_house`).setValue('12');
    this.clientHelper.formGroup.get(`registrationAddress_apartment`).setValue('74');
    this.clientHelper.formArray.controls.map(formGroup => {
      if (formGroup.get(`phoneNumbers_number`)) { formGroup.get(`phoneNumbers_number`).setValue(`+79037464579`); }
      if (formGroup.get(`documents_type`)) { formGroup.get(`documents_type`).setValue(`Паспорт РФ`); }
      if (formGroup.get(`documents_series`)) { formGroup.get(`documents_series`).setValue(rnd(4)); }
      //if (formGroup.get(`documents_series`)) { formGroup.get(`documents_series`).setValue(`4157`); }
      if (formGroup.get(`documents_number`)) { formGroup.get(`documents_number`).setValue(rnd(6)); }
      //if (formGroup.get(`documents_number`)) { formGroup.get(`documents_number`).setValue(`491596`); }
      if (formGroup.get(`documents_issuer`)) { formGroup.get(`documents_issuer`).setValue(`ГУВД УВД по г Москва`); }
      if (formGroup.get(`documents_issuerDepartmentCode`)) { formGroup.get(`documents_issuerDepartmentCode`).setValue(`000-000`); }
      if (formGroup.get(`documents_issueDate`)) { formGroup.get(`documents_issueDate`).setValue(new Date('2016-05-11T00:00:00')); }
    });
  }
}
