import {Pipe, PipeTransform} from '@angular/core';
import {convertToCardNumber} from "./pipe.helper";

@Pipe({
  name: 'cardNumber',
})
export class CardNumberPipe implements PipeTransform {
  transform(data: string): string {
    return convertToCardNumber(data);
  }
}
