export enum DataTypeEnum {
  Number = 'number',
  String = 'string',
  Boolean = 'boolean'
}
export enum DatePipeTypeEnum {
  Phone = 'Phone',
  Date = 'Date',
  DateTime = 'DateTime',
  Cash = 'Cash',
  YesOrNo = 'YesOrNo',
  Usual = 'Usual',
  Rate = 'Rate',
  OperationStatus = 'OperationStatus',
  Warning = 'Warning',
}
