import {HomeRedirect, SetToken, SetUser} from '../../../../kernel/store/actions/user.actions';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {ApiClient, IHardwareData, LoggerErrorTypeEnum} from '../../../../kernel/ApiClient';
import {NotifyService} from '../../../core/services/notify.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {errorMessages} from '../../../../kernel/constants/errors';
import {AuthService} from '../../services/auth.service';
import {Navigate} from '@ngxs/router-plugin';
import {isExpectedError, mapInvalidFields} from '../../../../kernel/helpers/data.helper';
import {FormGroup} from "@angular/forms";
import {UserState} from "../../../../kernel/store/state/user.state";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AuthHardwareProposalComponent} from "../../dialogs/auth-hardware-proposal/auth-hardware-proposal.component";
import {LoginProviderEnum} from "../../../../kernel/enum/login-provider.enum";


@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss', '../auth-area/auth-area.component.scss']
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.hardware) hardware$: Observable<IHardwareData>;
  subs = new Subscription();

  hardware: IHardwareData;
  isSubmit = false;
  proposalActive = false;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private store: Store,
    private apiClient: ApiClient,
    private notify: NotifyService,
  ) {
  }

  get authGroup(): FormGroup {
    return this.authService.authGroup;
  }
  get isLoginCorrect(): boolean {
    return this.authService.isLoginCorrect;
  }

  ngOnInit(): void {
    this.subs.add(this.hardware$.subscribe(hardware => {
      this.hardware = hardware;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/auth/${url}`]));
  }

  onLogin(): void {
    this.isSubmit = true;
    if (!this.authService.isLoginCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }

    this.apiClient
      .auth_Login(this.authService.loginPayload(this.hardware))
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(token => this.store.dispatch(new SetToken(token))),
        switchMap(() => this.apiClient.users_GetCurrentUser())
      )
      .subscribe(
        user => {
          this.authService.setDefaultState();
          this.store.dispatch(new SetUser(user.user));
          this.store.dispatch(new HomeRedirect());
        },
        error => {
          if (error?.type === LoggerErrorTypeEnum.HardwareProposal) {
            this.openProposal();
          } else {
            this.notify.error(this.authService.showError(error));
            console.error(error);
          }
        }
      );
  }

  openProposal(): void {
    if (this.proposalActive) {
      return;
    }
    this.proposalActive = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const dialog = this.dialog.open(AuthHardwareProposalComponent, dialogConfig);
    dialog.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.proposalActive = false;
      })
  }
}
