import {Injectable} from '@angular/core';
import {BillingVariablesService} from "./billing-variables.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {IUnitListResponse} from "../../../kernel/models/billing/kkm.models";
import {CashboxListDialogComponent} from "../dialogs/cashbox-list-dialog/cashbox-list-dialog.component";
import {ICreateBillRequest, ICreatePreBillRequest} from "../../../kernel/ApiClient";
import {BillPreviewDialogComponent} from "../dialogs/bill-preview-dialog/bill-preview-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class BillingHelperService {

  constructor(
    private variables: BillingVariablesService,
    private dialog: MatDialog,
  ) {
  }

  openCashboxListDialog(cashbox: IUnitListResponse, payload: ICreateBillRequest, prePayload: ICreatePreBillRequest): void {
    if (this.variables.cashboxListDialogOpen) {
      return;
    }
    this.variables.cashboxListDialogOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { cashbox, payload, prePayload };
    this.variables.cashboxListDialogSub = this.dialog.open(CashboxListDialogComponent, dialogConfig);
    this.variables.cashboxListDialogSub.afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.variables.cashboxListDialogOpen = false;
      });
  }

  openBillPreviewDialog(payload: ICreateBillRequest, prePayload: ICreatePreBillRequest): void {
    if (this.variables.billPreviewDialogOpen) {
      return;
    }
    this.variables.billPreviewDialogOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { payload, prePayload };
    this.variables.billPreviewDialogSub = this.dialog.open(BillPreviewDialogComponent, dialogConfig);
    this.variables.billPreviewDialogSub.afterClosed()
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(data => {
        this.variables.billPreviewDialogOpen = false;
      });
  }
}
