import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {fadeInOut, fadeInOutUp, fadeInOutUpStagged} from 'src/kernel/animation';

@Component({
  selector: 'app-control-validator',
  templateUrl: './control-validator.component.html',
  styleUrls: ['./control-validator.component.scss'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged],
})
export class ControlValidatorComponent {
  @Input() showErrors = false;
  @Input() nowrap = false;
  @Input() name = '';
  @Input() parentForm?: FormGroup;
  @Input() fromComponent = '';

  get currentControl(): AbstractControl {
    return this.parentForm && this.parentForm.controls[this.name];
  }

  get wrapperErrorHeight(): number {
    return (
      24 *
      (!!this.currentControl && this.showErrors
        ? this.errorCount + this.apiErrorCount
        : 0)
    );
  }

  get errorCount(): number {
    return !!this.currentControl && !!this.currentControl.errors
      ? Object.keys(this.currentControl.errors).length
      : 0;
  }

  get apiErrorCount(): number {
    return !!this.currentControl && !!this.currentControl['apiErrors']
      ? this.currentControl['apiErrors'].length
      : 0;
  }

  showControlErrors(): void {
    console.log(this.currentControl);
  }
}
