<div class="expand" [ngClass]="{'open': expanded}" id="operationSettings">

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/papers.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">БИЛЛИНГ ОПЕРАЦИИ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <div class="expand-table-container" [style.min-height.px]="250" *ngIf="billingForm">

      <app-input inputType="text"
                 label="Номер операции"
                 name="TransferNumber"
                 width="full"
                 wrapperClass="operation-half-item-left"
                 [controlInvalid]="submitted"
                 [parentForm]="billingForm"></app-input>

      <div class="operation-half-item-right t-align-right">
        <button class="button main" [ngClass]="{'disabled': isLoad}" (click)="getBillingInfo()">Поиск</button>
      </div>

      <div class="billing-info-wrapper" *ngIf="info">
        <app-info-array [info]="info"></app-info-array>
      </div>

      <div class="mt-30" *ngIf="!!billingInfo && !!billingInfo.operation">
        <button class="button unfilled decoration-under d-block m-auto" (click)="updateBillInfo(checkTypes.FirstCheck, billingInfo.operation.firstBill)">{{billingInfo.operation.firstBill ? 'Удалить' : 'Сделать'}} запись о предчеке</button>
        <button class="button unfilled decoration-under d-block m-auto" (click)="updateBillInfo(checkTypes.SecondCheck, billingInfo.operation.secondBill)">{{billingInfo.operation.secondBill ? 'Удалить' : 'Сделать'}} запись о чеке</button>
        <button class="button unfilled decoration-under d-block m-auto" (click)="updateBillInfo(checkTypes.RejectFirstCheck, billingInfo.operation.firstRejectBill)">{{billingInfo.operation.firstRejectBill ? 'Удалить' : 'Сделать'}} запись о предчеке отмены</button>
        <button class="button unfilled decoration-under d-block m-auto" (click)="updateBillInfo(checkTypes.RejectSecondCheck, billingInfo.operation.secondRejectBill)">{{billingInfo.operation.secondRejectBill ? 'Удалить' : 'Сделать'}} запись о чеке отмены</button>
      </div>

    </div>
  </div>
</div>
