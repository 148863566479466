<section class="main-operations-wrapper" *ngIf="operationTypes">
  <h1 class="main-title">Операции</h1>

  <div class="cards-wrapper mb-50" *ngIf="operationTypes.items">
    <div class="card-item-wrapper" *ngFor="let item of operationTypes.items">
      <div class="card-item" (click)="goto(item.url)">
        <div class="card-img-wrapper" [style]="'background-image: url(' + item.ico + ');'">
        </div>
        <div class="card-title-wrapper">
          <p class="card-title">{{item.title}}</p>
        </div>
      </div>
    </div>

    <!--<div class="card-item-wrapper">
      <div class="card-item">
        <div class="card-img-wrapper">
          <img src="../../../../assets/img/icons/3-spin-dots.svg">
        </div>
        <p class="card-title">Денежный перевод</p>
      </div>

      <div class="card-tooltip-wrapper" *ngIf="showIndex === 3">
        <div class="card-tooltip">
          <p class="card-tooltip-header">Список подопераций</p>
          <div class="card-tooltip-item">
            <img src="../../../../assets/img/icons/3-spin-dots.svg">
            <p class="card-tooltip-title">Операция 1</p>
          </div>
          <div class="card-tooltip-item">
            <img src="../../../../assets/img/icons/3-spin-dots.svg">
            <p class="card-tooltip-title">Операция 1</p>
          </div>
          <div class="card-tooltip-item">
            <img src="../../../../assets/img/icons/3-spin-dots.svg">
            <p class="card-tooltip-title">Операция 1</p>
          </div>
        </div>
      </div>
    </div>





    <div class="card-item-wrapper">
      <div class="card-item">
        <div class="card-img-wrapper">
          <img src="../../../../assets/img/icons/3-spin-dots.svg">
        </div>
        <p class="card-title">Денежный перевод</p>
      </div>
    </div>
    <div class="card-item-wrapper">
      <div class="card-item">
        <div class="card-img-wrapper">
          <img src="../../../../assets/img/icons/3-spin-dots.svg">
        </div>
        <p class="card-title">Денежный перевод</p>
      </div>
    </div>-->

  </div>

</section>
