import {OperationVariableService} from "./operation-variable.service";
import {Injectable} from "@angular/core";
import {OperationListenerHelperService} from "./operation-listener-helper.service";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {IStepsControlHelper} from "../../../kernel/models/common.models";
import {operationAddressesArray} from "../../../kernel/constants/common.constants";
import {BehaviorService} from "../../core/services/behavior.service";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {
  ApiClient, BillingTypeEnum,
  IOperationConfirmRequest,
  IOperationItemFilterResponse,
  IPaymentHubOperationCreateRequest, IPaymentHubResponseOfPaymentHubOperationCheckStatusResponse,
  IPaymentHubResponseOfPaymentHubOperationCreateResponse,
  ITransfer,
  LoggerErrorTypeEnum,
  OperationAddOrUpdateRequestOfTransfersCreateRequest,
  OperationConfirmRequest,
  OperationItemFilterResponse,
  OperationTypeEnum,
  PaymentHubOperationCreateRequest,
  PaymentHubResponseOfPaymentHubOperationCreateResponse,
  PaymentHubResponseOfPaymentHubOperationPayResponse,
  TransferResponse,
  TransfersConfirmRequest,
  TransfersCreateRequest
} from "../../../kernel/ApiClient";
import {NotifyService} from "../../core/services/notify.service";
import {PopupLoaderTypeEnum} from "../../../kernel/enum/popupLoaderTypeEnum.enum";
import {createOperationPayload} from "../../../kernel/helpers/operation.helper";
import {forkJoin, Observable} from "rxjs";
import {getErrorMessage} from "../../../kernel/helpers/data.helper";
import {OperationCommissionService} from "./operation-commission.service";
import {OperationHelperService} from "./operation-helper.service";
import {
  confirmPaymentHubOperationPayload,
  createPaymentHubOperationPayload
} from "../../../kernel/helpers/payment-hub-helpers/payment-hub.helper";


@Injectable({
  providedIn: 'root',
})
export class OperationPaymentHubService {
  constructor(
    private variables: OperationVariableService,
    private behavior: BehaviorService,
    private commission: OperationCommissionService,
    private helper: OperationHelperService,
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {
  }

  createPaymentHubOperation(): void {
    if (!this.variables.isReady) {
      return;
    }
    this.behavior.popupLoaderStatus.next(PopupLoaderTypeEnum.CreateOperation);
    let arr: any[] = [];
    this.variables.operationInfoArray.map((x, operationIndex) => {
      const payload = createPaymentHubOperationPayload(
        this.variables.client?.clientId,
        x.formGroup,
        this.variables.operationTypeEnum.type
      ) as PaymentHubOperationCreateRequest;
      arr.push(this.apiClient.operationPaymentHub_CheckToCreatePaymentHubOperation(payload));
    });
    this.variables.blockAllActions = true;
    forkJoin(arr)
      .pipe(takeUntil(this.variables.ngUnsubscribe))
      .subscribe(
        (data: IPaymentHubResponseOfPaymentHubOperationCreateResponse[]) => {
          const arr = [];
          data.map((x, index) => {
            this.variables.operationInfoArray[index].operation = x.operation as IOperationItemFilterResponse;
            arr.push(x.operation);
          });
          this.variables.operationCreated = true;
          this.variables.blockAllActions = false;
          this.helper.clientAndOperationCreatedDisableForms();

          this.variables.paymentHubOperations = arr;
          this.behavior.popupLoaderStatus.next(null);
          // TODO
          // this.initOperationsStatusTimer();
          this.behavior.operationBuilderCommandStatus.next({
            type: BuilderCommandEnum.CheckCashboxCountBeforeBilling,
            model: { payload: {
                numDevice: 0,
                type: BillingTypeEnum.FirstCheck,
                isPreview: true,
                isFromQueue: false,
                operationId: this.variables.paymentHubOperations[0].transferId
              }}
          });
        },
        (error) => {
          if (error.type === LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance) {
            this.behavior.infoDialogListenerStatus.next({
              type: LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance,
              title: error.message
            });
          }
          this.behavior.popupLoaderStatus.next(null);
          this.variables.blockAllActions = false;
          this.variables.operationCreated = false;
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
    // this.apiClient.transfers_Create()
  }

  tryConfirmOperation(countTry: number): void {
    setTimeout(() => {
      this.helper.fillOperationsForConfirmLoader();
      let arr: Observable<PaymentHubResponseOfPaymentHubOperationPayResponse>[] = [];
      this.variables.operationInfoArray.map(x => {
        if (!!x.operation) {
          const payload = confirmPaymentHubOperationPayload(x.operation.id) as OperationConfirmRequest;
          arr.push(this.apiClient.operationPaymentHub_ConfirmAndPayPaymentHubOperation(payload as OperationConfirmRequest));
        }
      });
      forkJoin(arr)
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(
          (data) => {
            const arr = [];
            data.map((x, index) => {
              this.variables.operationInfoArray[index].operation = x.operation as IOperationItemFilterResponse;
              arr.push(x.operation);
            });
            this.variables.paymentHubOperations = arr;
            this.helper.fillOperationsForConfirmLoader();
            this.behavior.operationBuilderCommandStatus.next({
              type: BuilderCommandEnum.InitOperationsStatusTimer
            });
          },
          (error) => {
            if (error?.type === LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance) {
              countTry = 0;
              this.behavior.infoDialogListenerStatus.next({
                type: LoggerErrorTypeEnum.OperationCreateOrConfirmLowBalance,
                title: error.message
              });
            }
            if (countTry > 0) {
              countTry--;
              this.tryConfirmOperation(countTry--);
            } else {
              this.variables.blockAllActions = false;
              this.notify.error(getErrorMessage(error));
            }
            console.error(error);
          },
        );
    }, (10 - countTry) * 1000);
  }

  checkPaymentHubOperationStatuses(): void {
    setTimeout(() => {
      if (!this.variables.isReady) {
        return;
      }
      if (!this.variables.requestIds || this.variables.requestIds.length === 0) {
        this.behavior.popupLoaderStatus.next(null);
        this.variables.blockAllActions = false;
        this.behavior.operationBuilderCommandStatus.next({
          type: BuilderCommandEnum.StopOperationsStatusTimer
        });
        return;
      }
      const arr = [];
      this.variables.requestIds.map(id => {
        arr.push(this.apiClient.operationPaymentHub_CheckStatusPaymentHubOperation(id))
      });

      forkJoin(arr)
        .pipe(takeUntil(this.variables.ngUnsubscribe))
        .subscribe(
          (data: IPaymentHubResponseOfPaymentHubOperationCheckStatusResponse[]) => {
            const arr = [];
            data.map((x, index) => {
              this.variables.operationInfoArray[index].operation = x.operation as IOperationItemFilterResponse;
              arr.push(x.operation);
            });
            this.variables.paymentHubOperations = arr;
            this.helper.fillOperationsForConfirmLoader();
            if (this.variables.confirmDialogType === 0) {
              if (this.variables.countRequestStatusTry > 0) {
                this.variables.countRequestStatusTry--;
                this.checkPaymentHubOperationStatuses();
              }
            }
          },
          error => {
            if (this.variables.countRequestStatusTry > 0) {
              this.variables.countRequestStatusTry--;
              this.checkPaymentHubOperationStatuses();
            } else {
              this.behavior.popupLoaderStatus.next(null);
              this.variables.requestIds = [];
              this.behavior.operationBuilderCommandStatus.next({
                type: BuilderCommandEnum.StopOperationsStatusTimer
              });
              this.notify.error(getErrorMessage(error));
            }
            console.error(error);
          }
        );
    }, (this.variables.countRequestStatusTry) * 1000);
  }
}
