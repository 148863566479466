<div class="dialog-wrapper" *ngIf="isReady">
  <header class="dialog-header">
    <p class="dialog-title">Выберите систему переводов</p>
    <img
            src="../../../../assets/img/icons/close.svg" (click)="close(null, null)" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper commission-list-wrapper">
    <div class="operation-summary-wrapper commission-list-item-wrapper"
         *ngFor="let item of info; let ngItemIndex=index"
         [ngClass]="{'parent': !item.array || item.array.length === 0, 'top': info?.length > 1 && ngItemIndex === 0}">
      <p class="operation-summary-title" [ngClass]="{'sub': item.array && item.array.length > 0}"> {{item.title }}</p>

      <ng-container *ngFor="let array of item.array; let ngArrayIndex=index">
        <ng-container *ngFor="let arrayItem of array.items; let ngArrayItemIndex=index">
          <div class="operation-summary-items-wrapper" *ngIf="arrayItem?.title && arrayItem?.value">
            <div class="operation-summary-item-wrapper">
              <div class="operation-summary-item-title">{{arrayItem.title}}:</div>
              <div class="operation-summary-item-value" [ngClass]="{'rate-value': arrayItem.isRate}">{{arrayItem.value}}</div>
            </div>
          </div>
        </ng-container>
        <div class="t-align-center">
          <button
            class="button unfilled decoration-under"
            [style.width.px]="230"
            (click)="setOperationCommission(ngItemIndex)">Выбрать</button>
        </div>
      </ng-container>
    </div>
  </div>

  <footer>
    <div class="t-align-center">
      <button
        class="button border decoration-under"
        [style.width.px]="140"
        (click)="close(null, null)"
      >Закрыть</button>
    </div>
  </footer>
</div>
