import {Pipe, PipeTransform} from '@angular/core';
import {BillingTypeEnum} from "../ApiClient";
import {billTypeRu} from "../constants/common.constants";
import {getBillTitle} from "../helpers/data.helper";

@Pipe({
  name: 'billType',
})
export class BillTypePipe implements PipeTransform {
  transform(type: BillingTypeEnum): string {
    return getBillTitle(type);
  }
}
