import {IOperationStatusHelper} from "../models/common.models";

export enum OperationStatusEnum {
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
  NOT_FOUND = 'NOT_FOUND',
  TIMEOUT_RECEIVE = 'TIMEOUT_RECEIVE',
  REJECTED = 'REJECTED',
  EXECUTED = 'EXECUTED',
  PAID = 'PAID'
}

export const operationStatusesHelper = {
  created: {
    status: 'Создан',
    hint: 'Перевод создан в системе Мультитрансфер, а также создан в СДП',
    isFinal: false
  },
  processed: {
    status: 'Подтвержден',
    hint: 'Перевод успешно подтвержден',
    isFinal: true
  },
  processing: {
    status: 'Ожидает подтверждения',
    hint: 'Перевод принят в СДП и ожидает подтверждения. При получении этого статуса клиент получает чек и квитанцию и может ожидать подтверждения перевода',
    isFinal: true
  },
  notFound: {
    status: 'Не найден',
    hint: 'Перевод, который ранее был создан, не найден в платежной системе. При появлении подобного статуса необходимо создать новый запрос на перевод',
    isFinal: true
  },
  timeoutReceive: {
    status: 'Подтвердите еще раз',
    hint: 'Необходимо выполнить повторный запрос на подтверждение. Если подтверждение не выполнено, перевод через 24 часа перейдет в статус Отклонена',
    isFinal: false
  },
  rejected: {
    status: 'Отклонена',
    hint: 'Невозможности подтвердить перевод СДП',
    isFinal: true
  },
  executed: {
    status: 'Подтвержден СДП',
    hint: 'Перевод успешно подтвержден СДП',
    isFinal: true
  },
  paid: {
    status: 'Оплачен',
    hint: 'Перевод успешно оплачен СДП',
    isFinal: true
  },
  empty: {
    status: '',
    hint: 'Не удалось определить статус',
    isFinal: true
  }
};

export const notFinalStatuses = [OperationStatusEnum.CREATED, OperationStatusEnum.TIMEOUT_RECEIVE];
export const readyForConfirmStatuses = [OperationStatusEnum.PROCESSING];
export const successFinalStatuses = [OperationStatusEnum.EXECUTED, OperationStatusEnum.PAID, OperationStatusEnum.PROCESSED];
export const errorFinalStatuses = [OperationStatusEnum.NOT_FOUND, OperationStatusEnum.REJECTED];
