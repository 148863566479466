<div [id]="'input_' + id" [formGroup]="parentForm" *ngIf="parentForm" [class]="getWrapperClass()" [id]="wrapperId" itype="input"
(mouseenter)="setActiveWrapper(true)" (mouseenter)="setActiveWrapper(false)">
  <div [class]="inputWrapperClass" [ngClass]="{'invalid': setInvalidClass, 'input-drop': readonly}">
    <label [id]="'label_' + wrapperId" *ngIf="!!label">{{label}}<span class="color-red" *ngIf="requiredField">*</span></label>
    <div class="inputBox-wrapper" *ngIf="!onlyVisible else onlyVisibleInput">
      <input autocomplete="off" name="hidden" type="text" style="display:none;">

      <div [class]="label ? 'input-left-icon-container-with-label' : 'input-left-icon-container'" *ngIf="!!inputIcon">
        <img [class]="'input-img input-left-icon-container-img ' + inputIconClass" [src]="inputIcon">
      </div>
      <input (focus)="setFocusInput(true)" (focusout)="setFocusInput(false)" [class]="(isPhoneInput||isSearchInput)&&isIcon ? 'input-left-icon' : ''" [readonly]="readonly"
        *ngIf="isTextInput||isPasswordInput||isPasswordInput||isPhoneInput||isSearchInput" [ngClass]="{'invalid': setInvalidClass}"
        [formControlName]="name" (keydown)="inputKeyDown($event)" [attr.maxLength]="!!maxLength ? maxLength : null" [id]="id" [mask]="mask" [suffix]="maskSuffix" [patterns]="maskRegExp" [prefix]="maskPrefix" [autocomplete]="autocomplete"
        appAutocomplete [type]="isPasswordInput && !pwdEyeOn || isSearchInput ? 'text' : inputType" [placeholder]="placeholder" #inputField>
      <input (focus)="setFocusInput(true)" (focusout)="setFocusInput(false)" [class]="(isPhoneInput||isSearchInput)&&isIcon ? 'input-left-icon' : ''" [readonly]="readonly"
        *ngIf="isNumberInput||isStringDateInput" [ngClass]="{'invalid': setInvalidClass}"
        (keypress)="onNumberInputChange($event)" [mask]="mask" [suffix]="maskSuffix" [prefix]="maskPrefix" [patterns]="maskRegExp"
        [formControlName]="name" [attr.maxLength]="!!maxLength ? maxLength : null" [id]="id" [autocomplete]="autocomplete"
        appAutocomplete [placeholder]="placeholder" #inputField>


        <ng-container *ngIf="isTextAreaInput">
          <textarea
          [ngClass]="{'invalid': setInvalidClass}"
          [rows]="rows"
          [formControlName]="name"
          [id]="'textarea_' + id"
          [placeholder]="placeholder"
          appAutocomplete
          [readonly]="readonly" appReadonly
          (mouseover)="focusedInputId = id"
          (keyup)="inputKeyUp($event)"
          (keydown)="inputKeyDown($event)"
          [autofocus]="(!disabled || !readonly) && autofocus"
          [maxLength]="maxLength"
          #inputField
          >
          </textarea>
          <div class="text-area-size">{{!!currentControl && !!currentControl.value ? currentControl.value.length : 0}}/{{maxLength}}</div>
        </ng-container>

    <div  *ngIf="isSelectInput" #selectInput>
      <input data-cy="add-account-select" (focus)="setFocusInput(true)" (focusout)="setFocusInput(false)" [readonly]="readonly" [formControlName]="name" [id]="id" autocomplete="new-password"
        [type]="inputType" [placeholder]="placeholder" (click)="selectOpenSetValue(true)" (input)="selectorInputSearch($event)" [ngClass]="{'invalid': setInvalidClass, 'hide-caret': isSelectInput && onlySelect}"
        (keyup)="inputKeyUp($event)" (keydown)="inputKeyDown($event)" #inputField>

      <span class="clear" *ngIf="selectedItem && !disabled && selectClear"
        (click)="clearSelect()">×</span>
      <div (click)="selectOpenSetValue(!selectOpenVal)" class="select-arrow-container">
        <img class="select-arrow" [ngClass]="{'open': selectOpenVal}" [src]="arrowSrc">
      </div>

      <div *ngIf="selectOpenVal" class="inputBox-selector"
      [id]="'content_' + id"
        delayClickOutsideInit="true" #selectList
        [style.max-height.px]="contentMaxHeight" [style.min-height.px]="contentMinHeight" [style.min-width.px]="contentMinWidth">

                      <div
                        *ngIf="selectData && selectData.length > 0 && multiSelect"
                        [ngClass]="{
    'inputBox-selector-item': true,
    'inputBox-selector-item--active': isMultiselectAll
  }"
                        (click)="selectAll()"
                      >
  <div id="select0" class="select-inner d-flex-vcenter">
    <div class="d-inline-block multi-select-checkbox">
      <app-checkbox
        [state]="isMultiselectAll"
        (change)="selectAll()"
      ></app-checkbox></div>
    <div *ngIf="multiSelect" class="d-inline-block multi-select-title-wrapper">
      <div class="multi-select-title">
        {{ multiselectAllTitle }}
      </div>
    </div>
  </div>
</div>

        <div [ngClass]="{
    'inputBox-selector-item': true,
    'pb-10': i === selectData.length - 1,
    'inputBox-selector-item-left-active': (selectedItem === item.id || selectedItem === item.label || focusedSelectIndex === i) && isLeftSelect,
    'inputBox-selector-item-common-active': (selectedItem === item.id || selectedItem === item.label || focusedSelectIndex === i) && !isLeftSelect
  }" *ngFor="let item of (selectData); let i = index; trackBy:trackBy" (click)="selectorClicked(item, i)"
          [attr.data-value]="!!item || item.id || item.label">
          <div id="select{{i}}" class="select-inner" [ngClass]="{'d-flex-vcenter': multiSelect}">
            <div class="d-inline-block multi-select-checkbox" [style.margin-right.px]="10" *ngIf="multiSelect">
              <app-checkbox
                name="checked"
                [parentForm]="multiSelectArr.controls[i]"
                (change)="selectorClicked(item, i)"
              ></app-checkbox></div>
            <div *ngIf="!multiSelect" class="d-inline-block">
              {{ item.label }}
            </div>
            <div *ngIf="multiSelect" class="d-inline-block multi-select-title-wrapper">
              <div class="multi-select-title">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="select-inner load-empty" *ngIf="!selectData || selectData.length === 0">
          <div [style.opacity]=".6">
            {{ isLoading ? 'Загрузка...' : 'Список пуст' }}
          </div>
        </div>
      </div>
    </div>

    <div class="picker-toggle" *ngIf="isCheckIcon || isPasswordInput">
      <img *ngIf="isCheckIcon" [src]="checkIconState ? '../../../../assets/img/icons/check-ok.svg' : '../../../../assets/img/icons/check-fail.svg'">
      <img class="pointer" *ngIf="isPasswordInput" [src]="pwdEyeOn ? '../../../../assets/img/icons/eye-on.svg' : '../../../../assets/img/icons/eye-off.svg'" (click)="pwdEyeOn = !pwdEyeOn">
    </div>
    <ng-container *ngIf="isDateInput">
      <input (focus)="setFocusInput(true)" (focusout)="setFocusInput(false)" [matDatepicker]="picker" [readonly]="readonly"
      [formControlName]="name" [id]="id" autocomplete="new-password" [type]="inputType" [ngClass]="{'invalid': setInvalidClass}"
      (input)="checkDate($event)" [max]="maxDate" [min]="minDate"
        [placeholder]="placeholder" (keyup)="inputKeyUp($event)" (keydown)="inputKeyDown($event)" #inputField>
      <div data-cy="calendar" class="picker-toggle">
        <img src="../../../../assets/img/icons/calendar.svg" alt="Выбрать дату"
             (click)="(!readonly) && (picker.opened ? picker.close() : picker.open())">
      </div>
      <mat-datepicker #picker></mat-datepicker>
    </ng-container>

  </div>

    <ng-template #onlyVisibleInput>
      <input autocomplete="off" name="hidden" type="text" disabled [value]="currentControl.value">
    </ng-template>

    <div class="input-hint-bottom" *ngIf="!!hintBottom" [ngClass]="{'pt-15': isTextAreaInput}">
      {{hintBottom}}
    </div>


  <app-control-validator [parentForm]="parentForm" [name]="name" [showErrors]="controlInvalid" [nowrap]="nowrap"  [fromComponent]="inputType"></app-control-validator>

  </div>
  <div class="input-hint" [id]="'hint_' + wrapperId" [ngClass]="{'active': focusedInput}" [attr.hintShift]="hintShift" *ngIf="!!hint">
    <div class="input-hint-container">
      <span class="hint-arrow" [id]="'arrow_hint_' + wrapperId"></span>
      <span class="input-hint-text">{{ hint }}</span>
    </div>
    </div>
  <div class="input-ref" [id]="'ref_' + wrapperId" [ngClass]="{'active': focusedInput}" *ngIf="!!isLink">
    <div class="input-ref-container">
      <span class="input-ref-text">
          Узнать свой <a href="https://service.nalog.ru/static/personal-data.html?svc=inn&from=%2Finn.do">ИНН</a>
      </span>
    </div>
  </div>
</div>
