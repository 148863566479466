import {Injectable} from '@angular/core';
import {
  IBottomMenuHelper,
  ICalculateCommissionParams,
  ICommissionHelper,
  IControlCordHelper,
  IDictionaries,
  IOperationDataHelper,
  IOperationStatusHelper,
  IOperationStepItem,
  IStepsControlHelper
} from "../../../kernel/models/common.models";
import {
  IClientResponse, ICommission,
  IFindTransfersResponse, IOperationItemFilterResponse,
  IOperationTypeItemResponse,
  IOperationTypeResponse, ITransfer,
  ITransferResponse,
  OperationTypeEnum
} from "../../../kernel/ApiClient";
import {createBottomMenu, createOperationSteps} from "../../../kernel/helpers/operation.helper";
import {FormArray, FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {DecimalPipe, Location} from "@angular/common";
import {nullOrUndefinedOrEmpty} from "../../../kernel/helpers/data.helper";
import {IMtDynamicFiledHelper, IMtSchemaResponse} from "../../../kernel/mt-dynamic/models/mt-dynamic.models";

@Injectable({
  providedIn: 'root',
})
export class OperationVariableService {
  constructor(
    private decimalPipe: DecimalPipe,
    private location: Location
  ) {
  }
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  public clientHelper: IStepsControlHelper;
  public operationInfoArray: IStepsControlHelper[] = [];
  public selectedDataAll: IDictionaries;

  public operations: ITransferResponse[] = [];
  public paymentHubOperations: IOperationItemFilterResponse[] = [];
  public editOperationId = '';
  public operationType = '';
  public isReady = false;
  public countRequestStatusTry = 0;
  public blockAllActions = false;
  public isFirstConfirmData = false;
  public isFirst = true;
  public thirdSubmitted = false;
  public isClientNeed = true;
  public client: IClientResponse;
  public helperStep = 2;
  public currentStep = 1;
  public operationIndex = 0;
  public commissions: ICommissionHelper[] = [];
  public operationSteps: IOperationStepItem[] = createOperationSteps();
  public bottomMenu: IBottomMenuHelper[] = createBottomMenu();

  public operationHelperGroup: FormGroup;
  public lastFindDocumentNumber: string;

  public timerStopped = true;
  public timerSubscribe: any;
  public requestIds: string[] = [];

  public controlHelperArray: IControlCordHelper[] = [];
  public commissionCalculateParams: ICalculateCommissionParams;
  public commissionCalculateParamsCountCompare = 0;
  public currentNonDbClientFIO: string;

  public info: IOperationDataHelper[] = [];

  public operationTypes: IOperationTypeResponse;
  public operationTypeEnum: IOperationTypeItemResponse;

  public submitted = false;
  public loaderIsActive = false;
  public loaderSub: any;
  public clientListActive = false;
  public clientListSub: any;
  public clientDialogFirstOpen = false;
  public clientDialogFirstSub: any;

  public blockCommission = false;
  public isNeedCalculateCommission = true;
  public operationCreated = false;
  public clientCreated = false;

  public commissionSub;
  public commissionDialogSub;
  public isCommissionAcceptedAmount = false;

  public history: IFindTransfersResponse;

  public confirmDialogTitle: string;
  public confirmDialogMessage: string;
  public confirmDialogImgSrc: string;
  public confirmDialogType: number;
  public confirmDialogInfo: IOperationStatusHelper[];

  public firstBillPrintOrQueue = false;
  public secondBillPrintOrQueue = false;

  public transferSchema: IMtSchemaResponse | undefined = null;
  public useDefaultFields: boolean = false;
  public transferFields: IMtDynamicFiledHelper[] | undefined = [];
  public transferArrayFields: IMtDynamicFiledHelper[] | undefined = [];
  public transferDynamicFormGroup: FormGroup;
  public transferDynamicFormArray: FormArray;
  public transferForDynamicData: ITransfer;
  public dynamicPaymentSystemId: string;

  public confirmTitle =
    `Примите, пожалуйста, денежные средства от клиента, пересчитайте и укажите полученную сумму.` +
    `Далее нажмите кнопку "Подтвердить". Операция будет подтверждена.`;

  get canGoToOnlyConfirm(): boolean {
    return (this.clientCreated || !this.isClientNeed) && this.operationCreated;
  }

  get amountLabel(): string {
    return `Введите сумму, полученную от клиента (не менее ${this.decimalPipe.transform(this.commissionTotal, '1.2','ru')} ${this.commissionCurrency})`;
  }

  get fourthStepCorrect(): boolean {
    return !!this.operationHelperGroup && !this.operationHelperGroup?.get(`CashboxAmount`)?.errors &&
      this.tapedAmount >= this.commissionTotal;
  }

  get tapedAmount(): number {
    return this.operationHelperGroup.value.CashboxAmount;
  }

  get backAmount(): number {
    return this.tapedAmount - this.commissionTotal > 0 ?
      this.tapedAmount - this.commissionTotal :
      0;
  }

  get showCreateOperationBtn(): boolean {
    return this.currentStep === 2 && !this.canGoToOnlyConfirm;
  }

  get currentStepTitle(): string {
    return !!this.operationSteps &&
    !!this.operationSteps[this.currentStep - 1] ?
      this.operationSteps[this.currentStep - 1].title :
      '';
  }

  get commissionTotal(): number {
    let result = 0;
    this.commissions.map(x => { result += Number(x.total) + Number(x.orgCom) });
    return result;
  }

  get commissionCurrency(): string {
    if (!this.commissions || !this.commissions[0]) {
      return 'RUB';
    }
    return this.commissions[0].currency;
  }

  get forwardBtnTitle(): string {
    switch (this.currentStep) {
      case 2:
        return this.showCreateOperationBtn ? 'Создать' : 'Далее';
      case 3:
        return 'Подтвердить';
      default:
        return 'Далее';
    }
  }
  get backBtnTitle(): string {
    switch (this.currentStep) {
      case 2:
        return 'Изменить';
      default:
        return 'Назад';
    }
  }

  get canTryShowName(): boolean {
    return !!this.client || this.clientDialogFirstOpen || this.currentNonDbClientFIO?.length > 0;
  }

  get clientId(): string {
    return !!this.client ? this.client.clientId : null;
  }

  get clientName(): string {
    if (this.client) {
      const arr = [];
      if (this.clientHelper.formGroup.get(`mainInfo_lastName`)?.value) { arr.push(this.clientHelper.formGroup.get(`mainInfo_lastName`)?.value) }
      if (this.clientHelper.formGroup.get(`mainInfo_firstName`)?.value) { arr.push(this.clientHelper.formGroup.get(`mainInfo_firstName`)?.value) }
      if (this.clientHelper.formGroup.get(`mainInfo_middleName`)?.value) { arr.push(this.clientHelper.formGroup.get(`mainInfo_middleName`)?.value) }
      return arr.join(' ');
    }
    return this.currentNonDbClientFIO ? `${this.currentNonDbClientFIO}` : `Создать нового клиента`;
  }

  get isMTDynamicValid(): boolean {
    return (
      this.isTransfer && (this.useDefaultFields || !this.useDefaultFields && !!this.transferSchema && !!this.isMTDynamicFGValid && !!this.isMTDynamicFAValid) ||
      !this.isTransfer
    )
  }

  get isMTDynamicFGValid(): boolean {
    if (!this.transferDynamicFormGroup) {
      return false;
    }
    let isValid = true;
    Object.keys(this.transferDynamicFormGroup.controls).map(name => {
      if (this.transferDynamicFormGroup.get(name)) {
        if (!this.transferDynamicFormGroup.get(name).valid) {
          if (name !== 'clientId' && !this.client) {
            isValid = false;
            console.log(this.transferDynamicFormGroup.get(name))
            console.log(name)
          }
        }
      }
    });

    return isValid;
  }
  get isMTDynamicFAValid(): boolean {
    return !!this.transferDynamicFormArray && this.transferDynamicFormArray.valid;
  }

  get isTransfer(): boolean {
    return this.operationTypeEnum?.type === OperationTypeEnum.TransferMTS;
  }
  get payAttentionForCalculateCommission(): boolean {
    return this.isNeedCalculateCommission ||
      (this.isTransfer ?
        nullOrUndefinedOrEmpty(this.operationInfoArray[this.operationIndex].formGroup.value.mainInfo_commissionId) :
        nullOrUndefinedOrEmpty(this.operationInfoArray[this.operationIndex].formGroup.value.mainInfo_organizationCommission));
  }
  get isPaymentHubOperation(): boolean {
    return this.operationTypeEnum?.type !== OperationTypeEnum.TransferMTS;
  }

  public useDefaultMtDynamicFields(): void {
    this.dynamicPaymentSystemId = null;
    this.transferSchema = null;
    this.useDefaultFields = false;
    this.transferFields = [];
    this.transferArrayFields = [];
    this.transferDynamicFormGroup = new FormGroup({});
    this.transferDynamicFormArray = new FormArray([]);
  }

  async defaultState(editOperationId = null, operationType = null): Promise<void> {
    this.blockCommission = false;
    this.isNeedCalculateCommission = true;
    this.firstBillPrintOrQueue = false;
    this.secondBillPrintOrQueue = false;
    this.useDefaultMtDynamicFields();
    this.transferForDynamicData = null;
    this.isReady = false;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clientHelper = null;
    this.operationInfoArray = [];
    this.operations = [];
    this.paymentHubOperations = [];
    this.currentStep = 1;
    this.editOperationId = editOperationId;
    this.operationType = operationType;
    this.blockAllActions = false;
    this.isFirstConfirmData = false;
    this.isFirst = true;
    this.thirdSubmitted = false;
    this.isClientNeed = true;
    this.client = null;
    this.countRequestStatusTry = 0;
    this.helperStep = 2;
    this.operationIndex = 0;
    this.commissions = [];
    this.operationSteps = createOperationSteps();
    this.bottomMenu = createBottomMenu();
    this.operationHelperGroup = new FormGroup({});
    this.lastFindDocumentNumber = null;
    this.timerStopped = true;
    this.timerSubscribe = null;
    this.requestIds = [];
    this.controlHelperArray = [];
    this.commissionCalculateParams = null;
    this.commissionCalculateParamsCountCompare = 0;
    this.currentNonDbClientFIO = null;
    this.info = [];
    this.submitted = false;
    this.loaderIsActive = false;
    this.clientListActive = false;
    this.clientDialogFirstOpen = false;
    this.operationCreated = false;
    this.clientCreated = false;
    this.commissionSub = null;
    this.commissionDialogSub = null;
    this.isCommissionAcceptedAmount = false;
    this.loaderSub = null;
    this.clientListSub = null;
    this.clientDialogFirstSub = null;
    this.history = null;
    this.confirmDialogTitle = null;
    this.confirmDialogMessage = null;
    this.confirmDialogImgSrc = null;
    this.confirmDialogType = null;
    this.confirmDialogInfo = [];
    if (!!this.operationType && !!this.currentStep) {
      this.location.replaceState(`operation/${this.operationType}/${this.currentStep}${this.editOperationId ? '/' + this.editOperationId : ''}`);
    }
    return;
  }
}
