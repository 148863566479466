<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Смена временного пароля</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close(false)" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper">
    <div class="temporary-form" *ngIf="!!passwordForm" [formGroup]="passwordForm">
      <app-input
        inputType="password"
        placeholder="Временный пароль"
        label="Временный пароль"
        name="OldPassword"
        wrapperClass="inputBox-group mb-30"
        autocomplete="new-password"
        [controlInvalid]="submitted"
        [parentForm]="passwordForm"></app-input>

      <app-input
        inputType="password"
        placeholder="Новый пароль"
        label="Новый пароль"
        name="NewPassword"
        wrapperClass="inputBox-group mb-30"
        autocomplete="new-password"
        [controlInvalid]="submitted"
        [parentForm]="passwordForm"></app-input>

      <app-input
        inputType="password"
        placeholder="Новый пароль еще раз"
        label="Новый пароль еще раз"
        name="RepeatNewPassword"
        wrapperClass="inputBox-group mb-30"
        autocomplete="new-password"
        [controlInvalid]="submitted"
        [parentForm]="passwordForm"></app-input>

    </div>
  </div>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main" [ngClass]="{'disabled': !isTemporaryCorrect}"
              [style.width.px]="190" (click)="onTemporary()">Изменить</button>
      <button class="button unfilled decoration-under" (click)="close(false)" [style.padding-left.px]="0">В другой раз</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="190"
              (click)="close(false)" [style.margin-right.px]="20">В другой раз</button>
      <button class="button main f-right" [ngClass]="{'disabled': !isTemporaryCorrect}"
              [style.width.px]="150" (click)="onTemporary()">Изменить</button>
    </div>


  </footer>

</div>
