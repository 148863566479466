import {IControlGroupHelper, IControlsArrayHelper, IStepsControlHelper} from "../../models/common.models";
import {Guid} from "guid-typescript";
import {FormArray, FormGroup, Validators as v} from "@angular/forms";
import {addOperationStepsFunctions} from "../functions.helper";
import {validateOnlyDigits} from "../../validators";

export function createPhPhoneOnlyDigitsControlHelper(): IStepsControlHelper {
  var result = {
    items: [
      createPhPhoneOnlyDigitsOperationParams(
        'mainInfo',
        'Основные данные операции',
        Guid.raw()
      ),
    ],
    formGroup: new FormGroup({}),
    formArray: new FormArray([]),
  };
  return addOperationStepsFunctions(result);
}

export function createPhPhoneOnlyDigitsOperationParams(
  id: string,
  title: string,
  arrayId: string
): IControlGroupHelper {
  return {
    id,
    title,
    isSubmitted: false,
    isFormArray: false,
    isFormGroup: true,
    formArrayBtnText: '',
    formArrayBtnTextWidth: 0,
    controlsArr: [createMtsPhoneOnlyDigitsOperationParamsControls(arrayId)],
  } as IControlGroupHelper;
}
export function createMtsPhoneOnlyDigitsOperationParamsControls(arrayId: string): IControlsArrayHelper {
  return {
    subs: [],
    ahunter: null,
    arrayId,
    controls: [
      {
        isInput: true,
        isCash: true,
        inputType: `number`,
        placeholder: `Сумма внесения`,
        label: `Сумма внесения`,
        name: `acceptedAmount`,
        wrapperClass: `operation-half-item-left`,
        value: null,
        mask: "separator.2",
        maskSuffix: " RUB",
        validators: [v.required],
      },
      {
        isInput: true,
        isHide: false,
        inputType: `tel`,
        placeholder: `Телефон`,
        label: `Телефон`,
        name: `phoneNumber`,
        wrapperClass: `operation-half-item-right`,
        autocomplete: `on`,
        value: null,
        validators: [v.required, validateOnlyDigits()],
      },
    ],
  } as IControlsArrayHelper;
}
