import {CommonModule, DatePipe, DecimalPipe} from "@angular/common";
import {NgModule} from "@angular/core";
import {HeaderComponent} from "./pages/header/header.component";
import {InputComponent} from "./pages/input/input.component";
import {ControlValidatorComponent} from "./pages/control-validator/control-validator.component";
import {CheckboxComponent} from "./pages/checkbox/checkbox.component";
import {FooterComponent} from "./pages/footer/footer.component";
import {PhonePipe} from "../../kernel/pipes/phone.pipe";
import {RolePipe} from "../../kernel/pipes/role.pipe";
import {AnswerPipe} from "../../kernel/pipes/answer.pipe";
import {PaginatorComponent} from "./pages/paginator/paginator.component";
import {RadioComponent} from "./pages/radio/radio.component";
import {BrowserModule} from "@angular/platform-browser";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OperationStatusPipe} from "../../kernel/pipes/operationStatus.pipe";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../kernel/helpers/data.helper";
import {LeftMenuComponent} from "./pages/left-menu/left-menu.component";
import {AppRoutingModule} from "../app-routing.module";
import { NotifyMenuComponent } from './pages/notify-menu/notify-menu.component';
import {DateLocalPipe} from "../../kernel/pipes/date-local.pipe";
import { InfoArrayComponent } from './pages/info-array/info-array.component';
import { OperationCommissionListComponent } from '../mts-operation/dialogs/operation-commission-list/operation-commission-list.component';
import {BehaviorService} from "./services/behavior.service";
import {NotifyService} from "./services/notify.service";
import {NotifyMessageService} from "./services/notify-message.service";
import {MainHeadComponent} from "./pages/main-head/main-head.component";
import {MainOperationsComponent} from "./pages/main-operations/main-operations.component";
import {TemporaryPasswordComponent} from "./dialogs/temporary-password/temporary-password.component";
import {HardwareService} from "./services/hardware.service";
import {MatIconModule} from "@angular/material/icon";
import {ClipboardDirective} from "../../kernel/directives/clipboard.directive";
import {MatDialogModule} from "@angular/material/dialog";
import { PopupLoaderComponent } from './pages/popup-loader/popup-loader.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import {SafeHtmlPipe} from "../../kernel/pipes/safe-html.pipe";
import { InfoDialogListenerComponent } from './pages/info-dialog-listener/info-dialog-listener.component';
import {CardNumberPipe} from "../../kernel/pipes/card-number.pipe";
import { TableCellComponent } from './pages/table-cell/table-cell.component';
import {BillTypePipe} from "../../kernel/pipes/bill-type.pipe";
import { FileSelectComponent } from './pages/file-select/file-select.component';
import {FileSizePipe} from "../../kernel/pipes/file-size.pipe";
import { MainOrganizationBalanceInfoComponent } from './pages/main-organization-balance-info/main-organization-balance-info.component';
import {StaticDictionariesService} from "./services/static-dictionaries.service";
import {PipeTransformService} from "./services/pipe-transform.service";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CheckboxComponent,
    ControlValidatorComponent,
    FooterComponent,
    HeaderComponent,
    InfoArrayComponent,
    InputComponent,
    LeftMenuComponent,
    MainHeadComponent,
    MainOperationsComponent,
    NotifyMenuComponent,
    PaginatorComponent,
    RadioComponent,
    TemporaryPasswordComponent,
    CardNumberPipe,
    AnswerPipe,
    FileSizePipe,
    BillTypePipe,
    DateLocalPipe,
    OperationStatusPipe,
    PhonePipe,
    RolePipe,
    ClipboardDirective,
    PopupLoaderComponent,
    InfoDialogComponent,
    SafeHtmlPipe,
    InfoDialogListenerComponent,
    TableCellComponent,
    FileSelectComponent,
    MainOrganizationBalanceInfoComponent
  ],
  entryComponents: [
    TemporaryPasswordComponent,
    InfoDialogComponent
  ],
    imports: [
        BrowserModule,
        NgxMaskModule.forRoot(options),
        ReactiveFormsModule,
        MatDatepickerModule,
        MatIconModule,
        MatDialogModule,
        AppRoutingModule,
        MatProgressSpinnerModule,
        FormsModule
    ],
    exports: [
      CheckboxComponent,
      ControlValidatorComponent,
      FooterComponent,
      HeaderComponent,
      InfoArrayComponent,
      InputComponent,
      LeftMenuComponent,
      MainHeadComponent,
      MainOperationsComponent,
      NotifyMenuComponent,
      PaginatorComponent,
      RadioComponent,
      TemporaryPasswordComponent,
      AnswerPipe,
      FileSizePipe,
      BillTypePipe,
      DateLocalPipe,
      OperationStatusPipe,
      PhonePipe,
      RolePipe,
      ClipboardDirective,
      PopupLoaderComponent,
      InfoDialogComponent,
      SafeHtmlPipe,
      InfoDialogListenerComponent,
      FileSelectComponent,
      MainOrganizationBalanceInfoComponent
    ],
  providers: [
    DatePipe,
    DecimalPipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    BehaviorService,
    StaticDictionariesService,
    NotifyService,
    NotifyMessageService,
    HardwareService,
    PipeTransformService
  ]
})
export class CoreModule {}
