import {Injectable} from "@angular/core";
import {IControlCordHelper, IControlHelper, IStepsControlHelper} from "../../../kernel/models/common.models";
import {OperationVariableService} from "./operation-variable.service";
import {notNullOrUndefined} from "../../../kernel/helpers/data.helper";


@Injectable({
  providedIn: 'root',
})
export class OperationHelperControlService {
  constructor(
    private variables: OperationVariableService,
  ) {
  }
  addControlHelperArrayItem(item: IControlCordHelper): void {
    this.variables.controlHelperArray.push(item);
  }

  controlHelperArrayChange(event: IControlCordHelper[]): void {
    this.variables.controlHelperArray = event;
  }

  getControlHelper(fullName: string, arrayId: string, operationIndex: number): IControlCordHelper {
    const index = notNullOrUndefined(arrayId) ?
      this.variables.controlHelperArray
        .findIndex(x =>
          x.arrayId === arrayId &&
          x.fullName === fullName &&
          x.operationIndex === operationIndex) :
      this.variables.controlHelperArray
        .findIndex(x =>
          x.fullName === fullName &&
          x.operationIndex === operationIndex);
    return index > -1 ? this.variables.controlHelperArray[index] : null;
  }

  getControlByStep(
    step: IControlCordHelper,
    operationInfoArray: IStepsControlHelper[],
    clientHelper: IStepsControlHelper): IControlHelper {
    if (step.operationIndex > -1) {
      return operationInfoArray[step.operationIndex]
        .items[step.itemIndex]
        .controlsArr[step.controlArrIndex]
        .controls[step.controlIndex];
    }
    return clientHelper.items[step.itemIndex]
      .controlsArr[step.controlArrIndex]
      .controls[step.controlIndex];
  }

  removeItemByArrayId(arrayId: string): void {
    while (this.variables.controlHelperArray.findIndex(x => x.arrayId === arrayId) > -1) {
      const i = this.variables.controlHelperArray.findIndex(x => x.arrayId === arrayId);
      if (i > -1) {
        this.variables.controlHelperArray.splice(i, 1);
      }
    }
  }

  removeAll(resetAll: boolean): void {
    if (resetAll) {
      this.variables.controlHelperArray = [];
      return;
    }
    this.variables.controlHelperArray = this.variables.controlHelperArray.filter(x => x.operationIndex === -1);
  }
}
