import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IMtDynamicFiledHelper} from "../../../../../kernel/mt-dynamic/models/mt-dynamic.models";
import {Subject} from "rxjs";
import {OperationBuilderService} from "../../../services/operation-builder.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-operation-dynamic-data-item-fields',
  templateUrl: './operation-dynamic-data-item-fields.component.html',
  styleUrls: ['./operation-dynamic-data-item-fields.component.scss', '../../mts-operation/mts-operation.component.scss']
})
export class OperationDynamicDataItemFieldsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() fields: IMtDynamicFiledHelper[] | undefined = [];
  @Input() typeArrayId: string | undefined;
  @Input() path: string;

  constructor(
    private builder: OperationBuilderService,
  ) {
  }

  get formGroup(): FormGroup {
    return this.builder.variables.transferDynamicFormGroup;
  }

  get formArray(): FormGroup[] {
    return this.builder.variables.transferDynamicFormArray.controls.filter(x => x.get('typeArrayId').value === this.typeArrayId) as FormGroup[];
  }

  get isArray(): boolean {
    return this.fields.every(x => x.parentType === 'array');
  }
  get isObject(): boolean {
    return this.fields.every(x => x.parentType === 'object');
  }

  get arrayTitle(): string {
    const index = this.fields.findIndex(x => x.type === 'array' && !x.hasOnlyObjectsOrArrays);
    return index > -1 ? this.fields[index].title : '';
  }

  get isAnyOfFields(): IMtDynamicFiledHelper[] {
    return !!this.fields ? this.fields.filter(x => x.isAnyOf) : [];
}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addDynamicFormArrayItem(fields: IMtDynamicFiledHelper[] | undefined, typeArrayId: string, path: string): void {
    this.builder.mtDynamic.addDynamicFormArrayItem(fields, typeArrayId, path);
  }
}
