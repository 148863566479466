<ng-container *ngIf="!!operationInfoArray && !!operationInfoArray[operationIndex] && currentStep === 1">
  <div class="operation-block-wrapper" *ngFor="let item of operationInfoArray[operationIndex].items; let ngControlGroupIndex=index">
    <p class="operation-block-title" *ngIf="item.title"> {{item.title }}</p>
    <div class="operation-block-items-wrapper" *ngFor="let controlArr of item.controlsArr">

      <ng-container *ngIf="item.isFormGroup">
        <ng-container *ngFor="let control of controlArr.controls; let controlIndex">
          <app-input
            *ngIf="control.isInput && !control.isHide" [inputType]="control.inputType"
            [isSelectExistListener]="control.isSelectExistListener"
            [onlySelect]="control.onlySelect" [readonly]="control.readonly"
            [placeholder]="control.placeholder" [label]="control.label"
            [name]="item.id + '_' + control.name" [mask]="control.mask" [onlyVisible]="control.onlyVisible"
            [maskPrefix]="control.maskPrefix" [maskSuffix]="control.maskSuffix" [wrapperClass]="control.wrapperClass"
            [contentMaxHeight]="control.contentMaxHeight" [selectData]="control.selectData || []" [selectDataAll]="control.selectData || []"
            [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formGroup" [maxLength]="control.maxLength"
            [controlInvalid]="item.isSubmitted">
          </app-input>
          <app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                            [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                            [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-radio>
          <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                        [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                        [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-checkbox>
        </ng-container>

        <ng-container *ngIf="item.id === 'mainInfo'">
          <div class="operation-half-item-left t-align-center" *ngIf="commissionDialogExist && isTransfer">
            <button
              class="button unfilled decoration-under"
              (click)="openCommissionList()"
            >Варианты комиссий</button>
          </div>
          <div [ngClass]="{'operation-half-item-right': commissionDialogExist}">
            <div class="w-100 t-align-center">
              <button [disabled]="calculateCommissionsDisabled"
                      class="button unfilled decoration-under"
                      (click)="calculateCommissions()"
                      [ngClass]="{'nav-bellCounter pulse': item.isSubmitted && payAttentionForCalculateCommission}"
              ><span [ngClass]="{'pulse-text': item.isSubmitted && payAttentionForCalculateCommission}">Рассчитать комиссию</span></button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="item.isFormArray">
        <ng-container *ngFor="let control of controlArr.controls; let ngControlIndex=index">
          <app-input
            *ngIf="control.isInput && !control.isHide" [inputType]="control.inputType"
            [onlySelect]="control.onlySelect" [readonly]="control.readonly"
            [placeholder]="control.placeholder" [label]="control.label"
            [name]="item.id + '_' + control.name" [mask]="control.mask" [onlyVisible]="control.onlyVisible"
            [maskPrefix]="control.maskPrefix" [maskSuffix]="control.maskSuffix" [wrapperClass]="control.wrapperClass"
            [contentMaxHeight]="control.contentMaxHeight" [selectData]="control.selectData || []" [selectDataAll]="control.selectData || []"
            [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formArray.controls[operationInfoArray[operationIndex].getArrayIndex(controlArr.arrayId)]" [maxLength]="control.maxLength"
            [controlInvalid]="item.isSubmitted">
          </app-input>
          <app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                            [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                            [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formArray.controls[operationInfoArray[operationIndex].getArrayIndex(controlArr.arrayId)]"></app-radio>
          <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                        [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                        [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="operationInfoArray[operationIndex].formArray.controls[operationInfoArray[operationIndex].getArrayIndex(controlArr.arrayId)]"></app-checkbox>
        </ng-container>
        <div class="operation-array-button-wrapper">
          <button
            class="button unfilled decoration-under"
            (click)="builder.array.removeFormArrayItem(item.id, controlArr.arrayId, operationIndex)"
            [style.padding-right.px]="0"
          >Удалить</button>
        </div>
      </ng-container>

    </div>
    <div class="operation-array-button-wrapper" *ngIf="item.isFormArray">
      <button
        class="button unfilled decoration-under"
        (click)="builder.array.addFormArrayItem(item.id, null, operationIndex)"
        [style.padding-right.px]="0"
      >Добавить</button>
    </div>
  </div>

  <app-operation-dynamic-data-step></app-operation-dynamic-data-step>

</ng-container>
