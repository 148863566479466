import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ApiClient, IUserResponse, LoggerErrorTypeEnum} from "../../../../kernel/ApiClient";
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {takeUntil} from "rxjs/operators";
import {ResetUser} from "../../../../kernel/store/actions/user.actions";
import {getErrorMessage} from "../../../../kernel/helpers/data.helper";
import {NotifyService} from "../../services/notify.service";
import {UserState} from "../../../../kernel/store/state/user.state";
import {isOperationRoute} from "../../../../kernel/helpers/operation.helper";
import {timer} from "rxjs/internal/observable/timer";
import {dateStrArr, getDateToString, timeConversion} from "../../../../kernel/helpers/notify.helper";
import {IDateHelper} from "../../../../kernel/models/common.models";
import {MatDialogConfig} from "@angular/material/dialog";
import {InfoDialogComponent} from "../../dialogs/info-dialog/info-dialog.component";
import {BehaviorService} from "../../services/behavior.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getRoute) route$: Observable<string>;
  @Select(UserState.getExpired) expired$: Observable<number>;
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  subs = new Subscription();
  year: number;

  route: string;
  isBottomMenu = false;
  isAuthorized = false;
  expired: number;
  expiredTime: string;

  user: IUserResponse;
  logos = {
    usual: '/assets/img/nav/nabix-logo-big.svg',
    newYear: '/assets/img/nav/nabix-new-year-logo-small.svg',
  }

  timerStopped = true;
  timerSubscribe: any;
  countByMins = false;
  dateHelper: IDateHelper

  constructor(
    private apiClient: ApiClient,
    private store: Store,
    private notify: NotifyService,
    private behavior: BehaviorService,
  ) {
    this.year = moment().year();
  }

  get tokenExpiredArray(): string[] {
    const arr = [
      { value: getDateToString(this.dateHelper.days, dateStrArr.day), isZero: this.dateHelper.days === 0 },
      { value: getDateToString(this.dateHelper.hours, dateStrArr.hour), isZero: this.dateHelper.hours === 0 },
      { value: getDateToString(this.dateHelper.minutes, dateStrArr.min), isZero: this.dateHelper.minutes === 0 },
      { value: getDateToString(this.dateHelper.seconds, dateStrArr.sec), isZero: this.dateHelper.seconds === 0 }
    ];
    if (this.countByMins) {
      arr.pop();
    }
    let firstUnZero = -1;
    let arrResult = [];
    arr.map((x, index) => {
      if (!x.isZero && firstUnZero === -1) {
        firstUnZero = index;
      }
      if (firstUnZero > 0) {
        arrResult.push(x.value);
      }
    });
    return arrResult;
  }

  ngOnInit(): void {
    this.subs.add(this.expired$.subscribe(expired => { this.expired = expired }));
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (this.isAuthorized) {
        this.initTimer(60000);
      }
    }));
    this.subs.add(this.route$.subscribe(route => {
      this.isBottomMenu = isOperationRoute(route);
    }));
  }

  ngOnDestroy(): void {
    this.stopTimer();
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initTimer(duration: number): void {
    if (this.timerStopped === false) { return }
    this.timerStopped = false
    this.timerSubscribe = timer(0, duration).subscribe(t => {
      this.dateHelper = timeConversion(this.expired - ((new Date()).getTime()));
      if (this.expired - ((new Date()).getTime()) < 0) {
        this.stopTimer();
        return;
      }
      if (this.tokenExpiredBy(10)) {
        this.countByMins = false;
        if (this.tokenExpiredBy(1)) {
          this.behavior.infoDialogListenerStatus.next({
            type: LoggerErrorTypeEnum.TokenExpired,
            title: this.dateHelper.minutes > 0 ?
              `Токен истекает менее чем через ${this.dateHelper.minutes ? getDateToString(this.dateHelper.minutes, dateStrArr.min, false) : 'минуту'}!` :
              `Ваш токен истек!`
          });
        }
        this.stopTimer();
        this.initTimer(1000);
      } else {
        this.countByMins = true;
      }
      this.expiredTime = this.tokenExpiredArray.join(' ');
    });
  }

  stopTimer(): void {
    if (this.timerStopped === false) {
      this.timerSubscribe.unsubscribe()
      this.timerStopped = true
    }
  }

  tokenExpiredBy(mins: number): boolean {
    return this.dateHelper &&
      this.dateHelper.days === 0 &&
      this.dateHelper.hours === 0 &&
      this.dateHelper.minutes < mins;
  }
}
