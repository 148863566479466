import {Component, OnDestroy} from '@angular/core';
import {errorMessages} from '../../../../kernel/constants/errors';
import {FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Store} from '@ngxs/store';
import {messages} from '../../../../kernel/constants/messages';
import {Navigate} from '@ngxs/router-plugin';
import {ApiClient} from '../../../../kernel/ApiClient';
import {NotifyService} from '../../../core/services/notify.service';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-auth-forgot',
  templateUrl: './auth-forgot.component.html',
  styleUrls: ['./auth-forgot.component.scss', '../auth-area/auth-area.component.scss']
})
export class AuthForgotComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  isSubmit = false;

  constructor(
    private authService: AuthService,
    private store: Store,
    private apiClient: ApiClient,
    private notify: NotifyService,
  ) {
  }

  get authGroup(): FormGroup {
    return this.authService.authGroup;
  }
  get isForgotCorrect(): boolean {
    return this.authService.isForgotByPhone && this.authService.isForgotByPhoneCorrect
          || !this.authService.isForgotByPhone && this.authService.isForgotByEmailCorrect;
  }
  get isForgotByPhone(): boolean {
    return this.authService.isForgotByPhone;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeForgotType(): void {
    this.authService.isForgotByPhone = !this.authService.isForgotByPhone;

    if (this.authService.isForgotByPhone == true) {
      this.authGroup.get('Email').clearValidators();
      this.authGroup.get('Phone').setValidators(Validators.required)
    }
    else {
      this.authGroup.get('Phone').clearValidators();
      this.authGroup.get('Email').setValidators(Validators.required)
    }
    
    this.authGroup.get('Phone').updateValueAndValidity();
    this.authGroup.get('Email').updateValueAndValidity();
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`auth/${url}`]));
  }

  onForgot(): void {
    this.isSubmit = true;
    if (!this.authService.isForgotCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }

    this.apiClient
      .auth_ForgotPassword(this.authService.forgotPayload)
      .subscribe(
        () => {
          this.notify.success(messages.passwordSendToEmail);
          this.authService.setDefaultState();
          this.goto('login');
        },
        error => {
          this.notify.error(this.authService.showError(error));
          console.error(error);
        }
      );
  }
}
