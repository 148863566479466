import {CommonModule, registerLocaleData} from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "../core/core.module";
import {RouterModule} from "@angular/router";
import {SettingsComponent} from "./pages/settings/settings.component";
import {UsersComponent} from "./pages/settings-block/users/users.component";
import {AccountSettingsComponent} from "./pages/settings-block/account-settings/account-settings.component";
import {UsersModalComponent} from "./modals/users-modal/users-modal.component";
import { HardwareProposalsComponent } from './pages/settings-block/hardware-proposals/hardware-proposals.component';
import { OrganizationsComponent } from './pages/settings-block/organizations/organizations.component';
import { HardwareProposalsModalComponent } from './modals/hardware-proposals-modal/hardware-proposals-modal.component';
import { OrganizationPointsComponent } from './pages/settings-block/organization-points/organization-points.component';
import { OrganizationsModalComponent } from './modals/organizations-modal/organizations-modal.component';
import { OrganizationPointsModalComponent } from './modals/organization-points-modal/organization-points-modal.component';
import { LimitsComponent } from './pages/settings-block/limits/limits.component';
import { OperationSettingsComponent } from './pages/settings-block/operation-settings/operation-settings.component';
import { HardwareSettingsComponent } from './pages/settings-block/hardware-settings/hardware-settings.component';
import { SiteSettingsComponent } from './pages/settings-block/site-settings/site-settings.component';
import { OperationBillingComponent } from './pages/settings-block/operation-billing/operation-billing.component';
import { OrganizationOperationTypesComponent } from './pages/settings-block/organization-operation-types/organization-operation-types.component';
import { OrganizationOperationTypeCommissionComponent } from './pages/settings-block/organization-operation-type-commission/organization-operation-type-commission.component';
import { OperationCryptoKeysModelComponent } from './modals/operation-crypto-keys-model/operation-crypto-keys-model.component';
import { OperationCryptoKeysComponent } from './pages/settings-block/operation-crypto-keys/operation-crypto-keys.component';

registerLocaleData(localeRU, 'ru');
registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [
    SettingsComponent,
    UsersComponent,
    AccountSettingsComponent,
    UsersModalComponent,
    HardwareProposalsComponent,
    OrganizationsComponent,
    HardwareProposalsModalComponent,
    OrganizationPointsComponent,
    OrganizationsModalComponent,
    OrganizationPointsModalComponent,
    LimitsComponent,
    OperationSettingsComponent,
    HardwareSettingsComponent,
    SiteSettingsComponent,
    OperationBillingComponent,
    OrganizationOperationTypesComponent,
    OrganizationOperationTypeCommissionComponent,
    OperationCryptoKeysComponent,
    OperationCryptoKeysModelComponent
  ],
  entryComponents: [
    UsersModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    CoreModule,
    RouterModule,
  ],
  exports: [
    SettingsComponent,
    UsersComponent,
    AccountSettingsComponent,
    UsersModalComponent
  ],
  providers: []
})
export class SettingsModule {}
