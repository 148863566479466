<div class="operation-third-wrapper" *ngIf="currentStep === 2">

    <p
      class="accordion-title t-align-center mt-15 mb-10"
      (click)="infoOpen = !infoOpen">
      {{infoOpen ? 'Скрыть детали операции' : 'Подробнее об операции'}}
    </p>

  <div class="accordion-wrapper-block" [ngClass]="{'open': infoOpen}">
    <div class="operation-info-wrapper">
      <app-info-array [info]="info"></app-info-array>
    </div>
  </div>

  <div class="commission-hint mt-30">
    Обратите внимание, что галочка <strong class="color-red">Данные верны</strong> должна быть нажата
    только если вы <strong class="color-red">уверены</strong> и <strong class="color-red">проверили</strong>
    все данные из предложенного списка выше.
  </div>

  <app-checkbox
    label="Данные верны"
    name="DataCorrect"
    wrapperClass="mt-30"
    [controlInvalid]="thirdSubmitted"
    [parentForm]="operationHelperGroup"></app-checkbox>

  <div class="operation-create-print-check-wrapper">
    <button class="button unfilled decoration-under" (click)="createPreCheck()">Распечатать дополнительный предчек проверки данных</button>
  </div>

</div>
