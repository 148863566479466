export enum BroadcastMessageEnum {
  None = 'None',
  SignIn = 'SignIn',
  SignOut = 'boolean',
  NotifyWithHistory = 'NotifyWithHistory',
  NotifyWithoutHistory = 'NotifyWithoutHistory',
  TemporaryPassword = 'TemporaryPassword',
  Logout = 'Logout'
}

export const BroadcastMessage = {
  None: 'None',
  SignIn: 'SignIn',
  SignOut: 'boolean',
  NotifyWithHistory: 'NotifyWithHistory',
  NotifyWithoutHistory: 'NotifyWithoutHistory',
  TemporaryPassword: 'TemporaryPassword',
  Logout: 'Logout'
}
