<div class="expand" [ngClass]="{'open': expanded}" id="filteredUsers">
  <app-organizations-modal (modalClosed)="modalClosed($event)"
                   #addOrganization
  ></app-organizations-modal>

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="../../../../../assets/img/icons/points.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">БПА</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="../../../../../assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-filter-users-proposals download-title" (click)="filterExpand = !filterExpand">Фильтры</p>
    <div class="expand-table-container">
      <div *ngIf="searchForm">

        <div class="filter-wrapper">
          <div class="filter-left-wrapper" [ngClass]="{'open': filterExpand}">
            <div class="filter-left-column-item">
              <div class="filter-left-row-item">
                <app-input inputType="select"
                           placeholder="Тип БПА"
                           label="Тип БПА"
                           name="OrganizationTypeId"
                           wrapperClass="inputBox-group"
                           [contentMaxHeight]="600"
                           [selectData]="availableOrganizationTypes || []"
                           [selectDataAll]="availableOrganizationTypes || []"
                           [parentForm]="searchForm"></app-input>
              </div>
            </div>
            <div class="filter-left-column-item">
              <div class="filter-left-row-item search">
                <app-input
                  inputType="search"
                  placeholder="Поиск"
                  name="Search"
                  width="full"
                  wrapperClass="inputBox-group"
                  [parentForm]="searchForm"></app-input>
              </div>
              <div class="filter-left-row-item">
                <app-checkbox
                  class="inputBox-group knowledge-filter-check"
                  [parentForm]="searchForm"
                  name="OnlyActive"
                  label="Только активные"
                ></app-checkbox>
              </div>
            </div>
            <span class="download-title" (click)="resetAll()"
               *ngIf="isNeedFilterReset">Сбросить фильтр</span>
          </div>
          <div class="filter-right-wrapper">
            <button class="button main"
                    (click)="addOrganization.toggleModal(null)">
              СОЗДАТЬ
            </button>
          </div>
        </div>

      </div>



      <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!organizationResponse && organizationResponse.organizations.length > 0 else emptyList">
        <table class="table w-100">
          <thead class="table-header">
          <th>НАЗВАНИЕ</th>
          <th>ТЕЛЕФОН</th>
          <th
            (click)="setSort(columns.BalanceTransfer)"
            [ngClass]="{'active': sortField === columns.BalanceTransfer, 'non-desc': sortField === columns.BalanceTransfer && !isDesc}">
            БАЛАНС <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.PointCount)"
            [ngClass]="{'active': sortField === columns.PointCount, 'non-desc': sortField === columns.PointCount && !isDesc}">
            ТОЧКИ <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.PcCount)"
            [ngClass]="{'active': sortField === columns.PcCount, 'non-desc': sortField === columns.PcCount && !isDesc}">
            ПК <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.UserCount)"
            [ngClass]="{'active': sortField === columns.UserCount, 'non-desc': sortField === columns.UserCount && !isDesc}">
            СОТРУДНИКИ <img class="table-header-arrow_down" src="../../../../../assets/img/icons/arrow-down.svg"/></th>
          <th>ТИП</th>
          </thead>
          <tbody class="text-align-center">
          <tr *ngFor="let organization of organizationResponse.organizations; let i=index"
              class="table-row" (click)="!isLoad && addOrganization.toggleModal(organization)">
            <td class="td-role">{{organization.name}}</td>
            <td class="td-role">{{organization.phone | phonePipe}}</td>
            <td class="td-role">{{organization.balance || 0 | number: "1.2":"ru"}} RUB</td>
            <td class="td-role">{{organization.organizationPointsCount}}</td>
            <td class="td-role">{{organization.hardwarePcsCount}}</td>
            <td class="td-role">{{organization.usersCount}}</td>
            <td class="td-role">{{organization.organizationType.name}}</td>
            <td class="td-role"></td>
            <td *ngIf="organization.isBlocked" class="t-align-center"><img src="/assets/img/icons/error.svg"></td>
            <td *ngIf="!organization.isBlocked" class="t-align-center"><img src="/assets/img/icons/success.svg"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <app-paginator #paginatorComp
        [page]="paginator.page"
        [total]="organizationResponse?.totalCount"
        [take]="paginator.take"
        [id]="paginatorId"
        (pageChange)="pageChanged($event)"
      ></app-paginator>
      <ng-template #emptyList>
        <div class="empty-list">
          <p>{{
            isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одного БПА...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
