import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store'
import {
  SetUserRoles,
  SetTimeZones,
  SetCountries,
  SetCurrencies,
  SetServicePoints,
  SetServices,
  SetAvailableUsers,
  ResetDictionaries,
  GetAvailableData,
  SetOrganizationTypes,
  SetOperationTypes,
  SetPaymentTypes,
  SetDbCountries,
  SetCountriesAddresses,
  SetOperationCryptoKeys
} from "../actions/dictionary.actions";
import {
  ApiClient,
  ICountriesResponse,
  IMtsService,
  IOperationTypeResponse,
  IPaymentSystemResponse
} from "../../ApiClient";
import {getErrorMessage, getUserRoleRu} from "../../helpers/data.helper";
import {NotifyService} from "../../../modules/core/services/notify.service";
import {createDocumentTypeSelectedData, createGenderTypeSelectedData} from "../../helpers/data-selected.helper";
import {IDataSelectedHelper, IDictionaries} from "../../models/common.models";
import {UserAvailableDataEnum} from "../../enum/user-available-data";
import {take} from "rxjs/operators";

export class Dictionary {
  organizationTypes: IDataSelectedHelper[];
  userRoles: IDataSelectedHelper[];
  timeZones: IDataSelectedHelper[];
  countries: IDataSelectedHelper[];
  countriesAddresses: IDataSelectedHelper[];
  currencies: IDataSelectedHelper[];
  servicePoints: IDataSelectedHelper[];
  services: IMtsService[];
  usersAvailable: IDataSelectedHelper[];
  organizationPointsAvailable: IDataSelectedHelper[];
  organizationsAvailable: IDataSelectedHelper[];
  operationTypes: IOperationTypeResponse;
  operationCryptoKeys: IDataSelectedHelper[];
  paymentSystems: IPaymentSystemResponse;
  dbCountries: ICountriesResponse;
}

let token = ''
const defaults = {
  organizationTypes: [],
  userRoles: [],
  timeZones: [],
  countries: [],
  countriesAddresses: [],
  currencies: [],
  servicePoints: [],
  services: [],
  usersAvailable: [],
  organizationPointsAvailable: [],
  organizationsAvailable: [],
  operationCryptoKeys: [],
  operationTypes: {
    items: [],
    totalCount: 0
  },
  paymentSystems: {
    items: [],
    totalCount: 0
  },
  dbCountries: {
    items: [],
    totalCount: 0
  }
}

@State<Dictionary>({
  name: 'dictionary',
  defaults
})
@Injectable()
export class DictionaryState {

  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService
  ) { }

  @Selector()
  static getDictionaries(dictionary: Dictionary): IDictionaries {
    return {
      countries: (!!dictionary && dictionary.countries) as IDataSelectedHelper[],
      countriesAddresses: (!!dictionary && dictionary.countriesAddresses) as IDataSelectedHelper[],
      documents: createDocumentTypeSelectedData(!!dictionary && dictionary.countriesAddresses),
      gender: createGenderTypeSelectedData(),
      currencies: (!!dictionary && dictionary.currencies) as IDataSelectedHelper[]
    } as IDictionaries;
  }

  @Selector()
  static getOrganizationTypes(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.organizationTypes;
  }
  @Selector()
  static getUserRoles(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.userRoles;
  }

  @Selector()
  static getTimeZones(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.timeZones;
  }

  @Selector()
  static getCountries(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.countries;
  }

  @Selector()
  static getCountriesAddresses(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.countriesAddresses;
  }

  @Selector()
  static getCurrencies(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.currencies;
  }

  @Selector()
  static getServicePoints(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.servicePoints;
  }

  @Selector()
  static getServices(dictionary: Dictionary): IMtsService[] {
    return !!dictionary && dictionary.services;
  }
  @Selector()
  static getOperationTypes(dictionary: Dictionary): IOperationTypeResponse {
    return !!dictionary && dictionary.operationTypes;
  }
  @Selector()
  static getOperationCryptoKeys(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.operationCryptoKeys;
  }


  @Selector()
  static getAvailableUsers(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.usersAvailable;
  }
  @Selector()
  static getAvailableOrganizationPoints(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.organizationPointsAvailable;
  }
  @Selector()
  static getAvailableOrganizations(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.organizationsAvailable;
  }
  @Selector()
  static getPaymentSystems(dictionary: Dictionary): IPaymentSystemResponse {
    return !!dictionary && dictionary.paymentSystems;
  }

  @Selector()
  static getDbCountries(dictionary: Dictionary): ICountriesResponse {
    return !!dictionary && dictionary.dbCountries;
  }

  @Action(SetOrganizationTypes)
  SetOrganizationTypes({ patchState, dispatch }: StateContext<Dictionary>, { }) {
    patchState({ organizationTypes: [
        { id: '1',  label: 'ККМ' },
        { id: '2', label: '1С' }
      ]});
  }

  @Action(SetUserRoles)
  SetUserRoles({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    const temp = data as IDataSelectedHelper[];
    if (!!temp) {
      temp.map(x => {
        x.role = x.label;
        x.label = getUserRoleRu(x.label);
      })
    }
    patchState({ userRoles: temp });
  }

  @Action(SetTimeZones)
  SetTimeZones({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ timeZones: data as IDataSelectedHelper[] });
  }

  @Action(SetCountries)
  SetCountries({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ countries: data as IDataSelectedHelper[] });
  }

  @Action(SetCountriesAddresses)
  SetCountriesAddresses({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ countriesAddresses: data as IDataSelectedHelper[] });
  }

  @Action(SetCurrencies)
  SetCurrencies({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ currencies: data as IDataSelectedHelper[] });
  }

  @Action(SetServicePoints)
  SetServicePoints({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ servicePoints: data as IDataSelectedHelper[] });
  }

  @Action(SetServices)
  SetServices({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ services: data });
  }

  @Action(SetDbCountries)
  SetDbCountries({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ dbCountries: data });
  }

  @Action(SetOperationTypes)
  SetOperationTypes({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ operationTypes: data });
  }
  @Action(SetOperationCryptoKeys)
  SetOperationCryptoKeys({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ operationCryptoKeys: data as IDataSelectedHelper[] });
  }
  @Action(SetPaymentTypes)
  SetPaymentTypes({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ paymentSystems: data });
  }

  @Action(SetAvailableUsers)
  SetAvailableUsers({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ usersAvailable: data });
  }

  @Action(GetAvailableData)
  GetAvailableData({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    this.apiClient.users_GetAvailableData(data)
      .pipe(take(1))
      .subscribe(
        (result) => {
          switch (String(data.type).toUpperCase()) {
            case String(UserAvailableDataEnum.User).toUpperCase():
              patchState({ usersAvailable: result as IDataSelectedHelper[] });
              break;
            case String(UserAvailableDataEnum.OrganizationPoint).toUpperCase():
              patchState({ organizationPointsAvailable: result as IDataSelectedHelper[] });
              break;
            case String(UserAvailableDataEnum.Organization).toUpperCase():
              patchState({ organizationsAvailable: result as IDataSelectedHelper[] });
              break;
          }
        },
        (error) => {
          this.notify.error(getErrorMessage(error));
          console.error(error);
        },
      );
  }

  @Action(ResetDictionaries)
  ResetDictionaries({ setState, dispatch }: StateContext<Dictionary>) {
    setState(defaults);
  }
}
