import * as moment from 'moment';
import {checkDoubleTimeNumbers} from "./data.helper";
import {IDateHelper} from "../models/common.models";

export function getTimeAgo(dateNow: moment.Moment, date: Date): string {
  let temp = moment.duration(dateNow.diff(date));
  if (Number(temp.asYears().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asYears().toFixed(0)), dateStrArr.year)
  }
  if (Number(temp.asMonths().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asMonths().toFixed(0)), dateStrArr.month)
  }
  if (Number(temp.asWeeks().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asWeeks().toFixed(0)), dateStrArr.week)
  }
  if (Number(temp.asDays().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asDays().toFixed(0)), dateStrArr.day)
  }
  if (Number(temp.asHours().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asHours().toFixed(0)), dateStrArr.hour)
  }
  if (Number(temp.asMinutes().toFixed(0)) > 0) {
    return getDateAgoString(Number(temp.asMinutes().toFixed(0)), dateStrArr.min)
  }
  const res = Number(temp.asSeconds().toFixed(0));
  if (res <= 10) {
    return `только что`;
  }
  if (res <= 30 && res > 10) {
    return `недавно`;
  }
  return `менее минуты назад`;
  // return getDateAgoString(Number(temp.asSeconds().toFixed(0)), dateStrArr.sec)
}

export const dateStrArr = {
  year: ['год', 'года', 'лет'],
  month: ['месяц', 'месяца', 'месяцев'],
  week: ['неделю', 'недели', 'недель'],
  day: ['день', 'дня', 'дней'],
  hour: ['час', 'часа', 'часов'],
  min: ['минуту', 'минуты', 'минут'],
  sec: ['секунду', 'секунды', 'секунд'],
};

export function getDateAgoString(value: number, dateStr: string[]): string {
  return `${value} ${dateStr[getDateArrStrIndex(value)]} назад`;
}
export function getDateToString(value: number, dateStr: string[], withDouble = true): string {
  return `${withDouble ? checkDoubleTimeNumbers(value) : value} ${dateStr[getDateArrStrIndex(value)]}`;
}

export function getDateArrStrIndex(value: number): number {
  if (Number((value % 100 / 10).toFixed(0)) === 1) {
    return 2;
  }
  switch (value % 10) {
    case 1:
      return 0
    case 2:
    case 3:
    case 4:
      return 1;
    default:
      return 2;
  }
}

export function timeConversion(duration: number): IDateHelper {
  const msInDay = (1000 * 60 * 60 * 24);
  const days = Math.trunc(duration / msInDay);
  if (days > 0) {
    duration = duration - days * msInDay;
  }
  const msInHours = (1000 * 60 * 60);
  const hours = Math.trunc(duration / msInHours);
  if (hours > 0) {
    duration = duration - hours * msInHours;
  }
  const msInMinute = (1000 * 60);
  const minutes = Math.trunc(duration / msInMinute);
  if (minutes > 0) {
    duration = duration - minutes * msInMinute;
  }
  const msInSecond = 1000;
  const seconds = Math.trunc(duration / msInSecond);
  return { days, hours, minutes, seconds };
}
