import {
  DocumentTypeEnum,
  DocumentTypeRuEnum,
} from '../enum/document-type.enum';
import {GenderTypeEnum, GenderTypeRuEnum} from '../enum/gender.enum';
import {IDataSelectedHelper} from "../models/common.models";

export function createDocumentTypeSelectedData(
  countries: IDataSelectedHelper[]
): IDataSelectedHelper[] {
  const result: IDataSelectedHelper[] = [];
  Object.keys(DocumentTypeEnum).forEach(name => {
    result.push({
      id: DocumentTypeEnum[name],
      label: DocumentTypeRuEnum[name],
    });
  });
  countries.map(x => {
    result.push({
      id: `Passport.${String(x.id)}`,
      label: `Паспорт ${String(x.label)}`,
    });
  });
  return result;
}

export function createGenderTypeSelectedData(): IDataSelectedHelper[] {
  const result: IDataSelectedHelper[] = [];
  Object.keys(GenderTypeEnum).forEach(name => {
    result.push({
      id: GenderTypeEnum[name],
      label: GenderTypeRuEnum[name],
    });
  });
  return result;
}
