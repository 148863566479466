<div class="main-head header-background">

  <h1 class="main-head-date">
    Сегодня, {{ today | dateLocal: 'd MMMM yyy':false:true }}
  </h1>

  <section class="main-head-stats">

    <content class="main-head-stats-list">

      <div class="main-head-stats-wrapper">
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg" alt="Оборот по моновалютным операциям">
            </div>
            <div class="main-head-stats-title-wrapper">
              <p class="main-head-stats-title">Оборот по моновалютным операциям</p>
            </div>
            <p class="main-head-stats-value">
              {{monoTurnoverCashOperations | number: "1.2":"ru"}}
              <span class="rouble-sign"></span>
            </p>
          </div>
        </div>

        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg" alt="Оборот по бивалютным операциям">
            </div>
            <div class="main-head-stats-title-wrapper">
              <p class="main-head-stats-title">Оборот по бивалютным операциям</p>
            </div>
            <p class="main-head-stats-value">
              {{biTurnoverCashOperations | number: "1.2":"ru"}}
              <span class="rouble-sign"></span>
            </p>
          </div>
        </div>
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg" alt="Количество операций">
            </div>
            <div class="main-head-stats-title-wrapper">
              <p class="main-head-stats-title">Количество операций</p>
            </div>
            <p class="main-head-stats-value">{{countOperations | number: "1.0":"ru"}}</p>
          </div>
        </div>
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg" alt="Средний чек">
            </div>
            <div class="main-head-stats-title-wrapper">
              <p class="main-head-stats-title">Средний чек</p>
            </div>
            <p class="main-head-stats-value">
              {{averageCashOperations | number: "1.2":"ru"}}
              <span class="rouble-sign"></span>
            </p>
          </div>
        </div>
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg" alt="Баланс">
            </div>
            <div class="main-head-stats-title-wrapper">
              <p class="main-head-stats-title">{{fifthColumnTitle}}</p>
            </div>
            <p class="main-head-stats-value">
              {{fifthColumn | number: "1.2":"ru"}}
              <span class="rouble-sign"></span>
            </p>
          </div>
        </div>
      </div>

    </content>
  </section>
</div>
<div
  class="main-head-last-activities"
  *ngIf="lastActivityOperations else emptyActivity"
>Последние действия по операциям
  <strong class="color-red">
    {{lastActivityOperations | dateLocal: 'd MMMM yyy':true:true}}
  </strong> в
  <strong class="color-red">
    {{lastActivityOperations | dateLocal:'HH:mm:ss'}}
  </strong>
</div>
<ng-template #emptyActivity>
  <div class="main-head-last-activities">Сегодня еще не было ни одной операции...</div>
</ng-template>

<div class="main-head-limits-wrapper" *ngIf="isOperator && summary">
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Ваш лимит на один перевод</div>
    <div class="main-head-stats-value">{{summary.limits.userLimits.limitForOperation.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Ваш дневной остаток</div>
    <div class="main-head-stats-value">{{summary.limits.userLimits.limitForDay.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Ваш месячный остаток</div>
    <div class="main-head-stats-value">{{summary.limits.userLimits.limitForMonth.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Лимит точки на один перевод</div>
    <div class="main-head-stats-value">{{summary.limits.organizationPointLimits.limitForOperation.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Дневной остаток точки</div>
    <div class="main-head-stats-value">{{summary.limits.organizationPointLimits.limitForDay.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
  <div class="main-head-limit-item-wrapper">
    <div class="main-head-stats-title">Месячный остаток точки</div>
    <div class="main-head-stats-value">{{summary.limits.organizationPointLimits.limitForMonth.balance || 0 | number: "1.2":"ru"}} RUB</div>
  </div>
</div>
