import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiClient, IOperationItemFilterResponse, ITransferResponse} from "../../../../kernel/ApiClient";
import {NotifyService} from "../../../core/services/notify.service";

@Component({
  selector: 'app-transfer-change-beneficiary',
  templateUrl: './transfer-change-beneficiary.component.html',
  styleUrls: ['./transfer-change-beneficiary.component.scss']
})
export class TransferChangeBeneficiaryComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  operationForm: FormGroup;

  transfer: ITransferResponse;
  transferDb: IOperationItemFilterResponse;

  constructor(
    public dialogRef: MatDialogRef<TransferChangeBeneficiaryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { transfer: ITransferResponse, transferDb: IOperationItemFilterResponse },
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {
    if (!data) {
      this.close();
    }
    this.transfer = data.transfer;
    this.transferDb = data.transferDb;
  }

  ngOnInit() {
    this.createFormControl();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createFormControl(): void {
    this.operationForm = new FormGroup({
      LastName: new FormControl(null, [Validators.required]),
      FirstName: new FormControl(null, [Validators.required]),
      Patronymic: new FormControl(null)
    });
  }

  close(): void {
  }

  changeBeneficiary(): void {

  }
}
