import {
  ApiClient,
  BillingTypeEnum, CreateBillQueueItemRequest,
  IClientResponse,
  IOperationItemFilterResponse,
  ITransferResponse, IUserResponse,
  TransfersConfirmRequest
} from '../../../../kernel/ApiClient';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NotifyService} from '../../../core/services/notify.service';
import {IDataSelectedHelper, IOperationDataHelper} from '../../../../kernel/models/common.models';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {getErrorMessage, getFio, getValueById,} from '../../../../kernel/helpers/data.helper';
import {errorMessages} from '../../../../kernel/constants/errors';
import {createInfoArrayItem, getOperationStatus,} from '../../../../kernel/helpers/operation.helper';
import {DatePipeTypeEnum} from '../../../../kernel/enum/data-type.enum';
import {Select} from '@ngxs/store';
import {DictionaryState} from '../../../../kernel/store/state/dictionary.state';
import {messages} from '../../../../kernel/constants/messages';
import {
  errorFinalStatuses, OperationStatusEnum,
  readyForConfirmStatuses,
  successFinalStatuses
} from "../../../../kernel/enum/operation-status.enum";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {CashboxListDialogComponent} from "../../../billing/dialogs/cashbox-list-dialog/cashbox-list-dialog.component";
import {ChangeBeneficiaryDialogComponent} from "../change-beneficiary-dialog/change-beneficiary-dialog.component";
import {UserState} from "../../../../kernel/store/state/user.state";

@Component({
  selector: 'app-operation-info',
  templateUrl: './operation-info.component.html',
  styleUrls: ['./operation-info.component.scss'],
})
export class OperationInfoComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(DictionaryState.getCountries) countries$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCountriesAddresses) countriesAddresses$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCurrencies) currencies$: Observable<IDataSelectedHelper[]>;
  @Select(UserState.isOperator) isOperator$: Observable<boolean>;

  subs = new Subscription();

  client: IClientResponse;
  transfer: ITransferResponse;
  transferDb: IOperationItemFilterResponse;
  transferId: string;
  info: IOperationDataHelper[] = [];
  load = false;
  isReady = false;
  isOperator = false;

  countries: IDataSelectedHelper[] = [];
  countriesAddresses: IDataSelectedHelper[] = [];
  currencies: IDataSelectedHelper[] = [];

  transferErr: string;
  clientErr: string;

  checkTypes = BillingTypeEnum;

  rejectOperationDialogOpen = false;
  changeBeneficiaryDialogOpen = false;
  rejectOperationDialogSub = null;
  changeBeneficiaryDialogSub = null;

  constructor(
    public dialogRef: MatDialogRef<OperationInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private apiClient: ApiClient,
    private notify: NotifyService,
    private billing: BillingBuilderService,
    private dialog: MatDialog,
  ) {
    if (!data) {
      this.close();
    }
    this.transferId = data;
    this.getOperation(true);
  }

  get operationConfirmed(): boolean {
    return successFinalStatuses.findIndex(x => x === String(this.transferDb?.transferStatus).toUpperCase()) > -1;
  }
  get operationConfirming(): boolean {
    return readyForConfirmStatuses.findIndex(x => x === String(this.transferDb?.transferStatus).toUpperCase()) > -1;
  }
  get operationRejected(): boolean {
    return errorFinalStatuses.findIndex(x => x === String(this.transferDb?.transferStatus).toUpperCase()) > -1;
  }
  get isAvailableRejectionBills(): boolean {
    return !!this.transfer &&
      (this.transfer.transferStatus == OperationStatusEnum.REJECTED) &&
      !!this.transferDb &&
      (this.transferDb.transferStatus == OperationStatusEnum.REJECTED) &&
      !this.transferDb.secondRejectBill;
  }

  ngOnInit(): void {
    this.subs.add(
      this.isOperator$.subscribe(isOperator => (this.isOperator = isOperator))
    );
    this.subs.add(
      this.countries$.subscribe(countries => (this.countries = countries))
    );
    this.subs.add(
      this.countriesAddresses$.subscribe(countriesAddresses => (this.countriesAddresses = countriesAddresses))
    );
    this.subs.add(
      this.currencies$.subscribe(currencies => (this.currencies = currencies))
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  getOperation(isFirst = false): void {
    if (isFirst) {
      this.transferErr = '';
    }
    this.load = true;
    this.apiClient
      .transfers_GetTransferByTransferId(this.transferId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(data => {
          this.transfer = data;
        }),
        switchMap(token =>
          this.apiClient.transfers_GetTransferFromDbByTransferId(this.transferId)
        )
      )
      .subscribe(
        data => {

          this.transferDb = data;
          if (!isFirst) {
            const temp = getOperationStatus(this.transfer.transferStatus);
            this.notify.info(temp.hint, temp.status);
          }
          if (isFirst && this.transfer.clientId) {
            this.getClientById(this.transfer.clientId);
          } else {
            this.load = false;
            this.initOperationInfo();
          }
        },
        error => {
          this.transferErr = getErrorMessage(error);
          this.initOperationInfo();
          this.load = false;
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  getClientById(clientId: string): void {
    this.apiClient
      .clients_FindClientById(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.client = data as IClientResponse;
          this.load = false;
          this.initOperationInfo();
        },
        error => {
          this.clientErr = getErrorMessage(error);
          this.initOperationInfo();
          this.load = false;
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  confirmOperation(): void {
    if (this.operationConfirming) {
      this.notify.error(errorMessages.waitForConfirm);
      return;
    }    if (this.operationConfirmed) {
      this.notify.error(errorMessages.alreadyConfirm);
      return;
    }
    if (this.operationRejected) {
      this.notify.error(errorMessages.alreadyReject);
      return;
    }
    this.apiClient
      .transfers_ConfirmTransfer({
        transferId: this.transferId,
      } as TransfersConfirmRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.transfer = data;
          this.initOperationInfo();
          this.notify.success(messages.operationConfirm);
          this.load = false;
        },
        error => {
          this.load = false;
          this.notify.error(getErrorMessage(error));
          console.error(error);
        }
      );
  }

  initOperationInfo(): void {
    this.isReady = true;
    this.info = [];
    if (this.transfer) {
      this.info.push({
        title: `Данные об операции`,
        array: [
          {
            items: [
              createInfoArrayItem(`Тип`, this.transferDb.operationType.title, DatePipeTypeEnum.Usual),
              createInfoArrayItem(
                `Статус`,
                this.transfer.transferStatus,
                DatePipeTypeEnum.OperationStatus
              ),
              createInfoArrayItem(
                `Номер операции`,
                this.transfer.transferNum,
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Номер в платежной системе`,
                this.transfer.paymentSystemTransferNum,
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Дата`,
                this.transferDb.created,
                DatePipeTypeEnum.DateTime
              ),
            ],
          },
        ],
      });
    } else {
      this.info.push({
        title: `Данные об операции`,
        array: [
          {
            items: [
              createInfoArrayItem(this.transferErr, ``, DatePipeTypeEnum.Warning),
            ],
          },
        ],
      });
    }
    this.info.push({
      title: `Данные об операторе`,
      array: [
        {
          items: [
            createInfoArrayItem(
              `ФИО`,
              `${getFio(this.transferDb.user.lastName, this.transferDb.user.firstName, this.transferDb.user.patronymic)}`,
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Номер телефона`,
              this.transferDb.user.phone,
              DatePipeTypeEnum.Phone
            ),
            createInfoArrayItem(
              `Почта`,
              this.transferDb.user.email,
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `ИНН`,
              this.transferDb.user.inn,
              DatePipeTypeEnum.Usual
            ),
          ],
        },
      ],
    });
    if (this.client) {
      this.info.push({
        title: `Данные отправителя`,
        array: [
          {
            items: [
              createInfoArrayItem(
                `ФИО`,
                `${getFio(this.client.lastName, this.client.firstName, this.client.middleName)}`,
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Дата рождения`,
                !!this.client.birthDate ? this.client.birthDate : 'Не указано',
                DatePipeTypeEnum.Date
              ),
              createInfoArrayItem(
                `Страна проживания`,
                getValueById(this.countriesAddresses, this.client.countryOfResidence),
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Гражданство`,
                getValueById(this.countriesAddresses, this.client.citizenship),
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Место рождения`,
                this.client.addresses?.birthPlaceAddress?.addressFull ??
                'Не указано',
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Место регистрации`,
                this.client.addresses?.registrationAddress?.addressFull ??
                'Не указано',
                DatePipeTypeEnum.Usual
              ),
              createInfoArrayItem(
                `Место проживания`,
                this.client.addresses?.residentalAddress?.addressFull ??
                'Не указано',
                DatePipeTypeEnum.Usual
              ),
            ],
          },
        ],
      });
      if (this.client.phoneNumbers) {
        this.client.phoneNumbers.map(x => {
          this.info[this.info.length - 1].array[0].items.push(
            createInfoArrayItem(
              `Номер телефона`,
              x.number,
              DatePipeTypeEnum.Phone
            )
          );
        });
      }
    } else {
      if (this.clientErr) {
        this.info.push({
          title: `Данные отправителя`,
          array: [
            {
              items: [
                createInfoArrayItem(this.clientErr, ``, DatePipeTypeEnum.Warning),
              ],
            },
          ],
        });
      }
    }

    this.info.push({
      title: `Данные получателя`,
      array: [
        {
          items: [
            createInfoArrayItem(
              `ФИО`,
              `${getFio(this.transfer.beneficiary.lastName, this.transfer.beneficiary.firstName, this.transfer.beneficiary.middleName)}`,
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Страна получения`,
              getValueById(this.countries, this.transfer.countryCode),
              DatePipeTypeEnum.Usual
            ),
          ],
        },
      ],
    });
    if (this.transfer.beneficiary.phoneNumbers) {
      this.transfer.beneficiary.phoneNumbers.map(x => {
        this.info[this.info.length - 1].array[0].items.push(
          createInfoArrayItem(
            `Номер телефона`,
            x.number,
            DatePipeTypeEnum.Phone
          )
        );
      });
    }
    this.info.push({
      title: `Платежные данные`,
      array: [
        {
          items: [
            createInfoArrayItem(
              `Валюта внесения`,
              getValueById(
                this.currencies,
                this.transfer.money.acceptedMoney.currencyCode
              ),
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Сумма в кассу`,
              (Number(this.transfer.money.acceptedMoney.amount) + Number(this.transferDb.organizationFee)),
              DatePipeTypeEnum.Cash
            ),
            createInfoArrayItem(
              `Валюта выдачи`,
              getValueById(
                this.currencies,
                this.transfer.money.withdrawMoney.currencyCode
              ),
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Сумма выдачи`,
              this.transfer.money.withdrawMoney.amount,
              DatePipeTypeEnum.Cash
            ),
            createInfoArrayItem(
              `Валюта комиссии`,
              getValueById(
                this.currencies,
                this.transfer.money.acceptedTotalFee.currencyCode
              ),
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Сумма комиссии`,
              this.transfer.money.acceptedTotalFee.amount,
              DatePipeTypeEnum.Cash
            ),
            createInfoArrayItem(
              `Валюта комиссии БПА`,
              getValueById(
                this.currencies,
                this.transferDb.organizationFeeCurrencyCode
              ),
              DatePipeTypeEnum.Usual
            ),
            createInfoArrayItem(
              `Сумма комиссии БПА`,
              this.transferDb.organizationFee,
              DatePipeTypeEnum.Cash
            ),
          ],
        },
      ],
    });
  }

  createCheck(type: BillingTypeEnum): void {
    if (this.load || this.transferErr || this.clientErr) {
      return;
    }
    if (this.transferDb?.secondBill && type === BillingTypeEnum.SecondCheck) {
      this.notify.error(errorMessages.billAlreadyExist);
      return;
    }
    if (this.transferDb?.secondRejectBill && type === BillingTypeEnum.RejectSecondCheck) {
      this.notify.error(errorMessages.rejectBillAlreadyExist);
      return;
    }
    this.billing.checkCashboxCountBeforeBilling({
      numDevice: 0,
      type,
      isPreview: true,
      isFromQueue: false,
      operationId: this.transferDb.id
    });
  }

  changeBeneficiary(): void {
    if (this.changeBeneficiaryDialogOpen) {
      return;
    }
    this.changeBeneficiaryDialogOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { client: this.client, transfer: this.transfer, transferDb: this.transferDb };
    this.changeBeneficiaryDialogSub = this.dialog.open(ChangeBeneficiaryDialogComponent, dialogConfig);
    this.changeBeneficiaryDialogSub.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.changeBeneficiaryDialogOpen = false;
        this.getOperation();
      });
  }

  rejectOperation(): void {
    return;
    if (this.rejectOperationDialogOpen) {
      return;
    }

    this.rejectOperationDialogOpen = true;
    this.apiClient.transfers_TransferReversal(this.transferDb.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify.success(messages.transferCanceled);
        this.getOperation();
      }, error => {
        this.notify.error(getErrorMessage(error));
      });
  }
}
