<div class="dialog-wrapper">
  <header class="dialog-header">
    <p class="dialog-title">Компьютер не привязан к точке</p>
    <img
      src="../../../../assets/img/icons/close.svg" (click)="close()" class="close" alt="Закрыть">
  </header>

  <div class="dialog-body-wrapper">
    <div class="proposal-form" *ngIf="!!proposalForm" [formGroup]="proposalForm">
      <p class="proposal-form--info">Для продолжения работы необходимо привязать данный компьютер к точке.</p>
      <p class="proposal-form--info">Заявка на добавление компьютера сформирована автоматически, вы можете её направить нажатием кнопки «Готово». При необходимости введите комментарий к заявке.</p>
      <p class="proposal-form--info">Заявку может обработать Администратор вашего БПА.</p>

      <div class="proposal-form-data">
        <div class="proposal-form--time" [ngClass]="{'active': success}">
          <p class="opacity-0-75 font-b f-12 t-align-center" *ngIf="createdTime">
            Заявка создана
            {{createdTime | dateLocal:'dd.MM.yyyy':false:false}} в
            {{createdTime | dateLocal:'HH:mm:ss':false:false}}</p>
        </div>
        <div class="proposal-form-data--copy" appClipboard [copyText]="pin">
          <p class="proposal-form-data--pin">{{ pin }}</p>
          <p class="proposal-form-data--pin--copy">Скопировать код</p>
        </div>

        <app-input type="text"
                   placeholder="Комментарий к заявке"
                   label="Комментарий к заявке"
                   name="ProposalComment"
                   wrapperClass=""
                   [hintBottom]="proposalHint"
                   [parentForm]="proposalForm"></app-input>
      </div>

    </div>
  </div>

  <footer>
    <div class="dialog-btn-wrapper-mobile">
      <button class="button main"
              [style.width.px]="160"
              [disabled]="success"
              [style.height.px]="60" (click)="sendProposal()">Отправить</button>
      <button class="button unfilled decoration-under" [style.width.px]="160"
              [style.height.px]="60"
              (click)="close()" [style.margin-right.px]="20">{{closeText}}</button>
    </div>
    <div class="dialog-btn-wrapper-full">
      <button class="button border decoration-under" [style.width.px]="160"
              [style.height.px]="60"
              (click)="close()" [style.margin-right.px]="20">{{closeText}}</button>
      <button class="button main f-right"
              [style.width.px]="160"
              [disabled]="success"
              [style.height.px]="60" (click)="sendProposal()">Отправить</button>
    </div>

    <div class="d-block w-100 mt-20 pb-10">
      <p class="opacity-0-75 font-b f-12 t-align-center">Сообщите код Администратору БПА или НАБИКС</p>
    </div>
  </footer>

</div>
