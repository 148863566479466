<input type="file" #photoInput (change)="onFileChange($event)" hidden multiple/>
<ng-container *ngIf="isBtn">
  <a class="btn-download" (click)="!abortedAll && photoInput.click()">{{title}}</a>
  <a class="label-remove pointer" (click)="!abortedAll && removeFile()" *ngIf="isFile" [style.margin-left.px]="10">Удалить файл</a>
  <p class="file-title w-100 t-align-center" *ngIf="isLabel">{{fileSize | fileSize: 2:false}}{{fileSize > 0 ? ', ' : ''}}{{fileTitle}}</p>
</ng-container>
<ng-container *ngIf="isText">
  <div class="btn-download-text-wrapper">
    <div class="btn-spinner" [ngClass]="{'opacity-0-5': abortedAll}" *ngIf="abortedAll">
      <mat-spinner *ngIf="abortedAll"
                   [diameter]="16">
      </mat-spinner>
    </div>
    <div class="btn-download-text pointer" [ngClass]="{'load disabled opacity-0-5': abortedAll, 'unload': !abortedAll}" (click)="!abortedAll && photoInput.click()">{{title}}</div>
    <div class="label-remove font-b pointer" (click)="!abortedAll && removeFile()" [style.margin-left.px]="6" *ngIf="isFile"> - Удалить</div>
  </div>
  <p class="file-title w-100 t-align-center mt-10 mb-5" *ngIf="isLabel">{{fileSize | fileSize: 2:false}}{{fileSize > 0 ? ', ' : ''}}{{fileTitle}}</p>
</ng-container>
