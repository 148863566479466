export const evnTypeProvider = {
  dev: 'dev',
  local: 'local',
  prod: 'prod',
  stage: 'stage'
};

export const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const digitsStr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const addressFields = [
  { control: 'countryCode', prefix: ''},
  { control: 'zipCode', prefix: ''},
  { control: 'region', prefix: ''},
  { control: 'district', prefix: 'р-н '},
  { control: 'city', prefix: 'г.'},
  { control: 'locality', prefix: ''},
  { control: 'street', prefix: 'ул.'},
  { control: 'house', prefix: 'д.'},
  { control: 'block', prefix: 'корп. '},
  { control: 'building', prefix: 'стр. '},
  { control: 'apartment', prefix: 'кв.'},
];

export const inputTypes = {
  tel: 'tel',
  search: 'search',
  select: 'select',
  datepicker: 'picker',
  stringdate: 'stringdate',
  card: 'card',
  number: 'number',
  text: 'text',
  password: 'password',
  textarea: 'textarea',
  checkbox: 'checkbox',
  radio: 'radio',
};

export const keyBoardRus = `йцукенгшщзхъфывапролджэ\\ячсмитьбю.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ\ЯЧСМИТЬБЮ.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ/ЯЧСМИТЬБЮ;!"№;%:?*()_+`;
export const keyBoardEng = `qwertyuiop[]asdfghjkl;'\\zxcvbnm;./QWERTYUIOP[]ASDFGHJKL;'\ZXCVBNM;./QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>?!@#$%^&*()_+`;

export const navItems = [
  {
    name: 'Главная',
    link: 'home',
    roles: []
  },
  {
    name: 'Операции',
    link: 'operations',
    roles: []
  },
  {
    name: 'Техподдержка',
    link: 'support',
    roles: []
  },
  {
    name: 'Настройки',
    link: 'settings',
    roles: []
  },
  {
    name: 'Очередь',
    link: 'queue',
    roles: []
  }
]

export const defaultUrls = {
  kkmUrl: 'https://localhost:5893/Execute',
  mtsAgentUrl : 'http://localhost:4021',
  demoMtsAgentUrl: 'http://demo5.nabix.ru:5021'
}

export const operationAddressesArray = ['registrationAddress', 'residentalAddress', 'birthPlaceAddress'];

export const billTypeRu = {
  transferFioChangeCheck: 'Чек изменения ФИО', // добавить либо обновить операцию в 1с по id
  operationAddOrUpdate: 'Обновление статуса операции', // добавить либо обновить операцию в 1с по id
  testCheck: 'Тестовый чек', // печать тестового чека
  firstCheck: 'Предчек', // печать предчека
  secondCheck: 'Чек', // печать фискального чека
  rejectFirstCheck: 'Предчек отмены', // печать предчека возврата
  rejectSecondCheck: 'Чек отмены', // печать чека возврата
  rejectFirstCheckWithoutCommission: 'Предчек отмены без комиссии', // печать чека возврата без комиссии
  rejectSecondCheckWithoutCommission: 'Чек отмены без комисии', // печать чека возврата без комиссии
}
