import {Injectable} from '@angular/core';
import {KkmService} from "./kkm.service";
import {BillingVariablesService} from "./billing-variables.service";
import {BillingHelperService} from "./billing-helper.service";
import {take} from "rxjs/operators";
import {NotifyService} from "../../core/services/notify.service";
import {getErrorMessage} from "../../../kernel/helpers/data.helper";
import {BillingTypeEnum, ICreateBillRequest, ICreatePreBillRequest, IUserResponse} from "../../../kernel/ApiClient";
import {BillingService} from "./billing.service";
import {KkmQueueService} from "./kkm-queue.service";
import {isKkm} from "../../../kernel/helpers/user.helper";
import {UserState} from "../../../kernel/store/state/user.state";
import {BuilderCommandEnum} from "../../../kernel/enum/builder-command.enum";
import {BehaviorService} from "../../core/services/behavior.service";

@Injectable({
  providedIn: 'root',
})
export class BillingBuilderService {
  constructor(
    public kkm: KkmService,
    public kkmQueue: KkmQueueService,
    public variables: BillingVariablesService,
    public bill: BillingService,
    public helper: BillingHelperService,
    private notify: NotifyService,
    private behavior: BehaviorService
  ) {
  }

  get user(): IUserResponse {
    return UserState.user;
  }

  checkCashboxCountBeforeBilling(payload: ICreateBillRequest, prePayload?: ICreatePreBillRequest): void {
    if (isKkm(this.user?.organization)) {
      this.kkm.getListOfCashbox()
        .pipe(take(1))
        .subscribe(data => {
          if (!data || data.ListUnit?.length === 1) {
            this.helper.openBillPreviewDialog(payload, prePayload);
          } else {
            this.helper.openCashboxListDialog(data, payload, prePayload);
          }
        }, error => {
          this.notify.error(getErrorMessage(error));
        });
    } else {
      this.behavior.operationBuilderCommandStatus.next({
        type: BuilderCommandEnum.CreateBillQueueItem,
        model: { type: payload.type, operationId: payload.operationId }
      });
    }
  }
}
