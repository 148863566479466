export const kkmOperationType = {
  RegisterCheck: 'RegisterCheck', // Печать чека
  OpenShift: 'OpenShift', // Открыть смену
  CloseShift: 'CloseShift', // Закрыть смену
  XReport: 'XReport', // Печать X-отчета
  ZReport: 'ZReport', // Печать Z-отчета
  DepositingCash: 'DepositingCash', // Печать чека внесения/изъятия
  OpenCashDrawer: 'OpenCashDrawer', // Открыть денежный ящик
  GetLineLength: 'GetLineLength', // Получить ширину строки чека в символах
  GetDataCheck: 'GetDataCheck', // Получить данные чека из ФН по номеру чека
  GetDataKKT: 'GetDataKKT', // Получить текущее состояние ККТ
  KkmRegOfd: 'KkmRegOfd', // Регистрация и фискализация ККМ
  PayByPaymentCard: 'PayByPaymentCard', // Оплатить платежной картой
  ReturnPaymentByPaymentCard: 'ReturnPaymentByPaymentCard', // Вернуть платеж по платежной карте
  CancelPaymentByPaymentCard: 'CancelPaymentByPaymentCard', // Отменить платеж по платежной карте
  AuthorisationByPaymentCard: 'AuthorisationByPaymentCard', // Блокировка суммы на счете карты
  AuthConfirmationByPaymentCard: 'AuthConfirmationByPaymentCard', // Списать блокированную сумму со счета карты
  CancelAuthorisationByPaymentCard: 'CancelAuthorisationByPaymentCard', // Разблокировать сумму на счете карты
  EmergencyReversal: 'EmergencyReversal', // Аварийная отмена операции (Метод отменяет последнюю транзакцию)
  Settlement: 'Settlement', // Закрыть смену по картам
  TerminalReport: 'TerminalReport', // Получить итоги дня по картам
  TransactionDetails: 'TransactionDetails', // Получить копию слип-чека
  PrintSlipOnTerminal: 'PrintSlipOnTerminal', // Есть ли печать квитанций на терминале?
  GetRezult: 'GetRezult', // Асинхронная проверка выполнения команды
  List: 'List', // Получение списка устройств
  GetServerData: 'GetServerData', // Данные ПК
}

export interface IUnitItemResponse {
  NumDevice: number;
  IdDevice: string;
  OnOff: boolean;
  Active: boolean;
  TypeDevice: string;
  IdTypeDevice: string;
  Firmware_Version: string;
  IP: string;
  Port: string;
  NameDevice: string;
  UnitName: string;
  KktNumber: string;
  INN: string;
  RegNumber: string;
  FnNumber: string;
  InnOfd: string;
  NameOrganization: string;
  AddressSettle: string;
  TaxVariant: string;
  AddDate: Date;
  BSOMode: boolean;
  ServiceMode: boolean;
  OFD_Error: string;
  OFD_NumErrorDoc: number;
  OFD_DateErrorDoc: Date;
  FN_DateEnd: Date;
  FN_MemOverflowl: boolean;
  FN_IsFiscal: boolean;
  PaperOver: boolean;
  FFDVersion: string;
  FFDVersionFN: string;
  FFDVersionKKT: string;
  IsRegisterCheck: boolean;
}

export interface ListUnit {
  NumDevice: number;
  IdDevice: string;
  OnOff: boolean;
  Active: boolean;
  TypeDevice: string;
  IdTypeDevice: string;
  Firmware_Version: string;
  IP: string;
  Port: string;
  NameDevice: string;
  UnitName: string;
  KktNumber: string;
  INN: string;
  RegNumber: string;
  FnNumber: string;
  InnOfd: string;
  NameOrganization: string;
  AddressSettle: string;
  TaxVariant: string;
  AddDate: Date;
  BSOMode: boolean;
  ServiceMode: boolean;
  OFD_Error: string;
  OFD_NumErrorDoc: number;
  OFD_DateErrorDoc: Date;
  FN_DateEnd: Date;
  FN_MemOverflowl: boolean;
  FN_IsFiscal: boolean;
  PaperOver: boolean;
  FFDVersion: string;
  FFDVersionFN: string;
  FFDVersionKKT: string;
  IsRegisterCheck: boolean;
}

export interface IUnitListResponse {
  ListUnit: IUnitItemResponse[];
  Command: string;
  Error: string;
  Warning: string;
  Message: string;
  Status: number;
  IdCommand: string;
}

export interface IKkmResponseData {
  Command: string
  Error: string
  IdCommand: string
  Status: number
  Warning: string
}

export interface ICashboxPrefer {
  NameDevice: string,
  TypeDevice: string,
  IdTypeDevice: string,
  NumDevice: number,
  IsRegisterCheck: boolean,
}
