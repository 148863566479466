<ng-container *ngIf="isObject">
  <ng-container *ngFor="let control of fields; let controlIndex">
    <ng-container *ngIf="!control.isHidden && control.type !== 'object' && control.type !== 'array'">
      <app-input
        *ngIf="control.type === 'string' || control.type === 'Phone'"
        [inputType]="control.inputType"
        [placeholder]="control.placeholder"
        [label]="control.inputLabel"
        [name]="control.controlName"
        [wrapperClass]="''"
        [selectData]="control.selectedData" [selectDataAll]="control.selectedData"
        [hintBottom]="control.placeholder"
        [parentForm]="formGroup"
        [controlInvalid]="true">
      </app-input>
      <!--<app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                 [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                 [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-radio>
      <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                    [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                    [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-checkbox>-->
    </ng-container>
  </ng-container>
</ng-container>


<ng-container *ngIf="isArray">
  <ng-container *ngFor="let fg of formArray">
    <ng-container *ngFor="let control of fields; let controlIndex">
      <ng-container *ngIf="!control.isHidden && control.type !== 'object' && control.type !== 'array'">
        <app-input
          *ngIf="control.type === 'string' || control.type === 'Phone'"
          [inputType]="control.inputType"
          [placeholder]="control.placeholder"
          [label]="control.inputLabel"
          [name]="control.controlName"
          [wrapperClass]="''"
          [selectData]="control.selectedData" [selectDataAll]="control.selectedData"
          [hintBottom]="control.placeholder"
          [parentForm]="fg"
          [controlInvalid]="true">
        </app-input>
        <!--<app-radio *ngIf="control.isRadio && !control.isHide" [label]="control.label" [controlInvalid]="item.isSubmitted"
                   [inline]="false" [name]="item.id + '_' + control.name" [readonly]="control.readonly"
                   [selectData]="control.selectData || []" [hint]="control.hint" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-radio>
        <app-checkbox *ngIf="control.isCheckbox && !control.isHide" [label]="control.label"
                      [name]="item.id + '_' + control.name" [wrapperClass]="control.wrapperClass" [readonly]="control.readonly"
                      [hint]="control.hint" [controlInvalid]="item.isSubmitted" [parentForm]="operationInfoArray[operationIndex].formGroup"></app-checkbox>-->
      </ng-container>
    </ng-container>
    <div class="operation-array-button-wrapper">
      <button
        class="button unfilled decoration-under"
        (click)="builder.mtDynamic.removeDynamicFormArrayItem(fg.get('arrayId').value)"
        [style.padding-right.px]="0"
      >Удалить</button>
    </div>
  </ng-container>
  <div class="operation-array-button-wrapper">
    <button
      class="button unfilled decoration-under"
      (click)="builder.mtDynamic.addDynamicFormArrayItem(fields, typeArrayId, path)"
      [style.padding-right.px]="0"
    >Добавить</button>
  </div>
</ng-container>

<!--<p class="opacity-0-75 f-12 mb-5 color-main" *ngIf="isAnyOfFields.length > 0">Необходима заполнить хотя бы одно из следующих полей:</p>
<ng-container *ngFor="let control of isAnyOfFields; let controlIndex">
  <p class="mt-0 mb-3 opacity-0-75 f-12 color-main"> - {{control.inputLabel}}</p>
</ng-container>-->
