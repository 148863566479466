import {Component} from '@angular/core';
import {OperationBuilderService} from "../../services/operation-builder.service";
import {IOperationDataHelper} from "../../../../kernel/models/common.models";
import {FormGroup} from "@angular/forms";
import {BillingBuilderService} from "../../../billing/services/billing-builder.service";
import {Guid} from "guid-typescript";
import {
  BillingTypeEnum,
  CreatePreBillArrayRequest,
  CreatePreBillItemRequest, CreatePreBillRequest,
  ICreatePreBillArrayRequest, ICreatePreBillItemRequest
} from "../../../../kernel/ApiClient";
import {PipeTransformService} from "../../../core/services/pipe-transform.service";

@Component({
  selector: 'app-operation-create-step',
  templateUrl: './operation-create-step.component.html',
  styleUrls: ['./operation-create-step.component.scss', '../mts-operation/mts-operation.component.scss']
})
export class OperationCreateStepComponent {
  infoOpen = true;

  constructor(
    private builder: OperationBuilderService,
    private billing: BillingBuilderService,
    private pipeTransformService: PipeTransformService
  ) { }
  get currentStep(): number {
    return this.builder.variables.currentStep;
  }
  get thirdSubmitted(): boolean {
    return this.builder.variables.thirdSubmitted;
  }
  get info(): IOperationDataHelper[] {
    return this.builder.variables.info;
  }
  get operationHelperGroup(): FormGroup {
    return this.builder.variables.operationHelperGroup;
  }

  createPreCheck(): void {
    let array: ICreatePreBillArrayRequest[] = [];
    this.info.map(x => {
      let subArray: ICreatePreBillItemRequest[] = [];
      x.array.map(y => {
          y.items.map(item => {
            subArray.push({
              title: item.title,
              value: this.pipeTransformService.convertToValue(item)
            })
          });
          if (x.id === `mainInfo` && this.builder.variables.commissions[this.builder.variables.operationIndex]) {
            this.builder.variables.commissions[this.builder.variables.operationIndex].items.map(com => {
              subArray.push({
                title: com.title,
                value: this.pipeTransformService.convertComToValue(com)
              })
            });
            subArray.push({
              title: 'Итого к оплате',
              value: `${this.pipeTransformService.convertToCashString(this.builder.variables.commissionTotal ?? 0)} ${this.builder.variables.commissionCurrency}`
            })
          }
      });
      array.push({
        title: x.title,
        items: subArray as CreatePreBillItemRequest[]
      })
    });
    let prePayload = {
      operationType: this.builder.variables.operationType,
      numDevice: 0,
      isFromQueue: false,
      array: array as CreatePreBillArrayRequest[]
    };
    this.billing.checkCashboxCountBeforeBilling({
      numDevice: 0,
      type: BillingTypeEnum.PreBill,
      isPreview: true,
      isFromQueue: true,
      operationId: Guid.raw(),
      preBill: prePayload as CreatePreBillRequest
    }, prePayload);
  }
}
